import { put, call, takeEvery, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import {
  STATS_FETCH_SUCCEDED,
  STATS_FETCH_FAILED,
  FETCH_STATS,
  SUPERADMIN
} from '../config';
import config from '../config';

const getToken = (state: ReduxState) => state.auth?.token || '';
const checkIsSuperAdmin = (state: ReduxState) => state.auth?.decoded.role === SUPERADMIN;

const fetchStats = async (token:string) => {
  const axiosOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response: AxiosResponse<statsResponse> = await axios.get(
    `${config.AWS_API_SITE}/client-stats`,
    axiosOptions,
  );

  const iframeDashboard: AxiosResponse = await axios.get(`
    ${config.AWS_API_SITE}/metabase-dashboard`, 
    axiosOptions
  );

  const stats = {
		stockLeft: response.data.stock,
    redirectionsThisMonth: response.data.total_redirects,
    users: response.data.total_users,
    statsIframeUrl: iframeDashboard.data.statsIframeUrl,
    ordersIframeUrl: iframeDashboard.data.ordersIframeUrl,
    logisticIframeUrl: iframeDashboard.data.logisticIframeUrl
  };

  return stats;
};

function* fetchStatsSaga() {
  yield takeEvery(FETCH_STATS, function* () {
		// @ts-ignore: Unreachable code error
    const token = yield select(getToken);
    // @ts-ignore: Unreachable code error
    const isSuperAdmin = yield select(checkIsSuperAdmin);
    try {
      if(isSuperAdmin) throw new Error('Cannot fetch in SUPERADMIN account');
			// @ts-ignore: Unreachable code error
      const stats = yield call(fetchStats,token);
      yield put({ type: STATS_FETCH_SUCCEDED, payload: stats });
    } catch (e: any) {
      yield put({ type: STATS_FETCH_FAILED, payload: e.message });
    }
  });
}

export default fetchStatsSaga;
