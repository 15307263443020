import { takeLatest, put, call, takeEvery, delay } from 'redux-saga/effects';
import { USER_LOGIN_SUCCESS } from 'react-admin';
import { TOKEN_LOADED, GET_NEW_GOOGLE_TOKEN, REFRESH_TOKEN, TOKEN_REFRESH_RATE } from '../config';
import axios from 'axios';
import authProvider from '../authProvider';
import jwt from 'jsonwebtoken';
import config from '../config';

function getOldToken() {
    return localStorage.getItem('token') || '';
}

function decodeToken(token: string) {
    return jwt.decode(token) || null;
}

function checkJWTExp(exp: number) {
    if (Date.now() >= exp * 1000) {
        return false;
    }
    return true;
}

async function getTokenFromGoogle(FirebaseToken: string) {
    const response = await axios.post(
        `${config.CLOUD_FUNCTIONS_REST_API}/authentication-getToken`,
        {
            token: FirebaseToken,
        },
        );
  return response.data.token;
}
// 
function setCookie(cname: string, cvalue: string, exdays: number) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/*
FIRES ONCE THE FIRST TOKEN IS OBTAINED
IF THERE IS AN OLD AWS TOKEN (THE SECOND TOKEN TO GET) CHECKS IF IT'S EXPIRED
IF IT IS EXPIRED, IT GETS ANOTHER ONE FROM GOOGLE
IF IT IS NOT EXPIRED, DECODES THE OLD ONE
THIS FLOW AVOIDS FETCHING TOKENS ON EVERY PAGE REFRESH, MAKING THE APP FASTER AND REDUCING GOOGLE CLOUD USAGE
*/

// token refresh loop
function* tokenRefresher(): any {
  yield delay(TOKEN_REFRESH_RATE);
  yield put({ type: REFRESH_TOKEN });
  yield call(tokenRefresher);
}

export default function* loginSaga() {
  // fires when user logs in
  yield takeLatest(USER_LOGIN_SUCCESS, function* () {
		// @ts-ignore: Unreachable code error
    const oldToken = yield call(getOldToken);
    if (!oldToken) {
      yield put({ type: GET_NEW_GOOGLE_TOKEN });
    } else {
			// @ts-ignore: Unreachable code error
      const oldTokenData = yield call(decodeToken, oldToken);
      if (!checkJWTExp(oldTokenData.exp)) {
        yield put({ type: GET_NEW_GOOGLE_TOKEN });
      } else {
        yield put({ type: TOKEN_LOADED, payload: oldToken });
      }
    }
    yield call(tokenRefresher);
  });

  // fires every refresh
  yield takeEvery(REFRESH_TOKEN, function* () {
    yield put({ type: GET_NEW_GOOGLE_TOKEN });
  });

  // call to get new token
  yield takeEvery(GET_NEW_GOOGLE_TOKEN, function* () {
		// @ts-ignore: Unreachable code error
    const FirebaseToken = yield call(authProvider.getJWTToken);
		// @ts-ignore: Unreachable code error
    const token = yield call(getTokenFromGoogle, FirebaseToken);
    yield put({ type: TOKEN_LOADED, payload: token });
  });
}
