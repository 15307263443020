// VENDOR
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const exporterFunction = async (records: any[], fieldsToExport: string[], columnsName: string[], fileName: string) => {
    const recordsForExport = records.map(record => {
        const postForExport: Record<string, any> = {}

        fieldsToExport.forEach(item => {
            postForExport[item] = record[item] || '-'
        })

        return postForExport;
    });

    jsonExport(recordsForExport, {
        headers: fieldsToExport, // order fields in the export
        rename: columnsName // rename de fields in the export
    }, (err, csv) => {
        downloadCSV(csv, fileName); // download as 'posts.csv` file
    });
}

export default exporterFunction