import { takeEvery, put } from 'redux-saga/effects';
import {
  UNREGISTER_RESOURCE
} from 'react-admin';
import { 
  FLUSH_HOME,
  FLUSH_STATS,
  FLUSH_TOKEN
} from '../config';


export default function* logoutSaga() {
  yield takeEvery(UNREGISTER_RESOURCE, function* () {
    yield put({type:FLUSH_TOKEN});
    yield put({type:FLUSH_HOME});
    yield put({type:FLUSH_STATS});
  });
}
