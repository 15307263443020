import React from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import StyledChip from '../../../components/StyledChip';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  edit: {
    marginLeft: theme.spacing(1),
  },
}));

interface IStockFieldProps {
  stock: number;
  callback?: () => void;
}
const StockField: React.FC<IStockFieldProps> = ({
  stock,
  callback,
}) => {
  const classes = useStyles();
  const chipColor =
    stock === 0 ? 'secondary' : stock < 15 ? 'tertiary' : 'quaternary';
  return (
    <div className={classes.root}>
      <StyledChip color={chipColor} label={stock} />
      {callback && <IconButton color="primary" children={<AddIcon fontSize="small" />} className={classes.edit} onClick={callback} />}
    </div>
  );
};

export default StockField;
