import { useTranslate } from 'react-admin';
import { BGsES, BGsPT } from '../../../img/onboardingAssets/backgrounds/index'; // BG stands for "BackGround"
import emailMobileES from '../../../img/onboardingAssets/mobile.png';
import emailMobilePT from '../../../img/onboardingAssets/mobile-PT.png';
import vickyWizard from '../../../img/onboardingAssets/vicky-wizard.png';
import developmentQR from '../../../img/onboardingAssets/qr-bridge-dev.png';
import stagingQR from '../../../img/onboardingAssets/qr-bridge-stg.png';
import productionQR from '../../../img/onboardingAssets/qr-bridge-prod.png';
import him from '../../../img/onboardingAssets/el.png';
import her from '../../../img/onboardingAssets/ella.png';

function useImagesPreloading () {
  const translate = useTranslate();
  const lang = translate('onboarding.language');
  const spanish = (lang === "ES" || lang === "EN");
  const BGs = spanish ? BGsES : BGsPT;
  const emailMobile = spanish ? emailMobileES : emailMobilePT;
  let imageForQR = productionQR;
  if (process.env.REACT_APP_NODE_ENV === 'development') imageForQR = developmentQR;
  if (process.env.REACT_APP_NODE_ENV === 'staging') imageForQR = stagingQR;

  return {
    vickyWizard: new Image().src = vickyWizard,
    cloudsAndArrowBG: new Image().src = BGs.cloudsAndArrowBG,
    dashboardBG: new Image().src = BGs.dashboardBG,
    cartBG: new Image().src = BGs.cartBG,
    cartSnippetBG: new Image().src = BGs.cartSnippetBG,
    cartProductBG: new Image().src = BGs.cartProductBG,
    cartGreetingBG: new Image().src = BGs.cartGreetingBG,
    cartSuccessBG: new Image().src = BGs.cartSuccessBG,
    gmailBG: new Image().src = BGs.gmailBG,
    tiendanubeSalesBG: new Image().src = BGs.tiendanubeSalesBG,
    tiendanubeOrderBG: new Image().src = BGs.tiendanubeOrderBG,
    tiendanubePrintSDBG: new Image().src = BGs.tiendanubePrintSDBG,
    tiendanubePrintSingleSDBG: new Image().src = BGs.tiendanubePrintSingleSDBG,
    dashboardPrintQRBG: new Image().src = BGs.dashboardPrintQRBG,
    emailMobile: new Image().src = emailMobile,
    imageForQR: new Image().src = imageForQR,
    him: new Image().src = him,
    her: new Image().src = her
  }
};

export default useImagesPreloading;
