export default `
<P>O
o acesso e a utilização deste serviço digital por um usuário (doravante designado por "usuário(s)") indica
adiante referido como "usuário(s)") indica a plena aceitação do
estes Termos e Condições e a Política de Privacidade
aplicável ao acesso, interacção com cartões e site fornecido por Storydots (¨storydots
por Storydots (¨storydots.app¨ ou o ¨Site¨) e dos serviços
aí oferecidos (os "Serviços").</P>
<P>
</P>
<P>O
a utilização do cartão digital é gratuita e gratuita e foi entregue em suporte físico
uma loja física após a compra.</P>
<P>O
o usuário garante e declara ter mais de 18 anos de idade, e/ou se aplicável,
que tenha a autorização expressa dos seus pais ou tutores
utilizar o serviço e que é competente para compreender e aceitar sem
aceitar sem reservas as obrigações, afirmações, representações e garantias estabelecidas nas presentes
e garantias estabelecidas nos presentes Termos e Condições, e que tem o direito
tem a capacidade legal para celebrar contratos. Por
o sítio declina a responsabilidade no caso de as informações fornecidas a este respeito serem falsas.
as informações fornecidas a este respeito.</P>
<P>
</P>
<P >Em
Consequentemente, todas as visitas e todos os contratos e transacções efectuados através do
transacções realizadas através do Sítio, bem como os seus efeitos legais, devem ser
efeitos jurídicos, serão regidos por estas regras e estão sujeitos aos
a legislação aplicável na República Argentina.</P>
<P
</P>
<P>
<B>1. Aplicação.</B></P>
<P>
Estes Termos Gerais e
Os Termos e Condições Gerais aplicar-se-ão e serão considerados como fazendo parte de todos e cada um dos
parte de cada um dos actos e contratos que são executados ou celebrados por meio dos Serviços
por meio dos Serviços oferecidos através do Site.</P>
<P>
<B>QUALQUER PESSOA QUE NÃO O FAÇA
ACEITAR ESTES TERMOS E CONDIÇÕES GERAIS, QUE SÃO DE NATUREZA OBRIGATÓRIA E VINCULATIVA, DEVEM
OBRIGATÓRIO E VINCULATIVO, DEVE ABSTER-SE DE ACEDER, INTERAGIR COM OU UTILIZAR O SÍTIO E/OU
INTERAGIR COM OU UTILIZAR O SÍTIO E/OU OS SERVIÇOS.</B></P>
<P>
</P>
<P>
<B>2.Aceitação</B></P>
<P>
Antes da utilização
cada utilização dos Serviços oferecidos no Site, o usuário deverá
ler, compreender e aceitar todas as condições estabelecidas no General
Termos e Condições Gerais.</P>
<P>
Se o usuário utiliza o
Site significa que ele/ela aceitou plenamente as condições
estabelecidos nos Termos e Condições, concordando em cumpri-los.
cumpri-las expressamente.</P>
<P>
</P>
<P>
<B>3.Cartão de felicitações
virtual</B></P>
<P>
Os usuários podem comprar
1 (um) cartão de oferta ou etiqueta de oferta StoryDots grátis com cada
cada produto ao fazer compras nas lojas que participam no Serviço. O
Cartão virtual dá ao usuário adquirente a possibilidade de registar uma saudação virtual
uma saudação virtual com o seu telemóvel de 60 segundos de duração e que estará online durante 30 dias
que estará online durante 30 dias (o "Prazo") na sua versão gratuita.
versão gratuita, podendo contratar melhorias dentro do Site.</P>
<P>
</P>
<P>
Em caso de expiração,
perda, roubo, ou destruição do Cartão Virtual, o usuário deverá
não terá quaisquer direitos a quaisquer serviços e/ou a qualquer reembolso e/ou a
qualquer reembolso e/ou para fazer valer qualquer reclamação contra qualquer Comerciante Afiliado e/ou StoryDots).
Comerciante Afiliado e/ou contra StoryDots).</P>
<P>
</P>
<P>
<B>4.Registo e utilização</B></P>
<P>
É obrigatório completar o
informação exigida no Sítio em todos os seus campos com dados válidos, a fim de
dados válidos para o acesso e utilização dos Serviços.</P>
<P>
StoryDots reserva-se o direito de
direito de cancelar o vídeo que se verifique ser contrário à lei e bom
às leis e aos bons costumes, sem que isso dê origem a qualquer direito a
direito a qualquer reclamação ou compensação, seja ela qual for. O usuário será
responsável por todas as acções realizadas pelo usuário no Sítio.</P>
<P
</P>
<P>
<B>5. Privacidade de
Informação</B></P>
<P>
A fim de utilizar os Serviços
oferecidos pela StoryDots, os usuários devem fornecer certos Dados Pessoais.
Dados pessoais. Informações e dados pessoais que voluntariamente lhe são fornecidos
fornecer-nos voluntariamente através da utilização do Sítio ou Serviço,
é processado e armazenado em servidores. Os Dados Pessoais serão
mantidos estritamente confidenciais e tratados de acordo com as disposições aplicáveis
sob a responsabilidade de StoryDots.
Além disso, o fornecimento de dados para além dos necessários para a utilização do
a utilização do Serviço, implicará a sua autorização para que lhe possamos enviar
para lhe enviar boletins, boletins informativos ou correspondência sobre a StoryDots institucional, comercial e promocional
notícias institucionais, comerciais e promocionais da StoryDots ou da empresa
parceiro comercial onde recebeu o cartão, com o objectivo de
fornecer-lhe um serviço personalizado, a fim de melhorar o actual
actual canal de comunicação com o usuário. Os dados também podem ser utilizados
podem também ser utilizados para produzir estatísticas e estudos de mercado.

</P>
<P>
</P>
<P>
O usuário pode exercer
direitos de acesso, rectificação e/ou eliminação dos seus Dados Pessoais em conformidade com o
Dados pessoais em conformidade com as disposições da Lei 25.326. De acordo com a
estabelecido na Provisão 10/2008 DNPDP: &quot;A DIRECTORA NACIONAL
DIRECÇÃO NACIONAL PARA A PROTECÇÃO DE DADOS PESSOAIS, Órgão de Controlo da Lei
A Lei Nº 25.326, é responsável pelo tratamento das queixas e reivindicações que são
queixas e reclamações apresentadas em relação ao não cumprimento dos regulamentos sobre o
regulamentos de protecção de dados pessoais.</P>
<P>
Da mesma forma, se o usuário
deseja modificar as suas informações pessoais, pode fazê-lo utilizando
qualquer um dos seguintes canais de comunicação:</P>
<P>
</P>
<P>
• Ao enviar um e-mail para
e-mail para storydots.app@gmail.com</P>
<P>
</P>
<P>
Cada usuário concorda
consente que as informações referentes aos seus Dados Pessoais
podem ser ASSIGNADAS E/OU TRANSFERIDAS a empresas controladas por
StoryDots, empresas controladoras de Storydots, ligadas à StoryDots,
NACIONAL OU EXTERNO, para fins publicitários, promocionais e/ou de marketing.
fins de marketing.</P>
<P>
</P>
<P>
<B>6. Privacidade de
vídeos</B></P>
<P>
</P>
<P>
Cada usuário concorda
acordo para que o vídeo carregado seja associado a um URL único.
Qualquer pessoa com acesso a esse URL (directamente ou através do URL atribuído)
o QR atribuído) terá acesso ao vídeo. Por conseguinte, os vídeos são públicos
acesso público, embora não serão listados em nenhum site parceiro de Storydots nem serão
com Storydots nem serão fornecidos de forma agregada por Storydots.
Histórias. Existe a possibilidade de que agentes externos possam obter estes URLs e vídeos
estes URLs e vídeos usando a força bruta. O usuário compreende e
concorda com o nível de privacidade que estes URLs oferecem.</P>
<P>
</P>
<P>
<B>7. Modificações ao
Termos e Condições Gerais.</B></P>
<P>
StoryDots pode modificar
os Termos e Condições em qualquer altura, através da publicação dos termos modificados
os termos modificados no Site.</P>
<P>
</P>
<P>
<B>8. Violações do
Sistema ou bases de dados.</B></P>
<P>
Nenhuma acção ou utilização de qualquer
acção ou utilização de qualquer dispositivo, software, ou outros meios para interferir com o
interferir com as actividades, operações, ou bases de dados pertencentes à StoryDots.
bases de dados pertencentes a StoryDots. Qualquer intrusão, tentativa ou
actividade que viole ou seja contrária às leis sobre direitos de propriedade intelectual
as leis de propriedade intelectual e/ou as proibições estabelecidas nos presentes Termos e Condições
estes Termos e Condições sujeitarão a parte responsável a um processo legal apropriado
às acções judiciais relevantes, e às sanções previstas na lei aplicável
quadro legal aplicável, bem como responsável pela indemnização dos danos causados.
por qualquer dano causado.</P>
<P>
</P>
<P>
<B>9. Propriedade Intelectual.</B></P>
<P>
O conteúdo do
StoryDots Services, assim como a informação, imagens, dados, gráficos
informação, imagens, dados, texto gráfico, fotografias, clips de áudio e vídeo, ícones, logótipos
clipes de áudio e vídeo, ícones, logótipos, logotipos, redes, programas, bases de dados, ficheiros que permitem a
ficheiros que permitem ao usuário aceder e operar o Sítio,
são propriedade da StoryDots e são protegidos por leis de direitos de autor e tratados internacionais
leis e tratados internacionais sobre direitos de autor, marcas, patentes, marcas registadas, patentes e desenhos e modelos industriais,
modelos e desenhos industriais. A utilização e reprodução impróprias
no todo ou em parte de tal conteúdo é estritamente proibido
sem a expressa autorização prévia por escrito da StoryDots é estritamente proibida.
StoryDots.</P>
<P>
</P>
<P>
<B>10. Outros sítios web.</B></P>
<P>
StoryDots não terá
responsabilidade pela informação fornecida por outros sítios Web e respectivas consequências.
websites e as suas consequências. StoryDots não
garantir, garantir ou endossar de qualquer forma o acesso, a utilização, a informação ou
informação ou conteúdo de qualquer outro website ou portal quando tal acesso é de ou
quando esse acesso é feito de ou para este, quer esse acesso seja feito por link, banner
acesso é através de um link, banner, botão ou qualquer dispositivo disponível no
qualquer dispositivo disponível na rede.</P>
<P>
O Site pode conter
links para outros sítios da web que não indicam que são propriedade ou operados pela StoryDots.
operado por StoryDots. Porque o StoryDots não tem controlo sobre esses sítios, o StoryDots NÃO deve ser
tais sítios, NÃO será responsável ou responsável pelo conteúdo, materiais,
acções e/ou serviços prestados por esses sítios, nem por quaisquer danos ou perdas
perda causada pela utilização de tais sítios, quer directa quer indirectamente.
causados directa ou indirectamente. A presença de links para outros
sítios não implica parceria, relação, aprovação, endosso, ou aprovação
de StoryDots para tais sites e o seu conteúdo.</P>
<P>
</P>
<P>
<B>11. Sanções. Suspensão
de operações.</B></P>
<P>
Sem prejuízo de qualquer outro
medidas, StoryDots irá, a seu exclusivo critério, remover permanentemente o vídeo e
o vídeo e reserva-se o direito de tomar qualquer acção que considere apropriada contra o
(a) violar ou não cumprir qualquer lei aplicável ou qualquer disposição dos Termos e
lei aplicável, ou qualquer uma das disposições dos Termos e Condições e outras StoryDots
Termos e Condições e outras políticas da StoryDots; (b) não cumpre com os seus
obrigações como usuário; (c) o usuário compromete-se, a critério exclusivo da StoryDots, em
(c) envolver-se, à discrição exclusiva da StoryDots, deliberadamente ou de forma fraudulenta
conduta ou actos fraudulentos;. No caso da suspensão de um vídeo, todos os
Os serviços contratados pelo usuário serão removidos do sistema,
sem qualquer reivindicação a favor do usuário.</P>
<P>
</P>
<P>
<B>12. Falhas do sistema.</B></P>
<P>
StoryDots não garante o
ausência de quaisquer dificuldades técnicas ou falhas nos sistemas ou na Internet.
sistemas ou a Internet. StoryDots não garante a continuação ou ininterrupta
acesso e utilização ininterruptos ou ininterruptos do seu site. O sistema pode
eventualmente não estar disponível devido a dificuldades técnicas ou Internet
Falhas na Internet, ou quaisquer outras circunstâncias fora do controlo da StoryDots.
StoryDots; em tais casos, serão feitos esforços razoáveis para o restaurar o mais rapidamente possível.
o mais rapidamente possível. Além disso, o StoryDots não garante a
ausência de erros ou omissões no Site.</P>
<P>
</P>
<P>
<B>13. Taxas de facturação.</B></P>
<P>
Interacção do usuário
no Site e o registo da saudação padrão é gratuito. Ao comprar
upgrade, o usuário deverá pagar o preço publicado e aceite.
A factura a ser emitida será a do consumidor final, sendo esta a única
a única modalidade de facturação do modelo de compra on-line.
StoryDots reserva-se o direito de tomar as medidas judiciais e extrajudiciais que considere necessárias
medidas extrajudiciais que considere adequadas para obter o pagamento do montante devido.
montante devido.</P>
<P>
</P>
<P>
<B>14. Separabilidade.</B></P>
<P>
No caso de qualquer um dos
as disposições dos presentes Termos e Condições Gerais serão declaradas nulas ou inaplicáveis por qualquer
Os Termos e Condições Gerais devem ser declarados inválidos ou inaplicáveis por qualquer tribunal competente.
a validade dos restantes termos e condições não será afectada.
não será afectado.</P>
<P>
</P>
<P>
<B>15. Legislação e
Jurisdição.</B></P>
<P>
Qualquer litígio resultante de ou relacionado com
que surjam relacionadas com o objecto dos presentes Termos e Condições Gerais serão
Os termos e condições serão resolvidos pelos tribunais competentes da capital federal
Capital Federal, com exclusão de qualquer outra jurisdição que possa ser aplicável a qualquer outra
qualquer outra jurisdição que possa ser aplicável por qualquer outro motivo, e ao abrigo do
Lei argentina, sem aplicação das suas disposições de conflito de leis.
conflito de leis.</P>
<P
</P>`