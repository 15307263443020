import React, { useState } from 'react';
import { Dialog, makeStyles, Button } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        padding: 25,
        paddingTop: 15,
        paddingBottom: 15,
        maxWidth: 450
    },
    title: {
        fontFamily: 'Manrope',
        fontWeight: 600,
        fontSize: '1.2rem',
        marginBottom: 15
    },
    content: {
        marginBottom: 15
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        gap: "2px"
    },
}));


interface IConfirmDiscardDialogProps {
    open: boolean;
    onClose: () => void;
    callback: () => void;
}

const ConfirmDiscardDialog: React.FC<IConfirmDiscardDialogProps> = ({
    open,
    onClose,
    callback
}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const onConfirm = () => {
        onClose();
        callback();
    }
    return (
        <Dialog onClose={onClose} open={open}>
            <div className={classes.root}>
                <div className={classes.title}>
                    {translate('client_form.confirm_discard.title')}
                </div>
                <div className={classes.content}>
                    <div>
                        {translate('client_form.confirm_discard.question')}
                    </div>
                    <div>
                        {translate('client_form.confirm_discard.consequences')}
                    </div>
                </div>
                <div className={classes.buttons}>
                    <Button color='primary' size="small" style={{padding: "3px", border: "1px solid #B2BEB5"}} onClick={onConfirm}>{translate('client_form.confirm_discard.discard')}</Button>
                    <Button color='primary' size="small" style={{padding: "3px", border: "1px solid #B2BEB5"}} onClick={onClose}>{translate('client_form.confirm_discard.continue')}</Button>
                </div>
            </div>
        </Dialog>
    );
};

export default ConfirmDiscardDialog;

export const useConfirmDiscardDialog = () => {
    const [confirmDiscardIsOpen, setConfirmDiscardIsOpen] = useState(false);
    return {
      confirmDiscardIsOpen,
      confirmDiscardOnClick: () => setConfirmDiscardIsOpen(true),
      confirmDiscardOnClose: () => setConfirmDiscardIsOpen(false)
    };
  };
  