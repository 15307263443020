import React from 'react';
import { Typography, makeStyles, CircularProgress } from '@material-ui/core';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  text: {
    fontSize: '18px',
    right: '122px',
    color: '#6242E8',
    fontWeight: 700,
  },
  progress: {
    color: '#6242E8',
  },
  progressTrack: {
    color: '#6242E8',
    opacity: '20%',
  },
}));

type Props = {
  totalStock: number;
  leftStock: number;
  planName: string;
};

export default function RemainingGreetings({ planName, totalStock, leftStock }: Props) {
  const styles = useStyles();

  return (
    <div style={{ display: 'inline-flex', position: 'relative' }}>
      <CircularProgress
        value={
          planName == "Unlimited" ? 100
          : leftStock === 0
            ? 0
            : leftStock > totalStock
            ? 100
            : Math.floor((leftStock * 100) / totalStock)
        }
        variant="static"
        size="75px"
        className={styles.progress}
      />
      <div className={styles.container}>
        <CircularProgress
          value={100}
          variant="static"
          size="75px"
          className={styles.progressTrack}
        />
      </div>

      <div className={styles.container}>
        {planName == "Unlimited" ? 
          <AllInclusiveIcon /> : 
          <Typography
            className={styles.text}
          >{`${leftStock}/${totalStock}`}</Typography>
        }
      </div>
    </div>
  );
}
