/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { ListProps } from 'react-admin';
import { useTranslate } from 'ra-core';
import PageTitle from '../../components/PageTitle';
import PageContainer from '../../components/PageContainer';
import {
  makeStyles,
  Box,
  Typography,
  Paper,
  Divider,
  Badge,
  Chip,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import useFetch from 'Utils/hooks/useFetch';
import axios from 'axios';
import config from 'config';
import SpinnerWrapper from '../../components/SpinnerWrapper';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import CircularProgress from '@material-ui/core/CircularProgress';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import InfoCard from './InfoCard';
import {
  CancelledPlanMessage,
  LeaveFreePlanMessage,
  ResumePlanMessage,
  UnavailblePlanMessage,
} from './Message/Messages';
import RemainingGreetings from './RemainingGreetings';
import PlanCard from './PlanCard';
import CancelSubDialog from './CancelSubDialog';
import OutOfStockDialog from './OutOfStockDialog';
import SubscriptionStatusComponent from './SubscriptionStatus';
import useSendGTMEvent from 'Utils/hooks/useSendGTMEvent';
import { SUPERADMIN } from 'config';

const useListStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
  },
  billingContainer: {
    margin: '1em 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  billingSwitcher: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '1em',
    padding: '7px',
    borderRadius: '50px',
    width: '10em',
    '& div': {
      marginLeft: '5px',
    },
  },
  sliderContainer: {
    marginTop: '2em',
    marginBottom: '3em',
    padding: '0 4em',
    '& p': {
      textAlign: 'center',
      marginBottom: '1em',
    },
  },
  planCard: {
    margin: '0 1em',
  },
  messageContainer: {
    margin: '1em 0',
  },
  plansContainer: {
    display: 'inline-block',
    height: '46em',
    width: '100%',
  },
  plansGrid: {
    display: 'flex',
    justifyContent: 'center',
    height: '27em',
    marginTop: '3em',
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
  },
  progressBarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}));

interface SubscriptionDataI {
  hasSubscription: boolean;
  stockLeft: number;
  stockTotal: number;
  isTrial: boolean;
  activeUntil?: Date | string;
  amount?: number;
  currency?: string;
  frecuency?: string;
  planID?: number;
  planName?: string;
  planIsActive?: boolean;
  status?: SubscriptionStatus;
}

interface PlanI {
  amount: number;
  currency: string;
  frecuency: string;
  link: string;
  name: string;
  id: number;
  greetingsAmount: number;
  features: string[];
}

// NAMES HAVE MUST BE IN UPPER CASE
export enum PLANS_NAMES {
  INITIAL = 'INITIAL',
  ADVANCED = 'ADVANCED',
  UNLIMITED = 'UNLIMITED',
}

export enum PLAN_STATUS {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  PAUSED = 'PAUSED',
  PENDING = 'PENDING',
  CREATED = 'CREATED',
}

// Each array value should be a key in translation/.../plan/features
const FeaturesToShowByPlan = {
  [PLANS_NAMES.INITIAL]: ['stats', 'fme'],
  [PLANS_NAMES.ADVANCED]: ['customTag', 'customLogo', 'customPalette'],
  [PLANS_NAMES.UNLIMITED]: [
    'customApp',
    'unlimitedStock',
    'onDemandSupport',
    'uniqueContentAccess',
  ],
};

interface PlanData {
  [PLANS_NAMES.INITIAL]: any | undefined;
  [PLANS_NAMES.ADVANCED]: any | undefined;
  [PLANS_NAMES.UNLIMITED]: any | undefined;
}

const Subscriptions: React.FC<ListProps> = () => {
  const translate = useTranslate();
  const classes = useListStyles();
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionDataI>();
  const [yearlyPlans, setYearlyPlans] = useState(false);
  const [fetchedPlansByPrice, setFetchedPlansByPrice] = useState<PlanI[]>();
  const [selectedPlans, setSelectedPlans] = useState<PlanData | undefined>(
    undefined,
  );
  const [storeCountry, setStoreCountry] = useState('');
  const [storeOrigin, setStoreOrigin] = useState(0);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openStockDialog, setOpenStockDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clientLoading, setClientLoading] = useState(false);
  //@ts-ignore
  const token = useSelector((state) => state.auth?.token);
  //@ts-ignore
  const clientId = useSelector((state) => state.auth?.decoded?.clientID);

  const isSuperAdmin = useSelector(
    (state: ReduxState) => state.auth?.decoded.role === SUPERADMIN,
  );

  const fetchClient = async () => {
    setClientLoading(true);
    const result = await axios.get(`${config.AWS_API_SITE}/client`, {
      params: { clientID: clientId },
      headers: { Authorization: `Bearer ${token}` },
    });
    setStoreCountry(result.data.store_country);
    setStoreOrigin(result.data.origin);
    setClientLoading(false);
    const storedFeatures = localStorage.getItem('f') || null;
    if (storedFeatures === null && result.data.features === null) return;
    if (storedFeatures !== JSON.stringify(result.data.features)) {
      localStorage.setItem(
        'f',
        result.data.features ? JSON.stringify(result.data.features) : '',
        );
        localStorage.setItem('r', 'true');
      window.location.reload();
    }
  };

  const handleClientData = async (clientData: any) => {
    setStoreCountry(clientData.store_country);
    setStoreOrigin(clientData.origin);
    setClientLoading(false);
    const storedFeatures = localStorage.getItem('f') || null;
    if (storedFeatures === null && clientData.features === null) return;
    if (storedFeatures !== JSON.stringify(clientData.features)) {
      localStorage.setItem(
        'f',
        clientData.features ? JSON.stringify(clientData.features) : '',
      );
      localStorage.setItem('r', 'true');
      window.location.reload();
    }
  };
  
    const handleSubscriptionData = async (subscription: SubscriptionDataI) => {
      setSubscriptionData(subscription);
      subscription.stockLeft === 0 && setOpenStockDialog(true);
      const fetchPlansResult = await fetchPlans();
      setFetchedPlansByPrice(fetchPlansResult);
      setSelectedPlans(getBasePlanData(fetchPlansResult, subscription));
      setLoading(false);
    };
  
  const pollFetchClientSubscription = async () => {
    let attempts = 0;
    setClientLoading(true);
    try {
      const clientInfo = await fetchClientSubscriptionData()
      if (clientInfo && clientInfo.subscription) {
        handleSubscriptionData(clientInfo.subscription);
        const executePoll = async (attempts: number, maxAttempts: number) => {
          attempts++;
          if (clientInfo.subscription.status === 'active') {
            handleClientData(clientInfo);
          } else if (clientInfo.subscription.status === 'pending') {
            setTimeout(executePoll, 5000);
          } else if (attempts === maxAttempts) {
            return new Error('Exceeded max attempts');
          }
        };
        return await executePoll(attempts, 10);
      } else {
        console.error('No subscription found');
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const fetchClientSubscriptionData = async () => {
    let attempts = 0;
    const executePoll = async (attempts: number, maxAttempts: number) => {
      attempts++;
      try {
        const result = await axios.get(`${config.AWS_API_SITE}/client`, {
          params: { clientID: clientId },
          headers: { Authorization: `Bearer ${token}` },
        });
        if (result?.data?.subscription) {
          return result.data;
        } else if (attempts < maxAttempts) {
          setTimeout(await executePoll(attempts, 10), 5000);
        } else {
          return false;
        }
      } catch (err) {
        console.warn(err);
        setTimeout(await executePoll(attempts, 10), 5000);
      }
    };
    return await executePoll(attempts, 10);
  };

  useSendGTMEvent('subscriptions');
  useEffect(() => {
    if (token && !isSuperAdmin) fetchClient();
    token && pollFetchClientSubscription();
  }, [token]);

  useEffect(() => {
    const fetchPlansData = async () => {
      const result = await fetchPlans();
      setFetchedPlansByPrice(result);
      setSelectedPlans(getBasePlanData(result));
    };
    setSelectedPlans(undefined);
    token && fetchPlansData();
  }, [yearlyPlans]);

  const getBasePlanData = (
    plansData: any,
    getSubscriptionData?: SubscriptionDataI,
  ) => {
    let selectedPlansData: PlanData = {
      [PLANS_NAMES.INITIAL]: undefined,
      [PLANS_NAMES.ADVANCED]: undefined,
      [PLANS_NAMES.UNLIMITED]: undefined,
    };
    plansData.forEach((element: any) => {
      Object.values(PLANS_NAMES).find((planName) => {
        if (element.name.toUpperCase().includes(planName)) {
          if (
            !selectedPlansData[planName] ||
            element.id === getSubscriptionData?.planID
          ) {
            selectedPlansData[planName] = element;
            return true;
          }
        }
      });
    });
    return selectedPlansData;
  };

  async function fetchPlans(
    order_by: 'default' | 'greeting_amount' = 'default',
  ): Promise<PlanI[]> {
    const plansData = await axios.get(
      `${config.AWS_API_SITE}/plans?frecuency=${
        yearlyPlans ? 'years' : 'months'
      }&order_by=${order_by}&platform=tiendanube`,
      { headers: { Authorization: `Bearer ${token}` } },
    );
    return plansData.data;
  }

  const getGreetingsoptionsByPlanName = (planName: PLANS_NAMES) => {
    let result: number[] = [];
    fetchedPlansByPrice?.forEach((elm) => {
      if (elm.name.toUpperCase().includes(planName)) {
        result.push(elm.greetingsAmount);
      }
    });
    return result;
  };

  const onChangePlanGreetingsAmount = (planName: string, newAmount: number) => {
    const newSelectedPlan = fetchedPlansByPrice?.find(
      (fetchedPlan) =>
        fetchedPlan.name.includes(planName) &&
        fetchedPlan.greetingsAmount === newAmount,
    );
    function getKeyByName(obj: any, name: any) {
      for (const key in obj) {
        if (obj[key].name === name) {
          return key;
        }
      }
      return '';
    }
    const selectedPlanName: string = getKeyByName(selectedPlans, planName);
    //@ts-ignore
    setSelectedPlans({
      ...selectedPlans,
      [selectedPlanName]: newSelectedPlan!,
    });
  };

  const handleClickOpen = () => {
    setOpenCancelDialog(true);
  };

  const handleClose = () => {
    setOpenCancelDialog(false);
  };

  const handleCancelSubscription = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${config.AWS_API_SITE}/mp-remove-subscription`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      // sets subscription as cancelled locally while its the backend is processing the cancel event
      //@ts-ignore
      setSubscriptionData({ ...subscriptionData, status: 'cancelled' });
    } catch (e) {
      console.log('Error', e);
    } finally {
      setLoading(false);
    }
  };

  const handleResumeSubscription = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${config.AWS_API_SITE}/mp-resume-subscription`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (e) {
      console.log('Error', e);
    } finally {
      window.location.reload();
    }
  };

  const handleBuyClick = async (id: number) => {
    setLoading(true);
    try {
      const { data: generatedSubscription } = await axios.post(
        `${config.AWS_API_SITE}/mp-generate-subscription`,
        { planId: id },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      window.location.replace(generatedSubscription.link);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const getPlansKeyByValue = (value: PLANS_NAMES) => {
    return Object.keys(PLANS_NAMES)[Object.values(PLANS_NAMES).indexOf(value)];
  };

  if (clientLoading) {
    return (
      <PageContainer className={classes.loadingContainer}>
        <div className={classes.progressBarContainer}>
          <CircularProgress />
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      {/* Only for TN stores outside of AR render pricing plans page in iframe */}
      {storeCountry !== 'AR' && storeOrigin === 1 ? (
        <>
          <div
            style={{
              overflow: 'hidden',
              paddingTop: '56.25%',
              position: 'relative',
              height: '100vh',
            }}
          >
            <iframe
              title="tn-pricing-plans"
              src={`${process.env.REACT_APP_FRONT_URL}/pricing/plans?country=${storeCountry}`}
              width="1500"
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                border: '0',
                width: '100%',
                height: '100%',
              }}
            ></iframe>
          </div>
        </>
      ) : (
        <>
          <CancelSubDialog
            open={openCancelDialog}
            onClose={handleClose}
            onContinue={handleCancelSubscription}
          />
          <OutOfStockDialog
            open={openStockDialog}
            onClose={() => setOpenStockDialog(false)}
          />

          <div className={classes.headerContainer}>
            <PageTitle
              title={translate('subscriptions_plans.title')}
              icon={<MoneyIcon />}
              subtitle={translate('subscriptions_plans.subtitle')}
            />
            <InfoCard
              text={translate('subscriptions_plans.currentPlan').toUpperCase()}
              isLoading={loading}
              value={
                <Typography color="primary">
                  {subscriptionData?.planName ||
                    (subscriptionData?.isTrial
                      ? translate(
                          'subscriptions_plans.container.plan.freePlan',
                        ).toUpperCase()
                      : translate(
                          'subscriptions_plans.container.plan.noPlan',
                        ).toUpperCase())}
                </Typography>
              }
            />
            <InfoCard
              text={translate(
                'subscriptions_plans.remainingGreetings',
              ).toUpperCase()}
              isLoading={loading}
              value={
                subscriptionData?.planName == 'Unlimited' ? (
                  <AllInclusiveIcon />
                ) : (
                  <p style={{ fontWeight: 800, fontSize: '25px' }}>
                    {subscriptionData?.stockLeft || 0}
                  </p>
                )
              }
            />
          </div>

          <SpinnerWrapper isLoading={loading}>
            {subscriptionData?.hasSubscription &&
              subscriptionData?.status === 'paused' && (
                <ResumePlanMessage onResumeClick={handleResumeSubscription} />
              )}

            {subscriptionData?.hasSubscription &&
              !subscriptionData?.isTrial && (
                <div style={{ display: 'flex' }}>
                  <Paper style={{ width: '100%' }}>
                    <Typography style={{ padding: '1em', fontSize: '14px' }}>
                      {translate(
                        'subscriptions_plans.container.title',
                      ).toUpperCase()}
                    </Typography>
                    <Divider />

                    <div style={{ padding: '1em' }}>
                      <Typography style={{ fontSize: '24px' }}>
                        {subscriptionData?.planName}
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '0.7em',
                        }}
                      >
                        <Typography style={{ marginRight: '7px' }}>
                          {translate('subscriptions_plans.subscriptionStatus')}:
                        </Typography>
                        <SubscriptionStatusComponent
                          status={subscriptionData?.status || 'cancelled'}
                        />
                      </div>
                    </div>
                    <div style={{ padding: '1em' }}>
                      {subscriptionData?.isTrial && <LeaveFreePlanMessage />}

                      {!subscriptionData?.planIsActive && (
                        <UnavailblePlanMessage />
                      )}

                      {subscriptionData?.status === 'cancelled' && (
                        <CancelledPlanMessage />
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        padding: '1em',
                        alignItems: 'center',
                      }}
                    >
                      <RemainingGreetings
                        totalStock={subscriptionData?.stockTotal}
                        leftStock={subscriptionData?.stockLeft}
                        planName={subscriptionData?.planName || ''}
                      />
                      <Typography style={{ marginLeft: '15px' }}>
                        {translate('subscriptions_plans.remainingGreetings')}
                      </Typography>
                    </div>
                  </Paper>
                </div>
              )}

            <Paper style={{ marginTop: '1em' }}>
              <div className={classes.plansContainer}>
                <Typography style={{ padding: '1em', fontSize: '14px' }}>
                  {translate(
                    'subscriptions_plans.container.planContainerTitle',
                  ).toUpperCase()}
                </Typography>
                <Divider />
                {/* <div className={classes.billingContainer}>
              <Typography>Facturacion</Typography>
              <Box className={classes.billingSwitcher} boxShadow={4}>
                <Chip
                  label={translate('subscriptions_plans.container.monthly')}
                  onClick={() => setYearlyPlans(false)}
                  variant={!yearlyPlans ? 'default' : 'outlined'}
                  color={!yearlyPlans ? 'primary' : 'default'}
                />
                <Badge badgeContent={'-30%'} color="secondary">
                  <Chip
                    label={translate('subscriptions_plans.container.yearly')}
                    onClick={() => setYearlyPlans(true)}
                    variant={yearlyPlans ? 'default' : 'outlined'}
                    color={yearlyPlans ? 'primary' : 'default'}
                  />
                </Badge>
              </Box>
            </div>*/}

                <div className={classes.plansGrid}>
                  {selectedPlans &&
                    selectedPlans[PLANS_NAMES.INITIAL] &&
                    Object.values(PLANS_NAMES).map((plan, index, array) => (
                      <PlanCard
                        onBuy={() => handleBuyClick(selectedPlans[plan]!.id)}
                        billingType={yearlyPlans ? 'yearly' : 'monthly'}
                        title={translate(
                          `subscriptions_plans.container.plan.planNames.${getPlansKeyByValue(
                            plan,
                          )}`,
                        )}
                        subtitle={translate(
                          `subscriptions_plans.container.plan.includes.${getPlansKeyByValue(
                            plan,
                          )}`,
                        )}
                        price={selectedPlans[plan]!.amount}
                        features={FeaturesToShowByPlan[plan]}
                        selected={
                          selectedPlans[plan]!.id ===
                            subscriptionData?.planID &&
                          subscriptionData?.status !== 'cancelled'
                        }
                        selectedPlanCategory={subscriptionData?.planName
                          ?.toUpperCase()
                          .includes(plan)}
                        selectedOption={selectedPlans[plan]!.greetingsAmount}
                        greetingsOptions={getGreetingsoptionsByPlanName(plan)}
                        onChange={onChangePlanGreetingsAmount}
                        onCancel={handleClickOpen}
                        color={
                          index === array.length - 1 ? 'secondary' : 'primary'
                        }
                        className={classes.planCard}
                        key={selectedPlans[plan]!.id}
                        planName={selectedPlans[plan]?.name}
                      />
                    ))}
                </div>
                <Typography
                  style={{
                    marginTop: '130px',
                    fontSize: '14px',
                    textAlign: 'center',
                  }}
                >
                  {translate('subscriptions_plans.container.bottomText.start')}
                  <strong>
                    {translate('subscriptions_plans.container.bottomText.bold')}
                  </strong>
                  {translate('subscriptions_plans.container.bottomText.end')}
                  <strong>
                    {translate(
                      'subscriptions_plans.container.bottomText.endBold',
                    )}
                  </strong>
                </Typography>
              </div>
            </Paper>
          </SpinnerWrapper>
        </>
      )}
    </PageContainer>
  );
};

export default Subscriptions;
export type { SubscriptionDataI };
