import React, { useEffect } from "react";
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 25,
    padding: 4,
    margin: "10px 5px 0 0",
    display: "flex",
    alignItems: "center",
    background: "#6242E8",
    borderRadius: 15,
  },
  paragraph: {
    color: "white",
    margin: "0px 5px",
    fontSize: 12,
    lineHeight: "17px",
    whiteSpace: "nowrap"
  },
  button: {
    width: 15,
    minWidth: 15,
    height: 15,
    padding: 5,
    borderRadius: "50%",
    background: "#947AFF",
    "& span": {
      position: "absolute",
      top: '4%',
      left: '32%',
      color: "white",
    }
  }
}))

interface rangeProps {
  from: string
  to: string
}

interface IProps {
  filterText: string
  removeFilter: Function
  removeString: string
  setFilterApplied: React.Dispatch<React.SetStateAction<null | rangeProps>>
}

const FilterAppliedLabel = ({filterText, removeFilter, removeString, setFilterApplied}:IProps) => {
  const classes = useStyles();

  const setRemoveFilter = () => {
    setFilterApplied(null);
    removeFilter(removeString);
  }

  return (
    <div className={classes.root}>
      <p className={classes.paragraph}>
        {filterText}
      </p>
      <IconButton className={classes.button} onClick={setRemoveFilter} component='span' >
        <ClearIcon viewBox="0 0 40 40" />
      </IconButton>
    </div>
  )
}

export default FilterAppliedLabel;
