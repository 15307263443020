import React, { ReactElement } from 'react';
import { Card, CardContent, makeStyles } from '@material-ui/core';
import StatCardAction, { IStatCardActionProps } from './StatCardAction';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  content: {
    overflow: 'visible',
    padding: '12px 16px',
    flexGrow: 1,
  },
  innerFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerFlex: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    textAlign: 'right',
    width: '100%'
  },
  // I had to use tag names because classnames here have a random name after compilation
  primaryColor: {
    '& section': {
      '& > div': {
        backgroundColor: theme.palette.bg.main,
        '& svg': {
          fill: theme.palette.primary.main,
        },
      },
      '& > article': {
        '& > header': {
          color: theme.palette.common.black,
        },
      },
    },
  },
  secondaryColor: {
    '& section': {
      '& > div': {
        backgroundColor: theme.palette.bg.main,
        '& svg': {
          fill: theme.palette.primary.main,
        },
      },
      '& > article': {
        '& > header': {
          color: theme.palette.common.black,
        },
      },
    },
  },
  tertiaryColor: {
    '& section': {
      '& > div': {
        backgroundColor: theme.palette.bg.main,
        '& svg': {
          fill: theme.palette.primary.main,
        },
      },
      '& > article': {
        '& > header': {
          color: theme.palette.common.black,
        },
      },
    },
  },
  quaternaryColor: {
    '& section': {
      '& > div': {
        backgroundColor: theme.palette.quaternary.light,
        '& svg': {
          fill: theme.palette.quaternary.main,
        },
      },
      '& > article': {
        '& > header': {
          color: theme.palette.common.black,
        },
      },
    },
  },
  icon: {
    // I could have used a Paper component from Mui with "elevation" to make a shadow, but I wouldn't have as much control over
    // nested tags as I have now.
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12)',
    width: 64,
    height: 64,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    position: 'absolute',
    top: -16,
    '& svg': {
      width: 32,
      height: 32,
    },
  },
  title: {
    fontSize: '0.8125rem',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
    lineHeight: '1em',
    marginTop: theme.spacing(1),
  },
  number: {
    fontSize: '2.5rem',
    lineHeight: '2.4rem',
    fontFamily: 'Manrope',
    fontWeight: 600,
  },
}));

type colors = 'primary'|'secondary'|'tertiary'|'quaternary';

interface IStatCardProps {
  number?: any;
  title: string;
  icon?: ReactElement;
  color?: colors;
  action?: IStatCardActionProps;
  isLoading?: boolean;
}

const StatCard: React.FC<IStatCardProps> = ({
  number,
  title,
  icon,
  color,
  action,
  isLoading
}) => {
  const classes = useStyles();
  //@ts-ignore
  const colorClass = classes[`${color}Color`] || classes.primaryColor;
  return (
    <Card className={classes.root}>
      <CardContent className={`${classes.content} ${colorClass}`}>
        <section className={classes.innerFlex}>
          <div className={classes.icon}>{icon}</div>
          <article className={classes.headerFlex}>
            {!isLoading ? <div className={classes.number}>{number}</div> :
            <Skeleton variant="text" height="2.4rem" width="6rem"/>
            }
            <header className={classes.title}>{title}</header>
          </article>
        </section>
      </CardContent>
      {action && (
        <StatCardAction title={action.title} callback={action.callback} />
      )}
    </Card>
  );
};

export default StatCard;
