// VENDOR
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageContainer from '../../components/PageContainer';
import SpinnerWrapper from '../../components/SpinnerWrapper';
import { FETCH_STATS, LOGISTIC } from '../../config';
import { useCheckFeature } from 'customHooks';
import IframeResizer from 'iframe-resizer-react';
import useSendGTMEvent from "Utils/hooks/useSendGTMEvent";

const Stats: React.FC = (props) => {
  const dispatch = useDispatch();
  //@ts-ignore
  const statsState = useSelector((state) => state.stats);
  //@ts-ignore
  const token = useSelector((state) => state.auth?.token);
  //@ts-ignore
  const role = useSelector((state) => state.auth?.decoded.role);

  // Checks if the user has the feature that allows him to use this resource
  useCheckFeature('stats');

  useSendGTMEvent("stats")
  React.useEffect(() => {
    if (token) {
      dispatch({ type: FETCH_STATS });
    }
  }, [dispatch, token]);  

  return (
    <PageContainer>
      <SpinnerWrapper isLoading={statsState.statsLoading}>
        <div style={{ height: '100vh' }}>
          <IframeResizer
            src={role === LOGISTIC ? statsState.data?.logisticIframeUrl : statsState.data?.statsIframeUrl}
            frameBorder={0}
            width={'100%'}
            // style={{marginTop: 20}}
            title="metabase-dashboard"
          />
        </div>
      </SpinnerWrapper>
    </PageContainer>
  );
};

export default Stats;


