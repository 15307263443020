import React, { useState } from 'react';
import {
  Dialog,
  makeStyles,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useTranslate, useNotify } from 'react-admin';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from '../../../config';

const regenerateStock = async (id: number | undefined, token: string) => {
  return await axios.post(
    `${config.AWS_API_SITE}/regenerateStock`,
    {
      clientId: id,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: 25,
    paddingTop: 15,
    paddingBottom: 15,
    maxWidth: 450,
  },
  title: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: '1.2rem',
    marginBottom: 15,
  },
  content: {
    marginBottom: 15,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
}));

interface IConfirmRegenerateDialogProps {
  clientId: number | undefined;
  open: boolean;
  onClose: () => void;
}

const ConfirmRegenerateDialog: React.FC<IConfirmRegenerateDialogProps> = ({
  clientId,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const token = useSelector(
    (state: ReduxState) => state.auth && state.auth.token,
  );
  const notify = useNotify();
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackDialogOpened, setFeedbackDialogOpened] = useState(false);

  const onConfirm = async () => {
    setIsLoading(true);
    try {
      await regenerateStock(clientId, token);
      notify('clients.dialog.regenerate.success', 'info', { clientId });
      setFeedbackDialogOpened(true);
    } catch (error) {
      notify('clients.dialog.regenerate.error', 'error', { clientId });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };
  return (
    <>
      <Dialog onClose={onClose} open={open}>
        <div className={classes.root}>
          <div className={classes.title}>
            {translate('clients.dialog.regenerate.title')}
          </div>
          <div className={classes.content}>
            <div>{translate('clients.dialog.regenerate.description')}</div>
          </div>
          <div className={classes.buttons}>
            {isLoading && <CircularProgress size={'1.2rem'} />}
            <Button color="primary" onClick={onConfirm}>
              {translate('clients.dialog.regenerate.confirm')}
            </Button>
            <Button color="primary" onClick={onClose}>
              {translate('clients.dialog.regenerate.cancel')}
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={feedbackDialogOpened}
        onBackdropClick={() => setFeedbackDialogOpened(false)}
        onEscapeKeyDown={() => setFeedbackDialogOpened(false)}
      >
        <div className={classes.root}>
          <div className={classes.title}>
            {translate('clients.dialog.regenerate.feedback.title')}
          </div>
          <div className={classes.content}>
            <div>{translate('clients.dialog.regenerate.feedback.message')}</div>
          </div>
          <div className={classes.buttons}>
            <Button
              color="primary"
              onClick={() => setFeedbackDialogOpened(false)}
            >
              {translate('clients.dialog.regenerate.feedback.close')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmRegenerateDialog;

export const useConfirmRegenerateDialog = () => {
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  return {
    confirmDialogIsOpen,
    confirmDialogOnClick: () => setConfirmDialogIsOpen(true),
    confirmDialogOnClose: () => setConfirmDialogIsOpen(false),
  };
};
