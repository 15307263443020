import React from 'react';
import { makeStyles, Button, Box } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Subscription } from '../types';

const useStyles = makeStyles((theme) => ({
  status: {
    color: 'white',
    fontFamily: 'Manrope',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '0.9rem',
  },
  infoText: {
    color: 'white',
    fontFamily: 'Manrope',
    fontSize: '1rem',
  },
  button: {
    height: '2.5rem',
  },
  whiteButton: {
    color:'white'
  }
}));

interface ICancelledSubscriptionActionsProps {
  subscription?: Subscription;
  callback: () => void;
}

const CancelledSubscriptionActions: React.FC<ICancelledSubscriptionActionsProps> = ({
  subscription,
  callback,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <>
      <Box display="flex" flexGrow={1} alignItems="flex-end" flexWrap="wrap">
        <Box display="flex" flexDirection="column" marginRight="5rem">
          <div className={classes.status}>
            {translate('purchases.cancelled_subscription_action_card.title')}
          </div>
          <div className={classes.infoText}>
            {translate(
              'purchases.cancelled_subscription_action_card.subscription_cancelled',
              {
                subscription: translate(
                  `purchases.products.${subscription?.name}`,
                ),
              },
            )}
          </div>
          <div className={classes.infoText}>
            {translate(
              'purchases.cancelled_subscription_action_card.info',
              { amount: subscription?.tagsPerMonth },
            )}
          </div>
        </Box>
      </Box>
      <Box display="flex">
        <Button onClick={callback} className={`${classes.button} ${classes.whiteButton}`} variant="text">
          {translate(
            'purchases.cancelled_subscription_action_card.reactivate_button',
          )}
        </Button>
        <Button
          onClick={callback}
          className={classes.button}
          variant="contained"
        >
          {translate(
            'purchases.cancelled_subscription_action_card.see_plans_button',
          )}
        </Button>
      </Box>
    </>
  );
};

export default CancelledSubscriptionActions;
