import { TranslationMessages } from 'react-admin';
import portugueseMessages from '@blackbox-vision/ra-language-spanish';
//@ts-ignore
//import portugueseMessages from 'ra-language-portuguese'
import userTags from './userTags/es';
import termsAndConditions from './termsAndConditions/pt';
import GDPR from './GDPR/pt';
import storeTypes from './stores_types/pt';

const customPortugueseMessages: TranslationMessages = {
  ...portugueseMessages,
  platforms: {
    tiendanube: 'TiendaNube',
    shopify: 'Shopify',
    api: 'api',
    physical: 'Loja física',
    vtex: 'VTEX',
    nuvemshop: 'NuvemShop',
    magento: 'Magento',
    dispenser: "Ponto de Compra (Dispenser)"
  },
  GDPR: {
    title: 'Regulamento Geral de Protecção de Dados',
    content: GDPR,
  },
  go_to_store_button: "VÁ A MINHA LOJA",
  go_to_onboarding_button: "Veja o tutorial sobre como usar",
  terms_and_conditions: {
    title: 'Termos e Condições',
    content: termsAndConditions,
  },
  dashboard: {
    title: 'Início',
    subtitle: 'Bem-vindo ao seu painel de configuração do StoryDots!',
    stat_cards: {
      stock: {
        title: 'Restante estoque',
        footer_link: 'Pedido',
      },
      redirections: {
        title: 'Redirecções este mês',
        footer_link: 'Ver mais',
      },
      users: {
        title: 'Usuários',
        footer_link: 'Ver mais',
      },
      subtitle:
        'Navegar entre os usuários participantes nas saudações, seleccionando entre remetentes, receptores e rastreabilidade.',
      header_titles: {
        senders: 'Emissores',
        receivers: 'Receptores',
        tracking: 'Rastreamento',
      },
      table: {
        name: 'Nome',
        email: 'Email',
        user: 'Usuário',
        gender: 'Género',
        age: 'Idade',
        last_activity: 'Última actividade',
        first_activity: 'Primeira utilização',
        greetings_sent: 'Saudações enviadas',
        greetings_amount: 'Número de saudações',
        gifted_amount: 'Montante total entregue',
        received_amount: 'Montante total recebido',
        tags: 'Etiquetas',
        not_identified: 'Usuário não identificado',
      },
      tags: { ...userTags },
      admin_users: {
        table: {
          name: 'Nome',
          email: 'Email',
          role: 'Papel',
          clientID: 'Cliente',
        },
      },
    },
    clients: {
      title: 'Os clientes',
      subtitle: 'Lista de clientes Storydots.',
      search: 'Pesquisa',
      table: {
        name: 'Nome',
        type: 'Tipo',
        platform: 'Plataforma',
        url: 'URL',
        subscription: 'Assinatura',
        stock: 'Estoque',
        actions: {
          label: 'Acções',
          add: 'Estoque de carga',
          impersonate: 'Impersonalizar',
        },
        custom_app: 'Aplicação personalizada',
        custom_tag: 'Etiqueta personalizada',
        status: 'Estado',
      },
      popover: {
        editStock: 'Mudança de estoque',
        viewClient: 'Ver cliente',
        editClient: 'Editar',
      },
      dialog: {
        add: {
          title: 'Estoque de carga',
          helper: 'Seleccionar o número de etiquetas QR a carregar',
          tags_amount: 'Número de etiquetas',
          batch_name: 'Nome do lote',
          custom_template: 'Etiqueta do modelo',
          storydots_template: 'Template Storydots',
          client_template: 'Template do cliente',
          save_notification: 'Carregou %{amount} Tags QR para %{client}',
          webapp_url: 'URL do webapp',
          storydots_url: 'Genéricos Storydots',
          client_url: 'URL do cliente',
          buttons: {
            cancel: 'Cancelar',
            save: 'Estoque de carga',
          },
        },
        view: {
          title: 'Detalhes de contacto',
          representative: 'Representante',
          email: 'Email',
          phone: 'Telefone',
          url: 'Url',
          close: 'Fechar',
        },
        config: {
          tag: {
            title: 'Etiqueta personalizada',
            subtitle:
              'Para ativar esta opção, é necessário preencher os campos de personalização da etiqueta do cliente.',
          },
          app: {
            title: 'Apreciação personalizada',
            subtitle:
              'Para activar esta opção, é necessário preencher os campos de personalização da aplicação do cliente.',
          },
          confirm: 'Completar agora',
          close: 'Mais tarde',
        },
      },
    },
    tracking: {
      table: {
        sender_name: 'Nome do emissor',
        sender_email: 'E-mail do remetente',
        receiver_name: 'Nome do destinatário',
        receiver_email: 'Recepção de correio electrónico',
        date: 'Data',
        sender: 'Emissor',
        gift: 'Presente',
        receiver: 'Receptor',
        status: 'Estado',
      },
    },
    tags_table: {
      order: 'Número de encomenda',
      title_regular: 'Últimas encomendas',
      title_superadmin: 'Últimas etiquetas ',
      action: 'Ver mais',
      date: 'Data',
      customer: 'Comprador',
      video: 'Vídeo gravado',
    },
    users_table: {
      title: 'Usuários mais recentes',
      action: 'Ver mais',
      date: 'Data',
      customer: 'Nome',
      email: 'Email',
    },
    second_register: {
      title: 'Complete o seu registo',
      subtitle:
        'Para que possamos registá-lo como cliente da StoryDots, precisamos que nos fale um pouco mais sobre si e a sua loja.',
      form: {
        name: {
          label: 'O seu nome *',
          minLenght: 'O seu nome deve ter pelo menos 2 caracteres',
          required: 'Este campo é obrigatório',
        },
        phone: {
          label: 'O seu telefone',
        },
        shop_url: {
          label: 'URL da sua loja',
        },
        content: {
          label: 'Fale-nos mais sobre a sua loja',
        },
        submit: {
          label: 'Enviar',
        },
        server_error: 'Erro no servidor, tente novamente mais tarde',
      },
      done: {
        title: 'Completou a sua inscrição!',
        inputs: {
          name: 'Nome',
          phone: 'Telefone',
          url: 'URL',
        },
        p1: 'O seu painel de controlo será activado em breve. Este processo pode demorar até 48 horas de trabalho.',
        p2: 'Se cometeu um erro em algum dos dados introduzidos, ou se o seu painel de controlo ainda não estiver activado após o tempo estimado, escreva-nos por favor para ',
      },
    },
    submitError: 'Ocorreu um erro ao tentar salvar os dados.',
  },
  users: {
    title: 'Usuários',
    subtitle:
      'Navegar entre os usuários participantes nas saudações, seleccionando entre remetentes, receptores e rastreabilidade.',
    header_titles: {
      senders: 'Emissores',
      receivers: 'Receptores',
      tracking: 'Rastreamento',
    },
    table: {
      name: 'Nome',
      email: 'Email',
      user: 'Usuário',
      gender: 'Género',
      age: 'Idade',
      last_activity: 'Última actividade',
      first_activity: 'Primeira utilização',
      greetings_sent: 'Saudações enviadas',
      greetings_amount: 'Número de saudações',
      gifted_amount: 'Montante total entregue',
      received_amount: 'Montante total recebido',
      tags: 'Etiquetas',
      not_identified: 'Usuário não identificado',
      id_regular: 'N.º de encomenda',
      id_superadmin: '#ID',

      complete: 'Registado',
    },
    tags: { ...userTags },
  },
  clients: {
    title: 'Clientes',
    subtitle: 'Lista de clientes Storydots.',
    search: 'Pesquisa',
    action: 'Criar cliente',
    table: {
      name: 'Nome',
      type: 'Tipo',
      platform: 'Plataforma',
      url: 'URL',
      subscription: 'Assinatura',
      stock: 'Estoque',
      actions: {
        label: 'Acções',
        add: 'Carregar estoque',
        impersonate: 'Impersonalizar',
      },
      custom_app: 'Aplicação personalizada',
      custom_tag: 'Marca personalizada',
      status: 'Estado',
    },
    popover: {
      editStock: 'Modificar estoque',
      viewClient: 'Ver cliente',
      editClient: 'Editar',
      regenerateStock: 'Regenerar estoque',
      downloadQRPOP: "Descarga QR POP",
    },
    dialog: {
      add: {
        title: 'Carregar estoque',
        helper: 'Seleccionar o número de etiquetas QR a carregar',
        tags_amount: 'Número de etiquetas',
        batch_name: 'Nome de lote',
        custom_template: 'Etiqueta do modelo',
        storydots_template: 'Modelo de Storydots',
        client_template: 'Modelo do cliente',
        save_notification: 'Carregou %{amount} QR Etiquetas a %{client}',
        webapp_url: 'URL o webapp',
        storydots_url: 'Genéricos Storydots',
        client_url: 'URL do cliente',
        tags_error: 'O valor deve ser um número e superior a 0',
        generate_image_tag: 'Geração de imagem tag',
        buttons: {
          cancel: 'Cancelar',
          save: 'Carregar estoque',
        },
      },
      view: {
        title: 'Detalhes de contato',
        representative: 'Representante',
        email: 'Email',
        phone: 'Telefone',
        url: 'Url',
        close: 'Fechar',
      },
      regenerate: {
        title: 'Regeneração de estoque',
        description: 'Esta acção irá regenerar o actual estoque de etiquetas.',
        confirm: 'Confirme',
        cancel: 'Cancelar',
        fail: 'Erro de regeneração de estoque para identificação do cliente %{clientId}',
        success:
          'O estoque está a ser regenerado para a identificação do cliente %{clientId}',
        feedback: {
          title: 'Regeneração de estoque',
          message:
            'Estoque atua para este cliente esta a ser recalculado,  estará pronto dentro de alguns minutos.',
          close: 'Fechar',
        },
      },
      config: {
        tag: {
          title: 'Etiqueta personalizada',
          subtitle:
            'Para activar esta opção, é necessário preencher os campos de personalização da etiqueta do cliente.',
        },
        app: {
          title: 'Apreciação personalizada',
          subtitle:
            'Para activar esta opção, é necessário preencher os campos de personalização da aplicação do cliente.',
        },
        confirm: 'Completar agora',
        close: 'Mais tarde no ano',
      },
    },
  },
  client_form: {
    create_title: 'Criar cliente',
    edit_title: 'Editar cliente: %{client}',
    create_subtitle:
      'Para criar um novo cliente, preencha os seguintes campos e clique em "guardar".',
    edit_subtitle:
      'Para editar um cliente, modificar os seguintes campos e clicar em "guardar".',
    confirm_discard: {
      title: 'Descarte de modificações',
      question: 'Tem a certeza de que quer descartar as modificações?',
      consequences: 'Uma vez trocados não podera recuperá-los.',
      discard: 'Alterações de descarte',
      continue: 'Continuar a edição',
    },
    form: {
      shop_data: 'Detalhes da loja',
      name: 'Nome',
      type: 'Tipo de loja',
      type_select: {
        online: 'Loja online',
        onsite: 'Loja física',
      },
      custom_app_url: 'URL de aplicação personalizada',
      custom_app_url_placeholder:
        'https://su_app_url_personalizado.storydots.app',
      custom_app_url_helperText:
        'https://su_app_url_personalizado.storydots.app',
      url: 'URL',
      contact_data: 'Detalhes de contacto',
      email: 'Email',
      email_helper:
        'Este será o endereço de correio electrónico utilizado para iniciar a sessão',
      phone: 'Telefone',
      platform: 'Plataforma',
      tag_custom: 'Personalizar a etiqueta QR normal - para a loja',
      sender_tag_custom: 'Personalizar a etiqueta física QR - para o cliente',
      upload_image: 'Carregar imagem',
      upload_image_helper: 'Recomendamos o formato svg ou png transparente.',
      upload_image_error:
        'Formato inválido, só são suportados ficheiros PNG ou JPG/JPEG.',
      upload_image_size_error:
        'O tamanho da imagem deve ter pelo menos 800px de largura e altura.',
      upload_video: 'Carregar vídeo',
      upload_video_error:
        'Formato inválido, apenas são aceites os formatos MP4 e OGG.',
      upload_video_size_error:
        'O tamanho do vídeo excede o limite de 100mb e 30 segundos.',
      default_greeting: 'Saudação por defeito',
      video: 'Seleccione um ficheiro de vídeo',
      own_greeting: 'Use a saudação adequada',
      sd_greeting: 'Use a saudação StoryDots',
      tag_design: 'Desenho da etiqueta',
      invalid_image: 'Imagem inválida',
      webapp_custom: 'Personalize a sua experiência',
      redirection_url: 'Redireccionar URL',
      redirection_url_access: 'Disponível com plano ilimitado',
      sender_redirect_url: 'URL de redirecionamento para o criador',
      redirection_time: 'Tempo de redireccionamento',
      dark_mode: 'Modo escuro',
      hide_product: "Ocultar produto",
      seconds: 'Segundos',
      color_palett: 'Paleta de cores',
      fme: 'Filtros, Molduras e Efeitos',
      urlDescription: 'Digite a URL da sua loja',
      shopNameDescription: 'Digite o nome da sua loja',
      nameDescription: 'Digite seu nome',
      phoneDescription: 'Digite seu número de telefone',
      typeDescription: 'Selecione o tipo de loja',
      emailDescription: 'Digite seu email',
      platformDescription: 'Escolha qual plataforma você usa',
      customTagSwitch: 'Etiqueta personalizada',
      customAppSwitch: 'Experiência de aplicação personalizada',
      customTagSwitchDescription: 'Ativar ou desativar este recurso',
      customAppSwitchDescription: 'Ativar ou desativar este recurso',
      tag_design_description:
        'Digite sua tag personalizada, preferencialmente no formato png ou svg. Ajuste as medidas ao seu gosto abaixo.',
      logoDescription:
        'Insira o logotipo da sua loja, ele será exibido na experiência.',
      colorPaletteDescription:
        'Selecione a paleta de cores a ser usada na experiência. Você pode visualizá-la no carrossel abaixo.',
      redirection_url_description:
        'Insira a url para a qual o cliente e o criador da saudação serão redirecionados uma vez que a experiência esteja completa. Por exemplo, você poderia redirecioná-los de volta à sua loja para ver outros itens relacionados.',
      custom_app_url_description:
        "Digite um URL personalizado, por exemplo: 'https://url_do_seu_aplicativo_personalizado.storydots.app'",
      redirection_time_description:
        'Digite, em segundos, o tempo que leva para o cliente ser redirecionado para sua url de redirecionamento.',
      dark_mode_description: 'Ativar ou desativar o modo escuro.',
      hide_product_description: "Ocultar o produto de saudação virtual da lista de produtos da loja.",
      storeTypeTitle: "Categoria",
      storeTypeDescription: "Sua categoria de loja",
      category: "Categoria"
    },
    store_types: {...storeTypes}
  },
  tracking: {
    table: {
      sender_name: 'Nome do emissor',
      sender_email: 'E-mail do remetente',
      receiver_name: 'Nome do destinatário',
      receiver_email: 'Recepção de e-mail',
      date: 'Data',
      sender: 'Emissor',
      gift: 'Presente',
      receiver: 'Receptor',
      status: 'Estado',
    },
  },
  receivers: {
    title: 'Receptores',
  },
  tags: {
    title_superadmin: 'Etiquetas QR',
    title_regular: 'Encomendas',
    subtitle: 'Resumo histórico das etiquetas emitidas pela sua loja.',
    action_card: {
      title: 'Restante estoque',
      button: 'Comprar',
    },
    emptyPage: 'Nenhuma tag QR foi usada até agora.',
    purge_button: 'Purga',
    table: {
      order: 'Número de encomenda',
      date: 'Data',
      customer: 'Comprador',
      video_recorded: 'Gravação',
      tag_received: 'Digitalização',
      sender_opened: 'Emissor aberto',
      replied: 'Resposta',
      views: 'Vistas',
      redirections: 'Redirecciona',
      code: 'Código',
      printed: 'Impresso',
      tagDownload: 'TagQR',
    },
    copied: 'Código copiado para prancheta',
    dialog: {
      view: {
        title: 'Detalhes do código',
        client: 'Cliente',
        order: 'encomenda',
        id: 'ID da etiqueta',
        price: 'valor de compra',
        link: 'Tag link',
        code: 'código',
        sender: 'comprador',
        receiver: 'Receptor',
        date: 'Emissão da etiqueta QR',
        recording: 'gravação de saudação',
        recording_received: 'recepção de saudação',
        response: 'Resposta',
        views: 'Reproduções',
        redirections: 'Redirecções',
        close: 'Fechar',
      },
      purge: {
        title: 'Código de purga',
        subtitle:
          'Está prestes a purgar o código %{code}. O que é que quer expurgar?',
        input_subtitle: 'Inserir o código e o recurso que pretende purgar',
        code: 'Código',
        options: {
          recording_reply: 'Saudações e resposta',
          reply: 'Apenas a resposta',
          recording: 'Apenas a saudação',
        },
        cancel: 'Cancelar',
        confirm: 'Confirme',
        blankCodes: 'Por favor insira um ou mais códigos',
        singlePurgeNotification: 'Código purgado com sucesso',
        bulkPurgeNotification: 'Códigos purgados com sucesso',
        detail: 'Detalhes',
        purgeFailed:
          'Alguma coisa correu mal, por favor verifique o(s) seu(s) código(s).',
      },
      disable: {
        title: 'Desativar código',
        subtitle:
          'Estás seguro de que deseas desactivar el código %{code}?.',
        cancel: 'Cancelar',
        confirm: 'Confirme',
        successNotification: 'Código desativado com sucesso',
       alreadyDisabledNotification: 'Código já foi desativado',
        failedNotification: 'Alguma coisa correu mal, por favor verifique o seu código.',
      },
      resendEmail: {
        title: 'Reenviar e-mail',
        subtitle:
          'Tem certeza de que deseja reenviar o e-mail de gravação de código %{code}?',
        cancel: 'Cancelar',
        confirm: 'Confirme',
        success: 'E-mail reenviado com sucesso',
        failed: 'Alguma coisa correu mal, por favor verifique os datos',
      }
    },
    disable: {
      title: 'Desativar código',
      successNotification: 'Código desativado com sucesso',
      alreadyDisabledNotification: 'Código já foi desativado',
      failedNotification: 'Alguma coisa correu mal, por favor verifique o seu código.',
    }
  },
  logisticPrint: {
    orderID: 'Busca por...',
    sequence: 'Busca por...',
    orderItem: "N° do pedido",
    sequenceItem: "N° de sequência",
    sequenceColumn: "N° de sequência",
    from: 'De',
    to: 'Para',
    searchButton: 'Busca',
    printButton: 'Imprimir',
    timeLabel: 'Hora',
    dateLabel: 'Data',
  },
  purchases: {
    title: 'As minhas compras',
    active_subscription_action_card: {
      title: 'As minhas compras',
      tags_per_month: '%{amount} Etiquetas QR mensais',
      pay_per_month: 'Paga $%{amount} por mês',
      next_billing: 'Facturação seguinte: %{date}',
      modify_button: 'modificar',
      cancel_button: 'cancelar',
    },
    cancelled_subscription_action_card: {
      title: 'Assinatura cancelada',
      subscription_cancelled:
        'Cancelou a sua assinatura do plano %{subscription}',
      info: 'A taxa de assinatura não será mais debitada.',
      reactivate_button: 'Reativar',
      see_plans_button: 'Ver planos',
    },
    no_subscription_action_card: {
      title: 'Ainda não tem uma assinatura activa!',
      subtitle: 'Inscreva-se para um plano mensal e obtenha o melhor valor.',
      button: 'VER PLANOS',
    },
    stock_action_card: {
      title: 'Estqoue restante',
      button: 'Comprar',
    },
    table: {
      header: 'Histórico de pagamentos',
      date: 'Data',
      type: 'Tipo',
      product: 'Produto',
      amount: 'Montante',
      status: 'Estado',
      voucher: 'Comprovante',
      download: 'Descarregar',
    },
    products: {
      mini_pack: 'Pack Mini',
      plus_pack: 'Pack Plus',
      full_pack: 'Pack Full',
      entrepreneur: 'Empreendedor',
      expansion: 'Expansão',
      professional: 'Profissional',
    },
    types: {
      subscription: 'Assinatura',
      pack: 'Pack',
    },
  },
  subscription_plans: {
    plans: {
      title: 'Planos de assinatura',
      subtitle:
        'Uma quantidade fixa de etiquetas QR que é renovada todos os meses e expira no final de cada mês.',
    },
    tag_packs: {
      title: 'Packs de etiquetas',
      subtitle:
        'Compras únicas. São cumulativos entre si e com assinaturas, e não expiram.',
    },
    subscription_cards: {
      expansion: 'Expansão',
      professional: 'Profissional',
      entrepreneur: 'Empreendedor',
      qr_tags_per_month: 'Etiquetas QR por mês',
      per_month: 'por mês',
      subscribe: 'Subscrever',
      best_value: 'Melhor valor',
      is_active: 'Plano Activo',
    },
    pack_cards: {
      buy: 'Comprar',
      qr_tags: 'Etiquetas QR',
    },
  },
  print: {
    title: 'Impressão',
    button: 'Imprimir',
    document_title: 'Etiquetas de Storydots',
    subtitle:
      'Gerar PDFs prontos a imprimir a partir de lotes de etiquetas QR.',
    form: {
      clientID: 'Cliente',
      batch: 'Lote',
      loading: 'Carregamento...',
      no_options: 'Não foram encontradas opções.',
      batch_not_found: 'Lotes não encontrados',
      submit: 'Gerar placa',
      qr_width: 'Largura de qr',
      tag_width: 'Largura da etiqueta',
      y_position: 'Posição Y do código',
      x_position: 'Posição X do código',
      change_template: 'Alterar modelo',
      page_size: 'Largura da página',
      page_sizes: {
        a4_portrait: 'A4 Vertical',
        a4_landscape: 'A4 Horizontal',
        a3_portrait: 'A3 Vertical',
        a3_landscape: 'A3 Horizontal',
        a2_portrait: 'A2 Vertical',
        a2_landscape: 'A2 Horizontal',
      },
      cutting_lines: 'Mostrar linhas de corte',
      code_index_ref: 'Mostrar Código ID',
      tag_spacing: 'Espaçamento das etiquetas',
      page_margin: 'Margem de página',
      sender_tag: 'Mostrar tags de remetente',
    },
    sheet: {
      client: 'Cliente',
      qr: 'QR',
      alt: 'Carregamento do código QR...',
    },
  },
  stats: {
    title: 'Estatísticas',
    subtitle:
      'Saiba mais sobre o comportamento dos seus clientes e o seu impacto na sua loja.',
    gridTitles: {
      tagsQR: {
        title: 'As etiquetas QR duram 30 dias',
      },
      averageTickets: {
        title: 'A média de bilhetes dura 30 dias',
      },
      stats: {
        title: 'Estatísticas',
      },
      averageTicketsTracking: {
        title: 'Rastreio de bilhetes promedio',
      },
    },
    stat_cards: {
      sent: {
        title: 'Enviadas',
      },
      recorded: {
        title: 'Gravadas',
      },
      viewed: {
        title: 'Vistas',
      },
      responded: {
        title: 'Aos inquiridos',
      },
      averageTicketWithGreeting: {
        title: 'Com saudações',
      },
      averageTicketWithoutGreeting: {
        title: 'Sem saudações',
      },
      averageAmountWithGreeting: {
        title: 'Montante médio com saudações',
      },
      averageAmountWithoutGreeting: {
        title: 'Quantidade média sem saudações',
      },
    },
    switch: {
      withGreeting: 'Com saudações',
      withoutGreeting: 'Sem saudações',
    },
    codesDataGroupByMonthArrayKeys: {
      sent: 'Emitidas',
      recorded: 'Gravadas',
      replied: 'Respondidas',
      views: 'Vistas',
    },
  },
  support: {
    title: 'Ajuda',
    subtitle:
      'Se tiver havido um problema com o StoryDots, este é o local para o relatar.',
    done: 'O seu relatório foi enviado',
    error: 'Houve um erro ao submeter o seu relatório',
    form: {
      name: {
        label: 'O seu nome',
        minLenght: 'O seu nome deve ter pelo menos %{value} caracteres',
      },
      email: {
        label: 'O seu email',
        helper: 'Responderemos a su pregunta a este e-mail.',
      },
      subject: {
        label: 'c',
        list: {
          register: 'Quero registar a minha loja',
          buy: 'Quero comprar etiquetas',
          print: 'Estou a ter problemas de impressão',
          bug: 'Quero denunciar um bug',
          stock: 'Há um problema com o meu estoque/compra',
          help: 'Preciso de ajuda',
        },
      },
      phone: {
        label: 'O seu telefone',
        optional_label: 'O seu telefone (opcional)',
      },
      register_shop_url: {
        label: 'URL da loja',
      },
      content: {
        label: 'Eu descrevi o problema',
        placeholder:
          'Envie-nos toda a informação que nos possa ajudar a resolver o seu caso.',
        minLenght: 'A descrição deve ter pelo menos %{value} caracteres',
      },
      submit: { label: 'Enviar pedido de informação' },
    },
  },
  settings: {
    title: 'Configuração',
    subtitle:
      'Ajuste as suas preferências para permissões, alertas e outros ajustes.',
    notifications_table: {
      title: 'Notificações',
      headers: {
        case: 'Caso',
        panel_notifications: 'Notificação de painel',
        email_notifications: 'Email',
      },
      cases: {
        stock_equal_less_than_ten:
          'O estoque de Tags QR é igual ou inferior a 10',
        stock_equal_less_than_five:
          'O estoque de Tags QR é igual ou inferior a 5',
        stock_out: 'As etiquetas QR estão fora de estoque',
        greeting_more_than_ten: 'A saudação recebeu mais de 10 reproduções',
        greeting_more_than_thirty: 'A saudação recebeu mais de 30 reproduções',
        user_sent_greeting:
          'Um usuário que recebeu uma saudação no passado, envia uma saudação',
      },
    },
    permissions_table: {
      title: 'Permissões',
      advanced_info: 'Informação sobre as vendas da minha loja',
      advanced_info_subtitle:
        'Habilite esta permissão para aceder a informação avançada sobre os seus usuários.',
    },
  },
  customizations: {
    title: 'Personalizações',
    subtitle:
      'Crie a sua etiqueta personalizada. Configure os estilos e ajuste a experiência à sua marca.',
    app_custom: {
      title: 'Personalizar a experiência',
      redirectTimeHelper:
        'Quanto tempo o usuário espera antes de ser redireccionado',
      redirectUrlHelper:
        'URL do seu sítio para o qual os seus usuários serão redireccionados após a gravação da saudação',
    },
    custom_tag: {
      title: 'Personalize a Tag',
      uploadFile: 'Experimente carregando o logotipo de sua marca',
      uploadFileBtn: 'Carregar arquivo',
      text: 'Texto introdutório',
      textPlaceholder: 'É um dia especial...',
      textTitle: 'Texto principal',
      textTitlePlaceholder: 'SURPRESA!',
      palette: 'Cor do texto',
      continueBtn: 'Gerar',
      resetBtn: 'Reinicialização',
      logoText: 'SEU LOGO AQUI',
    },
    custom_video: {
      title: 'Personalizar um vídeo padrão',
      label: 'Vídeo',
      upload_button: 'Envio vídeo',
      duration_error: 'A duração do vídeo deve ser inferior a 1 minuto.',
      size_error: 'O tamanho do vídeo deve ser inferior a 100 MB.',
      current_video: 'Vídeo selecionado',
      current_video_description: 'Mostrando vídeo atualmente na experiência',
      video_requirements_description:
        'O vídeo deve ter menos de 1 minuto e menos de 100 MB de tamanho.',
    },
    widget_anchor: {
      title: 'Ancorando o trecho',
      radio_group_label: 'Tipo de âncora',
      snippet_floating_label: 'Trecho flutuante',
      snippet_anchored_label: 'Snippet fixado',
      text_input_label: 'Insira as classes ou ids',
      warning_text:
        'A opção "snippet fixado" pode causar problemas ao renderizar o trecho, se você tiver algum problema, entre em contato conosco na guia de suporte ou veja este tutorial.',
      radio_group_label_description:
        'Escolha o tipo de âncora a ser usado. O snippet flutuante sempre será exibido no canto inferior direito de sua loja. O snippet ancorado tentará inserir o snippet no carrinho de compras, esta opção pode exigir configuração adicional.',
      text_input_label_title: 'Classes ou IDs',
      text_input_label_description:
        "Digite o id ou classes pertencentes ao seu carrinho de compras 'form', precedendo '#' para o id ou um '.' para as aulas. Se você não sabe como fazer isso, entre em contato conosco na aba de suporte.",
    },
    spOrderTag: {
      title: 'Adicionar a etiqueta ao pedido',
      copyBtn: 'Copiar HTML',
      inputLabel: 'App',
      copied: 'copiada',
      description:
        'Copie o HTML para gerar uma tag usando a impressora de pedidos.',
    }
  },
  announcements: {
    title: 'Anúncios',
  },
  appBar: {
    spanish: 'Espanhol',
    english: 'Inglés',
    portuguese: 'Portuguese',
  },
  auth: {
    sign_up_header: 'Registo',
    sign_in_header: 'Iniciar Sessão',
    bottom_nav: {
      terms: 'Termos e Condições',
      privacy: 'Privacidade',
      gdpr: 'GDPR',
    },
  },
  login: {
    btn: {
      sign_in: 'Entrar',
      tn_sso_sign_in: 'Iniciar sessão com Nuvemshop',
    },
    link: {
      sign_up: 'Registe-se',
      forgot_password: 'Esqueci-me da minha palavra-chave',
    },
  },
  sign_up: {
    btn: {
      sign_up: 'Registe-se',
    },
    link: {
      sign_in: 'Iniciar Sessão',
    },
    inputs: {
      full_name: 'Nome e apelido',
      email: 'Email',
      shop_url: 'URL da loja',
      phone: 'Telefone (opcional)',
      repeat_password: 'Repetir palavra-passe',
      errors: {
        register_password:
          'A senha deve conter um mínimo de oito caracteres e pelo menos uma letra e um número.',
        repeat_password: 'A senha deve ser a mesma em ambos os campos',
      },
    },
    errors: {
      email: 'Correio não disponível',
    },
  },
  genders: {
    male: 'Masculino',
    female: 'Feminino',
    other: 'Outro',
  },
  tag_status_chips: {
    pending: 'Pendente',
    sent: 'Enviada',
    received: 'Recivida',
    responded: 'Respondida',
    ready: 'Pronto',
  },
  response_status_chips: {
    pending: 'Pendente',
    text: 'Texto',
    selfie: 'Selfie',
  },
  video_status_chips: {
    pending: 'Pendente',
    ready: 'Pronto',
  },
  purchase_status_chips: {
    pending: 'Pendente',
    aproved: 'Aprovado',
    rejected: 'Rejeitado',
  },
  go_back: 'Voltar',
  submit_buttons: {
    save: 'Guardar',
    discard: 'Descartar',
  },
  admin_users: {
    title: 'Administrar usuários',
    subtitle: 'Lista de usuários da Firebase',
    table: {
      name: 'Nome',
      email: 'Email',
      role: 'Papel',
      clientID: 'Cliente',
      complete: 'Registado',
    },
    popover: {
      edit_password: 'Redefinir palavra-passe',
    },
    dialog: {
      view: {
        title: 'Dados do cliente',
        role: 'Papel',
        email: 'Email',
        clientID: 'Cliente',
      },
    },
  },
  subscriptions_plans: {
    title: 'Assinaturas',
    subtitle: 'Escolha o plano que melhor se adapta às suas necessidades',
    currentPlan: 'O seu plano actual',
    remainingGreetings: 'Saudações Restantes',
    subscriptionStatus: 'Estado de Assinaturas',
    statuses: {
      active: 'Actualizado',
      pending: 'Pendente de pagamento',
      cancelled: 'Cancelado',
      paused: 'Pausado',
    },
    messages: {
      unavailblePlan:
        '  O seu plano actual já não é <strong>disponível</strong>. Pode tentar subscrever um dos planos abaixo.',
    },
    popups: {
      outOfStock: {
        title: 'As suas saudações estão esgotadas!',
        subtitle:
          'Estamos convencidos de que os seus clientes adoraram a experiência.',
        paragraph:
          'Lembre-se que pode aumentar o número de saudações no seu plano sempre que precisar.',
        footer:
          'Receba mais saudações a um preço mais baixo, poupando 30% com um plano anual.',
        footerbtn: 'Ver planos',
      },
      cancelSub: {
        title: 'Tem a certeza de que quer cancelar a sua assinatura?',
        subtitle: 'Deixará de permitir presentes da sua loja',
        cancelbtn: 'Cancelar',
        continuebtn: 'Sim, quero cancelar a minha inscrição',
        confirmbtn: 'Compreendido',
        confirmtitle: 'Estamos a processar a anulação da inscrição',
        confirmsubtitle: 'O processo pode demorar alguns minutos',
      },
    },
    container: {
      title: 'O SEU PLANO ACTUAL - ESTADO DE ASSINATURA',
      billTitle: 'Facturação',
      monthly: 'Mensal',
      yearly: 'Anual',
      sliderTitle: 'Número de Saudações',
      planContainerTitle: 'Os nossos planos',
      plan: {
        planNames: {
          INITIAL: 'Início',
          ADVANCED: 'Avançado',
          UNLIMITED: 'Sem limites'
        },
        includes: {
          INITIAL: 'Contas com:',
          ADVANCED: 'Tudo desde o Plano início mais:',
          UNLIMITED: 'Tudo desde o Plano avançado mais:',
        },
        cancel: 'Anular a assinatura',
        greetingsToBuy: 'Saudações para comprar',
        availableStarting: 'Disponível em @ saudações',
        start: 'Começar',
        investment: {
          monthly: 'Investimento Mensal',
          yearly: 'Investimento Anual',
        },
        current: '(Plano actual)',
        noPlan: 'Sem um plano',
        freePlan: 'Grátis',
        alreadyContracted: 'Plano de Manutenção',
        contact: 'Contato',
        features: {
          customLogo: 'Logotipo personalizado',
          unlimitedStock: 'Estoque ilimitado',
          onDemandSupport: 'Suporte personalizado',
          uniqueContentAccess: 'Acesso a conteúdo exclusivo',
          customTag: 'Personalização de etiquetas QR',
          customApp: 'Customização do aplicativo',
          customPalette: 'Temas pré-definidos com paletas de cores',
          fme: 'Adicionar filtros, efeitos visuais e quadros temáticos para saudações',
          customRedirect: 'Redirecionar o tráfego para sua loja',
          customEmail: 'Correios personalizados',
          stats:
            'Acesso ao painel de controle com métricas e estatísticas de seus clientes',
          abandonedCarts: 'Recuperação de carrinhos abandonados',
        },
      },
      bottomText: {
        start: "As dotações são ",
        bold: "renovados automaticamente ",
        end: "numa base mensal e",
        endBold: "não são cumulativos."
      },
    },
  },
  onboarding: {
    language: "PT",
    thanksForJoining: {
      title: "Obrigado por juntar-se a nós!",
      subtitle: "A melhoria da experiência de compra também melhora o seu volume de negócios.",
      button: "Ir ao tutorial"
    },
    wizardContent: {
      nextBtn: "Próximo",
      beginBtn: "Iniciar",
      goBackBtn: "Voltar",
      welcome: {
        title: "Olá! Eu sou Vicky",
        messages: {
          msg1: {
            0: "Vou acompanhá-lo neste tutorial de ",
            1: "5 minutos ",
            2: "que lhe recomendo que complete. ",
            3: "Mantenha o seu telemóvel à mão.",
          },
        },
      },
      howItWorks: {
        title: "Como funciona",
        messages: {
          msg1: "É importante conhecer a experiência que os seus clientes terão quando utilizarem a nossa aplicação."
        },
      },
      QRScan: {
        title: "Escaneie o QR code com seu telefone",
        messages: {
          msg1: {
            0: "Vamos criar o seu primeiro cumprimento virtual. A continuação, e como exemplo, você será  ",
            1: "quem faz o presente ",
            2: "e também quem ",
            3: "recebe"
          },
          msg2: "Bom! Agora vamos ver como nos integramos na sua loja."
        }
      },
      storeIntegration: {
        title: "Integração",
        messages: {
          msg1: "Hoje estamos integrados no carrinho de compras da sua loja, permitindo-lhe indicar quando uma compra é um presente."
        }
      },
      simulatePurchase: {
        title: "Vamos simular uma compra de presente!",
        messages: {
          msg1: "Já escolheu o seu produto. Agora vamos ver o que acontece quando indica com StoryDots que se trata de um presente."
        }
      },
      greetingIsAddedToCart: {
        title: "El Saludo é adicionado ao seu carrinho",
        messages: {
          msg1: "Com o nome “Cumprimento Virtual”, não deve removê-lo ou escondê-lo dos seus produtos."
        }
      },
      quickGreetingCreation: {
        title: "Criação rápida",
        messages: {
          msg1: "O seu cliente pode completar aqui uma rápida saudação de texto. Podem então melhorá-lo a partir do seu e-mail."
        }
      },
      orderEndedSuccessfully: {
        title: "Cliente completa com sucesso a encomenda",
        messages: {
          msg1: "Vamos ver como criar a saudação na nossa aplicação."
        }
      },
      emailSent: {
        title: "Enviamos um e-mail para o cliente",
        messages: {
          msg1: "Ao iniciar sessão poderá criar uma saudação virtual em vídeo, fotografia, voz ou texto como a que já fez.",
          msg2: "Ainda aí estás? Só falta um passo!"
        }
      },
      SDArrivesAsQR: {
        title: "StoryDots chega em um QR",
        messages: {
          msg1: "Terá de identificar as encomendas de presentes e imprimir os QRs da NuvemShop.",
          msg2: "Vamos aprender como identificar e imprimir estas encomendas."
        }
      },
      printing: {
        title: "Imprimir",
        messages: {
          msg1: {
            0: "As encomendas de prendas têm uma nota de acompanhamento com o texto ",
            1: "“Contém tag StoryDots”",
            2: ". Deve assinalar a encomenda.",
          }
        }
      },
      deployOrderMenu: {
        title: "Puxar o menu “Escolhi uma acção”",
        messages: {
          msg1: "E seleccione “Imprimir etiquetas StoryDots”.",
        }
      },
      printingSingle: {
        title: "Impressão individual",
        messages: {
          msg1: "Você também poderá imprimir etiquetas da Storydots na vista de detalhes do pedido."
        }
      },
      printingEnd: {
        title: "Este é o fim da impressão",
        messages: {
          msg1: "Poderá escolher diferentes formatos, não se esqueça de anexar a etiqueta QR numa área visível do pacote.",
        }
      }
    },
    congratulations: {
      title: "Parabéns, você já é um especialista!",
      subtitle: "Está apenas a um passo de retornos como este:",
      cardTitle: {
        start: "Simulação de ",
        color: "desempenho extra",
        end: " no seu campo."
      },
      card1: "Número de vendas",
      card2: "Extra para presentes",
      card3: "Vendas extra com StoryDots",
      button: "Final"
    },
    stepper: {
      0: {
        title: "Introdução",
        subtitle: "Como funciona",
        tooltip: "Ir à introdução"
      },
      1: {
        title: "Integração",
        subtitle: "Ao carrinho de compras",
        tooltip: "Ir à integração"
      },
      2: {
        title: "Imprimir",
        subtitle: "Das etiquetas QR",
        tooltip: "Ir à imprimir"
      },
    },
    skip: {
      tooltip: "Sair do tutorial",
      question: "Quer sair do tutorial?",
      cancel: "Cancelar",
      confirm: "Confirmar"
    }
  },
};
export default customPortugueseMessages;
