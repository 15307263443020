import React from 'react';
import {
  Typography,
  makeStyles,
  Box,
  Button,
  Divider,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { useTranslate } from 'ra-core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '22em',
    height: '513.3px',
    textAlign: 'center',
    borderRadius: '8px',
    paddingTop: '15px',
  },
  priceContainer: {
    margin: '1em 0 2em 0',
    height: '4em',
  },
  priceContainerTitle: {
    fontSize: '32px',
  },
  priceContainerSubtitle: {
    fontSize: '14px',
  },
  backgroundPrimary: {
    borderRadius: '0px 0px 8px 8px',
    backgroundColor: theme.palette.primary.main,
  },
  backgroundSecondary: {
    borderRadius: '0px 0px 8px 8px',
    backgroundColor: theme.palette.secondary.main,
  },
  startButton: {
    bottom: '20px',
  },
  feature: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    '& span': {
      marginLeft: '10px',
    },
  },
  featuresContainer: {
    height: '11em',
    padding: '30px',
    textAlign: 'left',
  },
  blur: {
    '-webkit-filter': 'blur(5px)',
    '-moz-filter': 'blur(5px)',
    '-o-filter': 'blur(5px)',
    '-ms-filter': 'blur(5px)',
    filter: 'blur(5px)',
    marginTop: '27px',
  },
  selectedPrimary: {
    outline: `6px solid ${theme.palette.primary.main}`,
  },
  selectedSecondary: {
    outline: `6px solid ${theme.palette.secondary.main}`,
  },
  title: {
    fontSize: '20px',
    marginBottom: '5px',
  },
  subtitle: {
    fontSize: '12px',
    marginTop: '8px',
  },
  dividerPrimary: {
    backgroundColor: 'rgba(143, 63, 230, 0.4)',
  },
  dividerSecondary: {
    backgroundColor: 'rgba(255, 34, 102, 0.4)',
  },
  selectOutlinedPrimary: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutlinedPrimary: {
    borderWidth: '1px',
    borderColor: `${theme.palette.primary.main} !important`,
  },
  selectLabelPrimary: {
    color: `${theme.palette.primary.main}`,
  },
  selectOutlinedSecondary: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.secondary.main} !important`,
    },
  },
  notchedOutlinedSecondary: {
    borderWidth: '1px',
    borderColor: `${theme.palette.secondary.main} !important`,
  },
  selectLabelSecondary: {
    color: `${theme.palette.secondary.main}`,
  },
  higherContainer: {
    height: '541.3px',
  },
}));

type Props = {
  title: string;
  subtitle: string;
  price: number;
  billingType: 'monthly' | 'yearly';
  features: string[];
  greetingsOptions: number[];
  selectedOption: number;
  onChange: any;
  onCancel: any;
  color?: 'primary' | 'secondary';
  className?: any;
  selected?: boolean;
  selectedPlanCategory?: boolean;
  contact?: boolean;
  onBuy: () => void;
  planName: string;
};

export default function PlanCard({
  title,
  subtitle,
  price,
  billingType,
  features,
  className,
  greetingsOptions,
  selectedOption,
  onChange,
  onCancel,
  color = 'primary',
  selected = false,
  selectedPlanCategory = false,
  contact = false,
  onBuy,
  planName
}: Props) {
  const styles = useStyles();
  const translate = useTranslate();

  return (
    <Box
      className={classnames(
        styles.container,
        className,
        selected &&
          (color === 'primary'
            ? styles.selectedPrimary
            : styles.selectedSecondary),
        selected && styles.higherContainer,
      )}
      boxShadow={3}
    >
      <Typography className={styles.title}>
        {title.toUpperCase()}{' '}
        {selected && translate('subscriptions_plans.container.plan.current')}
      </Typography>

      <Typography className={styles.subtitle}>{subtitle}</Typography>

      <div className={classnames(styles.featuresContainer)}>
        {features.map((feature, index) => {
          return (
            <div className={styles.feature}>
              <CheckIcon color={color} fontSize="default" />
              <span
                style={{
                  fontSize: '14px',
                  ...(index === 0 && { fontWeight: 800 }),
                }}
              >
                {translate(
                  `subscriptions_plans.container.plan.features.${feature}`,
                )}
              </span>
            </div>
          );
        })}
      </div>

      <div style={{ margin: '1em 0', padding: '0 30px', marginBottom: '3em' }}>
        <TextField
          select
          value={selectedOption}
          label={translate('subscriptions_plans.container.plan.greetingsToBuy')}
          onChange={(e) => onChange(planName, parseInt(e.target.value))}
          fullWidth
          variant="outlined"
          color="primary"
          InputLabelProps={{
            classes: {
              root:
                color === 'primary'
                  ? styles.selectLabelPrimary
                  : styles.selectLabelSecondary,
              focused:
                color === 'primary'
                  ? styles.selectLabelPrimary
                  : styles.selectLabelSecondary,
            },
          }}
          InputProps={{
            classes: {
              root:
                color === 'primary'
                  ? styles.selectOutlinedPrimary
                  : styles.selectOutlinedSecondary,
              focused:
                color === 'primary'
                  ? styles.selectOutlinedPrimary
                  : styles.selectOutlinedSecondary,
              notchedOutline:
                color === 'primary'
                  ? styles.notchedOutlinedPrimary
                  : styles.notchedOutlinedSecondary,
            },
            inputMode: 'numeric',
          }}
        >
          {greetingsOptions.map((option) => (
            <MenuItem value={option}>{option.toString()}</MenuItem>
          ))}
        </TextField>
      </div>

      <div>
        <Divider
          className={
            color === 'primary'
              ? styles.dividerPrimary
              : styles.dividerSecondary
          }
        />
        <Button
          onClick={onBuy}
          variant="contained"
          color={color}
          disabled={selected}
          className={styles.startButton}
          style={{ backgroundColor: selected ? 'rgb(230 230 230)' : undefined }}
        >
          {selected || selectedPlanCategory
            ? translate('subscriptions_plans.container.plan.alreadyContracted')
            : contact
            ? translate('subscriptions_plans.container.plan.contact')
            : translate('subscriptions_plans.container.plan.start')}
        </Button>
      </div>

      {selected && (
        <Button
          variant="text"
          color="secondary"
          style={{ fontSize: '11px', bottom: '10px', textTransform: 'none' }}
          onClick={onCancel}
        >
          {translate('subscriptions_plans.container.plan.cancel')}
        </Button>
      )}

      <div className={styles.priceContainer}>
        <Typography className={styles.priceContainerTitle}>${price}</Typography>
        <Typography className={styles.priceContainerSubtitle}>
          {billingType === 'monthly'
            ? translate('subscriptions_plans.container.plan.investment.monthly')
            : translate('subscriptions_plans.container.plan.investment.yearly')}
        </Typography>
      </div>

      {/* BOTTOM BORDER  */}
      <div
        style={{
          height: '14px',
        }}
        className={
          color === 'primary'
            ? styles.backgroundPrimary
            : styles.backgroundSecondary
        }
      ></div>
    </Box>
  );
}
