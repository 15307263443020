import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  InputLabel,
  TextField,
  InputAdornment,
  Button,
  makeStyles,
  Collapse,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import qrImage from './img/qrImage.png';
import transparencyGrid from './img/transparencyGrid.png';
import useCurrentWidth from './useCurrentWidth';
import { useNotify } from 'ra-core';
import { getFileName } from '../../../../Utils';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
  },
  inputLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  image: {
    maxWidth: '100%',
    maxHeight: 450,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 18,
    borderRadius: 12,
    boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.23);',
    overflow: 'hidden',
    maxHeight: 486,
  },
  tagSandbox: {
    position: 'relative',
    maxWidth: '80%',
    maxHeight: 450,
  },
  grey: {
    filter: 'grayscale(100%)',
  },
}));

const useCheckeredStyles = (darkMode: boolean | undefined) =>
  makeStyles((theme) => ({
    checkeredBackground: {
      backgroundImage: `url("${transparencyGrid}")`,
      backgroundRepeat: 'repeat',
      backgroundColor: darkMode ? '#222222bc' : '#fff',
      transition: '0.5s',
    },
  }));

interface IImageInputProps {
  errors: any;
  register: any;
  className?: string;
  previewWrapperClassName?: string;
  label: string;
  name: string;
  helperText?: string;
  tagCoordinates?: any;
  setValue: any;
  darkMode?: boolean;
  disabled?: boolean;
  defaultUrl?: string;
}

function getCoordsByZoomRate(
  zoomRate: number,
  top: number,
  left: number,
  width: number,
) {
  return {
    top: top * zoomRate,
    left: left * zoomRate,
    width: width * zoomRate,
  };
}

function getImageSize(url: string) {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.src = url;
    } catch (e) {
      reject(e);
    }
  });
}

const ImageInput: React.FC<IImageInputProps> = ({
  errors,
  register,
  className,
  previewWrapperClassName,
  label,
  name,
  helperText,
  tagCoordinates,
  darkMode,
  defaultUrl,
  disabled = false,
}) => {
  const classes = useStyles();
  const checkeredClasses = useCheckeredStyles(darkMode)();
  const translate = useTranslate();
  const [imageName, setImageName] = useState<string | undefined>(undefined);
  const [image, setImage] = useState<string | undefined>(undefined);
  const [coords, setCoords] = useState({ left: 0, top: 0, width: 0 });
  const notify = useNotify();

  useEffect(() => {
    if (defaultUrl) {
      //append timestamp to make img url unique and avoid caching
      setImage(`${defaultUrl}?no_cache${performance.now()}`);
      setImageName(defaultUrl);
    }
  }, []);

  const onChangeHiddenInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files[0]) {
      const url = URL.createObjectURL(e.target.files[0]);
      getImageSize(url)
        .then((res: any) => {
          // Hotfix to make image upload easier for Leo (previously 800px). We need to review this size in the near future.
          if (res.width < 10 && res.height < 10) {
            notify('client_form.form.upload_image_size_error');
          } else {
            setImage(url);
          }
        })
        .catch((e) => {
          notify('Error' + e);
        });
    }

    const fullPath = e.target.value;
    if (!/(.jpg|.jpeg|.png)$/i.exec(fullPath)) {
      notify('client_form.form.upload_image_error');
    } else {
      setImageName(getFileName(fullPath));
    }
  };

  const sbRef = useRef<HTMLDivElement>(null);

  const calculateAndSetCoordinates = useCallback(() => {
    if (!sbRef.current || !tagCoordinates) return;
    const { top, left, width, tagWidth } = tagCoordinates;

    const zoomRateByPx = sbRef.current.clientWidth / tagWidth;

    const dataForSet = getCoordsByZoomRate(zoomRateByPx, top, left, width);

    setCoords(() => dataForSet);
  }, [tagCoordinates, sbRef]);

  const width = useCurrentWidth();

  useEffect(() => {
    calculateAndSetCoordinates();
  }, [tagCoordinates, width, calculateAndSetCoordinates]);

  return (
    <>
      <TextField
        error={Boolean(errors[name])}
        helperText={errors[name]?.message || helperText}
        variant="outlined"
        className={className}
        type="text"
        value={imageName || ''}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
              >
                {translate('client_form.form.upload_image')}
                <InputLabel htmlFor={name} className={classes.inputLabel}>
                  <input
                    type="file"
                    ref={register}
                    name={name}
                    id={name}
                    disabled={disabled}
                    style={{ display: 'none' }}
                    onChange={onChangeHiddenInput}
                  />
                </InputLabel>
              </Button>
            </InputAdornment>
          ),
          readOnly: true,
        }}
        disabled={disabled}
        label={label}
      />
      <Collapse
        in={!!image || !!defaultUrl}
        classes={{ wrapperInner: previewWrapperClassName }}
      >
        <div
          className={`${classes.imageContainer} ${checkeredClasses.checkeredBackground}`}
        >
          <div className={classes.tagSandbox} ref={sbRef}>
            <img
              className={classes.image}
              alt={translate('client_form.form.invalid_image')}
              src={image}
              title="custom tag"
              onLoad={calculateAndSetCoordinates}
            />
            {tagCoordinates && (
              <img
                src={qrImage}
                alt="Qr Code"
                style={{
                  ...coords,
                  position: 'absolute',
                  top: coords.top,
                  left: coords.left,
                  width: coords.width,
                }}
              />
            )}
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default ImageInput;
