import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Collapse,
  Typography,
  Icon,
} from '@material-ui/core';
import useStyles from '../../styles';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslate } from 'react-admin';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { useIsSuperAdmin } from 'customHooks';
import { InputContainer, ArrowChevron, CardTitleWithIcon } from 'components';

type Props = {
  disabled?: boolean;
};
export default function WidgetAnchor({ disabled }: Props) {
  const [toggleCard, setToggleCard] = useState(false);
  const [isSnippetFloating, setIsSnippetFloating] = useState<boolean>(true);
  const translate = useTranslate();
  const { register, control } = useFormContext();
  const isSuperadmin = useIsSuperAdmin();
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSnippetFloating(event.target.value === 'true');
  };
  useEffect(() => {
    setIsSnippetFloating(control.defaultValuesRef.current.snippet_floating);
  }, []);
  return (
    <Card className={classes.formPart}>
      <CardActionArea onClick={() => setToggleCard(!toggleCard)}>
        <CardHeader
          className={classes.cardHeader}
          title={CardTitleWithIcon(
            translate('customizations.widget_anchor.title'),
            <AccessibilityNewIcon color="primary" />,
          )}
          action={
            <ArrowChevron
              setToggleCard={setToggleCard}
              toggleCard={toggleCard}
            />
          }
        />
      </CardActionArea>
      <Collapse in={toggleCard}>
        <CardContent className={classes.formCardContent}>
          <InputContainer
            title={translate('customizations.widget_anchor.radio_group_label')}
            content={translate(
              'customizations.widget_anchor.radio_group_label_description',
            )}
            mb
          >
            <Controller
              control={control}
              name="snippet_floating"
              render={({ onChange, value, ...all }) => (
                <RadioGroup
                  aria-labelledby="snippet_floating"
                  row
                  value={isSnippetFloating}
                  onChange={(event) => {
                    handleChange(event);
                    onChange(event.target.value === 'true');
                  }}
                  {...all}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" />}
                    label={translate(
                      'customizations.widget_anchor.snippet_floating_label',
                    )}
                    disabled={disabled}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" />}
                    label={translate(
                      'customizations.widget_anchor.snippet_anchored_label',
                    )}
                    disabled={disabled}
                  />
                </RadioGroup>
              )}
            />
          </InputContainer>
          <Collapse in={!isSnippetFloating}>
            <InputContainer
              title={translate(
                'customizations.widget_anchor.text_input_label_title',
              )}
              content={translate(
                'customizations.widget_anchor.text_input_label_description',
              )}
            >
              <TextField
                name="widget_data.cart_form"
                variant="outlined"
                style={{ marginTop: '10px' }}
                type="text"
                inputProps={{
                  ref: register,
                }}
                label={translate(
                  'customizations.widget_anchor.text_input_label',
                )}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={disabled}
              />

              {!isSuperadmin && (
                <Container
                  style={{
                    marginTop: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px',
                    borderRadius: '10px',
                    border: '1px solid lightgray',
                  }}
                >
                  <Icon style={{ marginRight: 10 }}>
                    <WarningRoundedIcon color="primary" />
                  </Icon>
                  <Typography>
                    {translate('customizations.widget_anchor.warning_text')}
                  </Typography>
                </Container>
              )}
            </InputContainer>
          </Collapse>
        </CardContent>
      </Collapse>
    </Card>
  );
}
