import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
// UTILS
import { useClientAutocomplete } from './utils';

interface IProps {
  className?: string;
}

const ClientAutocomplete: React.FC<IProps> = ({ className }) => {
  const {
    open,
    options,
    control,
    hasError,
    helperText,
    isLoading,
    translate,
    togglePanel,
    getOptionSelected,
    onInputChange,
    onTextChange,
    getOptionLabel,
  } = useClientAutocomplete();

  return (
    <Controller
      name="client"
      control={control}
      render={(props) => (
        <Autocomplete
          {...props}
          className={className}
          open={open}
          onOpen={togglePanel}
          onClose={togglePanel}
          getOptionSelected={getOptionSelected}
          onChange={(_, data) => onInputChange(data, props.onChange)}
          getOptionLabel={getOptionLabel}
          options={options}
          loading={isLoading}
          loadingText={translate("print.form.loading")}
          noOptionsText={translate("print.form.no_options")}
          renderInput={(params) => (
            <TextField
              {...params}
              error={hasError}
              helperText={helperText}
              label={translate('print.form.clientID')}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                onChange: (e) => onTextChange(e.target.value),
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default ClientAutocomplete;
