import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    border: ` 2px solid ${theme.palette.primary.main}`,
    borderRadius: '50px',
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
  },
  icon: {
    marginRight: '10px',
  },
  textMessage: {
    fontSize: '18px',
  },
}));

type Props = {
  children: any;
  borderRadius?: string;
};

export default function Message({ children, borderRadius }: Props) {
  const styles = useStyles();

  return (
    <Box className={styles.container} style={{ borderRadius: borderRadius }}>
      <InfoIcon className={styles.icon} color="primary" />
      <Typography className={styles.textMessage}>{children}</Typography>
    </Box>
  );
}
