export default {
  clothing: "Clothing",
  clothing_accesories: "Clothing accessories",
  health_beauty: "Health and Beauty",
  home_garden: "Home and Garden",
  gifts: "Gifts",
  food_drinks: "Food and Drinks",
  electronics_it: "Electronics/IT",
  antiques: "Antiques",
  art: "Art",
  cars: "Cars",
  industrial: "Construction/Industrial",
  sports: "Sports/Recreation",
  digital: "Digital/Digital Assets",
  education: "Education",
  office_supplies: "Office supplies",
  jewelry: "Jewelry/Watches",
  toys: "Toys/Games/Hobbies",
  books: "Books/Magazines",
  pets: "Pets",
  music_movies: "Music/Movies",
  services: "Services",
  travel: "Travel",
  erotics: "Erotica",
  bookstore_graphic: "Bookstore/Graphics",
  equipment_machines: "Equipment/Machinery",
  other: "Another"
}