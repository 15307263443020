import React, { useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    }
}))

interface IImageProps {
    src?: string;
    alt?: string;
    className?: string;
}

const Image: React.FC<IImageProps> = ({ src, alt, className }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const classes = useStyles();
    return (<div className={classes.root}>
        {!isLoaded && <CircularProgress />}
        <img
            style={{ display: isLoaded ? 'initial' : 'none' }}
            onLoad={() => setIsLoaded(true)}
            src={src}
            alt={alt}
            className={className} />
    </div>);
}

export default Image;