import * as React from 'react';
import { ShowProps } from 'react-admin';
import PageContainer from '../../components/PageContainer';
import { ReceiverShow, SenderShow, TrackingShow } from './shows';

const UserShow: React.FC<ShowProps> = (props) => {
  return (
    <PageContainer>
      {props.resource === 'senders' && <SenderShow {...props} />}
      {props.resource === 'receivers' && <ReceiverShow {...props} />}
      {props.resource === 'tracking' && <TrackingShow {...props} />}
    </PageContainer>
  );
};

export default UserShow;
