import {
  SUBSCRIPTION_FETCH_LOADING,
  SUBSCRIPTION_FETCH_LOADED,
  SUBSCRIPTION_FETCH_SUCCEDED,
  SUBSCRIPTION_FETCH_FAILED,
} from '../config';

const initialState = {
  isLoading: true,
  data: {},
  message: null,
};

export default (previousState = initialState, { type, payload }: any) => {
  switch (type) {
    case SUBSCRIPTION_FETCH_LOADING:
      return { ...previousState, isLoading: true, data: null };
    case SUBSCRIPTION_FETCH_LOADED:
      return { ...previousState, isLoading: false };
    case SUBSCRIPTION_FETCH_SUCCEDED:
      return { ...previousState, data: payload };
    case SUBSCRIPTION_FETCH_FAILED:
      return { ...previousState, message: payload };
    default:
      return previousState;
  }
};
