import React, { useContext } from 'react';
import { useTranslate } from 'react-admin';
import { useTransition, animated, easings } from 'react-spring';
import { OnboardingContext } from '../context/OnboardingContext';
import Button from '@material-ui/core/Button';
import WizardContent from './WizardContent';

function Wizard ({ wizardContent, wizardImage, moveWizard }:any) {
  const translate = useTranslate();
  const { slideN, styles, stepper, preload } = useContext(OnboardingContext);
  const [slideToShow, setSlideToShow] = slideN;
  const onboardingStyles = styles;
  const { manrope, primaryLightBG, whiteBG, pAbsolute, pRelative, w100, mt4, p3, paddingLg, dFlex, column, alignCenter, justifyCenter, justifyBetween, noBorder, radiusSm, radiusMd, radius50, textWhite, textPrimary, textJustify, font18, paperShadow, btnShadow, noUppercase, pointer, btnHover} = onboardingStyles();
  const [activeStep, setActiveStep] = stepper.active; // eslint-disable-line @typescript-eslint/no-unused-vars
  const [introCompleted, setIntroCompleted] = stepper.intro; // eslint-disable-line @typescript-eslint/no-unused-vars
  const [integrationCompleted, setIntegrationCompleted] = stepper.integ; // eslint-disable-line @typescript-eslint/no-unused-vars
  const [printCompleted, setPrintCompleted] = stepper.print; // eslint-disable-line @typescript-eslint/no-unused-vars
  const preloadedImages = preload.current;
  let nextBtnText:any = translate('onboarding.wizardContent.nextBtn');
  let nextBtnWidth = "6.98vw"
  if (slideToShow === 1) {  // FIXED SLIDE NUMBER
    nextBtnText = `${translate('onboarding.wizardContent.beginBtn')} 💪`;
    nextBtnWidth = "8.3vw";
  };

  const handleNext = () => {
    setSlideToShow((prev:number) => prev + 1);
    if (slideToShow === 3) { // FIXED SLIDE NUMBER
      setIntroCompleted(true);
      setActiveStep(1);
    };
    if (slideToShow === 9) { // FIXED SLIDE NUMBER
      setIntegrationCompleted(true);
      setActiveStep(2);
    };
    if (slideToShow === 13) { // FIXED SLIDE NUMBER
      setPrintCompleted(true);
    };
  };

  const transitions = useTransition(slideToShow, {
    from: { opacity: 0, transform: "translateY(35px)" },
    enter: { opacity: 1, transform: "translateY(0)" },
    config: { duration: 600, easing: easings.easeOutCirc }
  });

  const handleGoBack = () => {
    setSlideToShow((prev:number) => prev - 1);
    if (slideToShow === 4) setActiveStep(0); // FIXED SLIDE NUMBER
    if (slideToShow === 10) setActiveStep(1); // FIXED SLIDE NUMBER
  };

  return (
    <div
      className={[pRelative, dFlex, alignCenter, justifyCenter].concat(moveWizard).join(' ')}
      style={{width: "35.21vw"}}
    >
      {transitions((props, item) => {
        return<animated.div
          className={[pAbsolute, dFlex, column, whiteBG, radiusMd, paperShadow, paddingLg, textJustify].join(' ')}
          style={{...props, width: "35.21vw", paddingTop: "2.92vw", paddingBottom: "1.67vw"}}
        >
          <img
            src={preloadedImages.vickyWizard}
            alt="Vicky smiling"
            className={[pAbsolute].join(' ')}
            style={{width: "7.6vw", top: "-5.21vw", left: "calc(50% - 3.8vw)"}}
          />
          {wizardImage && 
            <div 
              className={[pAbsolute, whiteBG, p3, dFlex, alignCenter, justifyCenter, radius50, paperShadow].join(' ')}
              style={{width: "2.604vw", height: "2.604vw", top: "-0.4167vw", left: "calc(50% + 1.875vw)"}}
            >
              <img
                src={wizardImage}
                alt="wizard action"
                style={{width: "1.5625vw"}}
              />
            </div>
          }
          {wizardContent.title && wizardContent.messages
            ? <WizardContent wizardContent={wizardContent} />
            : wizardContent
          }
          <div className={[w100, dFlex, alignCenter, justifyBetween, mt4].join(' ')}>
            <Button
              onClick={handleGoBack}
              className={[manrope, dFlex, whiteBG, noBorder, textPrimary, noUppercase, pointer].join(' ')}
              style={{fontSize: "0.78125vw", fontWeight: "600", letterSpacing: "0.46px"}}
            >{translate('onboarding.wizardContent.goBackBtn')}</Button>
            <Button
              onClick={handleNext}
              className={[manrope, dFlex, alignCenter, justifyCenter, primaryLightBG, noBorder, radiusSm, textWhite, font18, btnShadow, noUppercase, pointer, btnHover].join(' ')}
              style={{width: `${nextBtnWidth}`, height: "2.1875vw", padding: "0.4167vw 1.1458vw", fontWeight: "600", letterSpacing: "0.46px"}}
            >{nextBtnText}</Button>
          </div>
        </animated.div>
      })}
    </div>
  );
}

export default Wizard;