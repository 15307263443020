import React, { useContext } from 'react';
import { useTransition, animated } from 'react-spring';
import { OnboardingContext } from './context/OnboardingContext';
import useWizardContentTranslate from './helpers/useWizardContentTranslate';
import OnboardingStepper from './components/OnboardingStepper';
import SkipOnboardingBtn from './components/SkipOnboardingBtn';
import SkipOnboardingModal from './components/SkipOnboardingModal';
import SlideBG from './components/SlideBG';
import Wizard from './components/Wizard';
import ThanksForJoining from './components/ThanksForJoining';
import QRScan from './components/QRScan';
import Congratulations from './components/Congratulations';
import wizardIcons from '../../img/onboardingAssets/wizardIcons/index';

function OnboardingShow () {
  const { slideN, styles, preload } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const { pAbsolute, pRelative, w100, h100, primaryGradientBG, dFlex, alignCenter, justifyCenter, mtAuto, mbAuto, scrollY, printEndMoveWizard } = onboardingStyles();
  const [slideToShow] = slideN;
  const preloadedImages = preload.current;
  const { thumbsUp, cart, present, gmail, printer, success } = wizardIcons;
  const translateWizardContent = useWizardContentTranslate();
  const wc = translateWizardContent();

  // When adding extra slides take in consideration that slideToShow is set to a fixed number in some places and may need (or not) an update. In that case search for FIXED SLIDE NUMBER and make updates that are necessary.
  const onboardingSlides = [
    <SlideBG
      BGImage={preloadedImages.cloudsAndArrowBG}
      content={<ThanksForJoining />}
    />,
    <SlideBG
      BGImage={preloadedImages.dashboardBG}
      content={<Wizard wizardContent={wc.welcome} />}
    />,
    <SlideBG
      BGImage={preloadedImages.dashboardBG}
      content={<Wizard wizardContent={wc.howItWorks} />}
    />,
    <SlideBG
      BGImage={preloadedImages.dashboardBG}
      content={<Wizard wizardContent={<QRScan />} wizardImage={thumbsUp} />}
    />,
    <SlideBG
      BGImage={preloadedImages.cartBG}
      content={<Wizard wizardContent={wc.storeIntegration} wizardImage={cart} />}
    />,
    <SlideBG
      BGImage={preloadedImages.cartSnippetBG}
      content={<Wizard wizardContent={wc.simulatePurchase} wizardImage={present} />}
    />,
    <SlideBG
      BGImage={preloadedImages.cartProductBG}
      content={<Wizard wizardContent={wc.greetingIsAddedToCart} wizardImage={present} />}
    />,
    <SlideBG
      BGImage={preloadedImages.cartGreetingBG}
      content={<Wizard wizardContent={wc.quickGreetingCreation} wizardImage={present} />}
    />,
    <SlideBG
      BGImage={preloadedImages.cartSuccessBG}
      content={<Wizard wizardContent={wc.orderEndedSuccessfully} wizardImage={success} />}
    />,
    <SlideBG
      BGImage={preloadedImages.gmailBG}
      content={
        <div className={[dFlex, alignCenter, justifyCenter].join(' ')}>
          <Wizard wizardContent={wc.emailSent} wizardImage={gmail} />
          <img
            src={preloadedImages.emailMobile}
            alt="email shown at mobile"
            style={{width: "19.3229vw", height: "35.104vw", margin: "auto auto auto 3.64583vw", transform: "translateY(-2.2917vw)"}}
          />
        </div>
      }
    />,
    <SlideBG
      BGImage={preloadedImages.tiendanubeSalesBG}
      content={<Wizard wizardContent={wc.SDArrivesAsQR} />}
    />,
    <SlideBG
      BGImage={preloadedImages.tiendanubeOrderBG}
      content={<Wizard wizardContent={wc.printing} wizardImage={printer} />}
    />,
    <SlideBG
      BGImage={preloadedImages.tiendanubePrintSDBG}
      content={<Wizard wizardContent={wc.deployOrderMenu} wizardImage={printer} />}
    />,
    <SlideBG
      BGImage={preloadedImages.tiendanubePrintSingleSDBG}
      content={<Wizard wizardContent={wc.printingSingle} wizardImage={printer} />}
    />,
    <SlideBG
      BGImage={preloadedImages.dashboardPrintQRBG}
      content={<Wizard wizardContent={wc.printingEnd} wizardImage={printer} moveWizard={[printEndMoveWizard]}/>}
    />,
    <SlideBG
      BGImage={preloadedImages.cloudsAndArrowBG}
      content={<Congratulations />}
    />,
  ];

  const transitions = useTransition(slideToShow, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 600 }
  });

  return (
    <div
      className={['hide-scrollbar', pAbsolute, primaryGradientBG, dFlex, scrollY].join(' ')}
      style={{width: "100vw", height: "100vh", right: "0", bottom: "0", left: "0", top: "0"}}
    >
      <SkipOnboardingModal />
      <div
        className={[pRelative, w100, dFlex, alignCenter, justifyCenter, mtAuto, mbAuto].join(' ')}
        style={{minHeight: "49.48vw"}}
      >
        {transitions((springProps, item) => {
        return<animated.div
          className={[pAbsolute, w100, h100, dFlex, alignCenter, justifyCenter].join(' ')}
          style={springProps}
        >
          {onboardingSlides[item]}
          {![0, 15].includes(slideToShow) && <OnboardingStepper /> /* FIXED SLIDE NUMBER */}
          <SkipOnboardingBtn />
        </animated.div>
        })}
      </div>
    </div>
  );
}

export default OnboardingShow;
