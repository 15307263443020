import React, { useState } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import ViewField from '../../../components/ViewField';
import EmailIcon from '@material-ui/icons/Email';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LaunchIcon from '@material-ui/icons/Launch';
import TitledDialog from '../../../components/TitledDialog';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  fields: {
    marginBottom: 15,
  },
}));

interface IClientViewProps {
  open: boolean;
  onClose: () => void;
  client?: ClientResource;
}

const ClientView: React.FC<IClientViewProps> = ({ client, open, onClose }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <TitledDialog
      title={translate(`clients.dialog.view.title`)}
      clientName={client?.store_name || '-'}
      onClose={onClose}
      open={open}
    >
      <ViewField
        className={classes.fields}
        label={translate('clients.dialog.view.representative')}
        value={client?.contact_name as string | undefined}
      />
      <ViewField
        className={classes.fields}
        label={translate('clients.dialog.view.email')}
        value={client?.contact_email as string | undefined}
        link
        linkHref={`mailto:${client?.contact_email}`}
        icon={<EmailIcon />}
      />
      <ViewField
        className={classes.fields}
        label={translate('clients.dialog.view.phone')}
        value={client?.contact_phone as string}
        link
        linkHref={`https://wa.me/${client?.contact_phone
          ?.split(' ')
          .join('')
          .replace('-', '')}`}
        icon={<WhatsAppIcon />}
      />
      <ViewField
        className={classes.fields}
        label={translate('clients.dialog.view.url')}
        value={client?.store_url}
        link
        icon={<LaunchIcon />}
      />
      <div className={classes.buttons}>
        <Button color="primary" onClick={onClose}>
          {translate('clients.dialog.view.close')}
        </Button>
      </div>
    </TitledDialog>
  );
};

export default ClientView;

export const useClientView = () => {
  const [clientViewIsOpen, setClientViewIsOpen] = useState(false);
  return {
    clientViewIsOpen,
    clientViewOnClick: () => setClientViewIsOpen(true),
    clientViewOnClose: () => setClientViewIsOpen(false),
  };
};
