// VENDOR
import React from 'react';
import {
    Card,
    CardContent,
    makeStyles,
    Hidden,
    FormControl,
    InputBase,
    InputLabel
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { CheckCircle } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(
    (theme) => ({
        supportCard: {
            maxWidth: 800,
            paddingTop: theme.spacing(.5),
            '& b': {
                color: theme.palette.primary.main
            }
        },
        supportCardContent: {
            padding: theme.spacing(3),
            display: 'flex',
            alignItems: 'center'
        },
        data: {
            height: 'inherit'
        },
        formControl: {
            marginTop: 10
        },
        input: {
            height: 45,
            paddingTop: 12
        },
        inputContainer: {
            width: '100%',
            borderBottom: '1px solid #E0E0E0'
        },
        standingPerson: {
            height: '387px',
            marginRight: theme.spacing(4)
        },
        title: {
            color: theme.palette.secondary.main,
            fontFamily: 'Manrope',
            fontSize: '22px',
            marginBottom: '32px',
            verticalAlign: 'center',
            display: 'flex',
            flexAlign: 'center',
            textAlign: 'left',
            fontWeight: 500,
            '& div': {
                marginLeft: 5
            }
        },
        subtitle: {
            lineHeight: '150%',
            marginTop: '32px'
        }
    }),
    { name: 'RaLoginForm' },
);

const RegistrationCompleted: React.FC = () => {
    const translate = useTranslate();
    const classes = useStyles();
    //@ts-ignore
    const registration_data = useSelector((state)=>state.auth?.decoded.registration_data);
    const { name, phone, shop_url } = registration_data;
    return (
        <Card className={classes.supportCard}>
            <CardContent className={classes.supportCardContent}>
                <div className={classes.data}>
                    <div className={classes.title}><CheckCircle fontSize='large' /><div>{translate('dashboard.second_register.done.title')}</div></div>
                    <div className={classes.inputContainer}>
                        {name && <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="name">
                                {translate('dashboard.second_register.done.inputs.name')}
                            </InputLabel>
                            <InputBase className={classes.input} id="name" defaultValue={name} readOnly />
                        </FormControl>}
                        {phone && <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="phone">
                                {translate('dashboard.second_register.done.inputs.phone')}
                            </InputLabel>
                            <InputBase className={classes.input} id="phone" defaultValue={phone} readOnly />
                        </FormControl>}
                        {shop_url && <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="url">
                                {translate('dashboard.second_register.done.inputs.url')}
                            </InputLabel>
                            <InputBase className={classes.input} id="url" defaultValue={shop_url} readOnly />
                        </FormControl>}
                    </div>
                    <div className={`${classes.subtitle}`}>{translate('dashboard.second_register.done.p1')}</div>
                    <div className={`${classes.subtitle}`}>{translate('dashboard.second_register.done.p2')}<b>soporte@storydots.app</b>.</div>
                </div>
            </CardContent>
        </Card>
    )
}

export default RegistrationCompleted;