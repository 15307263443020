import React from 'react';
import { Chip, ChipProps, makeStyles } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 500,
    letterSpacing: .5,
    height: 'unset',
    '& span': {
      padding: theme.spacing(.5),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    }
  },
  primaryColor: {
    backgroundColor: theme.palette.primary.light,
    '& *': {
      color: theme.palette.primary.dark,
    },
  },
  secondaryColor: {
    backgroundColor: theme.palette.secondary.light,
    '& *': {
      color: theme.palette.secondary.dark,
    },
  },
  tertiaryColor: {
    backgroundColor: theme.palette.tertiary.light,
    '& *': {
      color: theme.palette.tertiary.dark,
    },
  },
  quaternaryColor: {
    backgroundColor: theme.palette.quaternary.light,
    '& *': {
      color: theme.palette.quaternary.dark,
    },
  },
}));

export type StyledChipColors =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary';

interface IStyledChipProps extends Omit<ChipProps, 'color' | 'label'> {
  color?: StyledChipColors;
  label: string|number;
  //excessive padding in home screen workaround
  negativeMargin?: number;
}

const StyledChip: React.FC<IStyledChipProps> = ({
  color,
  label,
  negativeMargin,
  ...restProps
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Chip
      {...restProps}
      label={typeof label==='string' ? translate(label) : label}
      style={{
        marginTop: negativeMargin ? `-${negativeMargin}px` : 0,
        marginBottom: negativeMargin ? `-${negativeMargin}px` : 0,
      }}
      //@ts-ignore
      className={`${classes.root} ${classes[`${color || 'quaternary'}Color`]}`}
    />
  );
};

export default StyledChip;
