import React from 'react';
import { OnboardingContextProvider } from './context/OnboardingContext';
import OnboardingShow from './OnboardingShow';

function OnboardingList () {

  return (
    <OnboardingContextProvider>
      <OnboardingShow />
    </OnboardingContextProvider>
  );
}

export default OnboardingList;
