// VENDOR
import React from 'react';
import { Layout as LayoutRa } from 'react-admin';
// COMPONENTES
import SideBar from './SideBar';
import MyAppBar from './AppBar';

const Layout = (props) => (
  <LayoutRa {...props} menu={SideBar} appBar={MyAppBar} />
);

export { Layout };
