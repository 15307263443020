export const getPageSize = (name: string) => {
  switch (name) {
    case 'A4 Portrait':
      return { width: '21.0cm', height: '29.7' };
    case 'A4 Landscape':
      return { width: '29.7', height: '21.0cm' };
    case 'A3 Portrait':
      return { width: '29.7cm', height: '42.0' };
    case 'A3 Landscape':
      return { width: '42.0', height: '29.7cm' };
    default:
      return { width: '21.0cm', height: '29.7' };
  }
};

export const parseURLToTxt = (batch: TagResource[] | null): string | null => {
  if (batch) {
    const urls = batch
      .map(
        (code) =>
          `QR N°${code.code_index} | ${code.url}?a=r&source=wa_campaign`,
      )
      .join('\n');
    return urls;
  }
  return null;
};

export const downloadTxt = (tagsTxt: string | null | undefined): void => {
  if (tagsTxt) {
    const element = document.createElement('a');
    const file = new Blob([tagsTxt], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'qr_list.txt';
    document.body.appendChild(element);
    element.click();
  }
};
