export default {
  clothing: "Vestuário",
  clothing_accesories: "Acessórios de vestuário",
  health_beauty: "Saúde e beleza",
  home_garden: "Casa e Jardim",
  gifts: "Presentes",
  food_drinks: "Alimentos e bebidas",
  electronics_it: "Eletrônica/TI",
  antiques: "Antiguidades",
  art: "Arte",
  cars: "Carros",
  industrial: "Construção/Industrial",
  sports: "Esportes/Recreação",
  digital: "Ativos digitais/digitais",
  education: "Educação",
  office_supplies: "Material de escritório",
  jewelry: "Jóias/relógios",
  toys: "Brinquedos/Jogos/Hobbies",
  books: "Livros/Revistas",
  pets: "Animais de estimação",
  music_movies: "Música/Filmes",
  services: "Serviços",
  travel: "Viagens",
  erotics: "Erótica",
  bookstore_graphic: "Livraria/Gráficos",
  equipment_machines: "Equipamentos/Maquinaria",
  other: "Outro"
}