import React from 'react';
import { useTranslate, useRedirect } from 'react-admin';
import TableWithCustomHeader from '../../../../components/TableWithCustomHeader';
import ListWithCustomHeader from '../../../../components/ListWithCustomHeader';
import { Group as UsersIcon } from '@material-ui/icons';
import QRIcon from '../../../../img/QRIcon';
import { BooleanStatusField } from '../../../../components/StatusFields';
import ActionHeader from '../../../../components/ActionHeader';
import { dateFormatter, parseTimestamp } from '../../../../Utils';
import { useSelector } from 'react-redux';
import {useIsSuperAdmin} from '../../../../customHooks'
interface ILastTagsProps {
  rows?: { date: Date; customer: string; videoStatus: boolean; id: number, order_number: string }[];
  isLoading?: boolean;
}

export const LastTagsTable: React.FC<ILastTagsProps> = ({
  rows,
  isLoading,
}) => {
  const translate = useTranslate();
  const isSuperadmin = useIsSuperAdmin();
  const idColumnLabel = isSuperadmin
    ? '#ID'
    : translate('dashboard.tags_table.order');
  const lastTagsColumns = [
    idColumnLabel,
    translate('dashboard.tags_table.date'),
    translate('dashboard.tags_table.customer'),
    translate('dashboard.tags_table.video'),
  ];

  let rowsWithChips: any = rows;
  if (rows) {
    rowsWithChips = rows.map((row) => {
      const {id, order_number, ...parsedRow} = row
      return {
        id: isSuperadmin ? id : order_number,
        ...parsedRow,
        date: dateFormatter(parsedRow.date, navigator.language),
        videoStatus: (
          <BooleanStatusField status={parsedRow.videoStatus} negativeMargin={15} />
        ),
      };
    });
  }
  const redirect = useRedirect();
  return (
    <TableWithCustomHeader
      header={
        <ActionHeader
          title={
            isSuperadmin ? 
            translate('dashboard.tags_table.title_superadmin')
            : translate('dashboard.tags_table.title_regular')
          }
          icon={<QRIcon />}
          action={{
            title: translate('dashboard.tags_table.action'),
            callback: () => redirect('list', '/tags'),
          }}
        />
      }
      columns={lastTagsColumns}
      rows={rowsWithChips}
      pageSize={5}
      isLoading={isLoading}
    />
  );
};

interface ILastUsersProps {
  rows?: { date: Date; customer: string; email: string; id: number }[];
  isLoading?: boolean;
}

export const LastUsersTable: React.FC<ILastUsersProps> = ({
  rows,
  isLoading,
}) => {
  const translate = useTranslate();
  const isSuperadmin = useSelector(
    (state: ReduxState) => state.auth?.decoded.role === 'SUPERADMIN',
  );
  const idColumnLabel = isSuperadmin
    ? '#ID'
    : translate('dashboard.tags_table.order');
  const lastUsersColumns = [
    idColumnLabel,
    translate('dashboard.users_table.date'),
    translate('dashboard.users_table.customer'),
    translate('dashboard.users_table.email'),
  ];
  let rowsWithParsedDate = rows;
  if (rows) {
    rowsWithParsedDate = rows.map((row: any) => {
      const {id, order_number, ...parsedRow} = row
      return {
        id: isSuperadmin ? id : order_number,
        ...parsedRow,
        date: dateFormatter(parsedRow.date, navigator.language),
      }
    })
  }
  const redirect = useRedirect();
  return (
    <TableWithCustomHeader
      header={
        <ActionHeader
          title={translate('dashboard.users_table.title')}
          icon={<UsersIcon />}
          action={{
            title: translate('dashboard.users_table.action'),
            callback: () => redirect('list', '/senders'),
          }}
        />
      }
      columns={lastUsersColumns}
      rows={rowsWithParsedDate}
      pageSize={5}
      isLoading={isLoading}
    />
  );
};

export const LastTagsList: React.FC<ILastTagsProps> = ({ rows, isLoading }) => {
  const items = rows?.map(({ date, customer, videoStatus }) => ({
    primary: dateFormatter(date, navigator.language),
    secondary: customer,
    tertiary: <BooleanStatusField status={videoStatus} negativeMargin={15} />,
  }));
  const isSuperadmin = useIsSuperAdmin();
  const translate = useTranslate();
  const redirect = useRedirect();
  return (
    <ListWithCustomHeader
      items={items}
      isLoading={isLoading}
      header={
        <ActionHeader
          title={
            isSuperadmin ? 
            translate('dashboard.tags_table.title_superadmin')
            : translate('dashboard.tags_table.title_regular')
          }
          icon={<QRIcon />}
          action={{
            title: translate('dashboard.tags_table.action'),
            callback: () => redirect('list', '/tags'),
          }}
        />
      }
    />
  );
};

export const LastUsersList: React.FC<ILastUsersProps> = ({
  rows,
  isLoading,
}) => {
  const items = rows?.map(({ date, customer, email }) => ({
    primary: dateFormatter(date, navigator.language),
    secondary: customer,
    tertiary: email,
  }));
  const translate = useTranslate();
  const redirect = useRedirect();
  return (
    <ListWithCustomHeader
      items={items}
      isLoading={isLoading}
      header={
        <ActionHeader
          title={translate('dashboard.users_table.title')}
          icon={<UsersIcon />}
          action={{
            title: translate('dashboard.users_table.action'),
            callback: () => redirect('list', '/users'),
          }}
        />
      }
    />
  );
};
