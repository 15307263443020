import React, { useContext } from 'react';
import { OnboardingContext } from '../context/OnboardingContext';

function Tooltip ({ text, top, left, display }:any) {
  const { styles } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const { pAbsolute, p3, textWhite, tooltip } = onboardingStyles();

  return(
    <div
      className={[pAbsolute, p3, textWhite, tooltip].join(' ')}
      style={{top, left, display}}
    >
      <p style={{margin: "0 0.15625vw", whiteSpace: "nowrap", fontSize: "0.83vw"}}
      >{text}</p>
    </div>
  );
};

export default Tooltip;
