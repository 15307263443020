import {
  HOME_FETCH_LOADING,
  LAST_TAGS_FETCH_SUCCEDED,
  LAST_USERS_FETCH_SUCCEDED,
  CLIENT_FETCH_SUCCEDED,
  HOME_FETCH_FAILED,
  FLUSH_HOME,
} from '../config';

const initialState = {
  lastTagsLoading: true,
  lastUsersLoading: true,
  clientLoading: true,
  data: {},
  message: null,
};

export default (previousState = initialState, { type, payload }: any) => {
  switch (type) {
    case HOME_FETCH_LOADING:
      return {
        ...previousState,
        lastTagsLoading: true,
        lastUsersLoading: true,
        data: null,
      };
    case LAST_TAGS_FETCH_SUCCEDED:
      return {
        ...previousState,
        lastTagsLoading: false,
        data: { ...previousState.data, lastTags: payload },
      };
    case LAST_USERS_FETCH_SUCCEDED:
      return {
        ...previousState,
        lastUsersLoading: false,
        data: { ...previousState.data, lastUsers: payload },
      };
    case CLIENT_FETCH_SUCCEDED: 
      return {
        ...previousState,
        clientLoading: false,
        data: { ...previousState.data, clientData: payload },
      }
    case HOME_FETCH_FAILED:
      return { ...previousState, message: payload };
    case FLUSH_HOME:
      return initialState;
    default:
      return previousState;
  }
};
