import React, { useState } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import TitledDialog from '../../../components/TitledDialog';

const useStyles = makeStyles((theme) => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    fields: {
        marginBottom: 15
    },
}));

type dialogConfig = "tag" | "app";

interface IMissingConfigsDialogProps {
    open: boolean;
    onClose: () => void;
    callback: () => void;
    configType: dialogConfig;
}

const MissingConfigsDialog: React.FC<IMissingConfigsDialogProps> = ({
    callback,
    configType,
    open,
    onClose,
}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const onConfirm = () => {
        onClose();
        callback();
    }
    return (
        <TitledDialog title={translate(`clients.dialog.config.${configType}.title`)} subtitle={translate(`clients.dialog.config.${configType}.subtitle`)} onClose={onClose} open={open}>
            <div className={classes.buttons}>
                <Button color="primary" onClick={onConfirm}>{translate('clients.dialog.config.confirm')}</Button>
                <Button color="primary" onClick={onClose}>{translate('clients.dialog.config.close')}</Button>
            </div>
        </TitledDialog>
    );
};

export default MissingConfigsDialog;

export const useMissingConfigsDialog = () => {
    const [missingConfigsDialogIsOpen, setMissingConfigsDialogIsOpen] = useState(false);
    const [callbackConfig, setCallbackConfig] = useState<{ callback: () => void; configType: dialogConfig }>({ callback: () => { }, configType: "tag" });
    return {
        missingConfigsDialogIsOpen,
        missingConfigsDialogCallback: callbackConfig.callback,
        missingConfigsDialogConfigType: callbackConfig.configType,
        missingConfigsDialogOnClick: (callback: () => void, configType: dialogConfig) => {
            setCallbackConfig({ callback, configType });
            setMissingConfigsDialogIsOpen(true)
        },
        missingConfigsDialogOnClose: () => setMissingConfigsDialogIsOpen(false)
    };
};
