import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

interface ITableWithActionBodyProps {
  columns: string[];
  rows: object[];
  pageSize: number;
}

const TableWithActionBody: React.FC<ITableWithActionBodyProps> = ({
  columns,
  rows,
  pageSize,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col,index) => (
              <TableCell
                style={{
                  paddingTop: '13px',
                  paddingBottom: '10px',
                  fontWeight: 600,
                }}
                key={index}
              >
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) =>
            index < pageSize ? (
              <TableRow key={index}>
                {Object.values(row).map((rowColumn,index) => (
                  <TableCell key={index}>{rowColumn}</TableCell>
                ))}
              </TableRow>
            ) : (
              <></>
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableWithActionBody;
