import * as React from 'react';
import {
  DateField,
  FunctionField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import PageContainer from '../../components/PageContainer';
import {
  VideoStatusField,
  TagStatusAlt,
  ResponseStatusField,
} from '../../components/StatusFields';

const TagShow: React.FC<ShowProps> = (props) => {
  return (
    <PageContainer>
      <Show {...props}>
        <SimpleShowLayout>
          <TextField label="#ID" source="id" />
          <DateField label="tags.table.date" source="timestamp" locales={navigator.language} />
          <TextField label="tags.table.customer" source="senderEmail" />
          <FunctionField
            label="tags.table.video_recorded"
            render={(record:any) => (
              <VideoStatusField status={record?.status.record} />
            )}
          />
          <FunctionField
            label="tags.table.tag_received"
            render={(record:any) => (
              <TagStatusAlt status={record?.status.received} />
            )}
          />
          <FunctionField
            label="tags.table.response"
            render={(record:any) => (
              <ResponseStatusField status={record?.status.reply} />
            )}
          />
          <TextField label="tags.table.views" source="totalReproduction" />
          <TextField label="tags.table.redirections" source="totalRedirect" />
        </SimpleShowLayout>
      </Show>
    </PageContainer>
  );
};

export default TagShow;
