import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';
import userTags from './userTags/en';
import termsAndConditions from './termsAndConditions/en';
import GDPR from './GDPR/en';
import storeTypes from './stores_types/en';

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  platforms: {
    tiendanube: 'TiendaNube',
    shopify: 'Shopify',
    api: 'api',
    physical: 'Physical',
    vtex: 'VTEX',
    nuvemshop: 'NuvemShop',
    magento: 'Magento',
    dispenser: 'Point of Purchase (Dispenser)'
  },
  GDPR: {
    title: 'General Data Protection Regulation',
    content: GDPR,
  },
  go_to_store_button: "GO TO MY STORE",
  go_to_onboarding_button: "See tutorial",
  terms_and_conditions: {
    title: 'Terms and Conditions',
    content: termsAndConditions,
  },
  dashboard: {
    title: 'Home',
    subtitle: '¡Welcome to your StoryDots Dashboard!',
    stat_cards: {
      stock: {
        title: 'Remaining stock',
        footer_link: 'Order',
      },
      redirections: {
        title: 'Redirections this month',
        footer_link: 'See more',
      },
      users: {
        title: 'Users',
        footer_link: 'See more',
      },
    },
    tags_table: {
      order: 'Order Number',
      title_regular: 'Last Orders',
      title_superadmin: 'Last Tags',
      action: 'See more',
      date: 'Date',
      customer: 'Customer',
      video: 'Video recorded',
    },
    users_table: {
      title: 'Last Users',
      action: 'See more',
      date: 'Date',
      customer: 'Name',
      email: 'Email',
    },
    second_register: {
      title: 'Complete your registration',
      subtitle:
        'To register you as a StoryDots customer, we need you to tell us a little more about yourself and your store.',
      form: {
        name: {
          label: 'Your name *',
          minLenght: 'Your name must be at least 6 characters long',
          required: 'This field is required',
        },
        phone: {
          label: 'Your phone number',
        },
        shop_url: {
          label: 'Your shop URL',
        },
        content: {
          label: 'Tell us more about your store',
        },
        submit: {
          label: 'Send',
        },
        server_error: 'Server error, please try again later',
      },
      done: {
        title: 'You have completed your registration!',
        inputs: {
          name: 'Name',
          phone: 'Phone number',
          url: 'URL',
        },
        p1: 'We will activate your control panel soon. This process can take up to 48 business hours.',
        p2: 'If you made a mistake in any of the data entered, or your control panel still does not activate after the estimated time, write to ',
      },
    },
    submitError: "An error occurred while trying to save the data."
  },
  users: {
    title: 'Users',
    subtitle:
      'Browse all users that participate in greetings, selecting between senders, receivers and tracking.',
    header_titles: {
      senders: 'Senders',
      receivers: 'Receivers',
      tracking: 'Tracking',
    },
    table: {
      id_regular: 'Order number',
      id_superadmin: '#ID',
      name: 'Name',
      email: 'Email',
      user: 'User',
      gender: 'Gender',
      age: 'Age',
      last_activity: 'Last activity',
      first_activity: 'First activity',
      greetings_sent: 'Greetings sent',
      greetings_amount: 'Greetings amount',
      gifted_amount: 'Gifted amount',
      received_amount: 'Received amount',
      tags: 'Tags',
      not_identified: 'User not identified',
    },
    tags: { ...userTags },
  },
  clients: {
    title: 'Clients',
    subtitle: 'Storydots clients List.',
    action: 'Create client',
    search: 'Search',
    table: {
      name: 'Name',
      type: 'Type',
      platform: 'Platform',
      url: 'URL',
      subscription: 'Subscription',
      stock: 'Stock',
      actions: {
        label: 'Actions',
        add: 'Add',
        impersonate: 'Impersonate',
      },
      custom_app: 'Custom app',
      custom_tag: 'Custom Tag',
      status: 'Status',
    },
    popover: {
      editStock: 'Modify stock',
      viewClient: 'View client',
      editClient: 'Edit',
      regenerateStock: 'Regenerate stock',
      downloadQRPOP: "Download QR POP",
    },
    dialog: {
      edit: {
        title: 'Modify stock',
        helper: 'QR Tags stock',
        input_label: 'Total stock',
        save_notification: "You've modified %{client} stock to %{amount} tags",
        buttons: {
          cancel: 'Cancel',
          save: 'Modify stock',
        },
      },
      add: {
        title: 'Add stock',
        helper: 'Select the amount of QR Tags to add',
        tags_amount: 'Tags amount',
        batch_name: 'Batch name',
        custom_template: 'Tag template',
        storydots_template: 'Storydots template',
        client_template: "Client's template",
        save_notification: "You've added %{amount} tags to %{client}",
        webapp_url: 'Webapp URL',
        storydots_url: 'Storydots URL',
        client_url: "Client's URL",
        tags_error: 'The value must be a number and greater than 0',
        input_label: 'Stock to add',
        generate_image_tag: 'Image tag generation',
        buttons: {
          cancel: 'Cancel',
          save: 'Add stock',
        },
      },
      view: {
        title: 'Contact data',
        representative: 'Representative',
        email: 'Email',
        phone: 'Phone',
        url: 'Url',
        close: 'Close',
      },
      regenerate: {
        title: 'Regenerate stock',
        description: 'This action will regenerate the current tags stock.',
        confirm: 'Confirm',
        cancel: 'Cancel',
        fail: 'Error regenerating stock for client ID %{clientId}',
        success: 'Stock is being regenerated for client ID %{clientId}',
        feedback: {
          title: 'Regenerating stock',
          message:
            'The current stock for this customer is being regenerated, it will be ready in a few minutes.',
          close: 'Close',
        },
      },
      config: {
        tag: {
          title: 'Custom app',
          subtitle:
            'To turn on this option, you must complete app customization first',
        },
        app: {
          title: 'Custom tag',
          subtitle:
            'To turn on this option, you must complete tag customization first',
        },
        confirm: 'Complete now',
        close: 'Maybe later',
      },
    },
  },
  client_form: {
    create_title: 'Create client',
    edit_title: 'Edit client: %{client}',
    create_subtitle:
      'To create a new client, complete the next fields and click on "save".',
    edit_subtitle:
      'To edit a client, modify the following fields and click "save".',
    confirm_discard: {
      title: 'Discard changes',
      question: 'Are you sure you want to discard your changes?',
      consequences: 'This cannot be undone.',
      discard: 'Discard changes',
      continue: 'Continue editing',
    },
    form: {
      shop_data: 'Shop data',
      name: 'Name',
      type: 'Type of commerce',
      type_select: {
        online: 'Online commerce',
        onsite: 'Onsite commerce',
      },
      custom_app_url: 'Custom App URL',
      custom_app_url_placeholder: 'https://your_custom_app_url.storydots.app',
      custom_app_url_helperText: 'https://your_custom_app_url.storydots.app',
      url: 'URL',
      contact_data: 'Contact data',
      email: 'Email',
      email_helper: 'It will be used to sign in',
      phone: 'Phone number',
      platform: 'Platform',
      tag_custom: 'Custom qr tag - for the store',
      sender_tag_custom: 'Custom physical QR Tag - for client',
      upload_image: 'Upload image',
      upload_image_helper: 'Svg or transparent png format recommended.',
      upload_image_error:
        'Invalid format, we only accept PNG or JPG/JPEG files',
      upload_image_size_error:
        'The resolution must be at least 800px height and 800px width',
      upload_video: 'Upload video',
      upload_video_error: 'Invalid format, we only accept MP4 or OGG',
      upload_video_size_error:
        'Your video is larger than 100mb or longer than 30 seconds',
      default_greeting: 'Default greeting',
      video: 'Select a video file',
      own_greeting: 'Use own greeting',
      sd_greeting: 'Use storydots greeting',
      tag_design: 'Tag design',
      invalid_image: 'Invalid image',
      webapp_custom: 'Custom experience',
      redirection_url: 'Redirection URL',
      redirection_url_access: 'Available with Unlimited Plan',
      sender_redirect_url: 'Sender redirection URL',
      redirection_time: 'Redirection time',
      dark_mode: 'Dark mode',
      hide_product: "Hide product",
      seconds: 'Seconds',
      color_palett: 'Color palette',
      custom_tag: 'Personalize tag',
      fme: 'Filters, Frames and Effects',
      urlDescription: "Enter the URL of your store",
      shopNameDescription: "Enter your shop name",
      nameDescription: "Enter your name",
      phoneDescription: "Enter your phone number",
      typeDescription: "Select store type",
      emailDescription: "Enter your email",
      platformDescription: "Choose which platform you use",
      customTagSwitch: "Custom Tag",
      customAppSwitch: "Custom App Experience",
      customAppSwitchDescription: "Turn this feature on or off",
      customTagSwitchDescription: "Turn this feature on or off",
      tag_design_description: "Enter your custom tag, preferably in png or svg format. Adjust the measurements to your liking below.",
      logoDescription: "Enter your store logo, it will be displayed in the experience.",
      colorPaletteDescription: "Select the color palette to be used in the experience. You can preview it in the carousel below.",
      redirection_url_description: "Enter the url to which the customer and the greeting creator will be redirected once the experience is finished. For example, you could redirect them back into your store to view other related items.",
      custom_app_url_description: "Enter a custom url, for example: 'https://your_custom_app_url.storydots.app",
      redirection_time_description: "Enter, in seconds, the time it takes for the client to be redirected to your redirect url.",
      dark_mode_description: "Turn dark mode on or off.",
      hide_product_description: "Hide the virtual greeting product from the store's product list.",
      storeTypeTitle: "Category",
      storeTypeDescription: "Your store category",
      category: "Category"
    },
    store_types: {...storeTypes}
  },
  tracking: {
    table: {
      sender_name: 'Sender name',
      sender_email: 'Sender email',
      receiver_name: 'Receiver name',
      receiver_email: 'Receiver email',
      date: 'Date',
      sender: 'Issuer',
      gift: 'Gift',
      receiver: 'Receiver',
      status: 'Status',
    },
  },
  receivers: {
    title: 'Receivers',
  },
  tags: {
    title_superadmin: 'QR Tags',
    title_regular: 'Orders',
    subtitle: 'Historical summary of tags emmited by your store.',
    action_card: {
      title: 'Remaining stock',
      button: 'Buy',
    },
    emptyPage: 'No QR tags have been used so far.',
    purge_button: 'Purge',
    table: {
      order: 'Order Number',
      date: 'Date',
      customer: 'Customer',
      video_recorded: 'Recorded',
      tag_received: 'Scanned',
      sender_opened: 'Sender Opened',
      replied: 'Replied',
      views: 'Views',
      redirections: 'Redirects',
      code: 'Code',
      printed: 'Printed',
      tagDownload: 'TagQR',
    },
    copied: 'The code has been copied to your clipboard',
    dialog: {
      view: {
        title: 'Code details',
        client: 'client',
        order: 'order',
        id: 'Tag ID',
        price: 'Purchase value',
        link: 'Tag link',
        code: 'code',
        sender: 'Sender',
        receiver: 'Receiver',
        date: 'Date of emission',
        recording: 'Recording',
        recording_received: 'Recording received',
        response: 'Response',
        views: 'Views',
        redirections: 'Redirections',
        close: 'Close',
      },
      purge: {
        title: 'Purge code',
        subtitle: 'You are about to purge %{code}. ¿What do you want to purge?',
        input_subtitle: 'Insert the code and the resource you want to purge',
        code: 'Code',
        options: {
          recording_reply: 'Recording and reply',
          reply: 'Only reply',
          recording: 'Only recording',
        },
        cancel: 'Cancel',
        confirm: 'Confirm',
        blankCodes: 'Please insert codes',
        singlePurgeNotification: 'Code purged successfully',
        bulkPurgeNotification: 'Codes purged successfully',
        detail: 'Details',
        purgeFailed: 'Something fail, please check your code(s)',
      },
      disable: {
        title: 'Disable code',
        subtitle:
          'Sure you want to disable the code %{code}?.',
        cancel: 'Cancel',
        confirm: 'Confirm',
        successNotification: 'Code successfully disabled',
      alreadyDisabledNotification: 'The code was already disabled',
      failedNotification: 'Something fail, please check your code'
      },
      resendEmail: {
        title: 'Resend email',
        subtitle:
          'Sure you want to resend the recording email of the code %{code}?',
        cancel: 'Cancel',
        confirm: 'Confirm',
        success: 'Email successfully sent',
        failed: 'Somtehing fail, please check the data',
      }
    },
  },
  logisticPrint: {
    orderID: 'Search by...',
    sequence: 'Search by...',
    orderItem: "Order N°",
    sequenceItem: "Sequence N°",
    sequenceColumn: "Sequence N°",
    from: 'From',
    to: 'To',
    searchButton: 'Search',
    printButton: 'Print',
    timeLabel: 'Time',
    dateLabel: 'Date',
  },
  purchases: {
    title: 'My purchases',
    active_subscription_action_card: {
      title: 'Subscription active',
      tags_per_month: '%{amount} QR Tags per month',
      pay_per_month: 'You pay $%{amount} per month',
      next_billing: 'Next billing: %{date}',
      modify_button: 'modify',
      cancel_button: 'cancel',
    },
    cancelled_subscription_action_card: {
      title: 'Subscription cancelled',
      subscription_cancelled:
        'You canceled your subscription to the %{subscription} plan',
      info: 'The subscription charge will no longer be debited.',
      reactivate_button: 'reactivate',
      see_plans_button: 'see plans',
    },
    no_subscription_action_card: {
      title: "You don't have an active subscription yet!",
      subtitle: 'Subscribe to a monthly plan and get the best value.',
      button: 'SEE PLANS',
    },
    stock_action_card: {
      title: 'Remaining stock',
      button: 'Buy',
    },
    table: {
      header: 'Payment history',
      date: 'Date',
      type: 'Type',
      product: 'Product',
      amount: 'Amount',
      status: 'Status',
      voucher: 'Voucher',
      download: 'Download',
    },
    products: {
      mini_pack: 'Mini Pack',
      plus_pack: 'Plus Pack',
      full_pack: 'Full Pack',
      entrepreneur: 'Entrepreneur',
      expansion: 'Expansion',
      professional: 'Professional',
    },
    types: {
      subscription: 'Subscription',
      pack: 'Pack',
    },
  },
  subscription_plans: {
    plans: {
      title: 'Subscription Plans',
      subtitle:
        'A fixed number of QR Tags that is renewed every month and expires at the end of each month.',
    },
    tag_packs: {
      title: 'Tags Packs',
      subtitle:
        'Single payment purchases. They are cumulative with each other and with subscriptions, and do not expire.',
    },
    subscription_cards: {
      expansion: 'Expansion',
      professional: 'Professional',
      entrepreneur: 'Entrepreneur',
      qr_tags_per_month: 'QR Tags per month',
      per_month: 'per month',
      subscribe: 'Subscribe',
      best_value: 'Best Value',
      is_active: 'Current Plan',
    },
    pack_cards: {
      buy: 'Buy',
      qr_tags: 'QR Tags',
    },
  },
  print: {
    title: 'Print',
    button: 'Print',
    document_title: 'Storydots Tags',
    subtitle: 'Print tags by client, batch',
    form: {
      clientID: 'Client',
      loading: 'Loading...',
      no_options: 'No options found.',
      batch: 'Batch name',
      batch_not_found: 'Batches not found',
      submit: 'Generate sheet',
      qr_width: 'QR width',
      tag_width: 'Tag width',
      y_position: 'Y position of the code',
      x_position: 'X position of the code',
      change_template: 'Change template',
      page_size: 'Page width',
      page_sizes: {
        a4_portrait: 'A4 Portrait',
        a4_landscape: 'A4 Landscape',
        a3_portrait: 'A3 Portrait',
        a3_landscape: 'A3 Landscape',
        a2_portrait: 'A2 Portrait',
        a2_landscape: 'A2 Landscape',
      },
      cutting_lines: 'Show cutting mark',
      code_index_ref: 'Show code ID',
      tag_spacing: 'Tag spacing',
      page_margin: 'Page margin',
      sender_tag: 'Show sender tags',
    },
    sheet: {
      client: 'Client',
      qr: 'QR',
      alt: 'Loading QR code...',
    },
  },
  stats: {
    title: 'Statistics',
    subtitle:
      'Find out about the behavior of your customers and their impact on your store.',
    gridTitles: {
      tagsQR: {
        title: 'Tags QR last 30 days',
      },
      averageTickets: {
        title: 'Average tickets last 30 days',
      },
      stats: {
        title: 'Last 6 months',
      },
      averageTicketsTracking: {
        title: 'Average Ticket Tracking',
      },
    },
    stat_cards: {
      sent: {
        title: 'Issued',
      },
      recorded: {
        title: 'Recorded',
      },
      viewed: {
        title: 'Views',
      },
      responded: {
        title: 'Responses',
      },
      averageTicketWithGreeting: {
        title: 'With Greeting',
      },
      averageTicketWithoutGreeting: {
        title: 'Without Greeting',
      },
      averageAmountWithGreeting: {
        title: 'Average amount with greeting',
      },
      averageAmountWithoutGreeting: {
        title: 'Average amount without greeting',
      },
    },
    switch: {
      withGreeting: 'With greeting',
      withoutGreeting: 'Without greeting',
    },
    codesDataGroupByMonthArrayKeys: {
      sent: 'Issued',
      recorded: 'Recorded',
      replied: 'Replied',
      views: 'Views',
    },
  },
  support: {
    title: 'Support',
    subtitle:
      'If there was problem using StoryDots, this is the place to report it.',
    done: 'Your support ticket has been sent',
    error: 'There has been an error trying to send your ticket',
    form: {
      name: {
        label: 'Your name',
        minLenght: 'Your name must have at least %{value} characters',
      },
      email: {
        label: 'Your email',
        helper: 'We will answer your query to this email.',
      },
      // I'm not sure of this one, it sounds like a medical consultation
      subject: {
        label: 'Reason for consultation',
        list: {
          buy: 'I want to buy more tags',
          print: 'I have problems printing my tags',
          bug: 'I want to report a bug',
          stock: 'There is a problem with my stock/purchase',
          help: 'I need help',
          tag: 'I want to customize my tag',
          plan: 'I want to subscribe to the profesional plan',
        },
      },
      phone: {
        label: 'Your phone number',
        optional_label: 'Your phone number (optional)',
      },
      content: {
        label: 'Describe the issue',
        placeholder:
          'Write all the information that can help us to solve your case',
        minLenght: 'Issue description must have at least %{value} characters',
      },
      submit: { label: 'Send ticket' },
    },
  },
  settings: {
    title: 'Settings',
    subtitle:
      'Adjust your preferences for permissions, alerts, and other settings.',
    notifications_table: {
      title: 'Notifications',
      headers: {
        case: 'Case',
        panel_notifications: 'Panel notifications',
        email_notifications: 'Email',
      },
      cases: {
        stock_equal_less_than_ten: 'QR Tags stock is equal or less than 10',
        stock_equal_less_than_five: 'QR Tags stock is equal or less than 5',
        stock_out: 'QR Tags are out of stock',
        greeting_more_than_ten: 'Greeting got more than 10 views',
        greeting_more_than_thirty: 'Greeting got more than 30 views',
        user_sent_greeting:
          'An user who received a greeting in the past sent a greeting',
      },
    },
    permissions_table: {
      title: 'Permissions',
      advanced_info: 'Information about my store sales',
      advanced_info_subtitle:
        'Activate this permission to access advanced information about your users',
    },
  },
  customizations: {
    title: 'Customizations',
    subtitle:
      'Create your custom tag. Configure styles and adjust the experience to your branding.',
    app_custom: {
      title: 'Custom experience',
      redirectTimeHelper: 'How long the user waits before being redirected',
      redirectUrlHelper:
        'URL of your site to which your users will be redirected after recording the greeting',
    },
    custom_tag: {
      title: 'Customize Tag',
      uploadFile: 'Try uploading your brand logo',
      uploadFileBtn: 'Upload File',
      text: 'Intro Text',
      textPlaceholder: 'Today is a special day...',
      textTitle: 'Main Text',
      textTitlePlaceholder: 'Surprise!',
      palette: 'Text color',
      continueBtn: 'Generate',
      resetBtn: 'Reset',
      logoText: 'YOUR LOGO HERE',
    },
    custom_video: {
      title: "Customize default video",
      label: "Video",
      upload_button: "Upload video",
      duration_error: "The duration of the video must be less than 1 minute.",
      size_error: "The size of the video must be less than 100MB.",
      current_video: "Selected Video",
      current_video_description: "Video currently displayed in the experience",
      video_requirements_description: "The video must be less than 1 minute and less than 100MB in size."
    },
    widget_anchor:{
      title: "Anchoring of the snippet",
      radio_group_label: "Anchor type",
      snippet_floating_label: "Floating snippet",
      snippet_anchored_label: "Pinned snippet",
      text_input_label: "Enter the classes or ids",
      warning_text: 'The "anchored snippet" option can cause problems rendering the snippet, if you have any problems contact us in the support tab or see this tutorial.',
      radio_group_label_description: "Choose the type of anchor to use. The floating snippet will always show at the bottom right of your store. The anchored snippet will try to insert the snippet into the shopping cart, this option may require additional configuration.",
      text_input_label_title: "Classes or IDs",
      text_input_label_description: "Enter the id or classes belonging to your shopping cart 'form', prepending '#' for the id or a '.' for the classes. If you don't know how to do it, please contact us in the support tab.",
    },
    spOrderTag: {
      title: 'Add tag to Order',
      copyBtn: 'Copy HTML',
      inputLabel: 'App',
      copied: 'copied',
      description: "Copy the HTML to generate a tag using order printer.",
    },
  },
  announcements: {
    title: 'Announcements',
  },
  appBar: {
    spanish: 'Spanish',
    english: 'Inglés',
    portuguese: 'Portuguese',
  },
  auth: {
    sign_up_header: 'Sign Up',
    sign_in_header: 'Sign In',
    bottom_nav: {
      terms: 'Terms and Conditions',
      privacy: 'Privacy',
      gdpr: 'GDPR',
    },
  },
  login: {
    btn: {
      sign_in: 'Sign in',
      tn_sso_sign_in: 'Sign in with Tiendanube',
    },
    link: {
      sign_up: 'Sign Up',
      forgot_password: 'Forgot password',
    },
  },
  sign_up: {
    btn: {
      sign_up: 'Sign up',
    },
    link: {
      sign_in: 'Sign In',
    },
    inputs: {
      full_name: 'Full name',
      email: 'Email',
      shop_url: 'Shop URL',
      phone: 'Phone number (optional)',
      repeat_password: 'Repeat your password',
      errors: {
        register_password:
          'Password must contain eight characters minimum, and at least one letter and one number',
        repeat_password: 'Password must be the same in both fields',
      },
    },
    errors: {
      email: 'That email is already in use',
    },
  },
  genders: {
    male: 'Male',
    female: 'Female',
    other: 'Other',
  },
  tag_status_chips: {
    pending: 'Pending',
    sent: 'Sent',
    received: 'Received',
    responded: 'Responded',
    ready: 'Ready',
  },
  response_status_chips: {
    pending: 'Pending',
    text: 'Text',
    selfie: 'Selfie',
  },
  video_status_chips: {
    pending: 'Pending',
    ready: 'Ready',
  },
  purchase_status_chips: {
    pending: 'Pending',
    aproved: 'Aproved',
    rejected: 'Rejected',
  },
  go_back: 'Go Back',
  submit_buttons: {
    save: 'Save',
    discard: 'Discard ',
  },
  admin_users: {
    title: 'Users admin',
    subtitle: 'Firebase users list',
    table: {
      id: 'ID',
      role: 'Role',
      clientID: 'Client',
      complete: 'Registered',
    },
    popover: {
      edit_password: 'Reset password',
    },
    dialog: {
      view: {
        title: 'Client data',
        role: 'Role',
        email: 'Email',
      },
    },
  },
  subscriptions_plans: {
    title: 'Subscriptions',
    subtitle: 'Choose the best plan that suites your needs',
    currentPlan: 'Your current plan is',
    remainingGreetings: 'Remaining greetings',
    subscriptionStatus: 'Subscription Status',
    statuses: {
      active: 'Up to date',
      pending: 'Pending',
      cancelled: 'Cancelled',
      paused: 'Paused',
    },
    popups: {
      outOfStock: {
        title: "You're out of greetings!",
        subtitle: 'We are convinced your customers loved the experience.',
        paragraph:
          'Remember that you can increase the number of greetings in your plan whenever you need to.',
        footer:
          'Get more greetings at a lower price by saving 30% with an annual plan.',
        footerbtn: 'See plans',
      },
      cancelSub: {
        title: 'Are you sure you want to cancel your subscription?',
        subtitle: 'You will no longer allow gifts from your store',
        cancelbtn: 'cancel',
        continuebtn: 'yes, I want to unsubscribe',
        confirmbtn: 'understood',
        confirmtitle: 'We are processing your unsubscription',
        confirmsubtitle: 'This process may take a few minutes',
      },
      warningSub: {
        title: 'You will be redirected to Mercadopago to subscribe.',
        subtitle:
          'It is very important that you return to the Dashboard to finalize the subscription.',
        caption: {
          start: 'Do it from the button',
          finish: 'Go back to site',
        },
        confirmbtn: 'Understood',
        cancelbtn: 'Go Back',
      },
    },
    container: {
      title: 'choose you plan',
      billTitle: 'Billing',
      monthly: 'Monthly',
      yearly: 'Yearly',
      sliderTitle: 'Amount of Greetings',
      planContainerTitle: 'improve plan',
      plan: {
        planNames: {
          INITIAL: 'Initial',
          ADVANCED: 'Advanced',
          UNLIMITED: 'Unlimited'
        },
        includes: {
          INITIAL: 'Includes:',
          ADVANCED: 'Everything in Initial plus:',
          UNLIMITED: 'Everything in Advanced plus:',
        },
        cancel: 'Cancel subscription',
        greetingsToBuy: 'Greetings to Buy',
        availableStarting: 'Available Starting @ grettings',
        start: 'Start',
        investment: {
          monthly: 'Monthly Investment',
          yearly: 'Yearly Investment',
        },
        current: '(Current Plan)',
        noPlan: 'no plan',
        freePlan: 'free',
        alreadyContracted: 'Update Plan',
        contact: 'Consult',
        features: {
          customLogo: 'Custom Logo',
          unlimitedStock: 'Unilimited Stock',
          onDemandSupport: 'On Demand Support',
          uniqueContentAccess: 'Unique Content Access',
          customTag: 'QR Tags Customization',
          customApp: 'App Customization',
          customPalette: 'Predefined themes with color palettes',
          fme: 'Add Filters, Visual Effects and Thematic Frames for Greetings',
          customRedirect: 'Redirect traffic to your store',
          customEmail: 'Customized mails',
          stats:
            'Dashboard access with metrics and statistics of your customers',
          abandonedCarts: 'Recovery of abandoned carts',
        },
      },
      bottomText: {
        start: "The credits are ",
        bold: "automatically renewed ",
        end: "on a monthly basis and ",
        endBold: "they are not cumulative."
      },
    },
  },
  onboarding: {
    language: "EN",
    thanksForJoining: {
      title: "¡Gracias por sumarte!",
      subtitle: "Mejorando la experiencia de compra mejorás también tu facturación",
      button: "Ir al tutorial"
    },
    wizardContent: {
      nextBtn: "Siguiente",
      beginBtn: "Empezar",
      goBackBtn: "Atrás",
      welcome: {
        title: "¡Hola! Soy Vicky",
        messages: {
          msg1: {
            0: "Te voy a acompañar en este tutorial de ",
            1: "5 minutos ",
            2: "que te recomiendo hacer completa. ",
            3: "Tené tu celular a mano.",
          },
        },
      },
      howItWorks: {
        title: "Cómo funciona",
        messages: {
          msg1: "Es importante conocer la experiencia que van a vivir tus clientes al utilizar nuestra aplicación."
        },
      },
      QRScan: {
        title: "Escaneá el QR con tu celular",
        messages: {
          msg1: {
            0: "Vamos a crear tu primer saludo virtual donde vas a ponerte en el lugar del ",
            1: "regalador ",
            2: "como así también del ",
            3: "receptor"
          },
          msg2: "¡Listo! Veamos ahora cómo nos integramos a tu tienda."
        }
      },
      storeIntegration: {
        title: "Integración",
        messages: {
          msg1: "Hoy nos integramos al carrito de tu tienda permitiendo indicar allí cuando una compra es un regalo."
        }
      },
      simulatePurchase: {
        title: "¡Simulemos una compra de regalo!",
        messages: {
          msg1: "Ya tenés elegido tu producto. Ahora veamos qué sucede cuando se indica con StoryDots que es un regalo."
        }
      },
      greetingIsAddedToCart: {
        title: "El Saludo se agrega al carrito",
        messages: {
          msg1: "Con el nombre “Saludo Virtual”. No debes eliminarlo ni ocultarlo de tus productos."
        }
      },
      quickGreetingCreation: {
        title: "Creación rápida",
        messages: {
          msg1: "Tu cliente podrá completar aquí un saludo de texto rápido. Luego podrá mejorarlo desde su email."
        }
      },
      orderEndedSuccessfully: {
        title: "El cliente finaliza la orden con éxito",
        messages: {
          msg1: "Veamos cómo hace para crear el saludo en nuetra App."
        }
      },
      emailSent: {
        title: "Enviamos un mail al cliente",
        messages: {
          msg1: "Accediendo podrá crear un saludo virtual en video, foto, voz o texto como el que ya hicisite.",
          msg2: "¿Seguís ahí? ¡Ya sólo queda un paso!"
        }
      },
      SDArrivesAsQR: {
        title: "StoryDots llega en un QR",
        messages: {
          msg1: "Tendrás que identificar las órdenes para regalo e imprimir los QR desde TiendaNube.",
          msg2: "Aprendamos a identificar e imprimir estas órdenes."
        }
      },
      printing: {
        title: "Impresión",
        messages: {
          msg1: {
            0: "Las órdenes para regalo tienen una nota adjunta con el texto ",
            1: "“Contiene tag StoryDots”",
            2: ". Deberías tildar la orden.",
          }
        }
      },
      deployOrderMenu: {
        title: "Desplegar el menú “Elegí una acción”",
        messages: {
          msg1: "Y seleccionar “Imprimir etiquetas StoryDots”.",
        }
      },
      printingSingle: {
        title: "Impresión individual",
        messages: {
          msg1: "También podrás imprimir las etiquetas de Storydots en la vista de detalle de orden."
        }
      },
      printingEnd: {
        title: "Aquí finaliza la impresión",
        messages: {
          msg1: "Vas a poder elegir distintos formatos, no te olvides de adjuntar el tag QR en una zona visible del paquete.",
        }
      }
    },
    congratulations: {
      title: "¡Felicitaciones, ya sos un experto!",
      subtitle: "Estás a un paso de obtener rendimientos como este:",
      cardTitle: {
        start: "Simulación de ",
        color: "rendimiento extra",
        end: " en tu rubro."
      },
      card1: "Cantidad de ventas",
      card2: "Extra por regalos",
      card3: "Ventas extra con StoryDots",
      button: "Finalizar"
    },
    stepper: {
      0: {
        title: "Introducción",
        subtitle: "Cómo funciona",
        tooltip: "Ir a introducción"
      },
      1: {
        title: "Integración",
        subtitle: "Al carrito de compras",
        tooltip: "Ir a integración"
      },
      2: {
        title: "Impresión",
        subtitle: "De los tags QR",
        tooltip: "Ir a impresión"
      },
    },
    skip: {
      tooltip: "Salir del tutorial",
      question: "¿Querés salir del tutorial?",
      cancel: "Cancelar",
      confirm: "Confirmar"
    }
  },
};
export default customEnglishMessages;
