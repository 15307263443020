import React from 'react';
import { useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import PageTitle from '../../components/PageTitle';
import PageContainer from '../../components/PageContainer';
import NewUserForm from './NewUserForm';
import RegistrationComplete from './RegistrationCompleted';
import HomeScreen from './HomeScreen';
import HomeIcon from '@material-ui/icons/Home';

const Dashboard: React.FC = () => {
  const clientID = useSelector((state:ReduxState) => state.auth?.decoded.clientID);
  const registrationState = useSelector((state:ReduxState) => state.auth?.decoded.state);
  const role = useSelector((state:ReduxState) => state.auth?.decoded.role);
  const translate = useTranslate();

  return (
    <PageContainer>
      <PageTitle
        title={translate('dashboard.title')}
        icon={<HomeIcon />}
        subtitle={translate('dashboard.subtitle')}
      />
      { (clientID||role==="SUPERADMIN") ?
        <HomeScreen />
        :
        (registrationState==='REGISTERED') ? <RegistrationComplete /> : <NewUserForm />
      }
    </PageContainer>
  );
};

export default Dashboard;