import * as React from 'react';
import { FC, useEffect } from 'react';
import {
  ListBase,
  Datagrid,
  DateField,
  NumberField,
  ListProps,
  FunctionField,
  SimpleList,
  Pagination,
  ReduxState,
} from 'react-admin';
import { useTranslate } from 'ra-core';
import PageTitle from '../../components/PageTitle';
import StockActionCard from '../../components/StockActionCard';
import SubscriptionActionCard from './SubscriptionActionCard';
import { ProductField, TypeField } from './PurchaseFields';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PageContainer from '../../components/PageContainer';
import { PurchaseStatusField } from '../../components/StatusFields';
import {
  makeStyles,
  useMediaQuery,
  Card,
  CardHeader,
  Box,
  Button,
} from '@material-ui/core';
import { parseTimestamp } from '../../Utils';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_SUBSCRIPTION } from '../../config';

const useListStyles = makeStyles((theme) => ({
  datagrid: {
    borderCollapse: 'unset',
    '& thead': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      fontSize: '1.2rem',
      '& th': {
        paddingTop: '18px',
        paddingBottom: '8px',
      },
    },
  },
  centeredCell: {
    textAlign: 'center',
  },
  leftAlignCell: {
    textAlign: 'left',
  },
}));

const PurchasesList: FC<ListProps> = (props) => {
  const translate = useTranslate();
  const classes = useListStyles();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const subscriptionState = useSelector((state:ReduxState)=>state.subscription);

  useEffect(()=>{
    dispatch({type:FETCH_SUBSCRIPTION});
  },[dispatch]);

  return (
    <PageContainer>
      <PageTitle
        title={translate('purchases.title')}
        icon={<CreditCardIcon />}
      />
      <Box display="flex" flexWrap="wrap" justifyContent="flex-end">
        <Box flexGrow={1} marginBottom="20px">
          <SubscriptionActionCard subscription={subscriptionState.data?.subscription} isLoading={subscriptionState.isLoading} />
        </Box>
        <Box marginBottom="20px">
          <StockActionCard
            info={{
              title: translate('purchases.stock_action_card.title'),
              value: subscriptionState.data?.stockLeft,
            }}
            title={translate('purchases.stock_action_card.button')}
            callback={() => alert('hola')}
          />
        </Box>
      </Box>
      <ListBase
        {...props}
        exporter={false}
        bulkActionButtons={false}
        actions={false}
      >
        <Card>
          <CardHeader title={translate('purchases.table.header')} />
          {isSmall ? (
            <SimpleList
              linkType="show"
              primaryText={(record:any) => parseTimestamp(record.date, navigator.language)}
              secondaryText={(record:any) => (
                <>
                  <TypeField label="purchases.table.type" record={record} />
                  {' - '}
                  <ProductField
                    label="purchases.table.product"
                    record={record}
                  />
                </>
              )}
              tertiaryText={(record:any) => (
                <PurchaseStatusField status={record?.status} />
              )}
            />
          ) : (
            <Datagrid rowClick="show" className={classes.datagrid}>
              <DateField label="purchases.table.date" source="date" locales={navigator.language} />
              <TypeField label="purchases.table.type" />
              <ProductField label="purchases.table.product" />
              <NumberField
                headerClassName={classes.leftAlignCell}
                cellClassName={classes.leftAlignCell}
                label="purchases.table.amount"
                source="amount"
                style={{ fontWeight: 700 }}
                options={{ style: 'currency', currency: 'ARS' }}
              />
              <FunctionField
                source="status"
                label="purchases.table.status"
                render={(record:any) => (
                  <PurchaseStatusField status={record?.status} />
                )}
              />
              <FunctionField
                headerClassName={classes.centeredCell}
                cellClassName={classes.centeredCell}
                label="purchases.table.voucher"
                render={(record:any) =>
                  record?.status === 1 && (
                    <Button color="primary">
                      {translate('purchases.table.download')}
                    </Button>
                  )
                }
              />
            </Datagrid>
          )}
          <Pagination />
        </Card>
      </ListBase>
    </PageContainer>
  );
};

export default PurchasesList;
