import React, { useState } from "react";
import { useListContext } from "react-admin";
import { TextField as MuiTextField, Select, MenuItem} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'end',
    marginRight: 20,
  },
  input: {
    maxWidth: 150,
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  select: {
    marginRight: 0
  }
}))

interface rangeProps {
  from: string
  to: string
}

interface IProps {
  setDatetimeFilterApplied: React.Dispatch<React.SetStateAction<null | rangeProps>>
}

const CustomSearch = ({setDatetimeFilterApplied}:IProps) => {
  const { setFilters, displayedFilters, filterValues  } = useListContext();
  const [inputType, setInputType] = useState<string>(Object.keys(filterValues)[0] || 'orderID');
  const [inputValue, setInputValue] = useState<string>(filterValues[Object.keys(filterValues)[0]] || '')
  const classes = useStyles();
  const translate = useTranslate();

  const onChangeSelect = (event: any) => {
    setInputType(event.target.value)
  }

  const onChangeInput = (event: any) => {
    setInputValue(event.target.value)
    setDatetimeFilterApplied(null);
    setFilters({[event.target.name]: event.target.value}, displayedFilters);
  }

  return (
    <div className={classes.root}>
      <MuiTextField
        className={classes.input}
        value={inputValue}
        name={inputType}
        label={translate(`logisticPrint.${inputType}`)}
        onChange={onChangeInput}
      />
      <Select
        value={inputType}
        defaultValue={inputType}
        onChange={onChangeSelect}
        className={classes.select}
      >
          <MenuItem value={'orderID'}>{translate('logisticPrint.orderItem')}</MenuItem>
          <MenuItem value={'sequence'}>{translate('logisticPrint.sequenceItem')}</MenuItem>
      </Select>
    </div>
  )
}

export default CustomSearch;