// VENDOR
import firebase from 'firebase/compat/app'
import { RAFirebaseOptions } from 'react-admin-firebase';

// CREDENTIALS
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRE_BASE_KEY,
  authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIRE_BASE_DB_URL,
  projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
  measurementId: process.env.REACT_APP_FIRE_BASE_MEASURMENT_ID,
};

// INITIALIZER
const app = firebase.initializeApp(firebaseConfig);

// OPTIONS
const firebaseOptions: RAFirebaseOptions = {
  app,
  persistence:
    process.env.NODE_ENV === 'test'
      ? 'none'
      : 'local',
};

export { firebaseOptions, firebaseConfig, app };
