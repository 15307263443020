import React from 'react';
import { Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: 24,
    marginBottom: 24,
  },
  smallDivider: {
    marginTop: 24,
  },
}));

interface IPageDividerProps {
  small?: boolean;
}

const PageDivider: React.FC<IPageDividerProps> = ({ small }) => {
  const classes = useStyles();
  if (small) {
    return <div className={classes.smallDivider}></div>;
  } else return <Divider variant="fullWidth" className={classes.divider} />;
};

export default PageDivider;
