import React, { useState } from 'react';
import { makeStyles, Button, CircularProgress, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField } from '@material-ui/core';
import { useTranslate, useNotify } from 'react-admin';
import TitledDialog from '../../../components/TitledDialog';
import { useForm, Controller } from 'react-hook-form';
import axios from "axios";
import config from '../../../config';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  fields: {
    marginBottom: 15
  },
  textField: {
    marginBottom: 15,
    width: '100%'
  }
}));


interface IPurgeDialogProps {
  open: boolean;
  onClose: () => void;
  tag?: string;
}

const PurgeDialog: React.FC<IPurgeDialogProps> = ({
  tag,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, control, register, watch } = useForm();
  const notify = useNotify();
  const token = useSelector((state: ReduxState) => state.auth?.token);
  const tagInput: string = watch("codes");
  const purgeOptionInput: string = watch("option")
  const uniqueAndNotNull = (value: any, index: any, self: any) => {
    if (value) {
      return self.indexOf(value) === index;
    }
  };
  const onSubmit = (values: any) => {
    const processedCodes: string[] = tag ? [tag] : tagInput ? (tagInput.replace(/ /g, "").split(",").filter(uniqueAndNotNull)) : [];
    if (processedCodes.length < 1) {
      return notify('tags.dialog.purge.blankCodes', "info");
    } else {
      setIsLoading(true);
      axios.post(`${config.AWS_API_SITE}/purge`, {
        stories: processedCodes,
        purgeOption: values.option
      }, /*{
        headers: {
          Authorization: `Bearer ${token}`
        }
      }*/).then((r) => {
        notify(processedCodes.length <= 1 ? 'tags.dialog.purge.singlePurgeNotification' : 'tags.dialog.purge.bulkPurgeNotification', "info");
        onClose();
        setIsLoading(false);
      }).catch((e) => {
        setIsLoading(false);
        notify('tags.dialog.purge.purgeFailed', "info");
        console.error(e);
      });
    }
  }
  return (
    <TitledDialog title={translate(`tags.dialog.purge.title`)} subtitle={tag ? translate(`tags.dialog.purge.subtitle`, { code: tag }) : translate(`tags.dialog.purge.input_subtitle`)} onClose={onClose} open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!tag && <TextField
          name="codes"
          variant="outlined"
          type="text"
          label={translate(`tags.dialog.purge.code`)}
          className={classes.textField}
          inputProps={{
            ref: register
          }}
        />}
        <FormControl component="fieldset" name="option">
          <Controller
            rules={{ required: true }}
            control={control}
            name="option"
            as={
              <RadioGroup>
                <FormControlLabel value="storyAndReply" control={<Radio color="primary" />} label={translate(`tags.dialog.purge.options.recording_reply`)} />
                <FormControlLabel value="reply" control={<Radio color="primary" />} label={translate(`tags.dialog.purge.options.reply`)} />
                <FormControlLabel value="story" control={<Radio color="primary" />} label={translate(`tags.dialog.purge.options.recording`)} />
              </RadioGroup>}
          />
        </FormControl>
        <div className={classes.buttons}>
          <Button disabled={isLoading || !purgeOptionInput} startIcon={isLoading ? <CircularProgress size="1rem" /> : undefined} color="primary" type="submit">{translate('tags.dialog.purge.confirm')}</Button>
          <Button color="primary" onClick={onClose}>{translate('tags.dialog.purge.cancel')}</Button>
        </div>
      </form>
    </TitledDialog>
  );
};

export default PurgeDialog;

export const usePurgeDialog = () => {
  const [purgeDialogIsOpen, setPurgeDialogIsOpen] = useState(false);
  return {
    purgeDialogIsOpen,
    purgeDialogOnClick: () => setPurgeDialogIsOpen(true),
    purgeDialogOnClose: () => setPurgeDialogIsOpen(false)
  };
};
