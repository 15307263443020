// VENDOR
import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Button,
  TextField,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import PageTitle from '../../components/PageTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useTranslate, useNotify, ReduxState } from 'react-admin';
import PageContainer from '../../components/PageContainer';
import { useForm, Controller } from 'react-hook-form';
import config from '../../config';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { SUPPORT_CASES } from '../../config';
import useQuery from 'Utils/hooks/useQuery';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
    },
    input: {
      width: '100%',
      color: 'black',
    },
    field: {
      marginBottom: theme.spacing(4),
      width: '100%',
      '& fieldset': {
        borderWidth: 2,
      },
    },
    button: {
      alignSelf: 'flex-end',
    },
    inputError: {
      color: theme.palette.primary.main,
    },
    supportCard: {
      maxWidth: 640,
      paddingTop: theme.spacing(0.5),
    },
    supportCardContent: {
      padding: theme.spacing(3),
    },
  }),
  { name: 'RaLoginForm' },
);

const Support: React.FC = () => {
  const translate = useTranslate();
  const query = useQuery();
  const subject = query.get('subject');
  const { errors, control, register, handleSubmit } = useForm();
  const [isLoading, setLoadingState] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const email = useSelector((state: ReduxState) => state.auth?.decoded.email);
  const onSubmit = handleSubmit((values) => {
    setLoadingState(true);
    axios
      .post(`${config.AWS_API}/contactUs`, { ...values, email })
      .then((res) => {
        notify('support.done', 'info');
      })
      .catch((err) => {
        notify('support.error', 'error');
      })
      .finally(() => setLoadingState(false));
  });
  return (
    <PageContainer>
      <PageTitle
        title={translate('support.title')}
        icon={<HelpOutlineIcon />}
        subtitle={translate('support.subtitle')}
      />
      <Card className={classes.supportCard}>
        <CardContent className={classes.supportCardContent}>
          <form className={classes.form} onSubmit={onSubmit}>
            <TextField
              error={errors.name}
              helperText={errors.name?.message || null}
              name="name"
              className={classes.field}
              variant="outlined"
              inputProps={{
                className: classes.input,
                ref: register({
                  required: translate('ra.validation.required'),
                  minLength: {
                    value: 8,
                    message: translate('support.form.name.minLenght', {
                      value: 8,
                    }),
                  },
                }),
                required: true,
              }}
              type="text"
              label={translate('support.form.name.label')}
            />
            <TextField
              error={errors.phone}
              helperText={errors.phone?.message || null}
              name="phone"
              className={classes.field}
              variant="outlined"
              inputProps={{
                className: classes.input,
                ref: register({
                  required: translate('ra.validation.required'),
                }),
                required: true,
              }}
              type="text"
              label={translate('support.form.phone.label')}
            />
            <Controller
              control={control}
              name="subject"
              defaultValue={
                !subject ||
                (subject && !Object.values(SUPPORT_CASES).includes(subject!))
                  ? 'buy'
                  : subject
              }
              render={({ onChange, onBlur, value, name, ref }) => (
                <FormControl variant="outlined" className={classes.field}>
                  <InputLabel id="subject-label">
                    {translate('support.form.subject.label')}
                  </InputLabel>
                  <Select
                    labelId="subject-label"
                    id="subject"
                    value={value || ''}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    label={translate('support.form.subject.label')}
                  >
                    {Object.values(SUPPORT_CASES).map((name) => (
                      <MenuItem value={name}>
                        {translate(`support.form.subject.list.${name}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <TextField
              error={errors.content}
              helperText={errors.content?.message || null}
              name="content"
              className={classes.field}
              variant="outlined"
              rows={3}
              inputProps={{
                className: classes.input,
                placeholder: translate('support.form.content.placeholder'),
                ref: register({
                  required: translate('ra.validation.required'),
                  minLength: {
                    value: 12,
                    message: translate('support.form.content.minLenght', {
                      value: 12,
                    }),
                  },
                }),
                required: true,
              }}
              label={translate('support.form.content.label')}
              multiline
            />
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isLoading}
              className={classes.button}
            >
              {translate('support.form.submit.label')}
            </Button>
          </form>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default Support;
