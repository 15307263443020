export * from './Routes';
export * from './constants';

export default {
    AWS_API: process.env.REACT_APP_AWS_API,
    AWS_API_SITE: process.env.REACT_APP_AWS_API_SITE,
    CLOUD_FUNCTIONS_REST_API: process.env.REACT_APP_CLOUD_FUNCTIONS_REST_API,
    TN_SSO_AUTH_URL: process.env.REACT_APP_TN_SSO_AUTH_URL,
    GTM_ID: process.env.REACT_APP_GTM_ID || "",
    NODE_ENV: process.env.REACT_APP_NODE_ENV
}