import carouselWomen from './carousel-01-indumentaria-femenina.png';
import carouselWomenPT from './carousel-01-indumentaria-femenina-pt.png';
import carouselMen from './carousel-02-indumentaria-masculina.png';
import carouselMenPT from './carousel-02-indumentaria-masculina-pt.png';
import carouselBabies from './carousel-03-ropa-bebes.png';
import carouselBabiesPT from './carousel-03-ropa-bebes-pt.png';

const carouselSlides = {
  carouselWomen,
  carouselWomenPT,
  carouselMen,
  carouselMenPT,
  carouselBabies,
  carouselBabiesPT
};

export default carouselSlides;
