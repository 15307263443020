import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  TextField,
  Collapse,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import useStyles from '../styles';
import { useFormContext } from 'react-hook-form';
import { InputContainer, ArrowChevron, CardTitleWithIcon } from 'components';
import { isRequired } from 'Utils/index';
import PersonIcon from '@material-ui/icons/Person';

const ContactData: React.FC = () => {
  const [toggleCard, setToggleCard] = React.useState(false);
  const [formErrors, setErrors] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const { register, errors, watch } = useFormContext();
  const nameRef = useRef(document.createElement("div"));
  const emailRef = useRef(document.createElement("div"));

  useEffect(() => {
    if(errors.hasOwnProperty('contact_email') || errors.hasOwnProperty('contact_name')) {
      setErrors(true)
    } else {
      setErrors(false)
    }
    errors.hasOwnProperty('contact_name') && nameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    errors.hasOwnProperty('contact_email') && emailRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [Object.keys(errors).length])

  return (
    <Card className={`${classes.formPart} ${formErrors && classes.formPartError}`}>
      <CardActionArea onClick={() => setToggleCard(!toggleCard)}>
        <CardHeader
          classes={{ action: classes.cardHeader }}
          action={<ArrowChevron setToggleCard={setToggleCard} toggleCard={toggleCard} errorListener={toggleCard}/>}
          title={CardTitleWithIcon(
            translate('client_form.form.contact_data'),
            <PersonIcon color="primary" />,
          )}
        />
      </CardActionArea>
      <Collapse in={toggleCard}>
        <CardContent className={classes.formCardContent}>
          <InputContainer
            title={translate('client_form.form.email')}
            content={translate('client_form.form.emailDescription')}
            mb
          >
            <TextField
              error={Boolean(errors.contact_email)}
              helperText={errors.contact_email?.message || null}
              name="contact_email"
              variant="outlined"
              className={classes.input}
              inputProps={{
                ref: register({
                  validate: (value) => isRequired(value, ()=> setToggleCard(true)),
                }),
              }}
              type="contact_email"
              label={translate('client_form.form.email') + ' *'}
              InputLabelProps={{
                shrink: !!watch('contact_email'),
              }}
              ref={emailRef}
            />
          </InputContainer>
          <InputContainer
            title={translate('client_form.form.name')}
            content={translate('client_form.form.nameDescription')}
            mb
          >
            <TextField
              error={Boolean(errors.contact_name)}
              helperText={errors.contact_name?.message || null}
              name="contact_name"
              variant="outlined"
              className={classes.input}
              inputProps={{
                ref: register({
                  validate: (value) => isRequired(value, ()=> setToggleCard(true)),
                }),
              }}
              type="text"
              label={translate('client_form.form.name') + ' *'}
              InputLabelProps={{
                shrink: !!watch('contact_name'),
              }}
              ref={nameRef}
            />
          </InputContainer>
          <InputContainer
            title={translate('client_form.form.phone')}
            content={translate('client_form.form.phoneDescription')}
          >
            <TextField
              error={Boolean(errors.contact_phone)}
              name="contact_phone"
              variant="outlined"
              className={classes.input}
              
              inputProps={{
                ref: register,
              }}
              type="text"
              label={translate('client_form.form.phone')}
              InputLabelProps={{
                shrink: !!watch('contact_phone'),
              }}
            />
          </InputContainer>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ContactData;
