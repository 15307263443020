import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Controller } from 'react-hook-form';
import transparencyGrid from 'img/transparencyGrid.png';
import useStyles from '../../styles';
import phoneSrc from 'img/phone.png';
import phoneBrowserSrc from 'img/phoneBrowser.png';
import phoneTaskBarSrc from 'img/phoneTaskBar.png';
import axios from 'axios';
import config from 'config';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useIsSuperAdmin } from 'customHooks';

interface IColorPalette {
  id: number;
  name: string;
  client_id: number | null;
  is_active: boolean;
  muiTheme: string;
  s3_assets_prefix: string;
  flow_control: string | null;
  translations: string | null;
}

interface IColorPaletteFC {
  name: string;
  clientData: any;
  clientId?: string;
  darkMode?: boolean;
  disabled?: boolean;
}

interface IMuiThemePaletteColor {
  main: string;
  dark: string;
  light: string;
  background: string;
  border: string;
}

interface IMuiTheme {
  primary: IMuiThemePaletteColor;
  secondary: IMuiThemePaletteColor;
}

const useCheckeredStyles = (
  darkMode: boolean | undefined,
  option: number,
  color: string,
  muiTheme: IMuiTheme,
) =>
  makeStyles((theme) => ({
    checkeredBackground: {
      backgroundImage: `url("${transparencyGrid}")`,
      backgroundRepeat: 'repeat',
      backgroundColor: darkMode ? '#222222bc' : '#fff',
      transition: '0.5s',
    },
    imagePhoneContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      position: 'absolute',
      top: '78px',
      width: '178px',
      height: '300px',
    },
    imageSelectorList: {
      margin: '0 auto',
      padding: 0,
      display: 'flex',
      flexDirections: 'row',
      listStyle: 'none',
      width: '160px',
      marginBottom: '10px',
      alignItems: 'center',
      height: '20px',
    },
    imageSelectorItem: {
      cursor: 'pointer',
      margin: '0 10px',
      padding: 0,
      borderRadius: '50%',
      backgroundColor: color,
      width: '12px',
      height: '12px',
      opacity: '.4',
      transition: 'all .3s ease',
    },
    optionSelected: {
      opacity: '1',
    },
    imageCarousel: {
      left: 0,
      height: '300px',
      position: 'absolute',
      zIndex: 1,
      transition: `all .2s ease-in`,
      transform: `translateX(calc(-178px * ${option}))`,
    },
    colorBar: {
      display: 'flex',
      flexDirection: 'row',
      margin: 0,
      padding: 0,
      listStyle: 'none',
      justifyContent: 'space-evenly',
      borderRadius: '8px',
      overflow: 'hidden',
      height: '24px',
    },
    centerLoader: {
      height: '409px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    assetIsLoading: {
      opacity: '.4',
    },
    0: {
      backgroundColor: muiTheme?.primary?.main || 'white',
      width: '100%',
    },
    1: {
      width: '100%',
      backgroundColor: muiTheme?.primary?.light || 'white',
    },
    2: {
      backgroundColor: muiTheme?.primary?.dark || 'white',
      width: '100%',
    },
    3: {
      width: '100%',
      backgroundColor: muiTheme?.primary?.background || 'white',
    },
    4: {
      width: '100%',
      backgroundColor: muiTheme?.primary?.border || 'white',
    },
    5: {
      width: '100%',
      backgroundColor: muiTheme?.secondary?.main || 'white',
    },
    6: {
      width: '100%',
      backgroundColor: muiTheme?.secondary?.light || 'white',
    },
    7: {
      width: '100%',
      backgroundColor: muiTheme?.secondary?.dark || 'white',
    },
    8: {
      width: '100%',
      backgroundColor: muiTheme?.secondary?.background || 'white',
    },
    9: {
      width: '100%',
      backgroundColor: muiTheme?.secondary?.border || 'white',
    },
  }));

const ColorPaletteInput: React.FC<IColorPaletteFC> = ({
  name,
  clientId = '',
  clientData,
  darkMode,
  disabled = false,
}) => {
  const [option, setOption] = useState(0);

  const [imageSrc, setImageSrc] = useState<string>('');
  const [allAvilablePalettes, setAllAvilablePalettes] = useState([]);
  const [color, setColor] = useState(
    `${
      (clientData.app_customization?.muiTheme || '{}').palette?.primary?.main ||
      '#6242E8'
    }`,
  );
  const [defaultPalette, setDefaultPalette] = useState<IColorPalette>();
  const [isLoading, setIsLoading] = useState(false);
  const [isAssetLoading, setIsAssetLoading] = useState(false);
  const [allColors, setAllColors] = useState(
    (clientData.app_customization?.muiTheme || '{}').palette,
  );
  const translate = useTranslate();
  const localClasses = useCheckeredStyles(darkMode, option, color, allColors)();
  const classes = useStyles();
  const isSuperadmin = useIsSuperAdmin();

  useEffect(() => {
    if (clientId) {
      getAllDefaultColorPalettesAndById();
    } else {
      setAllToDefault();
    }
  }, []);

  useEffect(() => {
    const fetchImageSrc = async () => {
      let src = '';
      try {
        if (clientData.app_customization?.s3_assets_prefix) {
          const response = await axios.get(`${process.env.REACT_APP_S3_CLIENT_BUCKET}/assets/default/images/palette_preview`);
          src = response.config.url || '';
        }
      } catch (error: any) {
        if (error.code === 'MODULE_NOT_FOUND') {
          src = require('img/assets/notFound/palette_preview.png');
        }
      }
      setImageSrc(src);
    };
    fetchImageSrc();
  }, [])

  const getAllDefaultColorPalettesAndById = async () => {
    try {
      const response = await axios.get(
        `${config.AWS_API}/getDefaultPalettesAndByClientId`,
        {
          params: {
            id: isSuperadmin  ? 'superadmin' : clientId,
          },
        },
      );
      setAllAvilablePalettes(response.data.palettes);
      return response.data.palettes;
    } catch (error) {
      console.log(error);
    }
  };

  const setAllToDefault = async () => {
    setIsLoading(true);
    const palettes = await getAllDefaultColorPalettesAndById();
    const imageSrc = await axios.get(`${process.env.REACT_APP_S3_CLIENT_BUCKET}/assets/default/images/palette_preview`)
    setImageSrc(imageSrc.config.url || '');
    const defaultPalette = palettes.filter(
      (palette: IColorPalette) => palette.name === 'Default',
    );
    setDefaultPalette(defaultPalette[0]);
    setAllColors(defaultPalette[0].muiTheme.palette);
    setIsLoading(false);
  };

  const getAssetLocation = (id: number) => {
    const selectedPalette = allAvilablePalettes.filter(
      (palette: IColorPalette) => palette.id === id,
    );
    setColor(getColorsFromMuiTheme(selectedPalette[0]).primary.main);
    setAllColors(getColorsFromMuiTheme(selectedPalette[0]));
    // @ts-ignore
    return selectedPalette[0].s3_assets_prefix;
  };

  const getColorsFromMuiTheme = (app_customization: { muiTheme: any }) => {
    return app_customization.muiTheme.palette;
  };

  const changeCarouselImage = async (value: number) => {
    try {
      setIsAssetLoading(true);
      const imageSrc = await axios.get(`${process.env.REACT_APP_S3_CLIENT_BUCKET}/${getAssetLocation(value)}/images/palette_preview`)
      const imageLoad = new Image();
      imageLoad.src = imageSrc.config.url || '';
      setImageSrc(imageSrc.config.url || '');
      setIsAssetLoading(false);
    } catch (e) {
      console.log('Image not found/uploaded');
      setImageSrc(require(`img/assets/notFound/palette_preview.png`));
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      style={{
        border: '1px solid #c4c4c4',
        borderRadius: '14px',
        padding: '10px',
        marginBottom: '18px',
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          margin: '10px',
        }}
      >
        {!isLoading && allAvilablePalettes && (
          <Controller
            name={name}
            defaultValue={defaultPalette?.id}
            render={({ onChange, value, name, ref }) => (
              <FormControl variant="outlined" className={classes.input}>
                <InputLabel id="app_customizations_id-label">
                  {translate('client_form.form.color_palett')}
                </InputLabel>
                <Select
                  name={name}
                  labelId="app_customizations_id-label"
                  id="app_customizations_id"
                  value={value || defaultPalette?.id}
                  ref={ref}
                  onChange={(e) => {
                    //@ts-ignore
                    changeCarouselImage(e.target.value);
                    onChange(e);
                  }}
                  label={translate('client_form.form.color_palett')}
                  disabled={disabled}
                >
                  {allAvilablePalettes.map((palette: IColorPalette, index) => {
                    return (
                      <MenuItem key={index} value={palette.id}>
                        {palette.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          margin: '10px',
        }}
      >
        <ul className={localClasses.colorBar}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
            <li key={index} className={localClasses[index]}></li>
          ))}
        </ul>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          margin: '10px',
        }}
      >
        <div
          className={`${classes.imageContainer} ${
            localClasses.checkeredBackground
          } ${isAssetLoading && localClasses.assetIsLoading}`}
        >
          {isAssetLoading ? (
            <div className={localClasses.centerLoader}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <img
                src={phoneBrowserSrc}
                alt=""
                style={{
                  width: '178px',
                  position: 'absolute',
                  top: '28px',
                  zIndex: 2,
                }}
              />
              <img src={phoneSrc} alt="" style={{ zIndex: 3 }} />
              <img
                src={phoneTaskBarSrc}
                alt=""
                style={{
                  zIndex: 2,
                  width: '178px',
                  position: 'absolute',
                  bottom: '28px',
                }}
                onLoad={() => setIsAssetLoading(false)}
              />
              <div className={localClasses.imagePhoneContainer}>
                <img
                  src={imageSrc}
                  alt=""
                  className={localClasses.imageCarousel}
                />
              </div>
              {/* 
      Tamaño de las imagenes/proporción (Phone) para que entren bien:
      415px de ancho por 700px de alto
      O una proporcion de 0.5928571428
    */}
            </>
          )}
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ paddingTop: '0', margin: '10px 0 0 0' }}
      >
        <ul className={localClasses.imageSelectorList}>
          {[0, 1, 2, 3, 4].map((index) => (
            <li
              className={`${localClasses.imageSelectorItem} ${
                option === index && localClasses.optionSelected
              }`}
              onClick={() => setOption(index)}
              key={index}
            ></li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};

export default ColorPaletteInput;
