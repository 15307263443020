import React, { useContext } from 'react';
import { useTranslate } from 'react-admin';
import { useRedirect } from 'react-admin';
import { OnboardingContext } from '../context/OnboardingContext';
import Button from '@material-ui/core/Button';
import storydotsLogo from '../../../img/onboardingAssets/SD-logo-white.svg';
import him from '../../../img/onboardingAssets/el.png';
import her from '../../../img/onboardingAssets/ella.png';
import useSendGTMEvent from "Utils/hooks/useSendGTMEvent";

function Congratulations () {
  const translate = useTranslate();
  const redirect = useRedirect();
  const { slideN, styles, palette, storeStats } = useContext(OnboardingContext);
  const [slideToShow, setSlideToShow] = slideN; // eslint-disable-line @typescript-eslint/no-unused-vars
  const onboardingStyles = styles;
  const { manrope, greenGradientBG, whiteBG, pAbsolute, pRelative, w100, h100, mh100vh, m0, mb1, paddingLg, mrAuto, mbAuto, mlAuto, dFlex, column, alignCenter, justifyCenter, justifyBetween, radiusSm, radiusMd, textWhite, textPrimary, textCenter, hiddenOverflow, noBorder, uppercase, paperShadow, pointer} = onboardingStyles();
  const { primary, secondary } = palette;

  const handleFinish = () => redirect('/#');

  useSendGTMEvent("onboarding finish");

  return (
    <div
      className={[manrope, pRelative, w100, h100, mh100vh, dFlex, column, textWhite, textCenter, mbAuto].join(' ')}
      style={{height: "49.48vw"}}
    >
      <img
        src={storydotsLogo}
        alt="Storydots logo"
        className={[pAbsolute].join(' ')}
        style={{width: "14vw", top: "3vw", left: "4vw"}}
      />
      <img
        src={him}
        alt="a man"
        className={[pAbsolute, mbAuto].join(' ')}
        style={{zIndex: "6", height: "33.854vw", top: "11.09375vw", left: "14.5833vw"}}
      />
      <div 
        className={[h100, dFlex, column].join(' ')}
        style={{marginRight: "21.614853vw", marginLeft: "24.53125vw"}}
      >
        <h1
          className={[mb1].join(' ')}
          style={{marginTop: "4.89583vw", fontWeight: "600", fontSize: "1.67vw"}}
        >{translate('onboarding.congratulations.title')}</h1>
        <h2
          className={[m0].join(' ')}
          style={{marginBottom: "2.864583vw", fontWeight: "400", fontSize: "1.25vw"}}
        >{translate('onboarding.congratulations.subtitle')}</h2>
        <div
          className={[pRelative, whiteBG, paddingLg, dFlex, column, justifyCenter, radiusMd, hiddenOverflow, paperShadow].join(' ')}
          style={{width: "53.85417vw", height: "25.26vw"}}
        >
          <h2
            className={[m0].join(' ')}
            style={{marginBottom: "1.67vw", color: "black", fontSize: "1.4583vw", fontWeight: "600"}}
          >
            {translate('onboarding.congratulations.cardTitle.start')}
            <span style={{color: `${primary.main}`}}>{translate('onboarding.congratulations.cardTitle.color')}</span>
            {translate('onboarding.congratulations.cardTitle.end')}
          </h2>
          <div 
            className={[dFlex, justifyBetween, textPrimary].join(' ')}
            style={{marginBottom: "1.979vw"}}
          >
            <div
              className={[whiteBG, dFlex, column, alignCenter, justifyCenter, paperShadow].join(' ')}
              style={{width: "16.7vw", height: "10.15625vw", padding: "0px 1.25vw", borderRadius: "1.67vw"}}
            >
              <p style={{margin: "0", fontSize: "2.91vw", fontWeight: "700", lineHeight: "4.375vw"}}>
                {storeStats.total_sales || 122}
              </p>
              <p style={{margin: "0", fontSize: "1.25vw", fontWeight: "500"}}>
                {translate('onboarding.congratulations.card1')}
              </p>
            </div>
            <div
              className={[whiteBG, dFlex, column, alignCenter, justifyCenter, paperShadow].join(' ')}
              style={{width: "13.4375vw", height: "10.15625vw", padding: "0px 1.25vw", borderRadius: "1.67vw"}}
            >
              <p style={{margin: "0", fontSize: "2.91vw", fontWeight: "700", lineHeight: "4.375vw"}}>
                35%
              </p>
              <p style={{margin: "0", fontSize: "1.25vw", fontWeight: "500"}}>
                {translate('onboarding.congratulations.card2')}
              </p>
            </div>
            <div
              className={[whiteBG, dFlex, column, alignCenter, justifyCenter, paperShadow].join(' ')}
              style={{width: "16.7vw", height: "10.15625vw", padding: "0px 1.25vw", borderRadius: "1.67vw"}}
            >
              <p style={{margin: "0", fontSize: "2.91vw", fontWeight: "700", lineHeight: "4.375vw"}}>
                { storeStats?.total_sales ? Math.ceil(storeStats.total_sales * 0.35) : 43 }
              </p>
              <p style={{margin: "0", fontSize: "1.25vw", fontWeight: "500"}}>
                {translate('onboarding.congratulations.card3')}
              </p>
            </div>
          </div>
          <div
            className={[greenGradientBG, m0, mrAuto, mbAuto, mlAuto, radiusSm].join(' ')}
            style={{boxShadow: `0px 0px 2.5vw -0.52vw ${primary.light}`}}
          >
            <Button
              onClick={handleFinish}
              className={[greenGradientBG, m0, radiusSm, noBorder, textWhite, uppercase, paperShadow, pointer].join(' ')}
              style={{width: "19.74vw", height: "3.125vw", fontSize: "1.15vw", fontWeight: "600", letterSpacing: "0.46px"}}
            >{translate('onboarding.congratulations.button')}</Button>
          </div>
          <div
            className={[pAbsolute, w100].join(' ')}
            style={{bottom: "0", boxShadow: `0px 0px 4.17vw 3.125vw ${secondary.main}`}}
          />
          <div
            className={[pAbsolute].join(' ')}
            style={{bottom: "0", width: "20%", left: "10%", boxShadow: `0px 0px 4.17vw 0.52vw ${primary.light}`}}
          />
          <div
            className={[pAbsolute].join(' ')}
            style={{bottom: "0", width: "20%", left: "60%", boxShadow: `0px 0px 4.17vw 0.52vw ${primary.light}`}}
          />
        </div>
      </div>
      <img
        src={her}
        alt="a woman"
        className={[pAbsolute, mbAuto].join(' ')}
        style={{height: "31.4583vw", top: "10.78125vw", right: "12.0833vw"}}
      />
    </div>
  );
}

export default Congratulations;
