import React from 'react';
import { Link, LinkProps, makeStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    fontSize: '.875rem',
    display: 'flex',
    alignContent: 'center',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
    '& svg': {
      width: 16,
    }
  },
}));

interface ILinkWithChevronProps extends LinkProps {
  title: string;
}

const LinkWithChevron: React.FC<ILinkWithChevronProps> = (props) => {
  const classes = useStyles();
  const { title, className, ...linkProps } = props;
  return (
    <Link {...linkProps} className={`${classes.root} ${className}`}>
      {title} <ChevronRightIcon />
    </Link>
  );
};

export default LinkWithChevron;
