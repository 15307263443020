import React from 'react';
import { useRedirect } from 'react-admin';
import ActiveSubscriptionActions from './ActiveSubscriptionActions';
import NoSubscriptionActions from './NoSubscriptionActions';
import CancelledSubscriptionActions from './CancelledSubscriptionActions';
import { Skeleton } from '@material-ui/lab';
import { makeStyles, Card } from '@material-ui/core';
import { Subscription, SubscriptionStatus } from './types';

interface ISubscriptionActionCardProps {
  subscription?: Subscription;
  isLoading?: boolean;
}

const useStyles = (status?:SubscriptionStatus, isLoading?:boolean) => makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: '15px',
    alignItems: 'center',
    minWidth: '15rem',
    backgroundColor: isLoading ? 'white' : status === 'active' ? theme.palette.secondary.dark : '#fff',
    flexGrow: 1,
  },
}));

const SubscriptionActionCard: React.FC<ISubscriptionActionCardProps> = ({
  subscription,
  isLoading,
}) => {
  const redirect = useRedirect();
  const classes = useStyles(subscription?.status, isLoading)();
  return (
    <Card className={classes.root}>
      {isLoading && <Skeleton width="100%" height="100%"/>}
      {subscription?.status === 'active' && (
        <ActiveSubscriptionActions
          subscription={subscription}
          callback={() => redirect('/subscription-plans')}
        />
      )}
      {subscription?.status === 'none' && (
        <NoSubscriptionActions
          callback={() => redirect('/subscription-plans')}
        />
      )}
      {subscription?.status === 'cancelled' && (
        <CancelledSubscriptionActions
          subscription={subscription}
          callback={() => redirect('/subscription-plans')}
        />
      )}
    </Card>
  );
};

export default SubscriptionActionCard;
