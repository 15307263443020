import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ISpinnerWrapperProps {
  isLoading?: boolean;
}

const SpinnerWrapper: React.FC<ISpinnerWrapperProps> = ({
  children,
  isLoading,
}) => {
  return (
    <>
      {isLoading ? <div style={{height: "65vh", display: "flex", alignItems: "center", justifyContent: "center"}}><CircularProgress /></div> : children}
    </>
  );
};

export default SpinnerWrapper;
