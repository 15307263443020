export default {
  clothing: "Indumentaria",
  clothing_accesories: "Accesorios de indumentaria",
  health_beauty: "Salud y Belleza",
  home_garden: "Casa y Jardín",
  gifts: "Regalos",
  food_drinks: "Comida y Bebida",
  electronics_it: "Electrónica/IT/Computación",
  antiques: "Antigüedades",
  art: "Arte",
  cars: "Automóviles",
  industrial: "Construcción/Industrial",
  sports: "Deportes/Recreación",
  digital: "Digital/Bienes Digitales",
  education: "Educación",
  office_supplies: "Insumos de oficina",
  jewelry: "Joyas/Relojes",
  toys: "Juguetes/Juegos/Hobbies",
  books: "Libros/Revistas",
  pets: "Mascotas",
  music_movies: "Música/Películas",
  services: "Servicios",
  travel: "Viajes",
  erotics: "Erótica",
  bookstore_graphic: "Librería/Gráfica",
  equipment_machines: "Equipamento/Maquinaria",
  other: "Otro"
}