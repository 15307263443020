import React from 'react';
import { Card, makeStyles, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1.5),
    textAlign: 'right',
    alignItems: 'center',
    marginLeft: 'auto',
    minWidth: 287,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  value: {
    fontSize: '2.4rem',
    lineHeight: '2.4rem',
    fontFamily: 'Manrope',
    fontWeight: 600,
    marginLeft: theme.spacing(1.5),
  },
  infoTitle: {
    color: 'grey',
    fontSize: '0.8125rem',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
    lineHeight: '1em',
    maxWidth: 85,
  },
  button: {
    marginLeft: theme.spacing(1.5),
    flexShrink: 0,
  }
}));

interface IStockActionCardProps {
  info: {
    title: string;
    value: any;
  };
  title: string;
  callback: () => void;
  isLoading?: boolean;
}

const StockActionCard: React.FC<IStockActionCardProps> = ({
  info,
  title,
  isLoading,
  callback,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      {isLoading ? (
        <Skeleton variant="text" height="3rem" width="100%" />
      ) : (
        <>
          <span className={classes.infoTitle}>{info.title}</span>
          <span className={classes.value}>{info.value}</span>
          <Button
            color="primary"
            onClick={callback}
            variant="contained"
            className={classes.button}
          >
            {title}
          </Button>
        </>
      )}
    </Card>
  );
};

export default StockActionCard;
