import React, { useState } from 'react';
import { Switch, makeStyles } from '@material-ui/core';
import axios from 'axios';
import config from '../../../config';
import { useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import { get } from 'lodash';

const useStyles = makeStyles({  
  switch_track: {
      backgroundColor: "#000",
      opacity: 0.38,
      "$checked$checked + &": {
        // Controls checked color for the track
        opacity: 0.7,
        backgroundColor: "#00A48B"
      },
  },
  switch_base: {
      color: "#fafafa",
      "&.Mui-disabled": {
          color: "#ccc"
      },
      "&.Mui-checked": {
          color: "#ccc"
      },
      "&.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "#ccc",
      },
  },
  switch_primary: {
      "&.Mui-checked": {
          color: "#00A48B",
      },
      "&.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "#ccc",
      },
  },
  checked: {
    "&.MuiSwitch-track + .MuiSwitch-colorSecondary + .Mui-checked" : {
      backgroundColor: "#00A48B"
    },
  },
  disabled: {
    "&.Mui-disabled": {
      color: "#ccc",
    },
  }
});

interface ISwitchFieldProps{
    resource?: ClientResource;
    fieldName: string;
    value: any;
    color?: 'primary' | 'secondary';
    //use this prop if you want to disable default interaction (axios post) and replace it with another callback
    callbackOverride?: () => void;
}


const SwitchField: React.FC<ISwitchFieldProps> = ({ resource, value, fieldName, color, callbackOverride }) => {
    //@ts-ignore
    const [switchState, setSwitchState] = useState(value);
    const [isLoading, setIsLoading] = useState(false);
    const token = useSelector((state: ReduxState) => state.auth?.token);
    const notify = useNotify();
    const handleChange = () => {
        if (resource) {
            setIsLoading(true);
            setSwitchState(!switchState);
            axios.post(`${config.AWS_API_SITE}/switchCustomsFromClient`,
                { [fieldName]: !switchState, clientID: resource.id },
                { headers: { Authorization: `Bearer ${token}` } })
                .catch((e) => {
                    notify('Server error, see console for more details.');
                    console.log(e);
                    setSwitchState((prevSwitchState: Boolean) => !prevSwitchState);
                }).finally(() => {
                    setIsLoading(false);
                })
        }
    }
    const classes = useStyles();
    return (
        <Switch
            disabled={isLoading||!resource}
            checked={switchState}
            onChange={callbackOverride || handleChange}
            name="switch"
            color={color}
            classes={color === "secondary" ? {
              track: classes.switch_track,
              switchBase: classes.switch_base,
              colorSecondary: classes.switch_primary,
              checked: classes.checked,
              disabled: classes.disabled,
            } : {}}
        />
    )
}

export default SwitchField;
