import React, { useState, FC, useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ListProps,
  FunctionField,
  SimpleList,
  useRedirect,
  ReduxState,
  useNotify,
  useRefresh,
  useGetList
} from 'react-admin';
import { useTranslate } from 'ra-core';
import PageTitle from '../../components/PageTitle';
import StockActionCard from '../../components/StockActionCard';
import QRIcon from '../../img/QRIcon';
import PageContainer from '../../components/PageContainer';
import { BooleanStatusField } from '../../components/StatusFields';
import { makeStyles, useMediaQuery, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import config, { FETCH_STATS } from '../../config';
import { parseTimestamp } from '../../Utils';
import SpinnerWrapper from '../../components/SpinnerWrapper';
import UserField from '../../components/UserField/simple';
import PopoverMenu, { usePopoverMenu } from '../../components/PopoverMenu';
import ReplayIcon from '@material-ui/icons/Replay';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AllInclusive from '@material-ui/icons/AllInclusive'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmailIcon from '@material-ui/icons/Email';
import TagView, { useTagView } from './TagView';
import PurgeDialog, { usePurgeDialog } from './PurgeDialog';
import ResendEmailDialog, { useResendEmailDialog } from './ResendEmailDialog';
import Empty from './EmptyPage';
import ActionButton from '../../components/ActionButton';
import { Container } from '@material-ui/core';
import { useIsSuperAdmin } from '../../customHooks';
import IframeResizer from 'iframe-resizer-react';
import useSendGTMEvent from "Utils/hooks/useSendGTMEvent";

const useListStyles = makeStyles((theme) => ({
  datagrid: {
    borderCollapse: 'unset',
    '& thead': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      fontSize: '1.2rem',
      '& th': {
        paddingTop: 12,
        paddingBottom: 12,
        background: 'transparent',
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          padding: '6px 10px'
        }
      }
    }
  },
  list: {
    '& [class*="RaList-content"]': {
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
  },
  simpleList: {
    padding: 0,

    '& > a': {
      display: 'block',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },

    '& [class*="RaSimpleList"]': {
      opacity: 1,
    },
  },
  codeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
  },
}));

const TagList: FC<ListProps> = (props) => {
  const refresh = useRefresh();
  const translate = useTranslate();
  const classes = useListStyles();
  const {data: resourceData, loading: listLoading} = useGetList('tags');
  const isMedium = useMediaQuery((theme: any) => '(min-width:1300px)');
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const [selectedTag, setSelectedTag] = useState<TagResource | undefined>();
  const [showIframe, setShowIFrame] = useState<boolean>(false);
  const notify = useNotify();
  const statsState = useSelector((state: ReduxState) => state.stats);
  const token = useSelector(
    (state: ReduxState) => state.auth && state.auth.token,
  );
  const isSuperadmin = useIsSuperAdmin();
  const { tagViewIsOpen, tagViewOnClick, tagViewOnClose } = useTagView();
  const {
    popoverMenuAnchorEl,
    popoverMenuIsOpen,
    popoverMenuOnClick,
    popoverMenuOnClose,
  } = usePopoverMenu();
  const { purgeDialogIsOpen, purgeDialogOnClick, purgeDialogOnClose } =
    usePurgeDialog();
  const { resendEmailDialogIsOpen, resendEmailDialogOnClick, resendEmailDialogOnClose } =
  useResendEmailDialog();
  
  useSendGTMEvent("tags")

  const onMenuClick = (event: any, resource?: TagResource) => {
    setSelectedTag(resource);
    popoverMenuOnClick(event);
  };

  const onCopy = (text?: string) => {
    navigator.clipboard
      .writeText(`${process.env.REACT_APP_FRONT_URL}/story/${text}?a=r` || '')
      .then(() => notify('tags.copied'));
  };

  const onDownloadQR = (code?: string) => {
    return window.open(`${process.env.REACT_APP_AWS_API}/qr/${code}`, '_blank');
  };

  const onPurgeClick = () => {
    setSelectedTag(undefined);
    purgeDialogOnClick();
  };

  const onPurgeClose = () => {
    refresh();
    purgeDialogOnClose();
  };

  const onResendEmailClose = () => {
    refresh();
    resendEmailDialogOnClose();
  };

  const codeIsRecorded = (codeDetails: CodeDetails | undefined) => {
    if (!codeDetails) return false;
    const { recorded_audio, recorded_text, recorded_image, recorded_video } =
      codeDetails;
    return recorded_audio || recorded_image || recorded_text || recorded_video;
  };

  useEffect(() => {
    if (token) {
      dispatch({ type: FETCH_STATS });
    }
  }, [dispatch, token]);

  useEffect(() => {
    if(!listLoading && Object.keys(resourceData).length >= 1) {
      setShowIFrame(true)
    }
  }, [Object.keys(resourceData).length])

  return (
    <PageContainer>
      <PageTitle
        title={
          isSuperadmin
            ? translate('tags.title_superadmin')
            : translate('tags.title_regular')
        }
        icon={<QRIcon />}
        subtitle={translate('tags.subtitle')}
        action={
          isSuperadmin ? (
            <ActionButton
              title={translate('tags.purge_button')}
              icon={<ReplayIcon />}
              callback={onPurgeClick}
            />
          ) : (
            <StockActionCard
              info={{
                title: translate('tags.action_card.title'),
                value: statsState.data?.stockLeft > 100 ? <AllInclusive /> :statsState.data.stockLeft,
              }}
              title={translate('tags.action_card.button')}
              callback={() => redirect('/support')}
              isLoading={statsState.statsLoading}
            />
          )
        }
      />
      {(!statsState.statsLoading && showIframe) && (
        <div>
          <IframeResizer
            src={statsState.data?.ordersIframeUrl}
            frameBorder={0}
            width={'100%'}
            // style={{marginTop: 20}}
            allowTransparency={false}
            title="metabase-dashboard"
          />
        </div>
      )}
      <SpinnerWrapper isLoading={!token}>
        <List
          {...props}
          exporter={false}
          bulkActionButtons={false}
          actions={false}
          sort={{ field: 'assigned_at', order: 'DESC' }}
          className={classes.list}
          empty={<Empty />}
        >
          {isSmall ? (
            <SimpleList
              className={classes.simpleList}
              //linkType="show"
              secondaryText={(record: any) =>
                parseTimestamp(record.created_at, navigator.language)
              }
              primaryText={(record: any) =>
                  record?.code_details[0]?.sender_email ||
                  record?.code_details[0]?.buyer_email + '*'
              }
              tertiaryText={(record: any) => (
                <div>
                  <BooleanStatusField status={record?.code_details[0]?.record} />
                      <IconButton
                        children={<MoreVertIcon />}
                        onClick={(event) => onMenuClick(event, record)}
                      />
                </div>
              )}
            />
          ) : (
            <Datagrid rowClick="edit" className={classes.datagrid}>
              {!isSuperadmin && (
                <TextField
                  label="tags.table.order"
                  source="order.order_number"
                />
              )}
              <DateField
                label="tags.table.date"
                source="assigned_at"
                locales={navigator.language}
              />
              <FunctionField<TagResource>
                label="tags.table.customer"
                source="sender_email"
                render={(record) => (
                  <UserField
                    identification={
                      record?.code_details[0]?.sender_email
                        ? record?.code_details[0]?.sender_email
                        : record?.order?.buyer_email + '*'
                    }
                  />
                )}
              />
              {isMedium && (
                <FunctionField<TagResource>
                  label="tags.table.sender_opened"
                  source="received"
                  render={(record) => (
                    <BooleanStatusField
                      status={record?.code_details[0]?.sender_opened}
                    />
                  )}
                />
              )}
              <FunctionField<TagResource>
                label="tags.table.video_recorded"
                source="recorded"
                render={(record) => (
                  <BooleanStatusField
                    status={codeIsRecorded(record?.code_details[0])}
                  />
                )}
              />
              <FunctionField<TagResource>
                  label="tags.table.tag_received"
                  source="received"
                  render={(record) => (
                    <BooleanStatusField
                      status={record?.code_details[0]?.received}
                    />
                  )}
                />
              {isMedium && (
                <FunctionField<TagResource>
                label="tags.table.replied"
                source="replied"
                render={(record) => (
                  <BooleanStatusField
                    status={record?.code_details[0]?.replied}
                  />
                )}
              />
              )}
              {(isSuperadmin && isMedium) && (
                <FunctionField<TagResource>
                  label="tags.table.printed"
                  render={(record) => (
                    <BooleanStatusField
                      status={record?.code_details[0]?.printed}
                    />
                  )}
                />
              )}
              <FunctionField<TagResource>
                label="tags.table.tagDownload"
                render={(record) => {
                  return (
                    <IconButton
                      children={<QRIcon />}
                      onClick={() => onDownloadQR(record?.code)}
                    />
                  );
                }}
              />
              {isSuperadmin && (
                <FunctionField<TagResource>
                  label="tags.table.code"
                  render={(resource) => (
                    <Container className={classes.codeContainer}>
                      {resource?.code}
                      <IconButton
                        children={<FileCopyIcon />}
                        onClick={() => onCopy(resource?.code)}
                      />
                    </Container>
                  )}
                />
              )}
              {!isSuperadmin && (
                <>
                  <TextField
                    label="tags.table.views"
                    source="totalReproduction"
                  />
                  <TextField
                    label="tags.table.redirections"
                    source="totalRedirect"
                  />
                </>
              )}
              {isSuperadmin && (
                <FunctionField<TagResource>
                  label=""
                  render={(resource) => (
                    <>
                      <IconButton
                        children={<MoreVertIcon />}
                        onClick={(event) => onMenuClick(event, resource)}
                      />
                    </>
                  )}
                />
              )}
            </Datagrid>
          )}
        </List>
        <PopoverMenu
          anchorEl={popoverMenuAnchorEl}
          isOpen={popoverMenuIsOpen}
          onClose={popoverMenuOnClose}
          menuItems={[
            {
              label: translate('tags.dialog.purge.title'),
              onClick: purgeDialogOnClick,
              icon: <ReplayIcon />,
            },
            {
              label: translate('tags.dialog.purge.detail'),
              onClick: tagViewOnClick,
              icon: <VisibilityIcon />,
            },
            {
              label: translate('tags.dialog.resendEmail.title'),
              onClick: resendEmailDialogOnClick,
              icon: <EmailIcon />,
            },
          ]}
        />
        <TagView
          open={tagViewIsOpen}
          onClose={tagViewOnClose}
          tag={selectedTag}
        />
        <PurgeDialog
          open={purgeDialogIsOpen}
          onClose={onPurgeClose}
          tag={selectedTag?.code}
        />
        <ResendEmailDialog
          open={resendEmailDialogIsOpen}
          onClose={onResendEmailClose}
          tag={selectedTag}
        />
      </SpinnerWrapper>
    </PageContainer>
  );
};

export default TagList;
