import React, { createContext, useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import config from 'config';
import onboardingPalette from './onboardingPalette';
import onboardingStyles from './onboardingStyles';
import useImagesPreloading from './useImagesPreloading';

interface props {
  children: JSX.Element | JSX.Element[];
}
interface onboardingProv {
  slideN: any[];
  palette: any;
  styles: any;
  stepper: any;
  storeStats: any;
  modal: any;
  preload: any;
}

export const OnboardingContext = createContext({} as onboardingProv);

export const OnboardingContextProvider = ({ children }:props) => {
  const [slideToShow, setSlideToShow] = useState(0);

  const palette = onboardingPalette;

  const styles = onboardingStyles;

  // Stepper variables
  const [activeStep, setActiveStep] = useState(0);
  const [introCompleted, setIntroCompleted] = useState(false);
  const [integrationCompleted, setIntegrationCompleted] = useState(false);
  const [printCompleted, setPrintCompleted] = useState(false);

  // Skip onboarding modal
  const [openModal, setOpenModal] = useState("none");
  
  // Side effects
  const token = useSelector((state: ReduxState) => state.auth?.token || false);
  const [storeStats, setStoreStats] = useState({});
  useEffect(() => {
    const getStoreStats = async () => {
      const options = {
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      fetch(`${config.AWS_API_SITE}/tiendanube/store-stats`, options)
        .then((response) => response.json())
        .then((response) => {
          setStoreStats(response);
        })
        .catch((err) => console.error(err));
    };
    if (token) getStoreStats();
  }, [token]);

  // Preload images
  const imagesPreloaded = useRef(useImagesPreloading());

  return (
    <OnboardingContext.Provider
      value={{
        slideN: [slideToShow, setSlideToShow],
        palette,
        styles,
        stepper: {
          active: [activeStep, setActiveStep],
          intro: [introCompleted, setIntroCompleted],
          integ: [integrationCompleted, setIntegrationCompleted],
          print: [printCompleted, setPrintCompleted],
        },
        storeStats,
        modal: [openModal, setOpenModal],
        preload: imagesPreloaded
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

OnboardingContextProvider.propTypes = {
  children: propTypes.any,
};
