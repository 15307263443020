import * as React from 'react';
import {
  Card,
  makeStyles,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactElement } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  body: {
    width: '100%',
    padding: 0,

    '& li': {
      borderBottom: '1px solid rgba(0,0,0,.12)',

      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  listItemRoot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listPrimary: {
    fontSize: '.75rem',
    display: 'block',
    color: 'rgba(0,0,0,.6)',
  },
  listSecondary: {
    display: 'block',
  },
  listTertiary: {
    color: 'rgba(0,0,0,.87)',
  },
}));

type ListItem = { primary: any; secondary: any; tertiary: any };

interface IListWithCustomHeaderBodyProps {
  items?: ListItem[];
}

const ListWithCustomHeaderBody: React.FC<IListWithCustomHeaderBodyProps> = ({
  items,
}) => {
  const classes = useStyles();
  return (
    <List className={classes.body}>
      {items?.map(({ primary, secondary, tertiary },index) => (
        <ListItem alignItems="center" key={index}>
          <ListItemText
            className={classes.listItemRoot}
            primary={
              <div>
                <span className={classes.listPrimary}>{primary}</span>
                <span className={classes.listSecondary}>{secondary}</span>
              </div>
            }
            secondary={
              <span className={classes.listTertiary}>
                {tertiary}
              </span>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

interface IListWithCustomHeaderProps {
  header: ReactElement;
  items?: ListItem[];
  isLoading?: boolean;
}

const ListWithCustomHeader: React.FC<IListWithCustomHeaderProps> = ({
  header,
  items,
  isLoading,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      {header}
      {items && !isLoading ? (
        <ListWithCustomHeaderBody items={items} />
      ) : (
        <Skeleton variant="rect" width="100%" height={300} />
      )}
    </Card>
  );
};

export default ListWithCustomHeader;
