// VENDOR
import * as React from 'react';
import { Route } from 'react-router-dom';
import Print from '../containers/Print';
import ClientForm from '../containers/ClientForm';
// CONTAINERS
//import Custom from '../containers/Custom';
// import Stats from '../containers/Stats';
//import Announcements from '../containers/Announcements';
// import Settings from '../containers/Settings';
import Support from '../containers/Support';

// import ProfileEdit from '../containers/Profile';
// import SubscriptionPlans from '../containers/SubscriptionPlans'

const customRoutes = [
  // <Route exact path='/stats' component={Stats} />,
  //<Route exact path='/custom' component={Custom} />,
  //<Route exact path='/announcements' component={Announcements} />,
  // <Route exact path='/settings' component={Settings} />,
  // <Route exact path='/subscription-plans' component={SubscriptionPlans} />,
  <Route exact path='/support' component={Support} />,
  <Route exact path='/print' component={Print} />,
  <Route exact path='/clientForm/:mode/:name?/:id?' component={ClientForm} />,
  // <Route exact path='/my-profile' component={ProfileEdit} />,
];

export { customRoutes };
