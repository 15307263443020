import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ShowProps,
} from 'react-admin';

const TrackingShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField label="users.table.name" source="senderName" />
        <TextField label="users.table.email" source="senderEmail" />
        <DateField label="users.table.last_activity" source="lastActivity" locales={navigator.language} />
        <TextField label="users.table.greetings_sent" source="totalSent" />
      </SimpleShowLayout>
    </Show>
  );
};

export default TrackingShow;
