import { makeStyles } from '@material-ui/core/styles';
export default (form: 'login' | 'register') =>
  makeStyles(
    (theme) => ({
      form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      },
      input: {
        width: '100%',
      },
      textField: {
        marginBottom: '28px',
        width: '100%',
      },
      button: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "8px 22px",
        position: "relative",
        left: "0%",
        right: "0%",
        top: "0%",
        bottom: "0%",
        borderRadius: "12px",
        flex: "none",
        order: 0,
        alignSelf: "stretch",
        flexGrow: 0,
        margin: "0px 0px",
      },
      tnSsoButton: {
        position: 'relative',
        marginTop: '10px',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "8px 10px",
        left: "0%",
        right: "0%",
        top: "0%",
        bottom: "0%",
        backgroundColor: "#ECEBEC",
        boxShadow:
          "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
        borderRadius: "12px",
        flex: "none",
        order: 0,
        alignSelf: "stretch",
        flexGrow: 0,
        margin: "0px 0px",
        color: "#2C3154",
        "&.MuiButton-containedPrimary:hover":{
          backgroundColor: "#c0c0c0"
        }
      },
      icon: {
        marginRight: theme.spacing(1),
      },
      cardLinks: {
        marginTop: '40px',
        marginLeft: '40px',
        marginRight: '40px',
        width: '100%',
        justifyContent: /*form === 'register' ?*/ 'center' /*: 'space-between'*/,
        '& button':{
          textAlign: 'left'
        }
      },
      inputError: {
        color: theme.palette.primary.main,
      },
      ssoLoader: {
        margin: '100px'
      },
      tnLogo: {
        marginRight: 8
      },
    }),
    { name: 'RaLoginForm' },
  );
