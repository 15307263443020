import cloudsAndArrowBG from './clouds/clouds.jpg';
import dashboardBG from './dashboard/sd-dashboard.jpg';
import cartBG from './cart/cart-01.jpg';
import cartBGPT from './cart/cart-01-PT.jpg';
import cartSnippetBG from './cart/cart-02.jpg';
import cartSnippetBGPT from './cart/cart-02-PT.jpg';
import cartProductBG from './cart/cart-03.jpg';
import cartProductBGPT from './cart/cart-03-PT.jpg';
import cartGreetingBG from './cart/cart-04.jpg';
import cartGreetingBGPT from './cart/cart-04-PT.jpg';
import cartSuccessBG from './cart/cart-05.jpg';
import cartSuccessBGPT from './cart/cart-05-PT.jpg';
import gmailBG from './gmail/gmail-01.jpg';
import tiendanubeSalesBG from './tiendanube-ventas/tn-ventas-01.jpg';
import tiendanubeSalesBGPT from './tiendanube-ventas/tn-ventas-01-PT.jpg';
import tiendanubeOrderBG from './tiendanube-ventas/tn-ventas-02.jpg';
import tiendanubeOrderBGPT from './tiendanube-ventas/tn-ventas-02-PT.jpg';
import tiendanubePrintSDBG from './tiendanube-ventas/tn-ventas-03.jpg';
import tiendanubePrintSDBGPT from './tiendanube-ventas/tn-ventas-03-PT.jpg';
import tiendanubePrintSingleSDBG from './tiendanube-ventas/tn-ventas-04.jpg';
import tiendanubePrintSingleSDBGPT from './tiendanube-ventas/tn-ventas-04-PT.jpg';
import dashboardPrintQRBG from './dashboard/sd-print.jpg';
import dashboardPrintQRBGPT from './dashboard/sd-print-PT.jpg';

export const BGsES = {
  cloudsAndArrowBG,
  dashboardBG,
  cartBG,
  cartSnippetBG,
  cartProductBG,
  cartGreetingBG,
  cartSuccessBG,
  gmailBG,
  tiendanubeSalesBG,
  tiendanubeOrderBG,
  tiendanubePrintSDBG,
  tiendanubePrintSingleSDBG,
  dashboardPrintQRBG,
}

export const BGsPT = {
  cloudsAndArrowBG,
  dashboardBG,
  cartBG: cartBGPT,
  cartSnippetBG: cartSnippetBGPT,
  cartProductBG: cartProductBGPT,
  cartGreetingBG: cartGreetingBGPT,
  cartSuccessBG: cartSuccessBGPT,
  gmailBG,
  tiendanubeSalesBG: tiendanubeSalesBGPT,
  tiendanubeOrderBG: tiendanubeOrderBGPT,
  tiendanubePrintSDBG: tiendanubePrintSDBGPT,
  tiendanubePrintSingleSDBG: tiendanubePrintSingleSDBGPT,
  dashboardPrintQRBG: dashboardPrintQRBGPT
};
