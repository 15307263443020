import React from 'react';
import { Typography, Button } from '@material-ui/core';
import Message from './index';

export const LeaveFreePlanMessage = () => (
  <Message>
    <Typography>
      Actualmente{' '}
      <span style={{ fontWeight: 800 }}>contás con un plan gratuito</span>. Te
      sugerimos <span color="primary">comenzar</span> con el que mejor se ajuste
      a tus necesidades.
    </Typography>
  </Message>
);

export const UnavailblePlanMessage = () => (
  <Message>
    <Typography>
      Tu plan actual ya no esta <strong>disponible</strong>. Podes probar
      suscribiendote a uno de los planes que aparecen abajo.
    </Typography>
  </Message>
);

export const CancelledPlanMessage = () => (
  <Message>
    <Typography>
      Has cancelado tu plan.{' '}
      <span style={{ color: '#6242E8', fontWeight: 700 }}>
        Vas a seguir contando con las funcionalidades activas{' '}
      </span>{' '}
      hasta que finalice el período de tu última facturación.
    </Typography>
  </Message>
);

export const ResumePlanMessage = ({onResumeClick}: {onResumeClick: () => void}) => (
  <Message borderRadius="25px">
    <Typography>
      <Typography
        style={{ fontWeight: 700, marginBottom: '0.6em', fontSize: '18px' }}
      >
        Tu plan actual “Plan Inicial” se encuentra
        <span style={{ color: '#6242E8' }}>“Pausado”</span>
      </Typography>
      <Typography style={{ marginBottom: '0.6em', fontSize: '18px' }}>
        Reactivá tu plan para volver a contar con todos los beneficios del
        mismo.
      </Typography>
      <Button variant="contained" color="primary" onClick={() => onResumeClick()}>
        REACTIVAR PLAN
      </Button>
    </Typography>
  </Message>
);

// export const upgradePlanMessage = () => (
//   <Typography>
//     <Typography>
//       <span style={{ fontWeight: 800 }}>
//         Tu plan actual es “{userData?.planName}“.
//       </span>
//     </Typography>
//     <Typography>
//       ¿Sabías que con el{' '}
//       <span style={{ color: '#7410E0' }}>“{higherPlan?.name}”</span> podés
//       empezar a{' '}
//       <span style={{ color: '#7410E0' }}>
//         redirigir a tu público a una página personalizada de tu tienda?
//       </span>
//     </Typography>
//   </Typography>
// );
