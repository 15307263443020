import React, { useState } from 'react';
import { makeStyles, Button, Grid, IconButton, Container } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import config from "../../../config"
import { FunctionField, useNotify, useTranslate } from 'react-admin';
import ViewField from '../../../components/ViewField';
import TitledDialog from '../../../components/TitledDialog';
import { dateFormatter } from '../../../Utils';
import { BooleanStatusField } from '../../../components/StatusFields';
import QRIcon from 'img/QRIcon';
//VideoStatusField has the values I need for this view
//Status types aren't defined yet

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  grid: {
    overflowY: 'auto',
    marginTop: 16,
    marginBottom: 16,
  },
  fields: {
    height: 55,
    marginBottom: 16,
  },
  fullGrid: {
    padding: '0 8px !important',
  },
}));

interface ITagViewProps {
  open: boolean;
  onClose: () => void;
  tag?: TagResource;
}

const TagView: React.FC<ITagViewProps> = ({ tag, open, onClose }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();

  const onCopy = (text?: string) => {
    navigator.clipboard
      .writeText(`${process.env.REACT_APP_FRONT_URL}/story/${text}?a=r` || '')
      .then(() => notify('tags.copied'));
  };

  return (
    <TitledDialog
      title={translate(`tags.dialog.view.title`)}
      clientName={tag?.code || '-'}
      onClose={onClose}
      open={open}
    >
      <Grid className={classes.grid} container spacing={2} wrap="wrap">
        <Grid item xs={12} md={6} spacing={3} style={{ flexShrink: 1 }}>
          <ViewField
            className={classes.fields}
            label={translate('tags.dialog.view.client')}
            value={tag?.client?.store_name}
          />
          <ViewField
            className={classes.fields}
            label={translate('tags.dialog.view.order')}
            value={tag?.order?.id || '-'}
          />
          <ViewField
            className={classes.fields}
            label={translate('tags.dialog.view.id')}
            value={tag?.id}
          />
          <ViewField
            className={classes.fields}
            label={translate('tags.dialog.view.link')}
            value={<a href={`${process.env.REACT_APP_AWS_API}/qr/${tag?.code}`} target="_blank"><IconButton
            children={<QRIcon />}
            /></a>
          }
          />
          <ViewField
            className={classes.fields}
            label={translate('tags.dialog.view.price')}
            value={`$${tag?.order?.order_total || 0}`}
          />
          <ViewField
            className={classes.fields}
            label={translate('tags.dialog.view.sender')}
            value={tag?.code_details[0]?.sender_email ?
              tag?.code_details[0]?.sender_email :
              tag?.order?.buyer_email + "*"}
            link
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ flexShrink: 1 }}>
          <ViewField
            className={classes.fields}
            label={translate('tags.dialog.view.date')}
            value={tag?.created_at ? dateFormatter(tag?.created_at, navigator.language) : "-"}
          />
          <ViewField
            className={classes.fields}
            label={translate('tags.dialog.view.recording')}
            value={<BooleanStatusField status={tag?.code_details[0]?.recorded} />}
          />
          <ViewField
            className={classes.fields}
            label={translate('tags.dialog.view.recording_received')}
            value={<BooleanStatusField status={tag?.code_details[0]?.received} />}
          />
          <ViewField
            className={classes.fields}
            label={translate('tags.dialog.view.response')}
            value={<BooleanStatusField status={tag?.code_details[0]?.replied} />}
          />
            <ViewField
              className={classes.fields}
              label={translate('tags.dialog.view.code')}
              value={
                <div style={{display: "flex", alignItems: "center"}}>
                {tag?.code}
                <IconButton
                  children={<FileCopyIcon style={{fontSize: "14px"}}/>}
                  onClick={() => onCopy(tag?.code)}
                />
                </div>
              }
            />
          <ViewField
            className={classes.fields}
            label={translate('tags.dialog.view.receiver')}
            value={tag?.code_details[0]?.receiver_email}
            link
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ flexShrink: 1 }}>
          <ViewField
            className={classes.fields}
            label={translate('tags.dialog.view.views')}
            value={tag?.code_details[0]?.views}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ flexShrink: 1 }}>
          <ViewField
            className={classes.fields}
            label={translate('tags.dialog.view.redirections')}
            value={tag?.code_details[0]?.redirects}
          />
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button color="primary" onClick={onClose}>
          {translate('tags.dialog.view.close')}
        </Button>
      </div>
    </TitledDialog>
  );
};

export default TagView;

export const useTagView = () => {
  const [tagViewIsOpen, setTagViewIsOpen] = useState(false);
  return {
    tagViewIsOpen,
    tagViewOnClick: () => setTagViewIsOpen(true),
    tagViewOnClose: () => setTagViewIsOpen(false),
  };
};
