import React, { useContext } from 'react';
import { OnboardingContext } from '../context/OnboardingContext';
import TimerIcon from '@material-ui/icons/Timer';

function WizardContent ({ wizardContent }:any) {
  const { styles } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const { roboto, pAbsolute, primaryGradientBG, mt0, dFlex, alignCenter, textWhite, textCenter, font20 } = onboardingStyles();

  return (
    <>
      {wizardContent.time && <div
        className={[roboto, pAbsolute, dFlex, alignCenter, primaryGradientBG, textWhite].join(' ')}
        style={{ width: "6.51vw", height: "1.71875vw", fontSize: "0.78125vw", top: "0.78125vw", left: "0.78125vw", borderRadius: "2.8646vw" }}
      >
        <TimerIcon style={{margin: "0 0.25vw 0 auto", fontSize: "1.041vw"}}/>
        <p style={{margin: "0 auto 0 0.3125vw"}}>{wizardContent.time}</p>
      </div>}
      <h2 className={[mt0, textCenter].join(' ')} style={{fontSize: "1.25vw"}}>
        {wizardContent.title}
      </h2>
      {wizardContent.messages.map((msg:any) => {
        return(<p
          className={[mt0, font20].join(' ')}
          style={{fontWeight: "500", lineHeight: "1.5625vw"}}
        >{Array.isArray(msg)
          ? msg.map((msgPart:any) => {
            return(<strong
              className={[mt0, font20].join(' ')}
              style={{textDecoration: "none", fontWeight: `${msgPart.fontWeight}`}}
            >{msgPart.text}</strong>)
          })
          : msg
        }</p>)
      })}
    </>
  );
}

export default WizardContent;