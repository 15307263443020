import React from 'react';
import { Dialog, makeStyles } from '@material-ui/core';

//this is a copy of ClientDialog, I will replace ClientDialog implementation with this component when this and
//clients-screen-fix-conflicts are merged

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: 25,
  },
  title: {
    marginBottom: 20,
    fontSize: '1.2rem',
    fontWeight: 700,
    letterSpacing: 0.15,
    fontFamily: 'Manrope',
    maxWidth: '100%',
    overflowWrap: 'break-word',
  },
  subtitle: {
    marginBottom: 25,
  },
}));

interface ITitledDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  subtitle?: string;
  clientName?: string;
  className?: string;
}

const TitledDialog: React.FC<ITitledDialogProps> = ({
  open,
  onClose,
  title,
  subtitle,
  children,
  clientName,
  className,
}) => {
  const classes = useStyles();
  return (
    <Dialog onClose={onClose} open={open}>
      <div className={`${classes.root} ${className}`}>
        <div className={classes.title}>
          {title}
          {clientName && `: ${clientName}`}
        </div>
        {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
        {children}
      </div>
    </Dialog>
  );
};

export default TitledDialog;
