export default `
<P>El
acceso y uso de este servicio digital por parte de un usuario (en
adelante el/los ¨Usuario/s¨) indica la aceptación absoluta de los
presentes Términos y Condiciones y la Política de Privacidad
aplicables al acceso, interacción con la tarjeta y sitio provistos
por Storydots (¨storydots.app¨ o el ¨Sitio¨) y de los servicios
allí ofrecidos (los ¨Servicios¨).</P>
<P> 
</P>
<P>El
uso de la tarjeta digital es libre y gratuito y ha sido entregada en
alguna tienda física luego de la compra.</P>
<P>El
usuario garantiza y declara ser mayor de 18 años, y/o en su caso,
que tiene la expresa autorización de sus padres o de sus tutores
para poder utilizar el servicio y que es competente para entender y
aceptar sin reservas las obligaciones, afirmaciones, representaciones
y garantías establecidas en estos Términos y Condiciones, como así
también cuenta con la capacidad legal para celebrar contratos. Por
su parte el sitio deslinda su responsabilidad en caso de no ser veraz
la información suministrada al respecto.</P>
<P> 
</P>
<P >En
consecuencia, todas las visitas y cada uno de los contratos y
transacciones que se realicen a través del Sitio, como asimismo sus
efectos jurídicos, quedarán regidos por estas reglas y sometidas a
la legislación aplicable en la República Argentina.</P>
<P 
</P>
<P>
<B>1. Aplicación.</B></P>
<P>
Los presentes Términos y
Condiciones Generales se aplicarán y se entenderán como formando
parte de cada uno de los actos y contratos que se ejecuten o celebren
mediante los Servicios ofrecidos a través del Sitio.</P>
<P>
<B>CUALQUIER PERSONA QUE NO
ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN
CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE ACCEDER,
INTERACTUAR O USAR EL SITIO Y/O LOS SERVICIOS.</B></P>
<P>
</P>
<P>
<B>2.Aceptación</B></P>
<P>
En forma previa a utilizar
cada uso de los Servicios ofrecidos en el Sitio, el Usuario deberá
leer, entender y aceptar todas las condiciones establecidas en los
Términos y Condiciones Generales.</P>
<P>
Si el Usuario utiliza el
Sitio significa que ha aceptado plenamente las condiciones
establecidas en los Términos y Condiciones obligándose a cumplir
expresamente con las mismas.</P>
<P>
</P>
<P>
<B>3.Tarjeta de saludo
virtual</B></P>
<P>
Los Usuarios pueden adquirir
de manera gratuita 1 (una) tarjetas o handtag de regalo StoryDots con
cada producto al comprar en las tiendas adheridas al Servicio. La
Tarjeta Virtual otorga al Usuario adquirente la posibilidad de grabar
un saludo virtual con su celular de 60 segundos de duración y el
cuál estará en línea por 30 días (el ¨Plazo¨) en su versión
gratis, pudiendo contratar mejoras dentro del Sitio.</P>
<P>
</P>
<P>
En caso de expiración,
pérdida, robo o destrucción de la Tarjeta Virtual, el Usuario
carecerá de todo y cualquier derecho a cualquier Servicio y/o a
cualquier reembolso y/o a esgrimir cualquier reclamo contra cualquier
Comercio Adherido y/o contra StoryDots).</P>
<P>
</P>
<P>
<B>4.Registración y uso</B></P>
<P>
Es obligatorio completar la
información requerida en el Sitio en todos sus campos con datos
válidos para el acceso y el uso de los Servicios.</P>
<P>
StoryDots se reserva el
derecho de dar de baja el video efectuado que se encuentre contrario
a las leyes y las buenas costumbres, sin que ello genere algún
derecho a reclamo o resarcimiento alguno. El Usuario será
responsable por todas las acciones por él efectuadas en el Sitio.</P>
<P 
</P>
<P>
<B>5. Privacidad de la
Información</B></P>
<P>
Para utilizar los Servicios
ofrecidos por StoryDots, los Usuarios deberán facilitar determinados
Datos Personales. La información personal y datos que nos
proporcione voluntariamente a través del uso del Sitio o Servicio,
se procesa y almacena en servidores. Los Datos Personales se
mantendrán bajo estricta confidencialidad y manejados conforme lo
dispone la legislación vigente bajo la responsabilidad de StoryDots.
Asimismo, la proporción de datos adicionales a los necesarios para
la utilización del Servicio, implicará su autorización para que le
enviemos boletines, newsletters o correspondencia sobre las novedades
institucionales, comerciales y promocionales de StoryDots o socio
comercial donde recibió la tarjeta, con el objetivo de
proporcionarle un servicio personalizado, tendiente a mejorar el
canal de comunicación actual con el Usuario. Los datos también
podrán utilizarse para elaborar estadísticas y estudios de mercado.

</P>
<P>
</P>
<P>
El Usuario podrá ejercer
los derechos de acceso, rectificación y/o supresión sobre sus Datos
Personales conforme lo dispuesto por la Ley 25.326. De acuerdo a lo
establecido en la Disposición 10/2008 DNPDP: &quot;La DIRECCION
NACIONAL DE PROTECCION DE DATOS PERSONALES, Órgano de Control de la
Ley Nº 25.326, tiene la atribución de atender las denuncias y
reclamos que se interpongan con relación al incumplimiento de las
normas sobre protección de Datos Personales.</P>
<P>
Asimismo, si el Usuario
desea modificar su información personal, lo puede hacer utilizando
algunos de los siguientes canales de comunicación:</P>
<P>
</P>
<P>
• Enviando un correo
electrónico a storydots.app@gmail.com</P>
<P>
</P>
<P>
Todo Usuario presta
conformidad para que la información referida a sus Datos Personales
pueda ser CEDIDA Y/O transferida a sociedades controladas por
StoryDots, controlantes de Storydots, vinculadas a StoryDots,
NACIONALES O EXTRANJERAS, para fines publicitarios, promocionales y/o
de marketing.</P>
<P>
</P>
<P>
<B>6. Privacidad de los
videos</B></P>
<P>
</P>
<P>
Todo Usuario presta
conformidad para que el video subido sea asociado a una URL única.
Cualquier persona con acceso a esa URL (de forma directa o a través
del QR asignado) tendrá acceso al video. Por ello, los videos son de
acceso público aunque no estarán listados en ningún sitio asociado
a Storydots ni serán proporcionados de manera conjunta por
Storydots. Existe la posibilidad que agentes externos se hagan de
estas URLs y videos utilizando la fuerza bruta. El usuario entiende y
presta conformidad del nivel de privacidad que estas URLs ofrecen.</P>
<P>
</P>
<P>
<B>7. Modificaciones de los
Términos y Condiciones Generales.</B></P>
<P>
StoryDots podrá modificar
los Términos y Condiciones en cualquier momento haciendo públicos
en el Sitio los términos modificados.</P>
<P>
</P>
<P>
<B>8. Violaciones del
Sistema o Bases de Datos.</B></P>
<P>
No está permitida ninguna
acción o uso de dispositivo, software, u otro medio tendiente a
interferir tanto en las actividades, en la operatoria o en bases de
datos pertenencia de StoryDots. Cualquier intromisión, tentativa o
actividad violatoria o contraria a las leyes sobre derecho de
propiedad intelectual y/o a las prohibiciones estipuladas en los
presentes Términos y Condiciones harán pasible a su responsable de
las acciones legales pertinentes, y a las sanciones previstas en el
marco legal aplicable, así como lo hará responsable de indemnizar
los daños ocasionados.</P>
<P>
</P>
<P>
<B>9. Propiedad Intelectual.</B></P>
<P>
Los contenidos de las
pantallas relativas a los Servicios de StoryDots como así también
la información, imágenes, datos, textos gráficos, fotografías,
audio y video clips, íconos, logotipos, redes, programas, bases de
datos, archivos que permiten al Usuario acceder y operar en el Sitio,
son de propiedad de StoryDots y están protegidas por las leyes y los
tratados internacionales de derecho de autor, marcas, patentes,
modelos y diseños industriales. El uso indebido y la reproducción
parcial o total de tales contenidos quedan terminantemente
prohibidos, salvo autorización previa, expresa y por escrito de
StoryDots.</P>
<P>
</P>
<P>
<B>10. Otros sitios web.</B></P>
<P>
StoryDots no tendrá
responsabilidad sobre la información proporcionada por otros sitios
web y las consecuencias que de ello se deriven. StoryDots no
garantiza, ni avala de ninguna forma el acceso, el uso, la
información o el contenido de cualquier otro sitio web o portal
cuando dicho acceso se efectúe desde o hacia este, sea que el
mencionado acceso se realice mediante link, banner, botón o
cualquier dispositivo disponible en la red.</P>
<P>
El Sitio puede contener
enlaces a otros sitios web lo cual no indica que sean propiedad u
operados por StoryDots. Toda vez que StoryDots no tiene control sobre
tales sitios, NO será responsable por los contenidos, materiales,
acciones y/o servicios prestados por los mismos, ni por daños o
pérdidas ocasionadas por la utilización de los mismos, sean
causadas directa o indirectamente. La presencia de enlaces a otros
sitios web no implica una sociedad, relación, aprobación, respaldo
de StoryDots a dichos sitios y sus contenidos.</P>
<P>
</P>
<P>
<B>11. Sanciones. Suspensión
de operaciones.</B></P>
<P>
Sin perjuicio de otras
medidas, StoryDots, a su solo criterio, eliminará definitivamente el
video y se reservará el derecho de iniciar las acciones que estime
pertinentes al usuario si, (a) se violara o incumpliera alguna ley
aplicable, o cualquiera de las estipulaciones de los Términos y
Condiciones y demás políticas de StoryDots; (b) incumpliera sus
compromisos y obligaciones como Usuario; (c) se incurriera a criterio
exclusivo de StoryDots en comportamientos o actos dolosos o
fraudulentos;. En el caso de la suspensión de un video, todos los
Servicios que éste hubiera contratado serán removidos del sistema,
sin lugar a reclamo alguno a favor del Usuario.</P>
<P>
</P>
<P>
<B>12. Fallas en el sistema.</B></P>
<P>
StoryDots no garantiza la
inexistencia de eventuales dificultades técnicas o fallas en los
sistemas o en Internet. StoryDots no garantiza el acceso y uso
continuado o ininterrumpido de su sitio. El sistema puede
eventualmente no estar disponible debido a dificultades técnicas o
fallas de Internet, o por cualquier otra circunstancia ajena a
StoryDots; en tales casos se procurará razonablemente restablecerlo
con la mayor celeridad posible. Asimismo StoryDots no garantiza la
inexistencia de errores u omisiones en el Sitio.</P>
<P>
</P>
<P>
<B>13. Tarifas Facturación.</B></P>
<P>
La interacción del Usuario
en el Sitio y al grabar el saludo standard es gratuita. Al adquirir
una mejora, el Usuario deberá abonar el precio publicado y aceptado.
La factura a emitirse será la de consumidor final, siendo esta la
única modalidad de facturación del modelo de compra online.
StoryDots se reserva el derecho de tomar las medidas judiciales y
extrajudiciales que considere pertinentes para obtener el pago del
monto debido.</P>
<P>
</P>
<P>
<B>14. Separabilidad.</B></P>
<P>
En caso de que cualquiera de
las disposiciones previstas en estos Términos y Condiciones
Generales fuera declarada nula o ineficaz por cualquier tribunal
competente, la validez de las restantes condiciones no resultará
afectada.</P>
<P>
</P>
<P>
<B>15. Legislación y
Jurisdicción.</B></P>
<P>
Toda controversia que surja
en relación con el objeto de los presentes Términos y Condiciones
Generales será dirimido por ante los Tribunales Competentes de la
Capital Federal, con exclusión de cualquier otro fuero que por
cualquier otro motivo pudiera corresponder, y con arreglo a la
legislación argentina, sin aplicación de sus previsiones sobre
conflicto de leyes.</P>
<P
</P>`