import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  controlPanel: {
    marginBottom: '40px',
  },
  formPart: {
    margin: '10px 0px',
    maxWidth: 800,
  },
  formPartError: {
    border: '1px solid red'
  },
  formCardContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px !important',
    paddingBottom: '24px !important',
  },
  helperContainerColumn: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  containerColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  helperTextParagraph: {
    height: '56px',
    width: 'auto',
    padding: 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginBottom: 18,
    '&:last-child': {
      marginBottom: 0,
    },
    width: '100%',
  },
  noArrowNumericInput: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  submitButtons: {
    display: 'flex',
  },
  submitButton: {
    marginRight: 30,
  },
  greetingOrigin: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 18,
    borderRadius: 12,
    boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.23);',
    overflow: 'hidden',
    maxHeight: 486,
    position: 'relative',
  },
  cardHeader: {
    margin: 'auto 0',
  },
  width: {
    width: '100%',
  },
  grey: {
    filter: 'grayscale(100%)',
  },
}));
