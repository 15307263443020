import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as config from '../config';
import { FETCH_SUBSCRIPTION } from '../config';
import { useRedirect } from 'react-admin';
const useIsSuperAdmin = () =>
  useSelector(
    (state: ReduxState) => state.auth?.decoded.role === config.SUPERADMIN,
  );

const useCheckFeature = (feature: string) => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const isSuperadmin = useIsSuperAdmin();
  //@ts-ignore
  const role = useSelector((state) => state.auth?.decoded.role);
  const clientInfo: any | null = useSelector((state: ReduxState) => state.subscription);
  useEffect(() => {
    dispatch({ type: FETCH_SUBSCRIPTION });
  }, []);
  if(role === config.LOGISTIC) return true;
  if(isSuperadmin) return true;
  if(clientInfo.isLoading === false){
    if(clientInfo.data?.error){
      redirect("/subscriptions-plans")
      return;
    }
    const clientFeatures: string[] = clientInfo.data.features === null ? [] : clientInfo.data.features;
    clientFeatures === undefined || clientFeatures === null && redirect("/subscriptions-plans");
    !clientFeatures.includes(feature) && redirect("/subscriptions-plans");
  }
}
export { useIsSuperAdmin, useCheckFeature };
