import React from 'react'
import { Dialog, CardContent, makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme)=>({
    title: {
        padding: '15px',
        fontSize: '1.2rem',
        fontFamily: 'Manrope',
        color: theme.palette.primary.main,
        borderbottom: '3px solid gray'
    },
    content: {
        overflowY: 'scroll',
        marginBottom: 20
    }
}));

interface ITermsAndConditionsDialogProps {
    open: boolean;
    onClose: () => void;
    content: string;
    title: string;
}

const TermsAndConditionsDialog: React.FC<ITermsAndConditionsDialogProps> = ({ content, title, open, onClose }) => {
    const classes = useStyles();
    return (
        <Dialog onClose={onClose} open={open}>
            <div className={classes.title}>
                {title}
            </div>
            <CardContent className={classes.content} dangerouslySetInnerHTML={{ __html: content }} />
        </Dialog>
    )
}

export default TermsAndConditionsDialog;