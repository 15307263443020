const onboardingPalette = {
  primary: {
    light: "#8F3FE6",
    main: "#6242E8",
    semiDark: "#6621B0",
    dark: "#402B9A"
  },
  secondary: { main: "rgba(207, 63, 230, 0.54)"}
}

export default onboardingPalette;
