import React from 'react'
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useTranslate } from 'react-admin';


interface IPaperSelectProps {
    className?:string;
}

const PaperSelect: React.FC<IPaperSelectProps> = ({className}) => {
    const { control } = useFormContext();
    const translate = useTranslate();
    const paperSizes = [
        { value: "21cm 29.7cm", name: translate('print.form.page_sizes.a4_portrait') },
        { value: "29.7cm 21cm", name: translate('print.form.page_sizes.a4_landscape') },
        { value: "29.70cm 42cm", name: translate('print.form.page_sizes.a3_portrait') },
        { value: "42cm 29.7cm", name: translate('print.form.page_sizes.a3_landscape') },
        { value: "42cm 59.4cm", name: translate('print.form.page_sizes.a2_portrait') },
        { value: "59.4cm 42cm", name: translate('print.form.page_sizes.a2_landscape') }
      ]
    return (
        <Controller
            control={control}
            name="paperSize"
            className={className}
            defaultValue={paperSizes[0].value}
            render={({ onChange, onBlur, value, name, ref }) => (
                <FormControl variant="outlined" style={{width:'100%'}} className={className}>
                    <InputLabel id="paper-label">
                        {translate('print.form.page_size')}
                    </InputLabel>
                    <Select
                        className={className}
                        labelId="paper-label"
                        id="paper"
                        value={value || ''}
                        ref={ref}
                        name={name}
                        defaultValue={paperSizes[0].value}
                        onChange={onChange}
                        label={translate('print.form.page_size')}
                    >
                        {paperSizes.map(({ name, value }) =>
                        (<MenuItem value={value}>
                            {name}
                        </MenuItem>)
                        )}
                    </Select>
                </FormControl>
            )}
        />
    )
}

export default PaperSelect;