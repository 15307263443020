import React, { ReactElement } from 'react';
import { makeStyles, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    '& h1': {
      margin: 0,
      fontSize: '1.4rem',
      fontFamily: 'Manrope',
    },
    '& svg': {
      fill: theme.palette.primary.main,
      height: '1.8rem',
      width: 'auto',
      marginRight: theme.spacing(1),
    },
    margin: 0,
    padding: 0,
  },
  caption: {
    fontWeight: 500,
    fontSize: '1rem',
    color: 'rgba(0,0,0,.6)',
    marginTop: theme.spacing(1),
    marginBottom: 0,
  },
  titles: {
    marginBottom: theme.spacing(1),
  }
}));

interface IPageTitleProps {
  title: any;
  subtitle?: string;
  icon: ReactElement;
  action?: ReactElement
  onIconClick?: () => void;
}

/**
 * @param {string} title Title of the element
 * 
 * @param {string} [subtitle] Text below the title
 * 
 * @param {ReactElement} icon Icon of the element
 * 
 * @param {ReactElement} [action] Extra element that goes to the right of the element or below depending on screen size
 * 
 * @param {() => void} [onIconClick] Function that will trigger on click of the icon
 */

const PageTitle: React.FC<IPageTitleProps> = ({ title, subtitle, icon, action, onIconClick }) => {
  const classes = useStyles();
  return (
    <hgroup className={classes.root}>
      <div className={classes.titles}>
        <header className={classes.header}>
          <>
            {onIconClick ? <IconButton color="primary" onClick={onIconClick}>{icon}</IconButton> : icon} 
            <h1>{title}</h1> 
          </>
        </header>
        {subtitle && <span className={classes.caption}>{subtitle}</span>}
      </div>
      {action && action}
    </hgroup>
  );
};

export default PageTitle;
