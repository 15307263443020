import React, { useContext } from 'react';
import { useTranslate } from 'react-admin';
import { OnboardingContext } from '../context/OnboardingContext';

function QRScan () {
  const translate = useTranslate();
  const { styles, preload } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const { mt0,mb0, p2, textCenter, font19, paperShadow } = onboardingStyles();
  const preloadedImages = preload.current;

  return (
    <>
      <h2 className={[mt0, textCenter].join(' ')} style={{fontSize: "1.25vw"}}>
        {translate('onboarding.wizardContent.QRScan.title')}
      </h2>
      <p
        className={[mt0, mb0, font19, textCenter].join(' ')}
        style={{fontWeight: "500", lineHeight: "1.5625vw"}}
      >
        {translate('onboarding.wizardContent.QRScan.messages.msg1.0')}
        <strong>{translate('onboarding.wizardContent.QRScan.messages.msg1.1')}</strong>
        {translate('onboarding.wizardContent.QRScan.messages.msg1.2')}
        <strong>{translate('onboarding.wizardContent.QRScan.messages.msg1.3')}</strong>
        .
      </p>
      <div
        className={[paperShadow, p2].join(' ')}
        style={{width: "10.4167vw", height: "10.4167vw", margin: "1.67vw auto", borderRadius: "0.8854vw"}}
       >
        <img
          src={preloadedImages.imageForQR}
          alt="QR code"
          style={{width: "100%"}}
        />
      </div>
      <p
        className={[mt0, font19, textCenter].join(' ')}
        style={{fontWeight: "500", lineHeight: "1.5625vw"}}
      >{translate('onboarding.wizardContent.QRScan.messages.msg2')}</p>
    </>
  );
}

export default QRScan;
