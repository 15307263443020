import { useState, useEffect } from 'react';

const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

export default () => {
  let [width, setWidth] = useState(getWidth());

  useEffect(() => {
    let timeoutId:NodeJS.Timeout|null = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      if (timeoutId) clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  return width;
}
