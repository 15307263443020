import React from "react";
import { TextField as MuiTextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 25,
    display: "flex"
  },
  leftInput: {
    marginRight: 10,
    '&::after': {
      content: '""',
      top: 'calc(100% - 1px)',
      left: '100%',
      position: 'absolute',
      width: '10px',
      height: '0px',
      borderTop: '1px solid rgba(0, 0, 0, 0.42)',
    }
  },  
}))

interface rangeProps {
  from: string
  to: string
}

interface IProps {
  textFieldType: string
  range: rangeProps
  setRange: React.Dispatch<React.SetStateAction<rangeProps>>
}

const RangePicker = ({textFieldType, range, setRange}:IProps) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MuiTextField
        id={`from${textFieldType}`}
        className={classes.leftInput}
        label={translate('logisticPrint.from')}
        type={textFieldType}
        value={range.from}
        onChange={(e) => {
          const { value } = e.target;
          setRange((prevValues:rangeProps) => ({...prevValues, from: value}) )
        }}
        InputLabelProps={{
            shrink: true,
        }}
      />
      <MuiTextField
        id={`to${textFieldType}`}
        label={translate('logisticPrint.to')}
        type={textFieldType}
        value={range.to}
        onChange={(e) => {
          const { value } = e.target;
          setRange((prevValues:rangeProps) => ({...prevValues, to: value}))
        }}
        InputLabelProps={{
            shrink: true,
        }}
      />
    </div>
  )
}

export default RangePicker;
