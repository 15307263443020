import React from 'react';
import { Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '50px',
    paddingBottom: '15px',
    maxWidth: 1920,
  },
}));

interface IPageContainer {
  className?: string;
}

const PageContainer: React.FC<IPageContainer> = ({ children, className }) => {
  const classes = useStyles();
  //@ts-ignore
  return <Container className={`${classes.root} ${className}`}>{children}</Container>;
};

export default PageContainer;