// VENDOR
import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Button,
    TextField,
    makeStyles,
    Hidden,
    Grid,
    CircularProgress
} from '@material-ui/core';
import { useTranslate, useNotify, useAuthProvider } from 'react-admin';
import { useForm } from 'react-hook-form';
import config, { GET_NEW_GOOGLE_TOKEN, REGISTER_COMPLETE_UPDATE_DATA } from '../../../config';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowForward } from '@material-ui/icons'

const useStyles = makeStyles(
    (theme) => ({
        form: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
        },
        input: {
            width: '100%',
            color: 'black',
        },
        field: {
            marginBottom: theme.spacing(4),
            width: '100%',
            '& fieldset': {
                borderWidth: 2,
            },
        },
        doubleField: {
            marginBottom: 0
        },
        doubleFieldContainer: {
            marginBottom: theme.spacing(4)
        },
        button: {
            alignSelf: 'flex-start',
        },
        inputError: {
            color: theme.palette.primary.main,
        },
        supportCard: {
            maxWidth: 800,
            paddingTop: theme.spacing(.5),
        },
        supportCardContent: {
            padding: theme.spacing(3),
            display: 'flex',
            alignItems: 'center'
        },
        wavingPerson: {
            height: '387px',
            marginRight: theme.spacing(4)
        },
        formHeader: {
            width: '100%'
        },
        title: {
            color: theme.palette.primary.main,
            fontFamily: 'Manrope',
            fontSize: '22px',
            lineHeight: '133.4%',
            marginBottom: '8px'
        },
        subtitle: {
            lineHeight: '150%',
            marginBottom: '32px'
        },
        buttonAndSpinner: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
        }
    }),
    { name: 'RaLoginForm' },
);

interface IFormValues {
    name: string, phone: string, company: string, content: string
}

async function postRegistrationSupportTicket(formValues: IFormValues, email: string) {
    const response = await axios
        .post(`${config.AWS_API}/contactUs`, { ...formValues, email, subject: "register" })
    return response.data;
}

async function postRegistrationData(token: string, data: IFormValues) {
    const { company, ...registrationInfo } = data;
    const response = await axios.post(
        `${config.CLOUD_FUNCTIONS_REST_API}/authentication-register`,
        {
            token,
            shop_url: company,
            ...registrationInfo
        },
    );
    return response.data;
}

const NewUserForm: React.FC = () => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const { errors, register, handleSubmit } = useForm();
    const [isLoading, setLoadingState] = useState(false);
    const [FirebaseToken, setFirebaseToken] = useState('');
    const authProvider = useAuthProvider();
    useEffect(() => {
        authProvider.getJWTToken().then((token: string) => setFirebaseToken(token));
    }, [authProvider]);
    const classes = useStyles();
    const notify = useNotify();
    const email = useSelector((state: ReduxState) => state.auth?.decoded.email);
    const onSubmit = handleSubmit(async (values) => {
        if (FirebaseToken) {
            try {
                setLoadingState(true);
                const { name, phone, company, content } = values;
                await postRegistrationSupportTicket({ name, phone, company, content }, email);
                const registrationResponse = await postRegistrationData(FirebaseToken, { name, phone, company, content });
                dispatch({ type: REGISTER_COMPLETE_UPDATE_DATA, payload: registrationResponse });
                dispatch({ type: GET_NEW_GOOGLE_TOKEN });
                setLoadingState(false);
            }
            catch (e) {
                notify('dashboard.second_register.form.server_error');
                setLoadingState(false);
            }
        }
        else {
            notify('dashboard.second_register.token_error');
        }
    });
    return (
        <Card className={classes.supportCard}>
            <CardContent className={classes.supportCardContent}>
                <form className={classes.form} onSubmit={onSubmit}>
                    <div className={`${classes.title} ${classes.formHeader}`}>{translate('dashboard.second_register.title')}</div>
                    <div className={`${classes.subtitle} ${classes.formHeader}`}>{translate('dashboard.second_register.subtitle')}</div>
                    <Grid container spacing={3} className={classes.doubleFieldContainer}>
                        <Grid item xs={12} md={6} >
                            <TextField
                                error={errors.name}
                                helperText={errors.name?.message || null}
                                name="name"
                                className={`${classes.field} ${classes.doubleField}`}
                                variant="outlined"
                                inputProps={{
                                    className: classes.input,
                                    ref: register({
                                        required: translate('ra.validation.required'),
                                        minLength: {
                                            value: 2,
                                            message: translate('support.form.name.minLenght', {
                                                value: 2,
                                            }),
                                        },
                                    }),
                                    required: true,
                                }}
                                type="text"
                                label={translate('dashboard.second_register.form.name.label')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <TextField
                                error={errors.phone}
                                helperText={errors.phone?.message || null}
                                name="phone"
                                className={`${classes.field} ${classes.doubleField}`}
                                variant="outlined"
                                inputProps={{
                                    className: classes.input,
                                    ref: register(),
                                }}
                                type="text"
                                label={translate('dashboard.second_register.form.phone.label')}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        error={errors.company}
                        helperText={errors.company?.message || null}
                        name="company"
                        className={classes.field}
                        variant="outlined"
                        inputProps={{
                            className: classes.input,
                            ref: register(),
                        }}
                        type="text"
                        label={translate('dashboard.second_register.form.shop_url.label')}
                    />
                    <TextField
                        error={errors.content}
                        helperText={errors.content?.message || null}
                        name="content"
                        className={classes.field}
                        variant="outlined"
                        rows={3}
                        inputProps={{
                            className: classes.input,
                            placeholder: translate('support.form.content.placeholder'),
                            ref: register(),
                        }}
                        label={translate('dashboard.second_register.form.content.label')}
                        multiline
                    />
                    <div className={classes.buttonAndSpinner}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            size='large'
                            disabled={isLoading}
                            className={classes.button}
                            endIcon={<ArrowForward />}
                        >
                            {translate('dashboard.second_register.form.submit.label')}
                        </Button>
                        {isLoading && <CircularProgress />}
                    </div>
                </form>
            </CardContent>
        </Card>
    )
}

export default NewUserForm;