import React, { useState } from 'react';
import { Card, Link } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { LoginComponent, Notification, useTranslate } from 'react-admin';
import DefaultLoginForm from './Login/LoginForm';
import DefaultRegisterForm from './Register/RegisterForm';

import Logo from './img/storydotsLogo.svg';

import Partnership1 from './img/illus-partnership-1.png';
import Partnership3 from './img/illus-partnership-3.png';
import TermsAndConditionsDialog from './TermsAndConditionsDialog';
import useQuery from 'Utils/hooks/useQuery';

//we should use atomic styled components here, to avoid repeating styles

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        minHeight: '100%',
        height: '100vh',
        overflowX: 'auto',
        background: `linear-gradient(112.52deg, ${theme.palette.primary.light} 32.84%, ${theme.palette.secondary.main} 99.14%)`,
      },
      main: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#fff',
        height: '75vh',
        /* maxHeight: '600px', */
      },
      logo: {
        padding: '15px',
        maxWidth: '225px',
      },
      //there must be a better way to do this... (image positioning)
      person1: {
        height: '272px',
        width: 'auto',
        position: 'absolute',
        marginLeft: '-171px',
      },
      person2: {
        height: '232px',
        width: 'auto',
        position: 'absolute',
        marginLeft: '360px',
        marginBottom: '20px',
      },
      mainRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        position: 'relative',
        alignSelf: 'center',
      },
      card: {
        width: 356,
        maxWidth: '80vw',
        paddingTop: '37px',
        paddingBottom: '23px',
        paddingLeft: '24px',
        paddingRight: '24px',
        marginRight: '20px',
        marginLeft: '20px',
        marginTop: '30px',
        boxShadow:
          '0 24px 38px rgba(0, 0, 0, 0.14), 0 9px 46px rgba(0, 0, 0, 0.12), 0 11px 15px rgba(0, 0, 0, 0.2)',
      },
      cardWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 588,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
      },
      avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
      },
      formTitle: {
        color: '#000',
        textAlign: 'center',
        fontFamily: 'Manrope',
        fontWeight: 700,
        marginTop: 0,
        marginBottom: 30,
      },
      bottomNav: {
        marginBottom: '20px',
        marginTop: '100px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      bottomNavLinksList: {
        listStyle: 'none',
        display: 'flex',
        width: 250,
        maxWidth: '80vw',
        justifyContent: 'space-between',
        padding: 0,
      },
      bottomNavLink: {
        color: 'white',
      },
    }),
  { name: 'RaLogin' },
);

const Login: LoginComponent = ({ theme }) => {
  const classes = useStyles(theme);
  const translate = useTranslate();
  const redirectTo = useQuery().get("redirectTo") || "/"
  const [mode, setMode] = useState<'login' | 'register'>('login');
  const [termsIsOpen, setTermsIsOpen] = useState(false);
  const [GDPRIsOpen, setGDPRIsOpen] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.cardWrapper}>
          <img className={classes.logo} src={Logo} alt="logo" />

          <div className={classes.mainRow}>
            {/*
            <Hidden smDown>
              <img
                className={classes.person1}
                src={Partnership1}
                alt="Person"
              />
            </Hidden>
            */}
            <Card className={classes.card}>
              <h1 className={classes.formTitle}>
                {mode === 'login'
                  ? translate('auth.sign_in_header')
                  : translate('auth.sign_up_header')}
              </h1>
              {mode === 'login' ? (
                <DefaultLoginForm
                  redirectTo={redirectTo}
                  goRegister={() => setMode('register')}
                />
              ) : (
                <DefaultRegisterForm goLogin={() => setMode('login')} />
              )}
            </Card>
            {/*
            <Hidden smDown>
              <img
                className={classes.person2}
                src={Partnership3}
                alt="Person"
              />
            </Hidden>
            */}
          </div>
        </div>
        <Notification />
      </div>
      <nav
        className={classes.bottomNav}
        //this is ugly
        style={{ marginTop: /*mode === 'login' ?*/ '100px' /* : '200px' */ }}
      >
        <ul className={classes.bottomNavLinksList}>
          <li>
            <Link
              component="button"
              className={classes.bottomNavLink}
              onClick={() => setTermsIsOpen(true)}
            >
              {translate('auth.bottom_nav.terms')}
            </Link>
          </li>
          <li>
            <Link
              component="button"
              className={classes.bottomNavLink}
              onClick={() => setGDPRIsOpen(true)}
            >
              {translate('auth.bottom_nav.gdpr')}
            </Link>
          </li>
        </ul>
      </nav>
      <TermsAndConditionsDialog
        content={translate('terms_and_conditions.content', {
          escapeValue: false,
        })}
        title={translate('terms_and_conditions.title')}
        open={termsIsOpen}
        onClose={() => setTermsIsOpen(false)}
      />
      <TermsAndConditionsDialog
        content={translate('GDPR.content', { escapeValue: false })}
        title={translate('GDPR.title')}
        open={GDPRIsOpen}
        onClose={() => setGDPRIsOpen(false)}
      />
    </div>
  );
};

export default Login;
