import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  texts: {
    flexGrow: 1,
  },
  subtitle: {
    lineHeight: '2rem',
    fontFamily: 'Manrope',
    fontWeight: 600,
    color: 'white',
  },
  title: {
    color: 'white',
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: '1.8rem',
    lineHeight: '2rem',
  },
  button: {
    height: '2.5rem',
  },
}));

interface INoSubscriptionActionsProps {
  callback: () => void;
}

const NoSubscriptionActions: React.FC<INoSubscriptionActionsProps> = ({
  callback,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <>
      <div className={classes.texts}>
        <div className={classes.title}>
          {translate('purchases.no_subscription_action_card.title')}
        </div>
        <div className={classes.subtitle}>
          {translate('purchases.no_subscription_action_card.subtitle')}
        </div>
      </div>
      <Button onClick={callback} className={classes.button} variant="contained">
        {translate('purchases.no_subscription_action_card.button')}
      </Button>
    </>
  );
};

export default NoSubscriptionActions;
