import React, { useContext } from 'react';
import { useTranslate } from 'react-admin';
import { useRedirect } from 'react-admin';
import { OnboardingContext } from '../context/OnboardingContext';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

function SkipOnboardingModal() {
  const translate = useTranslate();
  const redirect = useRedirect();
  const { palette, styles, modal } = useContext(OnboardingContext);
  const [openModal, setOpenModal] = modal;
  const onboardingStyles = styles;
  const { manrope, pAbsolute, pRelative, primaryLightBG, whiteBG, w100, mr6, ml6, mAuto, dFlex, column, alignCenter, justifyCenter, noBorder, radiusSm, radiusMd, textWhite, textPrimary, font18, btnShadow, noUppercase, pointer, btnHover, backdropOpacity, exitModal} = onboardingStyles();
  const { primary } = palette;

  const handleClose = () => setOpenModal("none");

  const handleConfirm = () => redirect('/#');

  return (
      <div
        className={[pAbsolute, dFlex, mAuto, backdropOpacity].join(' ')}
        style={{zIndex: "8", width: "100vw", height: "100vh", minHeight: "49.48vw", display: `${openModal}`, position: "absolute", top:"0", left: "0"}}
        onClick={handleClose}
      >
        <div
          onClick={handleClose}
          className={[w100, dFlex, pRelative, mAuto].join(' ')}
          style={{height: "49.48vw", minHeight: "49.48vw"}}
        >
          <div
            className={[manrope, pAbsolute, whiteBG, mAuto, dFlex, column, alignCenter, justifyCenter, radiusMd, exitModal].join(' ')}
            style={{top: "29vw", right: "4.53125vw", padding: "0.78125vw 3.125vw 1.5625vw 3.125vw"}}
          >
            <ExitToAppIcon style={{fontSize: "3.2vw", color: `${primary.main}`}} />
            <p style={{margin: "0.52vw 0 1.5625vw 0", fontSize: "1.25vw", fontWeight: "700"}}>
              {translate('onboarding.skip.question')}
            </p>
            <div className={[dFlex].join(' ')}>
              <Button
                onClick={handleClose}
                className={[manrope, mr6, dFlex, alignCenter, justifyCenter, whiteBG, font18, noBorder, textPrimary, noUppercase, pointer].join(' ')}
                style={{width: "7vw", height: "2.1875vw", padding: "0.4167vw 1.1458vw", fontWeight: "600", letterSpacing: "0.46px"}}
              >{translate('onboarding.skip.cancel')}</Button>
              <Button
                onClick={handleConfirm}
                className={[manrope, ml6, dFlex, alignCenter, justifyCenter, primaryLightBG, noBorder, radiusSm, textWhite, font18, btnShadow, noUppercase, pointer, btnHover].join(' ')}
                style={{width: "7vw", height: "2.1875vw", padding: "0.4167vw 1.1458vw", fontWeight: "600", letterSpacing: "0.46px"}}
              >{translate('onboarding.skip.confirm')}</Button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default SkipOnboardingModal;
