import React from 'react';
import { makeStyles, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  helperTextParagraph: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    "@media (max-width: 770px)": {
      flexDirection: 'column',
    }
  },
  paragraph: {
    fontSize: 13,
    color: 'gray',
    margin: 0,
    wordBreak: 'break-word',
  },
  title: {
    margin: 0,
    marginBottom: 5,
  },
  textContainer: {
    minWidth: '300px',
    maxWidth: '300px',
    marginRight: "40px",
    "@media (max-width: 770px)": {
      minWidth: 'fit-content',
      maxWidth: 'fit-content',
    }
  },
  childrenContainer: {
    width: '100%',
    "@media (max-width: 770px)": {
      marginTop: '16px',
    }
  },
}));

interface IProps {
  title: string;
  content?: string;
  mb?: boolean;
  mt?: boolean;
}

const InputContainer: React.FC<IProps> = ({
  content,
  title,
  mb = false,
  mt = false,
  children,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.helperTextParagraph}
      style={{ marginBottom: mb ? '18px' : '0px', marginTop: mt ? "18px": "0px" }}
    >
      <div className={classes.textContainer}>
        <p className={classes.title}>{title}</p>
        {content && <p className={classes.paragraph}>{content}</p>}
      </div>
      <div className={classes.childrenContainer}>{children}</div>
    </div>
  );
};
export default InputContainer;
