import { useTranslate } from 'react-admin';

function useWizardContentTranslate () {
  const translate = useTranslate();
  const wc = 'onboarding.wizardContent.';
  const normalW = "500";
  const boldW = "700"

  const translateWizardContent = () => {
    return {
      welcome: {
        title: translate(wc + 'welcome.title'),
        messages: [
          [{
            text: translate(wc + 'welcome.messages.msg1.0'),
            fontWeight: normalW,
          }, {
            text: translate(wc + 'welcome.messages.msg1.1'),
            fontWeight: boldW,
          }, {
            text: translate(wc + 'welcome.messages.msg1.2'),
            fontWeight: normalW,
          }, {
            text: translate(wc + 'welcome.messages.msg1.3'),
            fontWeight: boldW,
          }]
        ]
      },
      howItWorks: {
        title: translate(wc + 'howItWorks.title'),
        messages: [
          translate(wc + 'howItWorks.messages.msg1'),
        ],
        time: "1’ 30’’"
      },
      storeIntegration: {
        title: translate(wc + 'storeIntegration.title'),
        messages: [
          translate(wc + 'storeIntegration.messages.msg1'),
        ],
        time: "1’ 30’’"
      },
      simulatePurchase: {
        title: translate(wc + 'simulatePurchase.title'),
        messages: [
          translate(wc + 'simulatePurchase.messages.msg1'),
        ]
      },
      greetingIsAddedToCart: {
        title: translate(wc + 'greetingIsAddedToCart.title'),
        messages: [
          translate(wc + 'greetingIsAddedToCart.messages.msg1'),
        ]
      },
      quickGreetingCreation: {
        title: translate(wc + 'quickGreetingCreation.title'),
        messages: [
          translate(wc + 'quickGreetingCreation.messages.msg1'),
        ]
      },
      orderEndedSuccessfully: {
        title: translate(wc + 'orderEndedSuccessfully.title'),
        messages: [
          translate(wc + 'orderEndedSuccessfully.messages.msg1'),
        ]
      },
      emailSent: {
        title: translate(wc + 'emailSent.title'),
        messages: [
          translate(wc + 'emailSent.messages.msg1'),
          translate(wc + 'emailSent.messages.msg2'),
        ]
      },
      SDArrivesAsQR: {
        title: translate(wc + 'SDArrivesAsQR.title'),
        messages: [
          translate(wc + 'SDArrivesAsQR.messages.msg1'),
          translate(wc + 'SDArrivesAsQR.messages.msg2'),
        ],
        time: "1’ 30’’" 
      },
      printing: {
        title: translate(wc + 'printing.title'),
        messages: [
          [{
            text: translate(wc + 'printing.messages.msg1.0'),
            fontWeight: normalW
          }, {
            text: translate(wc + 'printing.messages.msg1.1'),
            fontWeight: boldW
          }, {
            text: translate(wc + 'printing.messages.msg1.2'),
            fontWeight: normalW
          }]
        ]
      },
      deployOrderMenu: {
        title: translate(wc + 'deployOrderMenu.title'),
        messages: [
          translate(wc + 'deployOrderMenu.messages.msg1'),
        ]
      },
      printingSingle: {
        title: translate(wc + 'printingSingle.title'),
        messages: [
          translate(wc + 'printingSingle.messages.msg1'),
        ]
      },
      printingEnd: {
        title: translate(wc + 'printingEnd.title'),
        messages: [
          translate(wc + 'printingEnd.messages.msg1'),
        ]
      }
    }
  };
  return translateWizardContent;
}

export default useWizardContentTranslate;
