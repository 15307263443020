import React from 'react';
import {
  CardActions,
  Button,
  TextField,
  Link,
  CircularProgress,
} from '@material-ui/core';
import makeUseStyles from '../formStyles';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';
// FIREBASE
import { app } from '../../../firebaseConfig';
import { useForm } from 'react-hook-form';
import { FirebaseError } from '@firebase/util';


const useStyles = makeUseStyles('register');

interface IRegisterFormFields {
  register_email: string;
  register_password: string;
  register_repeat_password: string;
}

interface IRegisterFormProps {
  redirectTo?: string;
  goLogin: () => void;
}

const RegisterForm: React.FC<IRegisterFormProps> = (props) => {
  const { redirectTo, goLogin } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);
  const { handleSubmit, register, errors, setError } = useForm();
  const onSubmit = async (values: IRegisterFormFields) => {
    const { register_email, register_password, register_repeat_password } = values;
    if (register_password === register_repeat_password) {
      if (register_email && register_password) {
        try {
          setLoading(true);
          await app.auth().createUserWithEmailAndPassword(register_email.trim(), register_password);
          await login(values, redirectTo);
        } catch (error: any) {
          notify(
            typeof error === 'string'
              ? error
              : typeof error === 'undefined' || !error.message
                ? 'ra.auth.sign_in_error'
                //translate email error message
                : error.code === 'auth/email-already-in-use' ? 'sign_up.errors.email' : error.message,
            'warning',
          );
        } finally {
          setLoading(false);
        }
      }
    } else {
      setError('register_repeat_password', { message: translate('sign_up.inputs.errors.repeat_password') });
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <TextField
        error={errors.register_email}
        name="register_email"
        className={classes.textField}
        variant="outlined"
        inputProps={{
          className: classes.input,
          ref: register({ required: translate('ra.validation.required') }),
          required: true,
        }}
        disabled={loading}
        label={translate('sign_up.inputs.email')}
      />
      <TextField
        error={errors.register_password}
        name="register_password"
        className={classes.textField}
        variant="outlined"
        inputProps={{
          className: classes.input,
          ref: register({
            required: translate('ra.validation.required'), pattern: {
              value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
              message: translate('sign_up.inputs.errors.register_password')
            }
          }),
          required: true,
        }}
        type="password"
        disabled={loading}
        label={translate('ra.auth.password')}
        helperText={errors.register_password?.message}
      />
      <TextField
        error={errors.register_repeat_password}
        name="register_repeat_password"
        className={classes.textField}
        variant="outlined"
        inputProps={{
          className: classes.input,
          ref: register({ required: translate('ra.validation.required') }),
          required: true,
        }}
        type="password"
        disabled={loading}
        helperText={errors.register_repeat_password?.message}
        label={translate('sign_up.inputs.repeat_password')}
      />
      <Button
        variant="contained"
        type="submit"
        color="primary"
        disabled={loading}
        className={classes.button}
      >
        {loading && (
          <CircularProgress className={classes.icon} size={18} thickness={2} />
        )}
        {translate('sign_up.btn.sign_up')}
      </Button>
      <CardActions className={classes.cardLinks}>
        <Link component="button" onClick={goLogin} color="primary">
          {translate('sign_up.link.sign_in')}
        </Link>
      </CardActions>
    </form>
  );
};

export default RegisterForm;
