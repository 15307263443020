// VENDOR
import React from 'react';
import { MenuItemLink, useTranslate, useAuthProvider } from 'react-admin';
// ICONS
import QRIcon from '../../img/QRIcon';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import UserIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import StatsIcon from '@material-ui/icons/Equalizer';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PrintIcon from '@material-ui/icons/Print';
import StoreIcon from '@material-ui/icons/Store';
import BrushIcon from '@material-ui/icons/Brush';
import LockIcon from '@material-ui/icons/LockRounded';
import HeadSetMic from '@material-ui/icons/HeadsetMic';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import { makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';
import { useIsSuperAdmin } from '../../customHooks';
import { SUPERADMIN, LOGISTIC, Features, hasFeature } from '../../config';
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingTop: '85px',
    background: `#6242E8`,
    display: "flex",
    flexDirection: "column"
  },
  button: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  logoutBtn: {
    marginBottom: "auto"
  },
  onboardingBtn: {
    width: 48,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  visible: {
    height: "3em",
    width: "10em",
    marginBottom: 10,
  }
}));

const setAllFeatures = () => {
  localStorage.setItem('f', JSON.stringify(Object.values(Features)))
}

const SideBar = ({ onMenuClick }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const role = useSelector((state) => state.auth?.decoded.role);
  const clientID = useSelector((state) => state.auth?.decoded.clientID);
  const authProvider = useAuthProvider();
  const isSuperadmin = useIsSuperAdmin();
  const clientOrigin = useSelector((state) => state.home.data?.clientData?.user_data_origin);
  isSuperadmin && setAllFeatures()
  /*
  * BOOLEAN MEANINGS:
  *   client: USER HAS CLIENTID
  *   superadmin: USER HAS "SUPERADMIN" ROLE
  *   mixed: USER HAS CLIENTID OR HAS "SUPERADMIN" ROLE
  */
 if (role === LOGISTIC && (window.location.href.split('#')[1] !== '/logistic-print' && window.location.href.split('#')[1] !== '/stats')) {
   window.location.href = '/#/logistic-print';
  }

  const menuItems = [
    {
      route: '',
      text: translate('dashboard.title'),
      icon: <HomeIcon />,
      mixed: true,
    },
    //{ route: 'stats', text: translate('stats.title'), icon: <StatsIcon /> },
    {
      route: 'clients',
      text: translate('clients.title'),
      icon: <StoreIcon />,
      superadmin: true,
      isActive: () =>
        window.location.href.includes('/clients') ||
        window.location.href.includes('/createClient'),
    },
    {
      route: 'tags',
      text: isSuperadmin
        ? translate('tags.title_superadmin')
        : translate('tags.title_regular'),
      icon: <QRIcon />,
      disabled: !clientID && role !== SUPERADMIN,
      mixed: clientOrigin !== 7,
    },
    {
      route: 'logistic-print',
      text: "Print QR Tags",
      icon: <PrintIcon />,
      disabled: !clientID,
      clientLogistic: true,
    },
    {
      route: 'subscriptions-plans',
      text: translate('subscriptions_plans.title'),
      icon: <MoneyIcon />,
      disabled: !clientID && role !== SUPERADMIN,
      client: clientOrigin === 1,
    },
    {
      route: 'print',
      text: translate('print.title'),
      icon: <PrintIcon />,
      superadmin: true,
    },
    {
      route: hasFeature(Features.UsersList) ? 'senders' : 'subscriptions-plans',
      text: translate('users.title'),
      icon: hasFeature(Features.UsersList) ? <UserIcon /> : <LockIcon />,
      disabled: !clientID && role !== SUPERADMIN,
      isActive: () =>
        window.location.href.includes('/senders') ||
        window.location.href.includes('/tracking') ||
        window.location.href.includes('/receivers'),
      mixed: true
    },
    {
      route: 'users-list',
      text: translate('admin_users.title'),
      icon: <AccountCircleIcon />,
      superadmin: true,
    },
    // { route: 'custom', text: translate('custom.title'), icon: <BrushIcon /> },
    //{ route: 'announcements', text: translate('announcements.title'), icon: <AnnouncementsIcon /> },
    {
      route: 'customizations',
      text: translate('customizations.title'),
      icon: <BrushIcon />,
      disabled: !clientID,
      client: true
    },
    {
      route: hasFeature(Features.Stats) ? 'stats' : 'subscriptions-plans',
      text: translate('stats.title'),
      icon: hasFeature(Features.Stats) ? <StatsIcon /> : <LockIcon />,
      disabled: !clientID,
      isActive: () => window.location.href.includes('/subscriptions-plans') && false,
      client: true,
    },
    {
      route: 'stats',
      text: translate('stats.title'),
      icon: <StatsIcon />,
      disabled: !clientID,
      clientLogistic: true, 
    },
    {
      route: 'support',
      text: translate('support.title'),
      icon: <HeadSetMic />,
      disabled: !clientID,
      client: true,
    },
    // { route: 'settings', text: translate('settings.title'), icon: <SettingsIcon /> },
    // { route: 'purchases', text: translate('purchases.title'), icon: <CreditCardIcon /> },
  ];
  return (
    <div className={classes.root}>
      {menuItems.map(
        (
          { route, text, icon, superadmin, disabled, isActive, client, mixed, clientLogistic },
          index,
        ) => {
          //TODO: put roles in constants
          if (
            (client === true && role !== SUPERADMIN && role !== LOGISTIC) ||
            (superadmin === true && role === SUPERADMIN && role !== LOGISTIC) ||
            (clientLogistic === true && role === LOGISTIC) ||
            (mixed === true && role !== LOGISTIC)
          ) {
            return (
              <MenuItemLink
                key={index}
                to={`/${route}`}
                exact={!route}
                primaryText={text}
                leftIcon={icon}
                onClick={onMenuClick}
                disabled={disabled}
                className={classes.button}
                isActive={isActive}
              />
            );
          }
        },
      )}
      <MenuItemLink
        onClick={() => authProvider.logout()}
        to="/login"
        primaryText={translate('ra.auth.logout')}
        leftIcon={<PowerSettingsNewIcon />}
        className={classNames(classes.button, classes.logoutBtn)}
      />
      <abbr className={classes.visible} title={translate('go_to_onboarding_button')}>
        <MenuItemLink 
          onClick={onMenuClick}
          to="/onboarding"
          primaryText={''}
          leftIcon={<HelpOutlineIcon />}
          className={classNames(classes.button, classes.onboardingBtn)}
        />
      </abbr>
      
    </div>
  );
};

export default SideBar;
