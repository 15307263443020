import React from 'react';
import { makeStyles, Button, Box } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Subscription } from '../types';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2rem',
    fontFamily: 'Manrope',
    fontWeight: 600,
    lineHeight: 1.1,
    color: 'white',
  },
  status: {
    color: 'white',
    fontFamily: 'Manrope',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '0.9rem',
  },
  tagsAmount: {
    color: 'white',
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: '1rem',
    verticalAlign: 'bottom',
  },
  asideText: {
    color: 'white',
    fontFamily: 'Manrope',
    fontSize: '1rem',
  },
  button: {
    height: '2.5rem',
    color: 'white',
  },
}));

interface IActiveSubscriptionActionsProps {
  subscription?: Subscription;
  callback: () => void;
}

const ActiveSubscriptionActions: React.FC<IActiveSubscriptionActionsProps> = ({
  subscription,
  callback,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <>
      <Box display="flex" flexGrow={1} alignItems="flex-end" flexWrap="wrap">
        <Box display="flex" flexDirection="column" marginRight="5rem">
          <div className={classes.status}>
            {translate('purchases.active_subscription_action_card.title')}
          </div>
          <div className={classes.title}>
            {translate(`purchases.products.${subscription?.name}`)}
          </div>
          <div className={classes.tagsAmount}>
            {translate(
              'purchases.active_subscription_action_card.tags_per_month',
              { amount: subscription?.tagsPerMonth },
            )}
          </div>
        </Box>
        <Box display="flex" flexDirection="column">
          <div className={classes.asideText}>
            {translate(
              'purchases.active_subscription_action_card.pay_per_month',
              { amount: subscription?.cost },
            )}
          </div>
          <div className={classes.asideText}>
            {translate(
              `purchases.active_subscription_action_card.next_billing`,
              { date: subscription?.nextBilling },
            )}
          </div>
        </Box>
      </Box>
      <Box display="flex">
        <Button onClick={callback} className={classes.button} variant="text">
          {translate('purchases.active_subscription_action_card.modify_button')}
        </Button>
        <Button onClick={callback} className={classes.button} variant="text">
          {translate('purchases.active_subscription_action_card.cancel_button')}
        </Button>
      </Box>
    </>
  );
};

export default ActiveSubscriptionActions;
