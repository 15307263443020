// VENDOR
import * as React from 'react';
import {
  Admin,
  Resource,
  resolveBrowserLocale,
  fetchUtils,
  AuthProvider,
} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

// CONTAINERS
import onboarding from './containers/Onboarding/';
import tags from './containers/Tags/';
import LogisticPrint from './containers/LogisticPrint';
import users from './containers/Users/';
import purchases from './containers/Purchases/';
import Dashboard from './containers/Dashboard';
import clients from './containers/Clients';
import adminUsers from './containers/AdminUsers';
import subscriptions from './containers/subscriptions';
import customizations from './containers/ClientForm/ClientFormList';
import stats from './containers/Stats';

//Analytics
import TagManager from "react-gtm-module";

// PROVIDERS
import superDataProvider from './dataProvider';
//import fakeDataProvider from './dataProvider/fakerest';
import authProvider from './authProvider';

// ROUTES
import { customRoutes } from './config';

// CONFIG
import config from "./config";

// COMPONENTS
import { Layout, AuthPage } from './components';
import AuthChecker from './components/AuthChecker';
// FONTS
import './fonts.css';
// MUI CSS OVERRIDES
import './muiCssOverrides.css';
// THEME
import theme from './theme';
import { ThemeProvider } from '@material-ui/core';
import customEnglishMessages from './i18n/en';
import customSpanishMessages from './i18n/es';
import customPortugueseMessages from './i18n/pt';
// REDUCERS
import fetchSubscriptionReducer from './customReducers/fetchSubscriptionReducer';
import fetchHomeReducer from './customReducers/fetchHomeReducer';
import fetchStatsReducer from './customReducers/fetchStatsReducer';
import getTokenReducer from './customReducers/getTokenReducer';
// SAGAS
import fetchSubscriptionSaga from './customSagas/fetchSubscriptionSaga';
import fetchHomeSagas from './customSagas/fetchHomeSagas';
import fetchStatsSaga from './customSagas/fetchStatsSaga';
import logoutSaga from './customSagas/logoutSaga';
import loginSaga from './customSagas/loginSaga';
import checkRoleSagas from './customSagas/checkRoleSagas';
// HISTORY
// import history from "./history";
import { TranslationMessages } from 'react-admin';
import { loadScreenRecorder } from 'Utils';
const messages: Record<string, TranslationMessages> = {
  es: { ...customSpanishMessages },
  en: { ...customEnglishMessages },
  pt: { ...customPortugueseMessages },
};
const i18nProvider = polyglotI18nProvider(
  (locale: string) => (messages[locale] ? messages[locale] : messages.en),
  new URLSearchParams(window.location.search).get('locale') ||
    resolveBrowserLocale(),
);
if(config.NODE_ENV === "production") {
  TagManager.initialize({
    gtmId: config.GTM_ID,
  });
}

const httpClient = (url: string, options: fetchUtils.Options | any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

loadScreenRecorder();

//I had to use ThemeProvider because useStyles call in LoginComponent wasn't reading my theme

const App = () => (
  <ThemeProvider theme={theme}>
    <Admin
      theme={theme}
      // history={history}
      title="Storydots"
      layout={Layout}
      i18nProvider={i18nProvider}
      customRoutes={customRoutes}
      dashboard={Dashboard}
      dataProvider={superDataProvider('', httpClient)}
      authProvider={authProvider as unknown as AuthProvider}
      loginPage={AuthPage}
      customReducers={{
        subscription: fetchSubscriptionReducer,
        home: fetchHomeReducer,
        stats: fetchStatsReducer,
        auth: getTokenReducer,
      }}
      customSagas={[
        fetchSubscriptionSaga,
        ...fetchHomeSagas,
        fetchStatsSaga,
        logoutSaga,
        loginSaga,
        ...checkRoleSagas,
      ]}
      disableTelemetry={true}
    >
      <AuthChecker />
      <Resource name="onboarding" {...onboarding} />
      <Resource name="tags" {...tags} />
      <Resource name="logistic-print" {...LogisticPrint}/>
      <Resource name="purchases" {...purchases} />
      <Resource name="senders" {...users} />
      <Resource name="receivers" {...users} />
      <Resource name="tracking" {...users} />
      <Resource name="clients" {...clients} />
      <Resource name="users-list" {...adminUsers} />
      <Resource name="customizations" {...customizations} />
      <Resource name="stats" {...stats} />
      <Resource name="subscriptions-plans" {...subscriptions} />
    </Admin>
  </ThemeProvider>
);

export default App;
