import React, { useState, useEffect } from 'react';
import { makeStyles, Button, TextField, FormControl, InputLabel, MenuItem, Select, Grid, CircularProgress, Switch } from '@material-ui/core';
import { useTranslate, useNotify, LinearProgress, useRefresh } from 'react-admin';
import TitledDialog from '../../../components/TitledDialog';
import { Controller, useForm } from "react-hook-form";
import defaultPreview from "./preview/default-with-qr.png"
import axios from "axios";
import { useSelector } from 'react-redux';
import config from '../../../config';
import Image from './Image';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center'
  },
  linearProgress: {
    flexGrow: 1,
    marginRight: 5
  },
  input: {
    fontSize: '0.85rem',
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: 30,
    }
  },
  form: {
    marginTop: 15,
    width: '100%',
    maxWidth: '100%'
  },
  grid: {
    marginBottom: 15
  },
  preview: {
    maxWidth: '100%',
    maxHeight: '300px',
    '.loading': {
      display: 'none'
    },
  },
  previewWrapper: {
    textAlign: 'center'
  },
  switchInput: {
    border: "1px solid #c4c4c4",
    borderRadius: "10px",
    fontSize: '0.85rem',
    width: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 10px",
    '&:not(:last-child)': {
      marginBottom: 30,
    }
  }
}));

async function generateStock(
  { quantity, batch, customURL, customTemplate, generateTagImage }: any,
  clientID: number,
  token: string,
) {
  return await axios.post(
    `${config.AWS_API_SITE}/createTags`,
    {
      quantity,
      batch,
      //useCustomUrl and useCustomTemplate are converted to boolean since they come from a "select" input
      //that can only have strings as values
      useCustomUrl: customURL !== 'default',
      useCustomTemplate: customTemplate !== 'default',
      clientID,
      //generateTagImage if false omits image tag generation on backend making it restock instantly
      generateTagImage
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );
}

interface IGenerateStockDialogProps {
  open: boolean;
  onClose: () => void;
  client?: ClientResource;
}

interface IFormValues {
  customTemplate: string;
  customURL: string;
  quantity: string;
  batch: string;
  generateTagImage: string;
}

const GenerateStockDialog: React.FC<IGenerateStockDialogProps> = ({
  client,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const clientTemplate = (client?.clients_configs[0].tag_preview_url && client.clients_configs[0].tag_custom) ? "client" : "default";

  const { register, handleSubmit, watch, control, errors, reset } = useForm({
    defaultValues: {
      customTemplate: clientTemplate,
      customURL: "default",
      quantity: '0',
      batch: '',
      generateTagImage: "true",
    }
  });
  const token = useSelector((state: ReduxState) => state.auth?.token);
  const [isLoading, setLoadingState] = useState(false);
  const [progress, setProgress] = useState(0);
  const onSave = handleSubmit(async (values) => {
    setLoadingState(true);
    setProgress(0);
    const { generateTagImage } = values;
    if(generateTagImage){
      generateStockWithImage(values);
    }else{
      generateStockWithOutImage(values);
    }
  })

  const generateStockWithImage = async(values: IFormValues) =>{
    const { quantity } = values;
    const tagsPerCicle = 5;
    const initialQuantity = parseInt(quantity);
    const cycles = Math.floor(initialQuantity / tagsPerCicle);
    const remainder = initialQuantity % tagsPerCicle;
    try {
      for (let i = 0; i < cycles; i++) {
        await generateStock({ ...values, quantity: tagsPerCicle }, client!.id, token);
        // cross-multiplication to get percentage out of cycles
        setProgress((((i + 1) * tagsPerCicle) * 100) / initialQuantity);
      }
      if (remainder) {
        await generateStock({ ...values, quantity: remainder }, client!.id, token);
        setProgress(100);
      }
      notify(`clients.dialog.add.save_notification`, 'info', { amount: parseInt(values.quantity), client: client?.store_name });
      onClose();
      refresh();
    }
    catch (err) {
      notify('Error');
    }
    finally {
      setLoadingState(false);
      onClose()
    }
  }

  const generateStockWithOutImage = async(values: IFormValues) =>{
    try {
      await generateStock(values, client!.id, token);
      setProgress(100);
      notify(`clients.dialog.add.save_notification`, 'info', { amount: parseInt(values.quantity), client: client?.store_name });
      onClose();
      refresh();
    }
    catch (err) {
      notify('Error');
    }
    finally {
      setLoadingState(false);
      onClose()
    }
  }

  useEffect(() => {
    reset({ customTemplate: clientTemplate });
  }, [reset]);

  const showClientPreview = watch("customTemplate") !== "default";

  return (
    <TitledDialog title={translate(`clients.dialog.add.title`)} clientName={client?.store_name} onClose={onClose} open={open} className={classes.root}>
      <form onSubmit={onSave} className={classes.form}>
        <Grid container spacing={3} alignItems="center" className={classes.grid}>
          <Grid item sm={12} md={8}>
            <TextField label={translate(`clients.dialog.add.batch_name`)}
              variant="outlined"
              className={classes.input}
              name="batch"
              required
              inputProps={
                { ref: register() }
              }
            />
            <TextField label={translate(`clients.dialog.add.tags_amount`)}
              variant="outlined"
              defaultValue={0}
              className={classes.input}
              name="quantity"
              required
              error={!!errors.quantity}
              inputProps={
                { ref: register({ validate: (value) => !!parseInt(value) && value > 0 }) }
              }
              helperText={
                !!errors.quantity &&
                String(translate(`clients.dialog.add.tags_error`))
              }
            />
            <Controller
              control={control}
              name="customURL"
              defaultValue="default"
              render={({ onChange, value, name, ref }) => (
                <FormControl variant="outlined" className={classes.input}>
                  <InputLabel id="custom-url">
                    {translate(`clients.dialog.add.webapp_url`)}
                  </InputLabel>
                  <Select
                    labelId="custom-url"
                    id="customURL"
                    value={value || ''}
                    ref={ref}
                    name={name}
                    defaultValue="default"
                    onChange={onChange}
                    label={translate(`clients.dialog.add.webapp_url`)}
                  >
                    <MenuItem value="default">
                      {translate(`clients.dialog.add.storydots_url`)}
                    </MenuItem>
                    {client?.clients_configs[0]?.custom_app_url && (
                      <MenuItem value="client">
                        {translate(`clients.dialog.add.client_url`)}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="customTemplate"
              defaultValue={"default"}
              render={({ onChange, value, name, ref }) => (
                <FormControl variant="outlined" className={classes.input}>
                  <InputLabel id="custom-template">
                    {translate(`clients.dialog.add.custom_template`)}
                  </InputLabel>
                  <Select
                    labelId="custom-template"
                    id="customTemplate"
                    value={value || ''}
                    ref={ref}
                    name={name}
                    defaultValue="default"
                    onChange={onChange}
                    label={translate(`clients.dialog.add.custom_template`)}
                  >
                    <MenuItem value="default">
                      {translate(`clients.dialog.add.storydots_template`)}
                    </MenuItem>
                    {client?.clients_configs[0]?.tag_preview_url && client?.clients_configs[0]?.tag_custom && <MenuItem value="client">
                      {translate(`clients.dialog.add.client_template`)}
                    </MenuItem>}
                  </Select>
                </FormControl>
              )}
            />
            <Controller control={control}
              name="generateTagImage"
              defaultValue={false}
              render={({name, onChange, ref})=> (
                <>
                <div className={classes.switchInput} >
                  <InputLabel id="generate-tag-image"color='primary' >
                  {translate(`clients.dialog.add.generate_image_tag`)}
                  </InputLabel>
                    <Switch 
                    onChange={(_, value)=> onChange(value)} 
                    inputRef={ref} name={name} 
                    color="primary"
                    />
                  </div>
                </>
              
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} className={classes.previewWrapper}>
            {
              showClientPreview ?
                // A RANDOM QUERY STRING IS ADDED TO THE END, TO PREVENT IMAGE FROM CACHING (IF USER MAKES CHANGES TO CONFIG, HE MUST SEE THEM HERE)
                <Image src={client?.clients_configs[0]?.tag_preview_url + `?q=${Math.random().toString(36).substring(7)}`} alt="tag preview" className={classes.preview} /> :
                <img className={classes.preview} src={defaultPreview} alt="tag preview default" />
            }
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          {isLoading && <LinearProgress className={classes.linearProgress} variant="determinate" value={progress} />}
          <Button color="primary" type="submit" disabled={isLoading} startIcon={isLoading ? <CircularProgress size="1rem" /> : undefined}>
            {translate(`clients.dialog.add.buttons.save`)}
          </Button>
          <Button color="primary" onClick={onClose}>
            {translate(`clients.dialog.add.buttons.cancel`)}
          </Button>
        </div>
      </form>

    </TitledDialog>
  );
};

export default GenerateStockDialog;

export const useGenerateStockDialog = () => {
  const [generateStockDialogIsOpen, setGenerateStockDialogIsOpen] = useState(false);
  return {
    onGenerateStockDialogOpen: () => setGenerateStockDialogIsOpen(true),
    onGenerateStockDialogClose: () => setGenerateStockDialogIsOpen(false),
    generateStockDialogIsOpen,
  };
};
