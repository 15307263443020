import React from 'react';
import { Typography } from '@material-ui/core';

const CardTitleWithIcon = (title: string, icon: any, disabledText?: string) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {icon}
      <span style={{ margin: '0 0.5em' }}>{title} </span>
      {disabledText && (
        <Typography color="secondary">- ({disabledText})</Typography>
      )}
    </div>
  );
};
export default CardTitleWithIcon;
