// VENDOR
import React, { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ExportButton } from 'react-admin';
// UTILS;
import exporterFunction from './utils';
import useStyles from './styles';
interface IProps {
  actions: {
    name: string;
    callback: () => void;
    resource: string;
    sortField: string;
    fieldsNames: string[];
    columnsNames: string[];
  }[];
  resource: string;
}

const CustomTabsHeader: React.FC<IProps> = ({ actions, resource }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const classes = useStyles();
  useEffect(() => {
    actions.forEach((item, idx) =>
      item.resource === resource ? setCurrentIndex(idx) : null,
    );
  }, [resource]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentIndex(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={currentIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        {actions.map((action, idx) => (
          <Tab
            key={`tab_${idx}`}
            label={action.name}
            onClick={action.callback}
          />
        ))}
      </Tabs>
      <ExportButton
        variant="contained"
        size="small"
        resource={resource}
        sort={{ field: actions[currentIndex].sortField, order: 'DESC' }}
        filterValues={{}}
        className={classes.button}
        exporter={(records) =>
          exporterFunction(
            records,

            actions[currentIndex].fieldsNames,
            actions[currentIndex].columnsNames,
            resource,
          )
        }
      />
    </div>
  );
};

export default CustomTabsHeader;
