import React from 'react';
import {
  makeStyles,
  CardActionArea,
  CardActions,
} from '@material-ui/core';
import LinkWithChevron from '../../LinkWithChevron';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'flex-end',
  },
}));

export interface IStatCardActionProps {
  title: string;
  callback: () => void;
}

const StatCardAction: React.FC<IStatCardActionProps> = ({
  title,
  callback,
}) => {
  const classes = useStyles();
  return (
    <CardActionArea>
      <CardActions onClick={callback} className={classes.actions}>
        <LinkWithChevron title={title} />
      </CardActions>
    </CardActionArea>
  );
};

export default StatCardAction;
