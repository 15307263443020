import { TranslationMessages } from 'react-admin';
import spanishMessages from '@blackbox-vision/ra-language-spanish';
import userTags from './userTags/es';
import termsAndConditions from './termsAndConditions/es';
import GDPR from './GDPR/es';
import storeTypes from './stores_types/es';

const customSpanishMessages: TranslationMessages = {
  ...spanishMessages,
  platforms: {
    tiendanube: 'TiendaNube',
    shopify: 'Shopify',
    api: 'api',
    physical: 'Tienda física',
    vtex: 'VTEX',
    nuvemshop: 'NuvemShop',
    magento: 'Magento',
    dispenser: 'Punto de Compra (Dispenser)'
  },
  GDPR: {
    title: 'Reglamento General de Protección de Datos',
    content: GDPR,
  },
  go_to_store_button: "IR A MI TIENDA",
  go_to_onboarding_button: "Ver tutorial de uso",
  terms_and_conditions: {
    title: 'Términos y Condiciones',
    content: termsAndConditions,
  },
  dashboard: {
    title: 'Inicio',
    subtitle: '¡Bienvenido a tu panel de configuración StoryDots!',
    stat_cards: {
      stock: {
        title: 'Stock restante',
        footer_link: 'Solicitar',
      },
      redirections: {
        title: 'Redirecciones este mes',
        footer_link: 'Ver más',
      },
      users: {
        title: 'Usuarios',
        footer_link: 'Ver más',
      },
      subtitle:
        'Navega entre los usuarios que participan de los saludos, seleccionando entre emisores, receptores y trazabilidad.',
      header_titles: {
        senders: 'Emisores',
        receivers: 'Receptores',
        tracking: 'Trazabilidad',
      },
      table: {
        name: 'Nombre',
        email: 'Email',
        user: 'Usuario',
        gender: 'Género',
        age: 'Edad',
        last_activity: 'Última actividad',
        first_activity: 'Primer uso',
        greetings_sent: 'Saludos enviados',
        greetings_amount: 'Cantidad de saludos',
        gifted_amount: 'Monto total regalado',
        received_amount: 'Monto total recibido',
        tags: 'Tags',
        not_identified: 'Usuario no identificado',
      },
      tags: { ...userTags },
      admin_users: {
        table: {
          name: 'Nombre',
          email: 'Email',
          role: 'Rol',
          clientID: 'Cliente',
        },
      },
    },
    clients: {
      title: 'Clientes',
      subtitle: 'Listado de los clientes de Storydots.',
      search: 'Buscar',
      table: {
        name: 'Nombre',
        type: 'Tipo',
        platform: 'Plataforma',
        url: 'URL',
        subscription: 'Suscripción',
        stock: 'Stock',
        actions: {
          label: 'Acciones',
          add: 'Cargar stock',
          impersonate: 'Impersonar',
        },
        custom_app: 'App custom',
        custom_tag: 'Tag custom',
        status: 'Estado',
      },
      popover: {
        editStock: 'Modificar stock',
        viewClient: 'Ver cliente',
        editClient: 'Editar',
      },
      dialog: {
        add: {
          title: 'Cargar stock',
          helper: 'Seleccione la cantidad de Tags QR a cargar',
          tags_amount: 'Cantidad de tags',
          batch_name: 'Nombre de lote',
          custom_template: 'Template del tag',
          storydots_template: 'Template de Storydots',
          client_template: 'Template del cliente',
          save_notification: 'Cargaste %{amount} Tags QR a %{client}',
          webapp_url: 'URL de webapp',
          storydots_url: 'Genérico de Storydots',
          client_url: 'URL del cliente',
          buttons: {
            cancel: 'Cancelar',
            save: 'Cargar stock',
          },
        },
        view: {
          title: 'Datos de contacto',
          representative: 'Representante',
          email: 'Email',
          phone: 'Teléfono',
          url: 'Url',
          close: 'Cerrar',
        },
        config: {
          tag: {
            title: 'Customizar tag',
            subtitle:
              'Para activar esta opción, debés completar los campos de customización del tag del cliente',
          },
          app: {
            title: 'Customizar app',
            subtitle:
              'Para activar esta opción, debés completar los campos de customización de la app del cliente',
          },
          confirm: 'Completar ahora',
          close: 'Más tarde',
        },
      },
    },
    tracking: {
      table: {
        sender_name: 'Nombre emisor',
        sender_email: 'Email emisor',
        receiver_name: 'Nombre receptor',
        receiver_email: 'Email receptor',
        date: 'Fecha',
        sender: 'Emisor',
        gift: 'Regalo',
        receiver: 'Receptor',
        status: 'Estado',
      },
    },
    tags_table: {
      order: 'Número de orden',
      title_regular: 'Últimas órdenes',
      title_superadmin: 'Últimos tags ',
      action: 'Ver más',
      date: 'Fecha',
      customer: 'Comprador',
      video: 'Video grabado',
    },
    users_table: {
      title: 'Últimos usuarios',
      action: 'Ver más',
      date: 'Fecha',
      customer: 'Nombre',
      email: 'Email',
    },
    second_register: {
      title: 'Completá tu registro',
      subtitle:
        'Para que podamos registrarte como cliente de StoryDots, necesitamos que nos cuentes un poco más sobre vos y tu tienda.',
      form: {
        name: {
          label: 'Tu nombre *',
          minLenght: 'Tu nombre debe tener por lo menos 2 caracteres',
          required: 'Este campo es requerido',
        },
        phone: {
          label: 'Tu teléfono',
        },
        shop_url: {
          label: 'URL de tu tienda',
        },
        content: {
          label: 'Contanos algo más sobre tu tienda',
        },
        submit: {
          label: 'Enviar',
        },
        server_error: 'Error en el servidor, intentelo mas tarde',
      },
      done: {
        title: '¡Completaste tu registro!',
        inputs: {
          name: 'Nombre',
          phone: 'Teléfono',
          url: 'URL',
        },
        p1: 'Pronto activaremos tu panel de control. Este proceso puede demorar hasta 48 horas hábiles.',
        p2: 'Si te equivocaste en alguno de los datos ingresados, o tu panel de control sigue sin activarse luego del tiempo estimado, escribinos a ',
      },
    },
    submitError: "Ha ocurrido un error al intentar guardar los datos."
  },
  users: {
    title: 'Usuarios',
    subtitle:
      'Navega entre los usuarios que participan de los saludos, seleccionando entre emisores, receptores y trazabilidad.',
    header_titles: {
      senders: 'Emisores',
      receivers: 'Receptores',
      tracking: 'Trazabilidad',
    },
    table: {
      name: 'Nombre',
      email: 'Email',
      user: 'Usuario',
      gender: 'Género',
      age: 'Edad',
      last_activity: 'Última actividad',
      first_activity: 'Primer uso',
      greetings_sent: 'Saludos enviados',
      greetings_amount: 'Cantidad de saludos',
      gifted_amount: 'Monto total regalado',
      received_amount: 'Monto total recibido',
      tags: 'Tags',
      not_identified: 'Usuario no identificado',
      id_regular: 'N° Orden',
      id_superadmin: '#ID',

      complete: 'Registrado',
    },
    tags: { ...userTags },
  },
  clients: {
    title: 'Clientes',
    subtitle: 'Listado de los clientes de Storydots.',
    search: 'Buscar',
    action: 'Crear cliente',
    table: {
      name: 'Nombre',
      type: 'Tipo',
      platform: 'Plataforma',
      url: 'URL',
      subscription: 'Suscripción',
      stock: 'Stock',
      actions: {
        label: 'Acciones',
        add: 'Cargar stock',
        impersonate: 'Impersonar',
      },
      custom_app: 'App custom',
      custom_tag: 'Tag custom',
      status: 'Estado',
    },
    popover: {
      editStock: 'Modificar stock',
      viewClient: 'Ver cliente',
      editClient: 'Editar',
      regenerateStock: 'Regenerar stock',
      downloadQRPOP: "Descargar QR POP",
    },
    dialog: {
      add: {
        title: 'Cargar stock',
        helper: 'Seleccione la cantidad de Tags QR a cargar',
        tags_amount: 'Cantidad de tags',
        batch_name: 'Nombre de lote',
        custom_template: 'Template del tag',
        storydots_template: 'Template de Storydots',
        client_template: 'Template del cliente',
        save_notification: 'Cargaste %{amount} Tags QR a %{client}',
        webapp_url: 'URL de webapp',
        storydots_url: 'Genérico de Storydots',
        client_url: 'URL del cliente',
        tags_error: 'El valor debe ser un número y mayor a 0',
        generate_image_tag: 'Generación de imagen de tag',
        buttons: {
          cancel: 'Cancelar',
          save: 'Cargar stock',
        },
      },
      view: {
        title: 'Datos de contacto',
        representative: 'Representante',
        email: 'Email',
        phone: 'Teléfono',
        url: 'Url',
        close: 'Cerrar',
      },
      regenerate: {
        title: 'Regenerar stock',
        description: 'Esta acción va a regenerar el stock actual de tags.',
        confirm: 'Confirmar',
        cancel: 'Cancelar',
        fail: 'Error regenerando stock para el ID de cliente %{clientId}',
        success:
          'El stock está siendo regenerado para el ID de cliente %{clientId}',
        feedback: {
          title: 'Regenerando stock',
          message:
            'El stock actual para este cliente se está regenerando, estará listo en algunos minutos.',
          close: 'Cerrar',
        },
      },
      config: {
        tag: {
          title: 'Customizar Tag',
          subtitle:
            'Para activar esta opción, debés completar los campos de customización del tag del cliente',
        },
        app: {
          title: 'Customizar app',
          subtitle:
            'Para activar esta opción, debés completar los campos de customización de la app del cliente',
        },
        confirm: 'Completar ahora',
        close: 'Más tarde',
      },
    },
  },
  client_form: {
    create_title: 'Crear cliente',
    edit_title: 'Editar cliente: %{client}',
    create_subtitle:
      'Para crear un nuevo cliente, completá los siguientes campos y hacé click en “Guardar”.',
    edit_subtitle:
      'Para editar un cliente, modificá los siguientes campos y hacé click en “Guardar”.',
    confirm_discard: {
      title: 'Descartar cambios',
      question: '¿Estás seguro que deseas descartar los cambios?',
      consequences: 'Una vez descartados no podrás recuperarlos.',
      discard: 'Descartar cambios',
      continue: 'Continuar editando',
    },
    form: {
      shop_data: 'Datos de la tienda',
      name: 'Nombre',
      type: 'Tipo de tienda',
      type_select: {
        online: 'Tienda Online',
        onsite: 'Tienda Física',
      },
      custom_app_url: 'URL de App Personalizada',
      custom_app_url_placeholder:
      'https://su_app_url_personalizado.storydots.app',
      custom_app_url_helperText:
      'https://su_app_url_personalizado.storydots.app',
      url: 'URL',
      contact_data: 'Datos de contacto',
      email: 'Email',
      email_helper: 'Será el email utilizado para iniciar sesión',
      phone: 'Teléfono',
      platform: 'Plataforma',
      tag_custom: 'Personalizar Tag QR normal - para la tienda',
      sender_tag_custom: 'Personalizar Tag QR físico - para el cliente',
      upload_image: 'Subir imagen',
      upload_image_helper: 'Se recomienda formato svg o png transparente.',
      upload_image_error:
      'Formato inválido, sólo se admiten archivos PNG o JPG/JPEG',
      upload_image_size_error:
      'El tamaño de la imágen debe ser como mínimo de 800px de ancho y alto',
      upload_video: 'Subir video',
      upload_video_error:
      'Formato inválido, sólo se aceptan formatos MP4 y OGG',
      upload_video_size_error:
      'El tamaño del video excede el límite de 100mb y 30 segundos',
      default_greeting: 'Saludo por defecto',
      video: 'Seleccioná un archivo de video',
      own_greeting: 'Usar saludo propio',
      sd_greeting: 'Usar saludo de StoryDots',
      tag_design: 'Diseño del Tag',
      invalid_image: 'Imagen inválida',
      webapp_custom: 'Personalizar experiencia',
      redirection_url: 'URL de redirección',
      redirection_url_access: 'Disponible con plan ilimitado',
      sender_redirect_url: 'URL de redirección para el creador',
      redirection_time: 'Tiempo de redirección',
      dark_mode: 'Modo oscuro',
      hide_product: "Ocultar producto",
      seconds: 'Segundos',
      color_palett: 'Paleta de colores',
      custom_tag: 'Personalizar tag',
      fme: 'Filtros, Marcos y Efectos',
      urlDescription: "Ingrese el URL de su tienda",
      shopNameDescription: "Ingrese el nombre de su tienda",
      nameDescription: "Ingrese su nombre",
      phoneDescription: "Ingrese su numero de telefono",
      typeDescription: "Seleccione el  tipo de tienda",
      emailDescription: "Ingrese su email",
      platformDescription: "Elija que plataforma utiliza",
      customTagSwitch: "Tag personalizado",
      customAppSwitch: "Experiencia personalizada",
      customTagSwitchDescription: "Activa o desactiva esta funcionalidad",
      customAppSwitchDescription: "Activa o desactiva esta funcionalidad",
      tag_design_description: "Ingrese su tag personalizado, preferentemente en formato png o svg. Debajo acomode las medidas a su gusto.",
      logoDescription: "Ingrese el logo de su tienda, este se mostrara en la experiencia.",
      colorPaletteDescription: "Seleccione la paleta de colores que se utilizaran en la experiencia. Se puede previsualizar en el carrousel debajo.",
      redirection_url_description: "Ingrese el url al cual se redigirá al cliente y al creador del saludo una vez finalizada la experiencia. Por ejemplo, podria redirigirlo nuevamente dentro de su tienda para ver otros articulos relacionados.",
      custom_app_url_description: "Ingrese un url personalizado, por ejemplo: 'https://su_app_url_personalizado.storydots.app'",
      redirection_time_description: "Ingrese en segundos, el tiempo que tiene que pasar para que se redirija al cliente a su url de redirección.",
      dark_mode_description: "Active o desactive el modo oscuro.",
      hide_product_description: "Ocultar el producto saludo virtual de la lista de productos de la tienda.",
      storeTypeTitle: "Categoría",
      storeTypeDescription: "Rubro de su tienda",
      category: "Categoría"
    },
    store_types: {...storeTypes}
  },
  tracking: {
    table: {
      sender_name: 'Nombre Emisor',
      sender_email: 'Email Emisor',
      receiver_name: 'Nombre Receptor',
      receiver_email: 'Email Receptor',
      date: 'Fecha',
      sender: 'Emisor',
      gift: 'Regalo',
      receiver: 'Receptor',
      status: 'Estado',
    },
  },
  receivers: {
    title: 'Receptores',
  },
  tags: {
    title_superadmin: 'Tags QR',
    title_regular: 'Órdenes',
    subtitle: 'Resumen histórico de Tags emitidos por tu tienda.',
    action_card: {
      title: 'Stock restante',
      button: 'Comprar',
    },
    emptyPage: 'No se han utilizado tags QR hasta el momento.',
    purge_button: 'Purgar',
    table: {
      order: 'Número de orden',
      date: 'Fecha',
      customer: 'Comprador',
      video_recorded: 'Grabado',
      tag_received: 'Escaneado',
      sender_opened: 'Emisor abrió',
      replied: 'Respuesta',
      views: 'Vistas',
      redirections: 'Redirecciones',
      code: 'Código',
      printed: 'Impreso',
      tagDownload: 'TagQR',
    },
    copied: 'Código copiado al portapapeles',
    dialog: {
      view: {
        title: 'Detalles de código',
        client: 'Cliente',
        order: 'Orden',
        id: 'ID de tag',
        price: 'valor de compra',
        link: 'Tag link',
        code: 'código',
        sender: 'comprador',
        receiver: 'Receptor',
        date: 'Emisión de Tag QR',
        recording: 'grabación de saludo',
        recording_received: 'recepción de saludo',
        response: 'Respuesta',
        views: 'Reproducciones',
        redirections: 'Redirecciones',
        close: 'Cerrar',
      },
      purge: {
        title: 'Purgar código',
        subtitle:
          'Estás a punto de purgar el código %{code}. ¿Qué deseás purgar?',
        input_subtitle: 'Inserte el código y el recurso que desea purgar',
        code: 'Código',
        options: {
          recording_reply: 'Saludo y respuesta',
          reply: 'Sólo la respuesta',
          recording: 'Sólo el saludo',
        },
        cancel: 'Cancelar',
        confirm: 'Confirmar',
        blankCodes: 'Por favor inserte uno o más códigos',
        singlePurgeNotification: 'Código purgado con éxito',
        bulkPurgeNotification: 'Códigos purgados con éxito',
        detail: 'Detalles',
        purgeFailed: 'Algo falló, por favor revisa tu(s) código(s)',
      },
      disable: {
        title: 'Desactivar código',
        subtitle:
          'Estás seguro de que deseas desactivar el código %{code}?.',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
        successNotification: 'Código desactivado con éxito',
        alreadyDisabledNotification: 'El código ya se encuentra desactivado',
        failedNotification: 'Algo falló, por favor revisa el código',
      },
      resendEmail: {
        title: 'Reenviar mail',
        subtitle:
          '¿Seguro que deseas reeenviar el mail de grabación del código %{code}?',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
        success: 'Email reenviado con éxito',
        failed: 'Algo falló, por favor revisa los datos',
      }
    },
  },
  logisticPrint: {
    orderID: 'Buscar por...',
    sequence: 'Buscar por...',
    orderItem: "N° de orden",
    sequenceItem: "N° de secuencia",
    sequenceColumn: "N° de secuencia",
    from: 'Desde',
    to: 'Hasta',
    searchButton: 'Buscar',
    printButton: 'Imprimir',
    timeLabel: 'Hora',
    dateLabel: 'Fecha',
  },
  purchases: {
    title: 'Mis compras',
    active_subscription_action_card: {
      title: 'Suscripción activa',
      tags_per_month: '%{amount} Tags QR mensuales',
      pay_per_month: 'Pagás $%{amount} al mes',
      next_billing: 'Próxima facturación: %{date}',
      modify_button: 'modificar',
      cancel_button: 'cancelar',
    },
    cancelled_subscription_action_card: {
      title: 'Suscripción cancelada',
      subscription_cancelled:
        'Cancelaste tu suscripción al plan %{subscription}',
      info: 'Ya no se debitará el cobro de la suscripción.',
      reactivate_button: 'Reactivar',
      see_plans_button: 'Ver planes',
    },
    no_subscription_action_card: {
      title: '¡Aún no tienes una suscripción activa!',
      subtitle: 'Suscríbete a un plan mensual y obtén el mejor valor.',
      button: 'VER PLANES',
    },
    stock_action_card: {
      title: 'Stock restante',
      button: 'Comprar',
    },
    table: {
      header: 'Historial de pagos',
      date: 'Fecha',
      type: 'Tipo',
      product: 'Producto',
      amount: 'Monto',
      status: 'Estado',
      voucher: 'Comprobante',
      download: 'Descargar',
    },
    products: {
      mini_pack: 'Pack Mini',
      plus_pack: 'Pack Plus',
      full_pack: 'Pack Full',
      entrepreneur: 'Emprendedor',
      expansion: 'Expansión',
      professional: 'Profesional',
    },
    types: {
      subscription: 'Subscripción',
      pack: 'Pack',
    },
  },
  subscription_plans: {
    plans: {
      title: 'Planes de suscripción',
      subtitle:
        'Una cantidad fija de Tags QR que se renueva todos los meses y vence a fin de cada mes.',
    },
    tag_packs: {
      title: 'Packs de Tags',
      subtitle:
        'Compras de pago único. Son acumulables entre sí y con las suscripciones, y no tienen vencimiento.',
    },
    subscription_cards: {
      expansion: 'Expansión',
      professional: 'Profesional',
      entrepreneur: 'Emprendedor',
      qr_tags_per_month: 'Tags QR al mes',
      per_month: 'al mes',
      subscribe: 'Suscribirme',
      best_value: 'Mejor Valor',
      is_active: 'Plan Activo',
    },
    pack_cards: {
      buy: 'Comprar',
      qr_tags: 'Tags QR',
    },
  },
  print: {
    title: 'Impresión',
    button: 'Imprimir',
    document_title: 'Tags de Storydots',
    subtitle: 'Genera PDFs listos para imprimir a partir de lotes de tags QR.',
    form: {
      clientID: 'Cliente',
      batch: 'Lote',
      loading: 'Cargando...',
      no_options: 'No se encontraron opciones.',
      batch_not_found: 'Lotes no encontrados',
      submit: 'Generar plancha',
      qr_width: 'Ancho del QR',
      tag_width: 'Ancho del Tag',
      y_position: 'Posición Y del código',
      x_position: 'Posición X del código',
      change_template: 'Cambiar plantilla',
      page_size: 'Ancho de página',
      page_sizes: {
        a4_portrait: 'A4 Vertical',
        a4_landscape: 'A4 Horizontal',
        a3_portrait: 'A3 Vertical',
        a3_landscape: 'A3 Horizontal',
        a2_portrait: 'A2 Vertical',
        a2_landscape: 'A2 Horizontal',
      },
      cutting_lines: 'Mostrar líneas de corte',
      code_index_ref: 'Mostrar ID del código',
      tag_spacing: 'Espaciado entre tags',
      page_margin: 'Margen de página',
      sender_tag: 'Mostrar tags de sender',
    },
    sheet: {
      client: 'Cliente',
      qr: 'QR',
      alt: 'Cargando código QR...',
    },
  },
  custom: {
    title: 'Personalización',
    subtitle:
      'Configurá el aspecto y comportamiento de saludos para una experiencia única.',
    gridTitles: {
      tagsQR: {
        title: 'Tags QR últimos 30 días',
      },
      averageTickets: {
        title: 'Tickets promedio últimos 30 días',
      },
      stats: {
        title: 'Estadísticas',
      },
      averageTicketsTracking: {
        title: 'Tracking de tickets promedio',
      },
      greeting_form: {
        title: 'Personalización de Saludo Virtual',
        inputs: {
          logo_url: {
            label: 'Url de logotipo',
            info: 'Recomendamos formato SVG o PNG transparente.',
          },
          redirect_url: {
            label: 'Url de redirección',
            info: 'Llevaremos a los usuarios a esta página al terminar el proceso.',
          },
          redirect_time: {
            label: 'Tiempo de redirección',
            info: 'Espera antes de redirigir al usuario al terminar el proceso.',
          },
        },
      },
      qr_form: {
        title: 'Personalización de Tag QR',
        inputs: {
          logo_url: {
            label: 'Url de logotipo',
            info: 'Recomendamos formato SVG o PNG transparente.',
          },
          color: {
            label: 'Color principal',
          },
          header_text: {
            label: 'Texto de encabezado',
            info: '16 caracteres restantes.',
          },
          hashtag: {
            label: 'Hashtag en pie de página',
            info: '7 caracteres restantes',
          },
        },
      },
    },
  },
  stats: {
    title: 'Estadísticas',
    subtitle:
      'Informate sobre el comportamiento de tus clientes y su impacto en tu tienda.',
    gridTitles: {
      tagsQR: {
        title: 'Tags QR últimos 30 días',
      },
      averageTickets: {
        title: 'Tickets promedio últimos 30 días',
      },
      stats: {
        title: 'Últimos 6 meses',
      },
      averageTicketsTracking: {
        title: 'Tracking de tickets promedio',
      },
    },
    stat_cards: {
      sent: {
        title: 'Tags enviados',
      },
      recorded: {
        title: 'Saludos grabados',
      },
      viewed: {
        title: 'Saludos vistos',
      },
      responded: {
        title: 'Respondidos',
      },
      averageTicketWithGreeting: {
        title: 'Con saludo',
      },
      averageTicketWithoutGreeting: {
        title: 'Sin saludo',
      },
      averageAmountWithGreeting: {
        title: 'Monto promedio con saludos',
      },
      averageAmountWithoutGreeting: {
        title: 'Monto promedio sin saludos',
      },
    },
    switch: {
      withGreeting: 'Con saludo',
      withoutGreeting: 'Sin saludo',
    },
    codesDataGroupByMonthArrayKeys: {
      sent: 'Emitidos',
      recorded: 'Grabados',
      replied: 'Respondidos',
      views: 'Vistos',
      title: 'Respuestas selfie',
    },
  },
  support: {
    title: 'Soporte',
    subtitle:
      'Si hubo un inconveniente en el uso de StoryDots, este es el lugar donde reportarlo.',
    done: 'Tu reporte ha sido enviado',
    error: 'Ha habido un error al enviar tu reporte',
    form: {
      name: {
        label: 'Tu nombre',
        minLenght: 'Tu nombre debe tener por lo menos %{value} caracteres',
      },
      email: {
        label: 'Tu email',
        helper: 'Responderemos tu consulta a este correo.',
      },
      subject: {
        label: 'Motivo de la consulta',
        list: {
          register: 'Quiero registrar mi tienda',
          buy: 'Quiero comprar tags',
          print: 'Tengo problemas para imprimir',
          bug: 'Quiero reportar un bug',
          stock: 'Hay un problema con mi stock/compra',
          help: 'Necesito ayuda',
          tag: 'Quiero personalizar mi tag',
          plan: 'Quiero suscribirme al plan profesional',
        },
      },
      phone: {
        label: 'Tu teléfono',
        optional_label: 'Tu teléfono (opcional)',
      },
      register_shop_url: {
        label: 'URL de la tienda',
      },
      content: {
        label: 'Describí el problema',
        placeholder:
          'Envianos toda la información que nos pueda servir para resolver tu caso',
        minLenght: 'La descripción debe tener por lo menos %{value} caracteres',
      },
      submit: { label: 'Enviar consulta' },
    },
  },
  settings: {
    title: 'Configuración',
    subtitle:
      'Ajusta tus preferencias para permisos, alertas y otras configuraciones.',
    notifications_table: {
      title: 'Notificaciones',
      headers: {
        case: 'Caso',
        panel_notifications: 'Notificación en panel',
        email_notifications: 'Email',
      },
      cases: {
        stock_equal_less_than_ten: 'El stock de Tags QR es igual o menor a 10',
        stock_equal_less_than_five: 'El stock de Tags QR es igual o menor a 5',
        stock_out: 'El stock de Tags QR está agotado',
        greeting_more_than_ten: 'Saludo consiguió más de 10 reproducciones',
        greeting_more_than_thirty: 'Saludo consiguió más de 30 reproducciones',
        user_sent_greeting:
          'Un usuario que recibió un saludo en el pasado, envía un saludo',
      },
    },
    permissions_table: {
      title: 'Permisos',
      advanced_info: 'Información sobre las ventas de mi tienda',
      advanced_info_subtitle:
        'Activa este permiso para acceder a información avanzada sobre tus usuarios',
    },
  },
  customizations: {
    title: 'Customizaciones',
    subtitle:
      'Creá tu tag personalizado. Configurá estilos y ajustá la experiencia a tu branding.',
    app_custom: {
      title: 'Personalizar experiencia',
      redirectTimeHelper:
        'Cuánto tiempo espera el usuario antes de ser redirigido',
      redirectUrlHelper:
        'URL de tu sitio a la cual serán redirigidos tus usuarios luego de grabar el saludo',
    },
    custom_tag: {
      title: 'Personalizar Tag',
      uploadFile: 'Probalo subiendo el logo de tu marca',
      uploadFileBtn: 'Subir archivo',
      text: 'Texto introductorio',
      textPlaceholder: 'Es un dia especial...',
      textTitle: 'Texto principal',
      textTitlePlaceholder: '¡SORPRESA!',
      palette: 'Color de texto',
      continueBtn: 'Generar',
      resetBtn: 'Resetear',
      logoText: 'TU LOGO ACA',
    },
    custom_video: {
      title: "Personalizá un video por defecto",
      label: "Video",
      upload_button: "Subir video",
      duration_error: "La duracion del video debe que ser menor a 1 minuto.",
      size_error: "El tamaño del video debe ser menor a 100MB.",
      current_video: "Video seleccionado",
      current_video_description: "Video que actualmente se muestra en la experiencia",
      video_requirements_description: "El video tiene que durar menos de 1 minuto y tener un peso menor a 100MB."
    },
    widget_anchor:{
      title: "Anclado del snippet",
      radio_group_label: "Tipo de anclaje",
      snippet_floating_label: "Snippet flotante",
      snippet_anchored_label: "Snippet anclado",
      text_input_label: "Ingrese las clases o ids",
      warning_text: 'La opción "snippet anclado" puede generar problemas renderizando el snippet, si tiene algun inconveniente comuniquese con nosotros en la pestaña soporte o vea este tutorial.',
      radio_group_label_description: "Elija el tipo de anclaje a utilizar. El snippet flotante siempre se mostrará en la parte inferior derecha de su tienda. El snippet anclado intentará insertar el snippet en el carrito de compras, esta opción puede necesitar configuración adicional.",
      text_input_label_title: "Clases o IDs",
      text_input_label_description: "Ingrese el id o las clases pertenecientes a su 'form' del carrito de compras, anteponiendo '#' para el id o un '.' para las clases. Si no sabe como hacerlo porfavor cominiquesé con nosotros en la pestaña de soporte.",
    },
    spOrderTag: {
      title: 'Agrega el tag a la orden',
      copyBtn: 'Copiar HTML',
      inputLabel: 'App',
      copied: 'copiado',
      description: "Copia el HTML para generar un tag usando order printer.",
    }
  },
  announcements: {
    title: 'Anuncios',
  },
  appBar: {
    spanish: 'Español',
    english: 'Inglés',
    portuguese: 'Portugués',
  },
  auth: {
    sign_up_header: 'Registro',
    sign_in_header: 'Iniciar sesión',
    bottom_nav: {
      terms: 'Términos y condiciones',
      privacy: 'Privacidad',
      gdpr: 'GDPR',
    },
  },
  login: {
    btn: {
      sign_in: 'Ingresar',
      tn_sso_sign_in: 'Iniciar sesión con Tiendanube',
    },
    link: {
      sign_up: 'Registrarse',
      forgot_password: 'Olvidé mi contraseña',
    },
  },
  sign_up: {
    btn: {
      sign_up: 'Registrarme',
    },
    link: {
      sign_in: 'Iniciar sesión',
    },
    inputs: {
      full_name: 'Nombre y apellido',
      email: 'Email',
      shop_url: 'URL de la tienda',
      phone: 'Teléfono (opcional)',
      repeat_password: 'Repetir la contraseña',
      errors: {
        register_password:
          'La contraseña debe contener un mínimo de ocho caracteres y al menos una letra y un número',
        repeat_password: 'La contraseña debe ser igual en ambos campos',
      },
    },
    errors: {
      email: 'Mail no disponible',
    },
  },
  genders: {
    male: 'Masculino',
    female: 'Femenino',
    other: 'Otro',
  },
  tag_status_chips: {
    pending: 'Pendiente',
    sent: 'Enviado',
    received: 'Recibido',
    responded: 'Respondido',
    ready: 'Listo',
  },
  response_status_chips: {
    pending: 'Pendiente',
    text: 'Texto',
    selfie: 'Selfie',
  },
  video_status_chips: {
    pending: 'Pendiente',
    ready: 'Listo',
  },
  purchase_status_chips: {
    pending: 'Pendiente',
    aproved: 'Aprobado',
    rejected: 'Rechazado',
  },
  go_back: 'Volver',
  submit_buttons: {
    save: 'Guardar',
    discard: 'Descartar',
  },
  admin_users: {
    title: 'Administrar usuarios',
    subtitle: 'Listado de usuarios en Firebase',
    table: {
      name: 'Nombre',
      email: 'Email',
      role: 'Rol',
      clientID: 'Cliente',
      complete: 'Registrado',
    },
    popover: {
      edit_password: 'Resetear contraseña',
    },
    dialog: {
      view: {
        title: 'Datos del cliente',
        role: 'Rol',
        email: 'Email',
        clientID: 'Cliente',
      },
    },
  },
  subscriptions_plans: {
    title: 'Suscripciones',
    subtitle: 'Elegí el plan que mejor se adapte a tus necesidades',
    currentPlan: 'Tu plan Actual',
    remainingGreetings: 'Saludos Restantes',
    subscriptionStatus: 'Estado de la Suscripción',
    statuses: {
      active: 'Al día',
      pending: 'Pendiente de pago',
      cancelled: 'Cancelado',
      paused: 'Pausado',
    },
    messages: {
      unavailblePlan:
        ' Tu plan actual ya no está <strong>disponible</strong>. Podés probar suscribiéndote a uno de los planes que aparecen abajo.',
    },
    popups: {
      outOfStock: {
        title: '¡Se agotaron tus saludos!',
        subtitle:
          'Estamos convencidos de que a tus clientes les encantó la experiencia.',
        paragraph:
          'Recordá que podés aumentar la cantidad de saludos de tu plan cuando lo necesites.',
        footer:
          'Contratá más saludos a menor precio ahorrando un 30% con un plan anual.',
        footerbtn: 'Ver planes',
      },
      cancelSub: {
        title: '¿Estás seguro de cancelar tu suscripción?',
        subtitle: 'Dejarás de permitir hacer regalos desde tu tienda',
        cancelbtn: 'Cancelar',
        continuebtn: 'Sí, quiero desuscribirme',
        confirmbtn: 'Entendido',
        confirmtitle: 'Estamos procesando la baja de suscripción.',
        confirmsubtitle: 'El proceso puede demorar unos minutos',
      },
      warningSub: {
        title: 'Serás redirigido a Mercadopago para suscribirte',
        subtitle:
          'Es muy importante que vuelvas al Dashboard para finalizar la suscripción.',
        caption: {
          start: 'Hacelo desde el botón',
          finish: '“Volver al sitio”',
        },
        confirmbtn: 'Entendido',
        cancelbtn: 'Volver',
      },
    },
    container: {
      title: 'TU PLAN ACTUAL - ESTADO DE LA SUSCRIPCIÓN',
      billTitle: 'Facturación',
      monthly: 'Mensual',
      yearly: 'Anual',
      sliderTitle: 'Cantidad de Saludos',
      planContainerTitle: 'Nuestros planes',
      plan: {
        planNames: {
          INITIAL: 'Inicial',
          ADVANCED: 'Avanzado',
          UNLIMITED: 'Ilimitado'
        },
        includes: {
          INITIAL: 'Contás con:',
          ADVANCED: 'Todo lo del Plan Inicial más:',
          UNLIMITED: 'Todo lo del Plan Avanzado más:',
        },
        cancel: 'Cancelar Suscripción',
        greetingsToBuy: 'Saludos a comprar',
        availableStarting: 'Disponible a partir de @ saludos',
        start: 'Comenzar',
        investment: {
          monthly: 'Inversión Mensual',
          yearly: 'Inversión Anual',
        },
        current: '(Plan Actual)',
        noPlan: 'Sin plan',
        freePlan: 'Gratis',
        alreadyContracted: 'Actualizar Plan',
        contact: 'Consultar',
        features: {
          customLogo: 'Logo personalizado',
          unlimitedStock: 'Stock ilimitado',
          onDemandSupport: 'Soporte a medida',
          uniqueContentAccess: 'Acceso a contenido único',
          customTag: 'Customización de Branding en Tags QR',
          customApp: 'Customización de Branding en la App',
          customPalette: 'Themes predefinidos con paletas de color',
          fme: 'Sumá Filtros, Efectos Visuales y Marcos temáticos para los saludos',
          customRedirect: 'Redireccioná el tráfico a tu tienda',
          customEmail: 'Mails customizados a medida',
          stats:
            'Acceso a Dashboard con métricas y estadísticas de tus clientes',
          abandonedCarts: 'Recupero de carritos abandonados',
        },
      },
      bottomText: {
        start: "Los créditos se ",
        bold: "renuevan automáticamente ",
        end: "mes a mes y ",
        endBold: "no son acumulables."
      },
    },
  },
  onboarding: {
    language: "ES",
    thanksForJoining: {
      title: "¡Gracias por sumarte!",
      subtitle: "Mejorando la experiencia de compra mejorás también tu facturación",
      button: "Ir al tutorial"
    },
    wizardContent: {
      nextBtn: "Siguiente",
      beginBtn: "Empezar",
      goBackBtn: "Atrás",
      welcome: {
        title: "¡Hola! Soy Vicky",
        messages: {
          msg1: {
            0: "Te voy a acompañar en este tutorial de ",
            1: "5 minutos ",
            2: "que te recomiendo hacer completa. ",
            3: "Tené tu celular a mano.",
          },
        },
      },
      howItWorks: {
        title: "Cómo funciona",
        messages: {
          msg1: "Es muy importante conocer la experiencia que van a vivir tus clientes al utilizar nuestra aplicación."
        },
      },
      QRScan: {
        title: "Escaneá el QR con tu celular",
        messages: {
          msg1: {
            0: "Vamos a crear tu primer saludo virtual donde vas a ponerte en el lugar del ",
            1: "regalador ",
            2: "como así también del ",
            3: "receptor"
          },
          msg2: "¡Listo! Veamos ahora cómo nos integramos a tu tienda."
        }
      },
      storeIntegration: {
        title: "Integración",
        messages: {
          msg1: "Hoy nos integramos al carrito de tu tienda permitiendo indicar allí cuando una compra es un regalo."
        }
      },
      simulatePurchase: {
        title: "¡Simulemos una compra de regalo!",
        messages: {
          msg1: "Ya tenés elegido tu producto. Ahora veamos qué sucede cuando se indica con StoryDots que es un regalo."
        }
      },
      greetingIsAddedToCart: {
        title: "El Saludo se agrega al carrito",
        messages: {
          msg1: "Con el nombre “Saludo Virtual”. No debes eliminarlo ni ocultarlo de tus productos."
        }
      },
      quickGreetingCreation: {
        title: "Creación rápida",
        messages: {
          msg1: "Tu cliente podrá completar aquí un saludo de texto rápido. Luego podrá mejorarlo desde su email."
        }
      },
      orderEndedSuccessfully: {
        title: "El cliente finaliza la orden con éxito",
        messages: {
          msg1: "Veamos cómo hace para crear el saludo en nuetra App."
        }
      },
      emailSent: {
        title: "Enviamos un mail al cliente",
        messages: {
          msg1: "Accediendo podrá crear un saludo virtual en video, foto, voz o texto como el que ya hiciste.",
          msg2: "¿Seguís ahí? ¡Ya solo queda un paso!"
        }
      },
      SDArrivesAsQR: {
        title: "StoryDots llega en un QR",
        messages: {
          msg1: "Tendrás que identificar las órdenes para regalo e imprimir los QR desde TiendaNube.",
          msg2: "Aprendamos a identificar e imprimir estas órdenes."
        }
      },
      printing: {
        title: "Impresión",
        messages: {
          msg1: {
            0: "Las órdenes para regalo tienen una nota adjunta con el texto ",
            1: "“Contiene tag StoryDots”",
            2: ". Deberías tildar la orden.",
          }
        }
      },
      deployOrderMenu: {
        title: "Desplegar el menú “Elegí una acción”",
        messages: {
          msg1: "Y seleccionar “Imprimir etiquetas StoryDots”.",
        }
      },
      printingSingle: {
        title: "Impresión individual",
        messages: {
          msg1: "También podrás imprimir las etiquetas de Storydots en la vista de detalle de orden."
        }
      },
      printingEnd: {
        title: "Aquí finaliza la impresión",
        messages: {
          msg1: "Vas a poder elegir distintos formatos, no te olvides de adjuntar el tag QR en una zona visible del paquete.",
        }
      }
    },
    congratulations: {
      title: "¡Felicitaciones, ya sos un experto!",
      subtitle: "Estás a un paso de obtener rendimientos como este:",
      cardTitle: {
        start: "Simulación de ",
        color: "rendimiento extra",
        end: " en tu rubro."
      },
      card1: "Cantidad de ventas",
      card2: "Extra por regalos",
      card3: "Ventas extra con StoryDots",
      button: "Finalizar"
    },
    stepper: {
      0: {
        title: "Introducción",
        subtitle: "Cómo funciona",
        tooltip: "Ir a introducción"
      },
      1: {
        title: "Integración",
        subtitle: "Al carrito de compras",
        tooltip: "Ir a integración"
      },
      2: {
        title: "Impresión",
        subtitle: "De los tags QR",
        tooltip: "Ir a impresión"
      },
    },
    skip: {
      tooltip: "Salir del tutorial",
      question: "¿Querés salir del tutorial?",
      cancel: "Cancelar",
      confirm: "Confirmar"
    }
  },
};
export default customSpanishMessages;
