export const parseTimestamp = (timestamp: Date, locales: string) => {
  let date = new Date(timestamp);
  return date.toLocaleDateString(locales);
};

export const dateFormatter = (
  value: string | number | Date,
  locales: string,
  withHours?: boolean,
): string => {
  // CONFIGURACION DE INTERNACIONALIZACION DE JAVASCRIPT
  // const options: Record<string, string> = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }

  // if (!withHours) {
  //     delete options.hour
  //     delete options.minute
  // }

  // const formatter = new Intl.DateTimeFormat(locales, options)

  // let date: any = value
  // if (typeof value === 'string' || typeof value === 'number') {
  //     date = new Date(value)
  // }

  // return formatter.format(date)
  let date = value instanceof Date ? value : new Date(value);
  return date.toLocaleDateString(locales);
};

export const getAbsoluteUrl = (url: string) => {
  if (url.includes('https://') || url.includes('http://')) return url;
  else return 'http://' + url;
};

export const getFileName = (fullPath: string) => {
  const startIndex =
    fullPath.indexOf('\\') >= 0
      ? fullPath.lastIndexOf('\\')
      : fullPath.lastIndexOf('/');
  let filename = fullPath.substring(startIndex);
  if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
    filename = filename.substring(1);
  }
  return filename;
};

export const isValidUrl = (url: string) => {
  let isValid = false;
  try {
    new URL(url);
    isValid = true;
    if (
      !url.toLocaleLowerCase().includes('storydots.app') ||
      !url.toLocaleLowerCase().includes('https://')
    )
      isValid = false;
  } catch (e) {
    isValid = false;
    if (url === '') isValid = true;
  }
  return isValid;
};
export const isRequired = (value: any, action?: (arg?: any) => void) => {
  action && !Boolean(value) && action();
  return Boolean(value);
};

export function loadScreenRecorder() {
  if (window.location.host !== 'https://my-dev.storydots.app') {
    //@ts-ignore
    window.__lo_site_id = '96010ae9';
    (function () {
      var wa = document.createElement('script');
      wa.type = 'text/javascript';
      wa.async = true;
      wa.defer = true;
      wa.src = 'https://tools.luckyorange.com/core/lo.js?site-id=96010ae9';
      var s = document.getElementsByTagName('script')[0];
      s?.parentNode?.insertBefore(wa, s);
    })();
  }
}
