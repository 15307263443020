import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ShowProps,
} from 'react-admin';

const TrackingShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField label="users.table.name" source="receiverName" />
        <TextField label="users.table.email" source="receiverEmail" />
        <DateField label="users.table.first_activity" source="firstActivity" locales={navigator.language} />
        <TextField
          label="users.table.greetings_received"
          source="totalReceived"
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default TrackingShow;
