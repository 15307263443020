import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  InputLabel,
  Button,
  Typography,
  Input,
  useTheme,
  CardHeader,
  CardContent,
  CardActionArea,
  Collapse,
  Grid,
  makeStyles,
  Theme,
  SvgIcon,
} from '@material-ui/core';
import Resizer from 'react-image-file-resizer';
import { useTranslate } from 'react-admin';
import { toBlob } from 'html-to-image';
import { CloudUpload as FileUploadIcon } from '@material-ui/icons';
import { useFormContext } from 'react-hook-form';
import { ArrowChevron, CardTitleWithIcon } from 'components';
import useStyles from '../../styles';
import QRIcon from 'img/QRIcon';

interface StyleProps {
  toggleCard?: boolean;
}
const useLocalStyles = makeStyles<Theme, StyleProps>((theme) => ({
  cardMediaquerys: (props) => ({
    transition: 'all  0.3s linear',
    maxWidth: props.toggleCard ? '1200px' : '800px',
  }),
}));

const resizeFile = (file: Blob): Promise<string> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1280,
      1280,
      'PNG',
      100,
      0,
      (uri: any) => {
        resolve(uri);
      },
      'base64',
      800,
      1280,
    );
  });

const colors = [
  '#000000',
  'rgba(0, 0, 0, 0.3)',
  '#8F3FE6',
  '#E63F85',
  '#3F64E6',
  '#E68F3F',
];

type Props = {
  register?: any;
  templateUrl?: string;
  disabled: boolean;
};

function CustomTag({ register, templateUrl, disabled }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [showCustomization, setShowCustomization] = useState(
    templateUrl ? false : true,
  );
  const [color, setColor] = useState('black');
  const [toggleCard, setToggleCard] = useState(false);
  const [customLogoSrc, setCustomLogoSrc] = useState<string | null>(null);
  const translate = useTranslate();
  const [title, setTitle] = useState<string>(
    translate('customizations.custom_tag.textPlaceholder'),
  );
  const [subtitle, setSubtitle] = useState<string>(
    translate('customizations.custom_tag.textTitlePlaceholder'),
  );
  const classes = useStyles();
  const theme = useTheme();
  const { setValue } = useFormContext();
  const localStyles = useLocalStyles({ toggleCard });

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const node = document.getElementById('cardContainer');
      const generatedImageUrl = await toBlob(node!);
      const resized = await resizeFile(generatedImageUrl!);
      setValue('template', resized);
      setValue('tag_custom', true);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setShowCustomization(templateUrl ? false : true);
  }, [templateUrl]);
  useEffect(() => {
    console.log(toggleCard);
  }, [toggleCard]);

  const handleResetCustomization = () => {
    setCustomLogoSrc(null);
    setSubtitle(translate('customizations.custom_tag.textTitlePlaceholder'));
    setTitle(translate('customizations.custom_tag.textPlaceholder'));
    setColor('black');
    setShowCustomization(false);
    setValue('template', '');
    // setValue('tag_custom', false);
  };

  return (
    <Card className={`${classes.formPart} ${localStyles.cardMediaquerys}`}>
      <CardActionArea onClick={() => setToggleCard(!toggleCard)}>
        <CardHeader
          title={CardTitleWithIcon(
            translate('customizations.custom_tag.title'),
            <SvgIcon color="primary">
              <QRIcon />
            </SvgIcon>,
          )}
          classes={{ action: classes.cardHeader }}
          action={
            <ArrowChevron
              setToggleCard={setToggleCard}
              toggleCard={toggleCard}
              errorListener={toggleCard}
            />
          }
        />
      </CardActionArea>
      <Collapse in={toggleCard}>
        <CardContent>
          <Grid
            container
            spacing={4}
            justifyContent="space-between"
            className={`${disabled ? classes.grey : ''}`}
            onClick={(e) => disabled && e.preventDefault()}
          >
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Box
                style={{
                  backgroundColor: '#F2F0FC',
                  borderRadius: '16px',
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '40px',
                }}
              >
                <Box
                  style={{
                    background: `url(${
                      showCustomization ? '/tag-empty.png' : templateUrl
                    })`,
                    aspectRatio: '0.625',
                    backgroundSize: showCustomization ? 'contain' : 'cover',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '400px',
                    width: '100%',
                  }}
                  id={'cardContainer'}
                >
                  {showCustomization && (
                    <>
                      <Box
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: '1.375rem',
                            marginTop: '30px',
                            fontWeight: 600,
                            color: color,
                          }}
                        >
                          {title}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '2.3rem',
                            marginBottom: '30px',
                            marginTop: '0px',
                            fontWeight: 'bold',
                            color: color,
                          }}
                        >
                          {subtitle}
                        </Typography>
                      </Box>
                      {customLogoSrc ? (
                        <img
                          src={customLogoSrc}
                          alt="custom logo"
                          style={{
                            maxWidth: '200px',
                            maxHeight: '70px',
                            margin: '10px',
                            marginBottom: '25px',
                            marginTop: '320px',
                          }}
                        />
                      ) : (
                        <Typography
                          style={{
                            fontSize: '1.7rem',
                            color: theme.palette.primary.main,
                            fontWeight: 600,
                            marginTop: '355px',
                          }}
                        >
                          {translate('customizations.custom_tag.logoText')}
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <Box
                style={{
                  backgroundColor: '#EDF2F4',
                  borderRadius: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '40px',
                  paddingBottom: '40px',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                }}
              >
                <Box style={{ marginBottom: '40px' }}>
                  <InputLabel
                    style={{
                      marginBottom: '10px',
                      fontWeight: 600,
                      color: 'black',
                      fontSize: '18px',
                    }}
                  >
                    {translate('customizations.custom_tag.uploadFile')}
                  </InputLabel>
                  <Button
                    component="label"
                    variant="contained"
                    color="primary"
                    endIcon={<FileUploadIcon />}
                  >
                    {translate(
                      'customizations.custom_tag.uploadFileBtn',
                    ).toUpperCase()}

                    <input
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        setCustomLogoSrc(
                          e.target && e.target.files && e.target.files[0]
                            ? URL.createObjectURL(e.target.files[0])
                            : null,
                        );
                        !showCustomization && setShowCustomization(true);
                      }}
                    />
                  </Button>
                </Box>
                <Box style={{ marginBottom: '40px' }}>
                  <InputLabel
                    style={{
                      marginBottom: '10px',
                      fontWeight: 600,
                      color: 'black',
                      fontSize: '18px',
                    }}
                  >
                    {translate('customizations.custom_tag.text')}
                  </InputLabel>
                  <Input
                    placeholder={translate(
                      'customizations.custom_tag.textPlaceholder',
                    )}
                    value={
                      title ===
                      translate('customizations.custom_tag.textPlaceholder')
                        ? ''
                        : title
                    }
                    onChange={(e) => {
                      setTitle(
                        e.target.value === ''
                          ? translate(
                              'customizations.custom_tag.textPlaceholder',
                            )
                          : e.target.value,
                      );
                      !showCustomization && setShowCustomization(true);
                    }}
                  />
                </Box>
                <Box style={{ marginBottom: '40px' }}>
                  <InputLabel
                    style={{
                      marginBottom: '10px',
                      fontWeight: 600,
                      color: 'black',
                      fontSize: '18px',
                    }}
                  >
                    {translate('customizations.custom_tag.textTitle')}
                  </InputLabel>
                  <Input
                    placeholder={translate(
                      'customizations.custom_tag.textTitlePlaceholder',
                    )}
                    value={
                      subtitle ===
                      translate(
                        'customizations.custom_tag.textTitlePlaceholder',
                      )
                        ? ''
                        : subtitle
                    }
                    onChange={(e) => {
                      setSubtitle(
                        e.target.value === ''
                          ? translate(
                              'customizations.custom_tag.textTitlePlaceholder',
                            )
                          : e.target.value,
                      );
                      !showCustomization && setShowCustomization(true);
                    }}
                  />
                </Box>
                <Box style={{ marginBottom: '40px' }}>
                  <InputLabel
                    style={{
                      marginBottom: '10px',
                      fontWeight: 600,
                      color: 'black',
                      fontSize: '18px',
                    }}
                  >
                    {translate('customizations.custom_tag.palette')}
                  </InputLabel>
                  <Box
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    {colors.map((color, index) => (
                      <div
                        style={{
                          backgroundColor: color,
                          height: '45px',
                          width: '45px',
                          borderRadius: '12px',
                          marginLeft: index !== 0 ? '5px' : '0px',
                          cursor: 'pointer',
                        }}
                        onClick={() => setColor(color)}
                      ></div>
                    ))}
                  </Box>
                </Box>

                <Button
                  onClick={onSubmit}
                  variant="contained"
                  color="primary"
                  disabled={isLoading || !showCustomization}
                >
                  {translate('customizations.custom_tag.continueBtn')}
                </Button>

                {showCustomization && templateUrl && (
                  <Button
                    onClick={handleResetCustomization}
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: '1em' }}
                  >
                    {translate('customizations.custom_tag.resetBtn')}
                  </Button>
                )}

                <input
                  {...register('template')}
                  style={{ display: 'none' }}
                  disabled
                />
                <input
                  {...register('tag_custom')}
                  style={{ display: 'none' }}
                  disabled
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default CustomTag;
