import React from 'react';
import { Card, Typography, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

type Props = {
  text: string;
  value: any;
  isLoading: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '20em',
    height: '4em',
    justifyContent: 'center',
    marginLeft: '10px',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      display: 'inline-block',
      fontSize: '13px',
      padding: 0,
    },
    // whiteSpace: 'noWrap',
    overflow: 'initial',
    whiteSpace: 'nowrap',
  },
  titleText: {
    marginRight: '5px',
  },
}));

export default function InfoCard({ text, value, isLoading }: Props) {
  const styles = useStyles();

  return (
    <Card className={styles.container} elevation={6}>
      {isLoading ?  <Skeleton variant="text" width="100%" height="3em"/> :
        <>
          <Typography className={styles.titleText}>{text}</Typography>
          <Typography style={isNaN(value) ? {lineHeight: 'initial'}: {}}>{value}</Typography>
        </>
      }    
    </Card>
  );
}