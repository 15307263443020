import * as React from 'react';
import { makeStyles, CardActionArea, useMediaQuery } from '@material-ui/core';
import { ReactElement } from 'react';
import LinkWithChevron from '../LinkWithChevron';

const useStyles = (isSmall:boolean) => makeStyles((theme) => ({
  header: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    padding: '8px 16px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    borderTop: 'none',
  },
  spacer: {
    flex: 1,
  },
  title: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: 0,
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: '12px',
    '& svg': {
      fill: theme.palette.primary.main,
      width: '100%',
      height: '100%',
    },
  },
}));

export interface IActionHeaderProps {
  title: string;
  icon: ReactElement;
  action: {
    title: string;
    callback: () => void;
  };
}

const ActionHeader: React.FC<IActionHeaderProps> = ({
  title,
  icon,
  action,
}) => {
  const isSmall = useMediaQuery('(max-width:500px)');
  const classes = useStyles(isSmall)();
  return (
    <CardActionArea className={classes.header}>
      <span className={classes.icon}>{icon}</span>{' '}
      <h3 className={classes.title}>{title}</h3>{' '}
      <div className={classes.spacer}></div>
      <LinkWithChevron title={action.title} onClick={action.callback} />
    </CardActionArea>
  );
};

export default ActionHeader;