import { put, call, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import {
  HOME_FETCH_LOADING,
  LAST_TAGS_FETCH_SUCCEDED,
  HOME_FETCH_FAILED,
  LAST_USERS_FETCH_SUCCEDED,
  FETCH_HOME,
  STATS_FETCH_LOADING,
  CLIENT_FETCH_SUCCEDED,
  SUPERADMIN
} from '../config';
import config from '../config';
import { USER_LOGIN_SUCCESS } from 'react-admin';

const getToken = (state: any) => state.auth?.token || '';
const clientId = (state: any) => state.auth?.decoded?.clientID || '';
const checkIsSuperAdmin = (state: ReduxState) => state.auth?.decoded.role === SUPERADMIN;

const fetchLastTags = async (token: string) => {
  const axiosOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${config.AWS_API_SITE}/tags?offset=0&limit=5&sort=["assigned_at","desc"]`,
    axiosOptions,
  );

  const lastTags: lastTags[] = response.data.map((row: TagResource) => ({
    order_number: row.order?.order_number || '-',
    id: row.id || '-',
    date: row.order?.created_at || row.updated_at || '-',
    customer: row?.code_details[0]?.sender_email
      ? row?.code_details[0]?.sender_email
      : row?.order?.buyer_email + '*',
    videoStatus: row.code_details[0]?.recorded || false,
  }));
  return lastTags;
};

const fetchClient = async (token: string) => {
  try {
    const response = await axios.get(`${config.AWS_API_SITE}/client`, {
      params: { clientID: clientId },
      headers: { Authorization: `Bearer ${token}` },
    });

    let clientObject = {
      user_data_store_url: response.data.store_url || '',
      user_data_origin: response.data.origin || ''
    }

    return clientObject;
  } catch(err) {
    console.log(err)
  }
}

const fetchLastUsers = async (token: string) => {
  const axiosOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${config.AWS_API_SITE}/senders?offset=0&limit=5&sort=["last_activity","desc"]`,
    axiosOptions,
  );

  const lastUsers: lastUsers = response.data.map(
    (row: any /* usersResponse */) => ({
      order_number: row.order_number || '-',
      id: row.id || '-',
      date: row.last_activity,
      customer: row.sender_name || '-',
      email: row.buyer_email || '-',
    }),
  );
  return lastUsers;
};

function* fetchHomeSaga() {
  yield takeEvery(USER_LOGIN_SUCCESS, function* () {
    yield put({ type: HOME_FETCH_LOADING });
    yield put({ type: STATS_FETCH_LOADING });
  });
}

function* fetchLastTagsSaga() {
  yield takeEvery(FETCH_HOME, function* () {
		// @ts-ignore: Unreachable code error
    const token = yield select(getToken);
    try {
			// @ts-ignore: Unreachable code error
      const lastTags = yield call(fetchLastTags, token);
      yield put({ type: LAST_TAGS_FETCH_SUCCEDED, payload: lastTags });
    } catch (e: any) {
      yield put({ type: HOME_FETCH_FAILED, payload: e.message });
    }
  });
}

function* fetchLastUsersSaga() {
  yield takeEvery(FETCH_HOME, function* () {
		// @ts-ignore: Unreachable code error
    const token = yield select(getToken);
    try {
			// @ts-ignore: Unreachable code error
      const lastUsers = yield call(fetchLastUsers, token);
      yield put({ type: LAST_USERS_FETCH_SUCCEDED, payload: lastUsers });
    } catch (e: any) {
      yield put({ type: HOME_FETCH_FAILED, payload: e.message });
    }
  });
}

function* fetchClientSaga() {
  yield takeEvery(FETCH_HOME, function* () {
    // @ts-ignore: Unreachable code error
    const token = yield select(getToken);
    // @ts-ignore: Unreachable code error
    const isSuperAdmin = yield select(checkIsSuperAdmin);
    try {
      if(isSuperAdmin) throw new Error('Cannot fetch in SUPERADMIN account');
      // @ts-ignore: Unreachable code error
      const lastUsers = yield call(fetchClient, token);
      yield put({ type: CLIENT_FETCH_SUCCEDED, payload: lastUsers });
    } catch (e: any) {
      yield put({ type: HOME_FETCH_FAILED, payload: e.message });
    }
  })
}

export default [fetchHomeSaga, fetchLastTagsSaga, fetchLastUsersSaga, fetchClientSaga];
