import React from "react";
import { useTranslate } from "react-admin";
import QRIcon from "img/QRIcon";
import { Box, Typography, SvgIcon} from "@material-ui/core";

const Empty = () => {
  const translate = useTranslate();
  return (
    <Box textAlign={"center"} m={1}>
      <div>
        <QRIcon style={{fontSize: 200, color: 'rgba(0,0,0,.6)'}}/>
      </div>
      <Typography variant="h4" paragraph style={{color: 'rgba(0,0,0,.6)'}}>{translate('tags.emptyPage')}</Typography >
    </Box>
  )
}

export default Empty;