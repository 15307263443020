import React, { ReactElement } from 'react'
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    action: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
    }
}));

interface IActionButtonProps {
    title: string;
    callback: () => void;
    icon?: ReactElement;
}

const ActionButton: React.FC<IActionButtonProps> = ({ title, callback, icon }) => {
    const classes = useStyles();
    return (
        <div className={classes.action}>
            <Button startIcon={icon} variant="contained" color="primary" size="large" onClick={callback} >
                {title}
          </Button>
        </div>
    )
}

export default ActionButton;