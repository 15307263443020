import { takeEvery, select } from 'redux-saga/effects';
import { CHECK_ROUTE_SUPERADMIN, SUPERADMIN } from '../config';
// import history from '../history';


const getRole = (state: any) => state.auth?.decoded.role || '';

function* checkRoleSuperadmin() {
    yield takeEvery(CHECK_ROUTE_SUPERADMIN, function* () {
			// @ts-ignore: Unreachable code error
        const role = yield select(getRole);
        if (role !== SUPERADMIN) {
            // history.replace('/');
        }
    });
}

export default [checkRoleSuperadmin];