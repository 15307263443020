import React, { useState } from "react";
import {
  List,
  Datagrid,
  DateField,
  ListProps,
  FunctionField,
  useRefresh,
} from 'react-admin';
import { useTranslate } from 'ra-core';
import { useNotify } from "react-admin";
import { useSelector } from 'react-redux';
import PageTitle from 'components/PageTitle';
import QRIcon from 'img/QRIcon';
import PageContainer from 'components/PageContainer';
import PopoverMenu, { usePopoverMenu } from '../../components/PopoverMenu';
import { makeStyles, useMediaQuery, IconButton } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import EmailIcon from '@material-ui/icons/Email';
import ListActions from "./ListActions";
import UserField from 'components/UserField/simple';
import { useIsSuperAdmin } from 'customHooks';
import PurgeDialog, { usePurgeDialog } from './PurgeDialog';
import DisableDialog, { useDisableDialog } from './DisableDialog';
import ResendEmailDialog, { useResendEmailDialog } from './ResendEmailDialog';
// import useSendGTMEvent from "Utils/hooks/useSendGTMEvent";
import CustomFilters from "./CustomFilters";
  
const useListStyles = makeStyles((theme) => ({
  datagrid: {
    borderCollapse: 'unset',
    '& thead': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    fontSize: '1.2rem',
    '& th': {
      paddingTop: 12,
      paddingBottom: 12,
      background: 'transparent',
    },
    },
    '& tbody': {
    '& tr': {
      '& td': {
      padding: '6px 10px'
      }
    }
    }
  },
  list: {
    '& [class*="RaList-content"]': {
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
    '& [class*="MuiToolbar"]': {
      marginBottom: 20,
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
  },
  simpleList: {
    padding: 0,
    '& > a': {
      display: 'block',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    '& [class*="RaSimpleList"]': {
      opacity: 1,
    },
  },
  codeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
  },
}));

const VTPrintList: React.FC<ListProps> = (props) => {
  const translate = useTranslate();
  const classes = useListStyles();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const isSuperadmin = useIsSuperAdmin();

  const onDownloadQR = (code?: string) => {
    return window.open(`${process.env.REACT_APP_AWS_API}/qr/${code}`, '_blank');
  };

  const [selectedTag, setSelectedTag] = useState<TagResource | undefined>();
  const {
    popoverMenuAnchorEl,
    popoverMenuIsOpen,
    popoverMenuOnClick,
    popoverMenuOnClose,
  } = usePopoverMenu();
  const { purgeDialogIsOpen, purgeDialogOnClick, purgeDialogOnClose } =
    usePurgeDialog();
  const { disableDialogIsOpen, disableDialogOnClick, disableDialogOnClose } =
    useDisableDialog();
  const { resendEmailDialogIsOpen, resendEmailDialogOnClick, resendEmailDialogOnClose } =
    useResendEmailDialog();
  const refresh = useRefresh();
  const notify = useNotify();
  const onPurgeClose = () => {
    refresh();
    purgeDialogOnClose();
  };
  const onDisableClose = () => {
    refresh();
    disableDialogOnClose();
  };
  const onResendEmailClose = () => {
    refresh();
    resendEmailDialogOnClose();
  };

  const onMenuClick = (event: any, resource?: TagResource) => {
    setSelectedTag(resource);
    popoverMenuOnClick(event);
  };
  const token = useSelector((state: ReduxState) => state.auth?.token);

  return (
    <PageContainer>
    <PageTitle
      title={
        isSuperadmin
          ? translate('tags.title_superadmin')
          : translate('tags.title_regular')
      }
      icon={<QRIcon />}
      subtitle={translate('tags.subtitle')}
    />
      <List
        {...props}
        exporter={false}
        bulkActionButtons={<ListActions />}
        actions={<CustomFilters />}
        sort={{ field: 'assigned_at', order: 'DESC' }}
        className={classes.list}
        resource="tags"
      >
        <Datagrid rowClick="edit" className={classes.datagrid}>
          {!isSuperadmin && (
            <FunctionField<TagResource>
              label="tags.table.order"
              source="order_number"
              render={(record) => `${record?.order?.order_number || ''}`}
            />
          )}
          <FunctionField<TagResource>
            label="logisticPrint.sequenceColumn"
            source="sequence"
            render={(record) => `${record?.order?.sequence || ''}`}
          />
          <DateField
            label="tags.table.date"
            source="assigned_at"
            locales={navigator.language}
            showTime={true}
          />
          {!isSmall && (
            <FunctionField<TagResource>
              label="tags.table.customer"
              source="sender_email"
              render={(record) => (
                <UserField
                  identification={
                    record?.code_details[0]?.sender_email
                      ? record?.code_details[0]?.sender_email
                      : record?.order?.buyer_email + '*'
                  }
                />
              )}
            />
          )}
          <FunctionField<TagResource>
            label="tags.table.tagDownload"
            render={(record) => {
              return (
                <IconButton
                  children={<QRIcon />}
                  onClick={() => onDownloadQR(record?.code)}
                />
              );
            }}
          />
          <FunctionField<TagResource>
            label=""
            render={(resource) => (
              <>
                <IconButton
                  children={<MoreVertIcon />}
                  onClick={(event) => onMenuClick(event, resource)}
                />
              </>
            )}
          />
        </Datagrid>
      </List>
      <PurgeDialog
      open={purgeDialogIsOpen}
      onClose={onPurgeClose}
      tag={selectedTag?.code}
      />
      <DisableDialog
      open={disableDialogIsOpen}
      onClose={onDisableClose}
      tag={selectedTag?.code}
      />
      <ResendEmailDialog
      open={resendEmailDialogIsOpen}
      onClose={onResendEmailClose}
      tag={selectedTag}
        />
      <PopoverMenu
        menuItems={[
          {
            label: translate('tags.dialog.purge.title'),
            onClick: purgeDialogOnClick,
            icon: <ReplayIcon />,
          },
          {
            label: translate('tags.dialog.disable.title'),
            onClick: disableDialogOnClick,
            icon: <ToggleOffIcon />,
          },
          {
            label: translate('tags.dialog.resendEmail.title'),
            onClick: resendEmailDialogOnClick,
            icon: <EmailIcon />,
          },
        ]}
        isOpen={popoverMenuIsOpen}
        onClose={popoverMenuOnClose}
        anchorEl={popoverMenuAnchorEl}
      />
    </PageContainer>
  )
}

export default VTPrintList;