import React from 'react';
import { Button, Modal, Box, Typography } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
  onClose: any;
  open: any;
};

export default function OutOfStockDialog({ open, onClose }: Props) {
  const translate = useTranslate();
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 850,
          backgroundColor: 'white',
          borderRadius: '12px',
          textAlign: 'center',
        }}
      >
        <div
          onClick={onClose}
          style={{ cursor: 'pointer', padding: '10px', float: 'right' }}
        >
          <CloseIcon color="primary" />
        </div>
        <Box
          style={{
            padding: '2em 2em 1em 2em',
          }}
        >
          <Typography
            variant="body1"
            style={{ fontSize: '24px', fontWeight: 700 }}
          >
            {translate('subscriptions_plans.popups.outOfStock.title')}
          </Typography>
          <Typography
            variant="body1"
            style={{ fontSize: '18px', fontWeight: 600 }}
          >
            {translate('subscriptions_plans.popups.outOfStock.subtitle')}
          </Typography>
          <Typography
            variant="body1"
            style={{ fontSize: '16px', margin: '1.5em 0' }}
          >
            {translate('subscriptions_plans.popups.outOfStock.paragraph')}
          </Typography>
        </Box>

        <Box
          style={{
            backgroundColor: '#8F3FE6',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            borderRadius: '0 0 12px 12px',
          }}
        >
          <Typography
            variant="body1"
            style={{ fontSize: '18px', color: 'white', marginRight: '20px' }}
          >
            {translate('subscriptions_plans.popups.outOfStock.footer')}
          </Typography>
          <Button
            variant="contained"
            onClick={onClose}
            style={{ color: '#8F3FE6' }}
            size="small"
          >
            {translate('subscriptions_plans.popups.outOfStock.footerbtn')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
