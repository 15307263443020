// VENDOR
import * as React from 'react';
import { useLocation } from 'react-router-dom'
//commented imports are for the language button, I use it to test both languages when developing
import { AppBar, AppBarProps,/*, useSetLocale, useLocale, useTranslate*/
ReduxState, useTranslate} from 'react-admin';
import { Button, makeStyles, useMediaQuery } from '@material-ui/core';
// COMPONENTS
//import MyUserMenu from './UserMenu';
// LOGO
import Logo from "./img/storydotsLogo.svg";
import TNLogo from "./img/TN-Logo.svg"
import { useSelector, useDispatch } from 'react-redux';

import config, { FETCH_HOME } from "config";
import axios from "axios";

const useStyles = makeStyles((theme: any) => ({
  root: {
    '& header': {
      background: theme.palette.common.white,
    },
    '& [class^="MuiToolbar"]': {
      paddingRight: 0,
    },
    '& [class*="menuButton"]': {
      marginLeft: 2,
      marginRight: 2,
    },
  },
  appBarContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '85px',
    width: "100%",
    backgroundColor: 'white'
  },
  logo: {
    padding: '15px',
    maxWidth: '180px'
  },
  userData: {
    display: 'flex',
    alignItems: 'center'
  },
  storeButton: {
    backgroundColor: "#029CDC",
    color: "white",
    marginLeft: 15,
    '&:hover': {
      backgroundColor: "#029CDC",
    }
  },
  TNLogo: {
    marginRight: 5,
  }
}))

const MyAppBar: React.FC<AppBarProps> = (props) => {
    const [userData, setUserData] = React.useState<clientsResponse>();
    const location = useLocation();
    const dispatch = useDispatch();
    const classes = useStyles(props);
    const email = useSelector((state: ReduxState) => state.auth?.decoded.email);
    const token = useSelector((state: ReduxState) => state.auth?.token) || '';
    const clientLoading = useSelector((state: ReduxState) => state.home?.clientLoading)
    const clientData = useSelector((state: ReduxState) => state.home?.data?.clientData)
    const isSmall = useMediaQuery('(max-width:450px)');
    const translate = useTranslate();
    const isOnboarding = /\/onboarding/.test(location.pathname);

    React.useEffect(() => {
      if (token) {
        dispatch({ type: FETCH_HOME });
      }
    }, [dispatch, token]);  

    return (
        <div className={classes.root}>
          {!isOnboarding && 
            <AppBar {...props} color='default'>
                <div className={classes.appBarContent}>
                    <img className={classes.logo} src={Logo} alt="logo" />
                    <div className={classes.userData}>
                      {!isSmall && email}
                      {!isSmall && !clientLoading && (clientData?.user_data_origin === 1) &&
                      <Button 
                        href={clientData?.user_data_store_url}
                        variant="contained"
                        className={classes.storeButton}
                        target={"_blank"}
                      ><img src={TNLogo} className={classes.TNLogo}/>{translate("go_to_store_button")}</Button>}
                    </div>
                    {/* right-divs class is in theme overrides, to prevent this div from hiding */}

                </div>
            </AppBar>
          }
            {/* <div>
                {locale === 'en' ?
                    <Button onClick={() => setLocale('es')}>{translate('appBar.spanish')}</Button> :
                    <Button onClick={() => setLocale('en')}>{translate('appBar.english')}</Button>
                }
            </div> */}
        </div>
    )
}

export default MyAppBar;
