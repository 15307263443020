import React, { useState, useEffect , useRef } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Grid,
  InputAdornment,
  TextField,
  Collapse,
  Switch,
  SvgIcon
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import useStyles from '../styles';
import ImageInput from './ImageInput';
import { useFormContext } from 'react-hook-form';
import { DEFAULT_COORDINATES, TWO_DECIMAL_REG_EXP } from 'config/constants';
import { InputContainer, ArrowChevron, CardTitleWithIcon } from 'components';
import QRIcon from 'img/QRIcon';

const dfCoord = {
  ...DEFAULT_COORDINATES.senderTag,
  height: 3.11,
  tagWidth: 5.65,
};
const TagCustom: React.FC = () => {
  const [toggleCard, setToggleCard] = React.useState(false);
  const [formErrors, setErrors] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const { register, errors, watch, setValue } = useFormContext();
  const isChecked = watch('sender_tag_custom');
  const templateExists = watch('sender_tag_template_url');
  const SenderTagRef = useRef(document.createElement("div"));
  const tagConfigKeys = errors?.sender_tag_config || {};
  const keys = ['tagWidth', 'width', 'top', 'left'];

  useEffect(() => {
    let hasError = false;
    keys.forEach(key => {
      if(tagConfigKeys.hasOwnProperty(key)){ setErrors(true); hasError = true }
    });
    !hasError && setErrors(false);

    keys.forEach((key: string) => {
      tagConfigKeys?.hasOwnProperty(key) && SenderTagRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }, [Object.keys(tagConfigKeys).length])

  return (
    <Card className={`${classes.formPart} ${formErrors && classes.formPartError}`} ref={SenderTagRef}>
      <CardActionArea onClick={() => setToggleCard(!toggleCard)}>
        <CardHeader
          title={CardTitleWithIcon(
            translate('client_form.form.sender_tag_custom'),
            <SvgIcon color="primary">
              <QRIcon />
            </SvgIcon>,
          )}
          classes={{ action: classes.cardHeader }}
          action={<ArrowChevron setToggleCard={setToggleCard} toggleCard={toggleCard} />}
        />
      </CardActionArea>
      
      <Collapse in={toggleCard}>
        <CardContent className={classes.formCardContent}>
          <InputContainer
            title={translate('client_form.form.customTagSwitch')}
            content={translate('client_form.form.customTagSwitchDescription')}
            mb
          >
            <Switch
              defaultChecked={isChecked}
              inputRef={register}
              name="sender_tag_custom"
            />
          </InputContainer>
          <Collapse in={isChecked}>
            <InputContainer
              title={translate('client_form.form.tag_design')}
              content={translate('client_form.form.tag_design_description')}
            >
              <ImageInput
                register={register({
                  required:
                    isChecked && !templateExists
                      ? translate('ra.validation.required')
                      : false,
                })}
                className={classes.input}
                errors={errors}
                helperText={errors.message || null}
                setValue={setValue}
                tagCoordinates={{
                  top:
                    parseFloat(watch('sender_tag_config.top')) || dfCoord.top,
                  left:
                    parseFloat(watch('sender_tag_config.left')) || dfCoord.left,
                  width:
                    parseFloat(watch('sender_tag_config.width')) ||
                    dfCoord.width,
                  tagWidth:
                    parseFloat(watch('sender_tag_config.tagWidth')) ||
                    dfCoord.tagWidth,
                }}
                label={translate('client_form.form.tag_design') + ' *'}
                name="sender_template"
                defaultUrl={watch('sender_tag_template_url')}
              />
              <Collapse
                in={
                  (watch('sender_template') && watch('sender_template')[0]) ||
                  watch('sender_tag_template_url')
                }
              >
                <Grid
                  style={{ marginTop: 18 }}
                  container
                  spacing={3}
                  alignItems="center"
                >
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(errors.sender_tag_config?.width)}
                      helperText={errors.sender_tag_config?.width?.message || null}
                      name="sender_tag_config.width"
                      variant="outlined"
                      defaultValue={dfCoord.width}
                      className={classes.input}
                      value={watch('sender_tag_config.width') || dfCoord.width}
                      inputProps={{
                        ref: register({
                          required: isChecked
                            ? translate('ra.validation.required')
                            : false,
                          pattern: TWO_DECIMAL_REG_EXP,
                        }),
                        step: 0.01,
                        novalidate: true,
                        required: isChecked,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">cm</InputAdornment>
                        ),
                      }}
                      type="number"
                      label={translate('print.form.qr_width')}
                      InputLabelProps={{
                        shrink: !!watch('sender_tag_config.width'),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(errors.sender_tag_config?.tagWidth)}
                      helperText={errors.sender_tag_config?.tagWidth?.message || null}
                      name="sender_tag_config.tagWidth"
                      variant="outlined"
                      defaultValue={dfCoord.tagWidth}
                      value={watch('sender_tag_config.tagWidth') || dfCoord.tagWidth}
                      className={classes.input}
                      inputProps={{
                        ref: register({
                          required: isChecked
                            ? translate('ra.validation.required')
                            : false,
                          pattern: TWO_DECIMAL_REG_EXP,
                        }),
                        step: 0.01,
                        novalidate: true,
                        required: isChecked,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">cm</InputAdornment>
                        ),
                      }}
                      type="number"
                      label={translate('print.form.tag_width')}
                      InputLabelProps={{
                        shrink: !!watch('sender_tag_config.tagWidth'),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(errors.sender_tag_config?.top)}
                      helperText={errors.sender_tag_config?.top?.message || null}
                      name="sender_tag_config.top"
                      variant="outlined"
                      defaultValue={dfCoord.top}
                      value={watch('sender_tag_config.top') || dfCoord.top}
                      className={classes.input}
                      inputProps={{
                        ref: register({
                          required: isChecked
                            ? translate('ra.validation.required')
                            : false,
                          pattern: TWO_DECIMAL_REG_EXP,
                        }),
                        step: 0.01,
                        novalidate: true,
                        required: isChecked,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">cm</InputAdornment>
                        ),
                      }}
                      type="number"
                      label={translate('print.form.y_position')}
                      InputLabelProps={{
                        shrink: !!watch('sender_tag_config.top'),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(errors.sender_tag_config?.left)}
                      helperText={errors.sender_tag_config?.left?.message || null}
                      name="sender_tag_config.left"
                      variant="outlined"
                      defaultValue={dfCoord.left}
                      value={watch('sender_tag_config.left') || dfCoord.left}
                      className={classes.input}
                      inputProps={{
                        ref: register({
                          required: isChecked
                            ? translate('ra.validation.required')
                            : false,
                          pattern: TWO_DECIMAL_REG_EXP,
                        }),
                        step: 0.01,
                        novalidate: true,
                        required: isChecked,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">cm</InputAdornment>
                        ),
                      }}
                      type="number"
                      label={translate('print.form.x_position')}
                      InputLabelProps={{
                        shrink: !!watch('sender_tag_config.left'),
                      }}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </InputContainer>
          </Collapse>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default TagCustom;
