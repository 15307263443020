import { useEffect } from "react";
import TagManager from "react-gtm-module";
import config from "config";

/**
 * Takes an action type to be sent to GTM
 */
//TODO pas storyID as an argument
export default (actionType: string) =>
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: config.GTM_ID,
      dataLayer: {
        user: "",
        actionType,
        date: new Date().getTime(),
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);
