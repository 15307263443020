import React, { useState, useEffect } from 'react';
import { useListContext } from 'react-admin';
import { useSelector } from 'react-redux';
import ActionButton from 'components/ActionButton';
import { useLocale } from 'react-admin';
import { useTranslate } from 'ra-core';

interface IOrders {
  id: number;
  tag: string;
  date: string;
  name: string;
  number: string;
}

interface ICode {
  tag: string;
  date: string;
  name: string;
  number: string;
}

interface IStoresOrigin {
  [key: number]: string;
}

interface IPluginDashboardUrls {
  [key: string]: string;
}

const STORES_ORIGIN: IStoresOrigin = {
  0: 'ERROR',
  1: 'TIENDANUBE',
  2: 'PHYSICAL',
  3: 'API',
  4: 'SHOPIFY',
  5: 'VTEX',
  6: 'MAGENTO',
  7: 'POINT_OF_PURCHASE'
};

const ListActions = () => {
  const { selectedIds, data } = useListContext();
  const [codeList, setCodeList] = useState<IOrders[]>([]);
  const translate = useTranslate();
  const locale = useLocale();
  const clientOrigin: number =
    useSelector((state: ReduxState) => state.home?.data?.clientData)
      ?.user_data_origin || 0;
  const spLanguage = locale === 'es' ? 'es' : 'en';

  let pluginDashboardUrls: IPluginDashboardUrls = {
    TIENDANUBE: `/plugin-dashboard/legacy`,
    SHOPIFY: `/plugin-dashboard/shopify/${spLanguage}`,
    VTEX: `/plugin-dashboard/VTEX`,
    MAGENTO: `/plugin-dashboard/magento`,
  };

  const onPrint = async () => {
    const codes: ICode[] = [];
    codeList.forEach((item) => {
      if (selectedIds.includes(item.id) && item.tag && item.date)
        codes.push({
          tag: item.tag,
          date: item.date,
          name: item.name,
          number: item.number,
        });
    });

    const url = new URL(
      `${
        pluginDashboardUrls[STORES_ORIGIN[clientOrigin]]
      }/print/?orders=${JSON.stringify({ data: codes })}&platform=${
        STORES_ORIGIN[clientOrigin]
      }`,
      process.env.REACT_APP_FRONT_URL,
    ).toString();
    window.location.href = url;
  };

  useEffect(() => {
    const iterableData: any[] = [];
    Object.keys(data).forEach((key) => {
      const { id, code, order } = data[key];
      if (codeList.filter((item) => item.tag === code).length === 0)
        iterableData.push({
          id: id,
          tag: code,
          date: order?.order_date,
          name: order?.buyer_name,
          number: order?.order_number,
        });
    });
    setCodeList((oldArray) => [...oldArray, ...iterableData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <ActionButton
      title={translate('logisticPrint.printButton')}
      callback={onPrint}
    />
  );
};

export default ListActions;
