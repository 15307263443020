import * as React from 'react';
import {
  CardActions,
  Button,
  TextField,
  Link,
  CircularProgress,
} from '@material-ui/core';
import makeUseStyles from '../formStyles';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import useCustomLogin from '../../../Utils/hooks/useCustomLogin';
import useQuery from '../../../Utils/hooks/useQuery';
import config from '../../../config/index';
import TnLogo from '../img/sso/tiendanube.svg';

const useStyles = makeUseStyles('login');

interface ILoginFormFields {
  username: string;
  password: string;
}

interface ILoginFormProps {
  redirectTo?: string;
  goRegister: () => void;
}

const LoginForm: React.FC<ILoginFormProps> = (props) => {
  const { redirectTo, goRegister } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const customLogin = useCustomLogin();
  const query = useQuery();
  const [customToken, setCustomToken] = useState(query.get('customToken'));
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);
  const { handleSubmit, register, errors } = useForm();

  useEffect(() => {
    let mpSuscriptionId = query.get('preapproval_id');
    mpSuscriptionId &&
      sessionStorage.setItem('mp_suscription_id', mpSuscriptionId);

    if (customToken) {
      setLoading(true);
      customLogin(customToken, redirectTo)
        .catch((error) => {
          setCustomToken(null);
          notify(
            typeof error === 'string'
              ? error
              : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
            'warning',
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const onSubmit = (values: ILoginFormFields) => {
    setLoading(true);
    localStorage.removeItem('auth');
    const { username, password } = values;
    login({ username: username.trim(), password }, redirectTo)
      .then(() => window.localStorage.removeItem('nextPathName'))
      .catch((error) => {
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning',
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handleTNSso() {
    setLoading(true);
    config.TN_SSO_AUTH_URL && (window.location.href = config.TN_SSO_AUTH_URL);
  }

  if (customToken || loading) {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <CircularProgress className={classes.ssoLoader} size={100} />
      </form>
    );
  } else {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <TextField
          error={errors.username}
          name="username"
          className={classes.textField}
          variant="outlined"
          inputProps={{
            className: classes.input,
            ref: register({ required: translate('ra.validation.required') }),
            required: true,
          }}
          disabled={loading}
          label={translate('ra.auth.username')}
        />
        <TextField
          error={errors.password}
          name="password"
          className={classes.textField}
          variant="outlined"
          inputProps={{
            className: classes.input,
            ref: register({ required: translate('ra.validation.required') }),
            required: true,
          }}
          type="password"
          disabled={loading}
          label={translate('ra.auth.password')}
        />
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          className={classes.button}
        >
          {translate('login.btn.sign_in')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          className={classes.tnSsoButton}
          onClick={() => handleTNSso()}
        >
          <img src={TnLogo} className={classes.tnLogo} />{' '}
          {translate('login.btn.tn_sso_sign_in')}
        </Button>
        <CardActions className={classes.cardLinks}>
          {/* <Link component="button" onClick={goRegister} color="primary">
          {translate('login.link.forgot_password')}
        </Link> */}
          <Link component="button" onClick={goRegister} color="primary">
            {translate('login.link.sign_up')}
          </Link>
        </CardActions>
      </form>
    );
  }
};

export default LoginForm;
