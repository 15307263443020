import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslate, useNotify } from 'react-admin';
import { useFormContext, Controller } from 'react-hook-form';
import config from '../../../config';

interface IBatchAutocompleteProps {
    clientID?: string;
    className?: string;
}

const BatchAutocomplete: React.FC<IBatchAutocompleteProps> = ({ clientID, className }) => {
    const [open, setOpen] = React.useState<any>(false);
    const [options, setOptions] = React.useState<any>([]);
    const [isLoading, setLoading] = useState(false);
    const token = useSelector((state: ReduxState) => state.auth?.token);
    const translate = useTranslate();
    const notify = useNotify();
    const { setValue, control } = useFormContext();
    useEffect(() => {
        setOptions([]);
        setValue("batch", "");
        if (clientID) {
            setLoading(true);
            axios.get(`${config.AWS_API_SITE}/getBatchesByClientID`,
                { headers: { Authorization: `Bearer ${token}` }, params: { clientID } })
                .then((res) => {setOptions(res.data); if (res.data.length>0) setValue("batch",res.data[0])})
                .catch((e) => notify("print.form.batch_not_found", "error"))
                .finally(() => setLoading(false))
        }
    }, [clientID, token])
    return (
        <Controller
            name="batch"
            render={(props) =>
                <Autocomplete
                    {...props}
                    className={className}
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    getOptionSelected={(option, value) => option === value
                    }
                    onChange={(_, data) => { if (data) props.onChange(data) }}
                    getOptionLabel={(option) => option}
                    options={options}
                    loading={isLoading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={translate('print.form.batch')}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            }
            control={control}
        />
    );
}

export default BatchAutocomplete;