import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  notIdentified: {
    opacity: 0.7
  },
}));

interface ISimpleUserFieldProps {
  identification: string;
}

const UserField: React.FC<ISimpleUserFieldProps> = ({ identification }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const isIdentified = (!identification||identification==='-'||identification==='');
    const text = isIdentified ? translate('users.table.not_identified') : identification;
  return (
    <div className={isIdentified ? classes.notIdentified : ''}>
      {text}
    </div>
  );
};

export default UserField;
