// VENDOR
import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, Box, makeStyles } from '@material-ui/core';
import PageTitle from 'components/PageTitle';
import PageContainer from 'components/PageContainer';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useTranslate, useRedirect, useNotify  } from 'react-admin';
import { useForm, FormProvider } from 'react-hook-form';
import ShopData from './forms/ShopData';
import ContactData from './forms/ContactData';
import TagCustom from './forms/TagCustom';
import SenderTagCustom from './forms/SenderTagCustom';
import WebAppCustom from './forms/WebAppCustom';
import CustomTag from './forms/CustomTag';
import WidgetAnchor from './forms/WidgetAnchor';
import ConfirmDiscardDialog, {
  useConfirmDiscardDialog,
} from './ConfirmDiscardDialog';
import SubmitBtns from './forms/SubmitBtns';
import { useParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import config from '../../config';
import { useSelector, useDispatch } from 'react-redux';
import SpOrderTag from './forms/spOrderTag';
import { PLATFORMS, ONLINE_PLATFORMS, FETCH_SUBSCRIPTION } from '../../config/constants';
import { Features, hasFeature } from 'config';
import { useIsSuperAdmin } from 'customHooks';
import { SubscriptionDataI } from 'containers/subscriptions/Subscriptions';

function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  circularBar: {
    marginLeft: 24,
  },
  header: {
    backgroundColor: '#fafafa',
    top: 27,
    position: 'sticky',
    zIndex: 4,
    paddingLeft: 24,
    paddingTop: 5,
    borderBottom: "1px solid #B2BEB5",
    "@media (max-width: 770px)": {
      display: "inline-block"
    }
  },
  customizationsPage: {
    marginLeft: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const ClientForm: React.FC = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const clientID = useSelector(
    (state: ReduxState) => state.auth?.decoded.clientID,
  );
  const methods = useForm();
  const [clientData, setClientData] = useState<any>();
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionDataI>();
  const [isLoading, setIsLoading] = useState(true);
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [loadingPercent, setLoadingPercent] = useState(0);
  const { confirmDiscardIsOpen, confirmDiscardOnClick, confirmDiscardOnClose } =
    useConfirmDiscardDialog();
  const isSuperadmin = useIsSuperAdmin();
  const classes = useStyles();
  const notify = useNotify();

  // Checks if the user has the feature that allows him to use this resource

  const params = useParams<{
    mode: 'edit' | 'create';
    name?: string;
    id: string;
  }>();
  const token = useSelector((state: ReduxState) => state.auth.token);
  useEffect(() => {
    if (token) {
      dispatch({ type: FETCH_SUBSCRIPTION });
    }
  }, [token])

  const subscriptionState = useSelector((state:ReduxState)=>state.subscription);
  useEffect(() => {
    setSubscriptionData(subscriptionState.data.subscription)
  }, [subscriptionState])

  const editMode = params.mode === 'edit';
  useEffect(() => {
    if (editMode || !isSuperadmin) {
      if (params.id || clientID)
        axios
          .get(
            `${config.AWS_API}/getClientAndProductInfo?clientID=${
              params.id || clientID
            }`,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          )
          .then((res: AxiosResponse<clientsResponse>) => {
            let { tag_config, ...data } = res.data;
            // if tag_config is null, don't use it in methods.reset, so default coords can still be used
            if (tag_config) data = res.data;
            // 2 is onsite shop in db enums
            //TODO: make this part cleaner after refactor
            if(Object.values(ONLINE_PLATFORMS).includes(data.origin)) {
              data.type = 'online';
            } else {
              data.type = 'onsite';
            }
            methods.reset(data);
            setClientData(data);
            setIsLoading(false);
          })
          .catch((e) => console.log('fetch error: ', e));
    } else {
      setIsLoading(false);
    }
  }, [editMode, token]);

  //TODO type the onSubmit object param
  const onSubmit = async (values: any) => {
    try {
      setIsPostLoading(true);
      let {
        template,
        sender_template,
        logo,
        tag_config,
        sender_tag_config,
        type,
        origin,
        default_video_greeting,
        widget_data_cart_form,
        ...allValues
      } = values;
      const { app_custom, tag_custom, sender_tag_custom } = values;
      if (isSuperadmin && tag_custom) {
        if (template && template[0]) {
          allValues.template = await getBase64(template[0]);
        }
        allValues.tag_config = tag_config;
      } else {
        allValues.template = template;
      }
      if (isSuperadmin && sender_tag_custom) {
        if (sender_template && sender_template[0]) {
          allValues.sender_template = await getBase64(sender_template[0]);
        }
        allValues.sender_tag_config = sender_tag_config;
      }
      if (app_custom) {
        if (logo && logo[0]) {
          allValues.logo = await getBase64(logo[0]);
        }
      }
      // 2 is onsite shop in db enums
      if (isSuperadmin) {
        allValues.origin = origin;
      }
      if (default_video_greeting && default_video_greeting[0]) {
        const file = default_video_greeting[0];
        const endpoint_url =
          methods.control.defaultValuesRef.current.signedUrlVideoGreeting;
        await axios({
          method: 'PUT',
          url: endpoint_url,
          data: file,
          headers: {
            'Content-Type': 'video/mp4',
          },
          onUploadProgress: (progressEvent) => {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            setLoadingPercent(percent);
          },
        });
        allValues.default_video_greeting = true;
      }
      console.log('ENVIADOS', allValues);
      if (params.mode === 'edit' || !isSuperadmin) {
        console.log('EDIT', { allValues });
        await axios.post(
          `${config.AWS_API_SITE}/editClient`,
          { ...allValues, clientID: params.id },
          { headers: { Authorization: `Bearer ${token}` } },
        );
      } else {
        await axios.post(`${config.AWS_API_SITE}/createClient`, allValues, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
      redirect(isSuperadmin ? '/clients' : '/');
    } catch (e) {
      notify(translate('dashboard.submitError'),  {type: 'warning' })
    } finally {
      setIsPostLoading(false);
      //redirect(isSuperadmin ? '/clients' : '/');
    }
  };

  return (
    <PageContainer className={classes.customizationsPage}>
      <Box display="flex" alignItems="center" className={classes.header}>
        <PageTitle
          title={
            params.mode === 'edit' || !isSuperadmin
              ? translate('customizations.title')
              : translate('client_form.create_title')
          }
          subtitle={
            params.mode === 'edit' || !isSuperadmin
              ? translate('customizations.subtitle')
              : translate('client_form.create_subtitle')
          }
          icon={<ArrowBack />}
          onIconClick={confirmDiscardOnClick}
        />
        <SubmitBtns
          styles={{ marginTop: '20px' }}
          onCancel={confirmDiscardOnClick}
          isLoading={isPostLoading}
          titleSave={translate('submit_buttons.save')}
          titleDiscard={translate('submit_buttons.discard')}
          formID="customizations-form"
        />
      </Box>
      {isLoading && <CircularProgress className={classes.circularBar} />}
      {!isLoading && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} id="customizations-form" className={classes.root} noValidate>
            <Grid container spacing={2}>
              {!isSuperadmin && (
                <Grid item xs={12} md={12}>
                  <CustomTag
                    register={methods.register}
                    templateUrl={clientData?.tag_template_url}
                    disabled={!hasFeature(Features.CustomTag)}
                  />
                </Grid>
              )}
              {isSuperadmin && (
                <Grid item xs={12} md={12}>
                  <ShopData />
                </Grid>
              )}
              {isSuperadmin && (
                <Grid item xs={12} md={12}>
                  <ContactData />
                </Grid>
              )}
              {isSuperadmin && (
                <Grid item xs={12} md={12}>
                  <TagCustom />
                </Grid>
              )}
              {isSuperadmin && (
                <Grid item xs={12} md={12}>
                  <SenderTagCustom />
                </Grid>
              )}
              {(clientData?.origin === PLATFORMS.tiendanube || clientData?.origin === PLATFORMS.shopify) && (
                <Grid item xs={12} md={12}>
                  <WidgetAnchor disabled={!hasFeature(Features.WidgetAnchor)} />
                </Grid>
              )}

              <Grid item xs={12} md={12}>
                <WebAppCustom superAdmin={isSuperadmin} subscriptionData={subscriptionData} clientOrigin={clientData?.origin}/>
              </Grid>

              {clientData?.origin === PLATFORMS.shopify && (
                <Grid item xs={12} md={12}>
                  <SpOrderTag client={clientData} />
                </Grid>
              )}
              {/* {(isSuperadmin || hasFeature(Features.CustomVideoGreeting)) && <Grid item xs={12} sm={12}>
                <CustomVideoGreeting
                  isLoading={isPostLoading}
                  loadingPercent={loadingPercent}
                />
              </Grid>} */}
              {/* {isSuperadmin && editMode && (
                <Grid item xs={12} md={12}>
                  <DefaultGreeting />
                </Grid>
              )} */}
            </Grid>
          </form>
        </FormProvider>
      )}
      <ConfirmDiscardDialog
        open={confirmDiscardIsOpen}
        onClose={confirmDiscardOnClose}
        callback={() => redirect('/clients')}
      />
    </PageContainer>
  );
};

export default ClientForm;
