import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import CheckIcon from '@material-ui/icons/CheckSharp';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Clear';
import PauseIcon from '@material-ui/icons/Pause';
import ClockIcon from '@material-ui/icons/AccessTimeOutlined';

type Props = {
  status: SubscriptionStatus;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    backgroundColor: 'red',
    maxWidth: '15em',
    width: 'auto',
    justifyContent: 'center',
    borderRadius: '50px',
    padding: '5px 10px',
    color: 'white',
    alignItems: 'center',
  },
  text: {
    marginLeft: '5px',
    fontSize: '12px',
    fontWeight: 600,
  },
}));

const propsByStatus = {
  active: {
    icon: <CheckIcon fontSize="small" />,
    backgroundColor: '#4CAF50',
  },
  trial: {
    icon: <InfoIcon fontSize="small" />,
    backgroundColor: '#6242E8',
  },
  cancelled: {
    icon: <CloseIcon fontSize="small" />,
    backgroundColor: '#FF2266',
  },
  paused: {
    icon: <PauseIcon fontSize="small" />,
    backgroundColor: '#FBC63D',
  },
  pending: {
    icon: <ClockIcon fontSize="small" />,
    backgroundColor: '#757575',
  },
};

export default function SubscriptionStatus({ status }: Props) {
  const styles = useStyles();
  const translate = useTranslate();

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: propsByStatus[status].backgroundColor }}
    >
      {propsByStatus[status].icon}
      <Typography className={styles.text}>
        {translate(`subscriptions_plans.statuses.${status}`).toUpperCase()}
      </Typography>
    </div>
  );
}
