import React, { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  ListProps,
  FunctionField,
  SimpleList,
  useRedirect,
  Filter,
  TextInput,
} from 'react-admin';
import { useTranslate } from 'ra-core';
import PageTitle from '../../components/PageTitle';
import PageContainer from '../../components/PageContainer';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles, useMediaQuery, IconButton, Link } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SpinnerWrapper from '../../components/SpinnerWrapper';
import StockField from './StockField';
import GenerateStockDialog, {
  useGenerateStockDialog,
} from './GenerateStockDialog';
import ClientView, { useClientView } from './ClientView';
import PopoverMenu, { usePopoverMenu } from '../../components/PopoverMenu';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import SwitchField from './SwitchField';
import StoreIcon from '@material-ui/icons/Store';
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import RegenIcon from '@material-ui/icons/Autorenew';
import GetAppIcon from '@material-ui/icons/GetApp';
import MissingConfigsDialog, {
  useMissingConfigsDialog,
} from './MissingConfigsDialog';
import ActionButton from '../../components/ActionButton';
import ConfirmRegenerateDialog, {
  useConfirmRegenerateDialog,
} from './RegenerateStock';
import _ from 'lodash';
import { PLATFORMS } from '../../config';
import config from '../../config';
import { getAbsoluteUrl } from '../../Utils';
import useSendGTMEvent from "Utils/hooks/useSendGTMEvent";
import axios from 'axios';

const useListStyles = makeStyles((theme) => ({
  datagrid: {
    borderCollapse: 'unset',
    '& thead': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      fontSize: '1.2rem',
      '& th': {
        paddingTop: 12,
        paddingBottom: 12,
        background: 'transparent',
      },
    },
    '& a': {
      color: '#2196F3',
    },
  },
  name: {
    fontWeight: 500,
  },
  list: {
    '& [class*="RaList-content"]': {
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
  },
  simpleList: {
    padding: 0,

    '& > a': {
      display: 'block',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },

    '& [class*="RaSimpleList"]': {
      opacity: 1,
    },
  },
  addButton: {
    backgroundColor: 'white',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    marginRight: 10,
  },
  buttonWrapper: {
    display: 'flex',
  },
}));

const ClientFilter: React.FC<any> = (props) => (
  <Filter {...props}>
    <TextInput
      variant="outlined"
      label="clients.search"
      source="store_name"
      alwaysOn
    />
  </Filter>
);

const ClientList: React.FC<ListProps> = (props) => {
  const translate = useTranslate();
  const classes = useListStyles();
  const isSmall = useMediaQuery('(max-width:1000px)');
  const isMedium = useMediaQuery('(max-width:1400px)');
  const redirect = useRedirect();

  const token = useSelector(
    (state: ReduxState) => state.auth && state.auth.token,
  );
  const [currentRecord, setCurrentRecord] = useState<
    ClientResource | undefined
  >(undefined);

  useSendGTMEvent("clients")

  const {
    onGenerateStockDialogOpen,
    onGenerateStockDialogClose,
    generateStockDialogIsOpen,
  } = useGenerateStockDialog();

  const {
    popoverMenuAnchorEl,
    popoverMenuOnClick,
    popoverMenuOnClose,
    popoverMenuIsOpen,
  } = usePopoverMenu();

  const { clientViewIsOpen, clientViewOnClick, clientViewOnClose } =
    useClientView();

  const {
    missingConfigsDialogIsOpen,
    missingConfigsDialogOnClick,
    missingConfigsDialogOnClose,
    missingConfigsDialogCallback,
    missingConfigsDialogConfigType,
  } = useMissingConfigsDialog();

  const { confirmDialogIsOpen, confirmDialogOnClick, confirmDialogOnClose } =
    useConfirmRegenerateDialog();

  const onRegenerateClick = () => {
    confirmDialogOnClick();
  };

  const goToEdit = (
    clientID: number | undefined,
    clientName: string | undefined = '-',
  ) => {
    if (clientID) {
      redirect(`/clientForm/edit/${clientName}/${clientID}`);
    }
  };

  const onMenuClick = (event: any, record: any) => {
    setCurrentRecord(record);
    popoverMenuOnClick(event);
  };

  const onAddStockClick = (record: any) => {
    setCurrentRecord(record);
    onGenerateStockDialogOpen();
  };
  const [loadingQRPOP, setLoadingQRPOP] = useState(false)
  const onDownloadQRPOPClick = async () => {
    try {
      setLoadingQRPOP(true)
      
      const requestUrl = new URL(`/dispenserQR?clientID=${currentRecord?.id}`, config.AWS_API)
      const response = await axios.get(requestUrl.toString(), {
        headers: {
          Accept: 'application/json',
        }
      });
      const { senderQR, receiverQR } = response.data;

      // Create a blob from response
      const blob = new Blob([Buffer.from(senderQR,'base64')], { type: 'image/png' });
      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);
      // Create a link to download the image
      const link = document.createElement('a');
      link.href = url;
      link.download = 'SenderQR.png';
      document.body.appendChild(link);
      // Simulate link click
      link.click();
      // Clear link and object url after download
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      if(receiverQR){
        const blob = new Blob([Buffer.from(receiverQR, 'base64')], { type: 'image/png' });
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);
        // Create a link to download the image
        const link = document.createElement('a');
        link.href = url;
        link.download = 'ReceiverQR.png';
        document.body.appendChild(link);
        // Simulate link click
        link.click();
        // Clear link and object url after download
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
      setLoadingQRPOP(false)
    } catch (error) {
      console.error('Error al descargar la imagen', error);
    }
  }

  const getPlatformByNumber = (platformNumber?: number, country?: string | null, language?: string | null) => {
    if(platformNumber === 1 && (country === 'BR' || (country === null && language === 'pt'))){
      return translate('platforms.nuvemshop')
    }else{
      return translate(`platforms.${_.invert(PLATFORMS)[platformNumber || 0]}`);
    }
  };
  const popoverMenuItemsDefault = [
    {
      onClick: clientViewOnClick,
      label: translate('clients.popover.viewClient'),
      icon: <PermContactCalendarIcon />,
    },
    {
      onClick: () => goToEdit(currentRecord?.id, currentRecord?.store_name),
      label: translate('clients.popover.editClient'),
      icon: <CreateIcon />,
    },
    {
      onClick: onRegenerateClick,
      label: translate('clients.popover.regenerateStock'),
      icon: <RegenIcon />,
    },
  ];
  const [popoverMenuItems, setPopoverMenuItems] = useState(popoverMenuItemsDefault);

  useEffect(() => {
    if (currentRecord?.origin === PLATFORMS.dispenser) {
      setPopoverMenuItems([...popoverMenuItemsDefault, {
        onClick: onDownloadQRPOPClick,
        label: translate('clients.popover.downloadQRPOP'),
        icon: <GetAppIcon />,
      }]);
    }else{
      setPopoverMenuItems(popoverMenuItemsDefault)
    }
  }, [currentRecord]);

  return (
    <PageContainer>
      <PageTitle
        title={translate('clients.title')}
        icon={<StoreIcon />}
        subtitle={translate('clients.subtitle')}
        action={
          <ActionButton
            title={translate('clients.action')}
            icon={<AddIcon />}
            callback={() => redirect('/clientForm/create')}
          />
        }
      />
      <SpinnerWrapper isLoading={!token || loadingQRPOP}>
        <List
          {...props}
          exporter={false}
          bulkActionButtons={false}
          actions={false}
          filters={<ClientFilter />}
          sort={{ field: 'created_at', order: 'DESC' }}
          className={classes.list}
        >
          {isSmall ? (
            <SimpleList
              className={classes.simpleList}
              primaryText={(record) => record.store_name}
              secondaryText={(record) => getPlatformByNumber(record?.origin, record?.store_country, record?.store_language)}
              tertiaryText={(record: any) => (
                <div style={{display: "flex"}}>
                  <StockField
                    stock={record.stock}
                    callback={() => onAddStockClick(record)}
                  />
                  <IconButton
                        size="small"
                        children={<MoreVertIcon />}
                        onClick={(event) => onMenuClick(event, record)}
                      />
                </div>
              )}
              style={{}}
            />
          ) : (
            <Datagrid rowClick="edit" className={classes.datagrid}>
              <FunctionField<any>
                source="store_name"
                label="clients.table.name"
                render={(resource?) => <b>{resource?.store_name || '-'}</b>}
              />
              <FunctionField<ClientResource>
                source="origin"
                label="clients.table.platform"
                render={(resource?) => (
                  <>{getPlatformByNumber(resource?.origin, resource?.store_country, resource?.store_language)}</>
                )}
              />
              {!isMedium && (
                <FunctionField<ClientResource>
                  source="store_url"
                  label="clients.table.url"
                  render={(resource?) => (
                    <>
                      {resource?.store_url ? (
                        <Link
                          target="_blank"
                          href={getAbsoluteUrl(
                            resource.store_url,
                          )}
                        >
                          {resource.store_url}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </>
                  )}
                />
              )}
              <FunctionField<ClientResource>
                label="clients.table.custom_app"
                render={(resource?) => (
                  <SwitchField
                    resource={resource}
                    color="primary"
                    callbackOverride={
                      !resource?.clients_configs[0]?.app_custom
                        ? () =>
                            missingConfigsDialogOnClick(
                              () =>
                                goToEdit(resource?.id, resource?.store_name),
                              'app',
                            )
                        : undefined
                    }
                    fieldName="app_custom"
                    value={resource?.clients_configs[0]?.app_custom}
                  />
                )}
              />
              <FunctionField<ClientResource>
                label="clients.table.custom_tag"
                render={(resource?) => (
                  <SwitchField
                    resource={resource}
                    color="primary"
                    callbackOverride={
                      !resource?.clients_configs[0]?.tag_custom
                        ? () =>
                            missingConfigsDialogOnClick(
                              () =>
                                goToEdit(resource?.id, resource?.store_name),
                              'tag',
                            )
                        : undefined
                    }
                    fieldName="tag_custom"
                    value={resource?.clients_configs[0]?.tag_custom}
                  />
                )}
              />
              <FunctionField<ClientResource>
                label="clients.table.status"
                render={(resource?) => (
                  <SwitchField
                    color='secondary'
                    resource={resource}
                    fieldName="is_active"
                    value={resource?.is_active}
                  />
                )}
              />
              <FunctionField<ClientResource>
                source="stock"
                label="clients.table.stock"
                render={(resource?) => (
                  <StockField stock={resource?.stock || 0} />
                )}
              />
              <FunctionField<ClientResource>
                label=""
                render={(resource?) => (
                  <div className={classes.buttonWrapper}>
                    <IconButton
                      size="small"
                      className={classes.addButton}
                      children={<AddIcon color="primary" />}
                      onClick={() => onAddStockClick(resource)}
                    />
                    <IconButton
                      size="small"
                      children={<MoreVertIcon />}
                      onClick={(event) => onMenuClick(event, resource)}
                    />
                  </div>
                )}
              />
            </Datagrid>
          )}
        </List>
      </SpinnerWrapper>
      <ClientView
        client={currentRecord}
        open={clientViewIsOpen}
        onClose={clientViewOnClose}
      />

      {generateStockDialogIsOpen && (
        <GenerateStockDialog
          open={generateStockDialogIsOpen}
          onClose={onGenerateStockDialogClose}
          client={currentRecord}
        />
      )}
      <MissingConfigsDialog
        open={missingConfigsDialogIsOpen}
        onClose={missingConfigsDialogOnClose}
        callback={missingConfigsDialogCallback}
        configType={missingConfigsDialogConfigType}
      />
      <ConfirmRegenerateDialog
        open={confirmDialogIsOpen}
        onClose={confirmDialogOnClose}
        clientId={currentRecord?.id}
      />
      <PopoverMenu
        menuItems={popoverMenuItems}
        isOpen={popoverMenuIsOpen}
        onClose={popoverMenuOnClose}
        anchorEl={popoverMenuAnchorEl}
      />
    </PageContainer>
  );
};

export default ClientList;
