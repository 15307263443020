import * as React from 'react';
import { Card, makeStyles } from '@material-ui/core';
import TableWithActionBody from './body';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactElement } from 'react';

const useStyles = makeStyles((theme)=>({
  card: {
    height: '100%',
    minWidth:'100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 380,
  }
}));

interface ITableWithActionProps {
  header: ReactElement;
  columns: string[];
  rows?: object[];
  pageSize: number;
  isLoading?: boolean;
}

const TableWithAction: React.FC<ITableWithActionProps> = ({
  header,
  columns,
  rows,
  pageSize,
  isLoading
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div className={classes.table}>
        {header}
        {(rows&&!isLoading) ? <TableWithActionBody columns={columns} rows={rows} pageSize={pageSize} /> : 
        <Skeleton variant="rect" width="100%" height={300} />
        }
      </div>
    </Card>
  );
};

export default TableWithAction;
