import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  TextField,
  InputAdornment,
  FormControlLabel,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import useStyles from '../styles';
import Collapse from '@material-ui/core/Collapse';
import { Switch } from '@material-ui/core';
import ImageInput from './ImageInput';
import { useFormContext } from 'react-hook-form';
import { isValidUrl } from '../../../Utils/index';
import ColorPaletteInput from './ColorPaletteInput';
import { InputContainer, ArrowChevron, CardTitleWithIcon } from 'components';
import EditIcon from '@material-ui/icons/Edit';
import { Features, hasFeature } from 'config';
import { PLATFORMS } from 'config';
import classNames from 'classnames';
import {
  PLANS_NAMES,
  PLAN_STATUS,
  SubscriptionDataI,
} from 'containers/subscriptions/Subscriptions';

type Props = {
  superAdmin: boolean;
  subscriptionData: SubscriptionDataI | undefined;
  clientOrigin: number;
};
const WebAppCustom = ({ superAdmin, subscriptionData, clientOrigin }: Props) => {
  const [toggleCard, setToggleCard] = useState(false);
  const [validUrl, setValidUrl] = useState(true);
  const [formErrors, setErrors] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const { register, watch, errors, setValue, control } = useFormContext();
  const isChecked = watch('app_custom');
  const webAppRef = useRef(document.createElement('div'));
  const keys = ['redirect_url', 'custom_app_url', 'redirect_time', 'logo'];

  useEffect(() => {
    let hasError = false;
    keys.forEach((key) => {
      if (errors.hasOwnProperty(key)) {
        setErrors(true);
        hasError = true;
      }
    });
    !hasError && setErrors(false);

    keys.forEach((key: string) => {
      errors?.hasOwnProperty(key) &&
        webAppRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
    });
  }, [Object.keys(errors).length]);

  const unlimitedClientRestrict = Boolean(
    !superAdmin &&
      (!subscriptionData ||
        subscriptionData?.planName?.toUpperCase() !== PLANS_NAMES.UNLIMITED ||
        subscriptionData?.status?.toUpperCase() !== PLAN_STATUS.ACTIVE),
  );

  return (
    <Card
      className={`${classes.formPart} ${formErrors && classes.formPartError}`}
      ref={webAppRef}
    >
      <CardActionArea onClick={() => setToggleCard(!toggleCard)}>
        <CardHeader
          title={CardTitleWithIcon(
            translate('customizations.app_custom.title'),
            <EditIcon color="primary" />,
          )}
          action={
            <ArrowChevron
              setToggleCard={setToggleCard}
              toggleCard={toggleCard}
              errorListener={toggleCard}
            />
          }
        />
      </CardActionArea>
      <Collapse in={toggleCard}>
        <CardContent className={`${classes.formCardContent}`}>
          <InputContainer
            title={translate('client_form.form.customAppSwitch')}
            content={translate('client_form.form.customAppSwitchDescription')}
          >
            <Switch
              defaultChecked={isChecked}
              inputRef={register}
              name="app_custom"
            />
          </InputContainer>
          <Collapse in={isChecked}>
            <InputContainer
              title={'Logo'}
              content={translate('client_form.form.logoDescription')}
              mt
              mb
            >
              <ImageInput
                register={register}
                className={classNames(classes.input, {
                  [classes.grey]: !hasFeature(Features.CustomLogo),
                })}
                // previewWrapperClassName={classes.inputCollapseInner}
                errors={errors}
                setValue={setValue}
                darkMode={watch('dark_mode')}
                label="Logo"
                name="logo"
                helperText={translate('client_form.form.upload_image_helper')}
                defaultUrl={watch('logo_url')}
                disabled={!(hasFeature(Features.CustomLogo) || superAdmin)}
              />
            </InputContainer>
            <InputContainer
              title={translate('client_form.form.color_palett')}
              content={translate('client_form.form.colorPaletteDescription')}
            >
              <ColorPaletteInput
                name="app_customizations_id"
                clientData={control?.defaultValuesRef?.current}
                clientId={control?.defaultValuesRef?.current?.client_id}
                darkMode={watch('dark_mode')}
                disabled={!(hasFeature(Features.CustomPalette) || superAdmin)}
              />
            </InputContainer>
            <InputContainer
              title={translate('client_form.form.redirection_url')}
              content={translate(
                'client_form.form.redirection_url_description',
              )}
              mb
            >
              <TextField
                error={Boolean(errors?.redirect_url)}
                helperText={
                  unlimitedClientRestrict
                    ? translate('client_form.form.redirection_url_access')
                    : errors?.redirect_url?.message || null
                }
                disabled={unlimitedClientRestrict}
                name="redirect_url"
                variant="outlined"
                className={classes.input}
                inputProps={{
                  ref: register,
                }}
                type="text"
                label={translate('client_form.form.redirection_url')}
                InputLabelProps={{
                  shrink: !!watch('redirect_url'),
                }}
              />
              <TextField
                error={Boolean(errors?.sender_redirect_url)}
                helperText={
                  unlimitedClientRestrict
                    ? translate('client_form.form.redirection_url_access')
                    : errors?.sender_redirect_url?.message || null
                }
                disabled={unlimitedClientRestrict}
                name="sender_redirect_url"
                variant="outlined"
                className={classes.input}
                inputProps={{
                  ref: register,
                }}
                type="text"
                label={translate('client_form.form.sender_redirect_url')}
                InputLabelProps={{
                  shrink: !!watch('sender_redirect_url'),
                }}
              />
              <TextField
                error={Boolean(errors.webapp?.redirect_time)}
                helperText={translate(
                  'client_form.form.upload_image_helper',
                ).concat(
                  ' ',
                  unlimitedClientRestrict
                    ? translate('client_form.form.redirection_url_access')
                    : '',
                )}
                disabled={unlimitedClientRestrict}
                name="redirect_time"
                variant="outlined"
                className={`${classes.input} ${classes.noArrowNumericInput}`}
                inputProps={{
                  ref: register,
                }}
                type="number"
                label={translate('client_form.form.redirection_time')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {translate('client_form.form.seconds')}
                    </InputAdornment>
                  ),
                }}
                defaultValue={0}
                InputLabelProps={{
                  shrink: !!watch('redirect_time'),
                }}
              />
            </InputContainer>
            <InputContainer
              title={translate('client_form.form.custom_app_url') as string}
              content={translate('client_form.form.custom_app_url_description')}
              mb
            >
              <TextField
                name="custom_app_url"
                helperText={
                  validUrl
                    ? ''
                    : translate('client_form.form.custom_app_url_helperText')
                }
                variant="outlined"
                className={classes.input}
                label={translate('client_form.form.custom_app_url') as string}
                onBlur={({ target }) => setValidUrl(isValidUrl(target.value))}
                inputProps={{
                  //@ts-ignore
                  ref: register({ validate: { isValidUrl } }),
                }}
                placeholder={translate(
                  'client_form.form.custom_app_url_placeholder',
                )}
                type="text"
                error={!validUrl}
                disabled={!(hasFeature(Features.CustomAppUrl) || superAdmin)}
              />
            </InputContainer>
            <InputContainer
              title={translate('client_form.form.dark_mode')}
              content={translate('client_form.form.dark_mode_description')}
              mb
            >
              <FormControlLabel
                style={{ textAlign: 'center' }}
                control={
                  <Switch
                    inputRef={register}
                    name="dark_mode"
                    defaultChecked={watch('dark_mode')}
                  />
                }
                label={translate('client_form.form.dark_mode')}
              />
            </InputContainer>
            {(superAdmin && clientOrigin === PLATFORMS.tiendanube) && 
              <InputContainer
                title={translate('client_form.form.hide_product')}
                content={translate('client_form.form.hide_product_description')}
              >
                <FormControlLabel 
                  style={{ textAlign: 'center'}}
                  control={
                    <Switch 
                      inputRef={register}
                      name="hide_product"
                      defaultChecked={watch('hide_product')}
                    />
                  }
                  label={translate('client_form.form.hide_product')}
                />
                  
              </InputContainer>
            }
          </Collapse>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default WebAppCustom;
