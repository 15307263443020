export const FETCH_HOME = 'FETCH_HOME';
export const FLUSH_HOME = 'FLUSH_HOME';
export const FETCH_STATS = 'FETCH_STATS';
export const FLUSH_STATS = 'FLUSH_STATS';
export const FETCH_SUBSCRIPTION = 'FETCH_SUBSCRIPTION';
export const HOME_FETCH_LOADING = 'HOME_FETCH_LOADING';
export const LAST_TAGS_FETCH_SUCCEDED = 'LAST_TAGS_FETCH_SUCCEDED';
export const LAST_USERS_FETCH_SUCCEDED = 'LAST_USERS_FETCH_SUCCEDED';
export const CLIENT_FETCH_SUCCEDED = 'CLIENT_FETCH_SUCCEDED'
export const HOME_FETCH_FAILED = 'HOME_FETCH_FAILED';
export const STATS_FETCH_LOADING = 'STATS_FETCH_LOADING';
export const STATS_FETCH_SUCCEDED = 'STATS_FETCH_SUCCEDED';
export const STATS_FETCH_FAILED = 'STATS_FETCH_FAILED';
export const SUBSCRIPTION_FETCH_LOADING = 'SUBSCRIPTION_FETCH_LOADING';
export const SUBSCRIPTION_FETCH_LOADED = 'SUBSCRIPTION_FETCH_LOADED';
export const SUBSCRIPTION_FETCH_SUCCEDED = 'SUBSCRIPTION_FETCH_SUCCEDED';
export const SUBSCRIPTION_FETCH_FAILED = 'SUBSCRIPTION_FETCH_FAILED';
export const FLUSH_TOKEN = 'FLUSH_TOKEN';
export const TOKEN_LOADED = 'TOKEN_LOADED';
export const GET_NEW_GOOGLE_TOKEN = 'GET_NEW_GOOGLE_TOKEN';
export const CHECK_ROUTE_SUPERADMIN = 'CHECK_ROUTE_SUPERADMIN';
export const SUPERADMIN = 'SUPERADMIN';
export const REGULAR = 'REGULAR';
export const LOGISTIC = 'LOGISTIC'
export const REGISTER_COMPLETE_UPDATE_DATA = 'REGISTER_COMPLETE_UPDATE_DATA';
export const SUPPORT_CASES = {
  BUY: 'buy',
  PRINT: 'print',
  BUG: 'bug',
  STOCK: 'stock',
  HELP: 'help',
  TAG: 'tag',
  PLAN: 'plan',
};
export const PLATFORMS = {
  //numbers corresponding to it's enum values in database
  error: 0,
  tiendanube: 1,
  physical: 2,
  api: 3,
  shopify: 4,
  vtex: 5,
  magento: 6,
  dispenser: 7
};


interface IStoresOrigin {
  [key: string]: number
}

export const ONLINE_PLATFORMS: IStoresOrigin = {
  //numbers corresponding to it's enum values in database
  tiendanube: 1,
  api: 3,
  shopify: 4,
  vtex: 5,
  magento: 6
};

export const ONSITE_PLATFORMS: IStoresOrigin = {
  physical: 2,
  dispenser: 7
}

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
//token refresh frequency in miliseconds
export const TOKEN_REFRESH_RATE = 3600000;

export const DEFAULT_COORDINATES = {
  receiverTag: {
    top: 2.25,
    left: 1.23,
    width: 3.1,
  },
  senderTag: {
    top: 0.7,
    left: 3.09,
    width: 2.08,
  },
};

export const VIDEO_VALIDATION = {
  DURATION: 60,
  // 100 MB to bytes
  SIZE: 104857600,
};

export enum Features {
  Customizations = 'customizations',
  CustomLogo = 'custom-logo',
  CustomPalette = 'custom-palette',
  CustomTag = 'custom-tag',
  CustomUrlRedirect = 'custom-url-redirect',
  FramesFiltersEffects = 'frames-filters-effects',
  CustomEmail = 'custom-email',
  AbandonedCarts = 'abandoned-carts',
  CustomAssets = 'custom-assets',
  Stats = 'stats',
  UsersList = 'users-list',
  CustomVideoGreeting = 'custom-video-greeting',
  WidgetAnchor = 'widget-anchor',
  CustomAppUrl = 'custom_app_url',
}


/**
 * Checks if the user has a specific feature.
 *
 * @param  {string} featureName
 * Feature name to check if user has it. If an array of features ir provided the
 * function will return true if there's at least one of the of the provided features.
 *
 * @return {boolean}
 * Boolean
 */
export const hasFeature = (featureName: Features | Features[]): boolean => {
  const features = localStorage.getItem('f');
  if (!features) return false;
  const parsedFeatures = JSON.parse(features);
  if (Array.isArray(featureName)) {
    for (const feature of featureName) {
      if (parsedFeatures.includes(feature)) return true;
    }
    return false;
  } else if (!parsedFeatures.includes(featureName)) {
    return false;
  }
  return true;
};

export const TWO_DECIMAL_REG_EXP = /^[0-9]{1,2}([,.][0-9]{1,2})?$/;