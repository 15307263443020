import React, { CSSProperties } from 'react';
import useStyles from '../styles';
import { Button, CircularProgress } from '@material-ui/core';

interface ISubmitBtnsProps {
    onCancel: () => void;
    titleSave: string;
    titleDiscard: string;
    isLoading?: boolean;
    styles?: CSSProperties;
    formID?: string;
}

const SubmitBtns: React.FC<ISubmitBtnsProps> = ({onCancel, isLoading, titleSave, titleDiscard, styles, formID}) => {
    const classes = useStyles();
    return (
        <div className={`${classes.formPart} ${classes.submitButtons}`} style={styles}>
            <Button startIcon={isLoading?<CircularProgress size="1rem" />:undefined} disabled={isLoading} type="submit" size="large" className={classes.submitButton} variant="contained" color="primary" form={formID}>{titleSave}</Button>
            <Button size="large" className={classes.submitButton} color="primary" onClick={onCancel}>{titleDiscard}</Button>
        </div>
    )
}

export default SubmitBtns;