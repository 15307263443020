import React, { ReactElement, useState } from 'react';
import Popover, { PopoverProps } from '@material-ui/core/Popover';
import { List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(()=>({
    listItemIcon: {
        minWidth: 'unset',
        marginRight: 10
    }
}));

interface IPopoverMenuProps {
    isOpen: boolean;
    onClose: () => void;
    anchorEl: PopoverProps["anchorEl"];
    menuItems: { icon: ReactElement; label: string; onClick: () => void }[]
}

const PopoverMenu: React.FC<IPopoverMenuProps> = ({ isOpen, onClose, anchorEl, menuItems }) => {
    const classes = useStyles();
    const onOptionClick = (callback:()=>void) => {
        callback();
        onClose();
    }
    return (
        <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <List component="nav">
                {menuItems.map((menuItem)=>
                <ListItem button onClick={()=>onOptionClick(menuItem.onClick)}>
                    <ListItemIcon className={classes.listItemIcon}>
                        {menuItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={menuItem.label} />
                </ListItem>)}
            </List>
        </Popover>
    );
}

export default PopoverMenu;

export const usePopoverMenu = () => {
    const [popoverMenuAnchorEl, setPopoverMenuAnchorEl] = useState(null);

    const popoverMenuOnClick = (event: any) => {
        setPopoverMenuAnchorEl(event.currentTarget);
    };

    const popoverMenuOnClose = () => {
        setPopoverMenuAnchorEl(null);
    };

    const popoverMenuIsOpen = Boolean(popoverMenuAnchorEl);
    return { popoverMenuAnchorEl, popoverMenuOnClick, popoverMenuOnClose, popoverMenuIsOpen }
}