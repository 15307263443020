import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import ScissorIcon from './img/Scissor';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px dashed black',
    padding: '8px 12px',
    margin: '0 0 -1px -1px',
  },
  scissorsContainer: {
    paddingTop: 0,
    paddingBottom: 3,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: 0,
  },
  scissorsPath: {
    borderBottom: '1px dashed grey',
    flexGrow: 1,
    height: 0,
  },
  description: {
    fontSize: '12px',
    textAlign: 'center',
    margin: 0,
  },
}));

interface ICuttingLinesInfoWrapperProps {
  clientURL?: string;
  index?: number;
  contentWidth?: number;
  tagReceiver: React.ReactNode;
  tagSender: React.ReactNode;
  cuttingLines?: boolean;
  codeIndexRefs?: boolean;
  tagSpacing?: boolean;
  showSenderTag?: boolean;
  swapOrder?: boolean;
}

const CuttingLinesInfoWrapper: React.FC<ICuttingLinesInfoWrapperProps> = ({
  tagReceiver,
  tagSender,
  clientURL,
  index,
  contentWidth,
  codeIndexRefs,
  cuttingLines,
  tagSpacing,
  showSenderTag,
  swapOrder,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div
      className={classes.root}
      style={{ padding: tagSpacing ? '8px 12px' : 0 }}
    >
      <div style={{ maxWidth: contentWidth + 'cm' }}>
        {swapOrder ? tagReceiver : showSenderTag && tagSender}
        {showSenderTag && codeIndexRefs ? (
          <p className={classes.description}>#{index}</p>
        ) : null}
        {cuttingLines ? (
          <div className={classes.scissorsContainer}>
            <ScissorIcon fontSize="small" />
            <div className={classes.scissorsPath} />
          </div>
        ) : null}
      </div>
      {codeIndexRefs ? (
        <p className={classes.description} style={{ marginBottom: '10px' }}>
          #{index}
        </p>
      ) : null}

      {swapOrder ? showSenderTag && tagSender : tagReceiver}
    </div>
  );
};

export default CuttingLinesInfoWrapper;
