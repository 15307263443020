import { createTheme } from '@material-ui/core/styles';

// To add more colors, add them here first.
declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        tertiary: Palette['primary'];
        quaternary: Palette['primary'];
        bg: Palette['primary'];
    }
    interface PaletteOptions {
        tertiary: PaletteOptions['primary'];
        quaternary: PaletteOptions['primary'];
        bg: Palette['primary'];
    }
}

const { palette } = createTheme();
export default createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1300,
            xl: 1920,
        },
    },
    props: {
        MuiCard: {
            elevation: 2,
        },
    },
    sidebar: {
        '& .paperAnchorLeft': {
            backgroundColor: '#8F3FE6',
        },
        width: 200
    },
    palette: {
        primary: {
            // VIOLET
            // 50
            light: '#947AFF',
            // 500
            main: '#6242E8',
            // 800
            dark: '#402B9A',
        },
        secondary: {
            // PINK
            // 50
            light: '#FF4E844D',
            // 500
            main: '#FF2266',
            // 600
            dark: '#B21747',
        },
        tertiary: {
            // YELLOW
            light: '#FBECD5',
            // 500
            main: '#F5A623',
            // 600
            dark: '#E38D00',
        },
        quaternary: {
            // GREEN
            light: '#b3ece4',
            // 500
            main: '#b3ece4',
            dark: '#00A48B',
        },
        bg: {
            main: '#F4ECFD',
            light: '#F4ECFD',
            dark: '#F4ECFD',
            contrastText: '#F4ECFD'
        },

        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    overrides: {
        MuiLink: {
            root: {
                fontFamily: 'Roboto',
                fontSize: '0.96rem',
                fontWeight: 400,
            },
        },
        MuiCard: {
            root: {
                borderRadius: '12px',
            },
        },
        MuiTableHead: {
            root: {
                '& span': { fontWeight: 600 },
            },
        },
        MuiTableCell: {
            root:{
                background: 'white !important'
            }
        },
        MuiCardHeader: {
            root: {
                borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                textTransform: 'uppercase',
                '& span': {
                    fontWeight: 600,
                    fontFamily: 'Manrope',
                    fontSize: '1rem',
                },
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: '12px',
            },
        },
        MuiDrawer: {
            root: {
                boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
            },
        },
        //@ts-ignore
        // MuiDataGrid: {
        //   root: {
        //     borderRadius: '12px',
        //   },
        // },
        MuiButton: {
            root: {
                borderRadius: '12px',
                fontFamily: 'Manrope',
                letterSpacing: '0.75px',
            },
            contained: {
                backgroundColor: "white",
                color: '#D82C20'
            }
        },
        MuiInputBase: {
            root: {
                borderRadius: '12px !important',
            },
        },
        MuiFormHelperText: {
            contained: {
                marginLeft: 0
            }
        },
        //@ts-ignore
        RaLayout: {
            root: {
                maxHeight: '100vh',
                overflowY: 'hidden',
                minWidth: "auto"
                /* '& .RaSidebar-drawerPaper-146': {
                  width: 200,
                }, */
            },
            
            content: {
                //50px is the navbar's height plus it's marginBottom value
                maxHeight: 'calc(100vh - 50px)',
                overflowY: 'auto',
            },
            
        },
        //@ts-ignore
        RaListToolbar: {
            toolbar: {
                PaddingLeft: '0px !important',
                backgroundColor: 'unset !important'
            }
        },
        // this is ugly, but it's the only way I've found to hide the appbar refresh button without remaking the component from scratch
        // https://stackoverflow.com/questions/57510975/react-admin-how-to-hide-refresh-button-from-appbar
        // @ts-ignore
        RaAppBar: {
            toolbar: {
                '& button': {
                    // '&:not(:nth-child(1)):not(.right-divs)': {
                    //   display: 'none',
                    // },
                    '&:nth-last-child(2)': {
                        display: 'block',
                        visibility: 'hidden',
                        width: '20px'
                    },
                    '&:nth-last-child(1)': {
                        display: 'none'
                    },
                },
            },
        },
        MuiTooltip: {
            tooltip: {
                display: 'none'
            }
        },
        MuiListItem: {
            gutters: {
                paddingLeft: '11px !important'
            }
        },
        MuiMenuItem: {
            gutters: {
                paddingLeft: '11px !important'
            }
        },
        RaMenuItemLink: {
            root: {
                margin: 5,
                paddingTop: 10,
                paddingBottom: 10,
                color: 'white',
                '& *': {
                    fill: '#fff !important'
                }
            },
            active: {
                backgroundColor: 'rgba(255,255,255,0.2) !important',
                borderRadius: 5,
                color: '#fff !important',
                '& *': {
                    fill: '#fff !important'
                }
            },
            icon: {
                minWidth: '40px'
            }
        },
        MuiCardActionArea: {
            root: {
                borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            },
            focusHighlight: {
                display: 'none',
            },
        },
        MuiOutlinedInput: {
            root: {
                '&$focused $notchedOutline': {
                    borderColor: '#000',
                    borderWidth: 2,
                },
            },
        },
        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: '#000'
                }
            }
        }
    },
});