import React, { useState } from 'react';
import { makeStyles, Button, CircularProgress } from '@material-ui/core';
import { useTranslate, useNotify } from 'react-admin';
import TitledDialog from '../../../components/TitledDialog';
import axios from 'axios';
import config from '../../../config';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  fields: {
    marginBottom: 15,
  },
  textField: {
    marginBottom: 15,
    width: '100%',
  },
}));

interface IResendEmailDialogProps {
  open: boolean;
  onClose: () => void;
  tag?: TagResource;
}

const ResendEmailDialog: React.FC<IResendEmailDialogProps> = ({
  tag,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const token = useSelector((state: ReduxState) => state.auth?.token);

  const onSubmit = () => {
    setIsLoading(true);
    axios
      .post(
        `${config.AWS_API_SITE}/resendRecordingEmail`,
        {
          client_id: tag?.client.id,
          code: tag?.code,
          order: tag?.order,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((r) => {
        notify('tags.dialog.resendEmail.success', 'info');
        onClose();
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        notify('tags.dialog.resendEmail.failed', 'info');
        console.error(e);
      });
  };

  return (
    <TitledDialog
      title={translate(`tags.dialog.resendEmail.title`)}
      subtitle={translate(`tags.dialog.resendEmail.subtitle`, { code: tag?.code })}
      onClose={onClose}
      open={open}
    >
      <div className={classes.buttons}>
        <Button
          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size="1rem" /> : undefined}
          color="primary"
          onClick={onSubmit}
        >
          {translate('tags.dialog.resendEmail.confirm')}
        </Button>
        <Button color="primary" onClick={onClose}>
          {translate('tags.dialog.resendEmail.cancel')}
        </Button>
      </div>
    </TitledDialog>
  );
};

export default ResendEmailDialog;

export const useResendEmailDialog = () => {
  const [resendEmailDialogIsOpen, setResendEmailDialogIsOpen] = useState(false);
  return {
    resendEmailDialogIsOpen,
    resendEmailDialogOnClick: () => setResendEmailDialogIsOpen(true),
    resendEmailDialogOnClose: () => setResendEmailDialogIsOpen(false),
  };
};
