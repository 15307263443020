import React from 'react';
import {
  Datagrid,
  DateField,
  SimpleList,
  TextField,
  ListProps,
  ListBase,
  Pagination,
  //NumberField,
  FunctionField,
  //useTranslate,
  //useListContext,
} from 'react-admin';
import UserField from '../../../components/UserField/simple';
// import StyledChip from '../../../components/StyledChip';
// import UserField from '../../../components/UserField';
import { parseTimestamp } from '../../../Utils';
interface ISenderListProps extends ListProps {
  isSmall?: boolean;
  classes: any;
}

const SenderList: React.FC<ISenderListProps> = (props) => {
  const { isSmall, classes } = props;
  //const translate = useTranslate();
  return (
    <ListBase {...props} sort={{ field: 'last_activity', order: 'DESC' }}>
      {isSmall ? (
        <SimpleList
          className={classes.simpleList}
          linkType="show"
          primaryText={(record) => record?.sender_name}
          secondaryText={(record) => record?.buyer_email}
          tertiaryText={(record) => parseTimestamp(record?.last_activity, navigator.language)}
        />
      ) : (
          <Datagrid optimized className={classes.datagrid} size="medium">
            <FunctionField
              label="users.table.name"
              source="sender_name"
              render={(record: any) => (
                <UserField identification={record.sender_name} />
              )}
            />
            <FunctionField
              label="users.table.email"
              source="sender_email"
              render={(record: any) => (
                <UserField identification={record.buyer_email} />
              )}
            />
            <DateField
              label="users.table.last_activity"
              source="last_activity"
              locales={navigator.language}
            />
            <TextField
              label="users.table.greetings_sent"
              source="total_sent"
            />
            {/* ADVANCED USERS FIELDS */}
            {/* <FunctionField
                source="senderName"
                label="users.table.user"
                render={(record) => (
                  <UserField name={record?.senderName} email={record?.senderEmail} />
                )}
              /> */}
            {/* <FunctionField
                label="users.table.gender"
                render={(record) => {
                  switch (record?.sender.gender) {
                    case 'M':
                      return translate('genders.male');
                    case 'F':
                      return translate('genders.female');
                    default:
                      return translate('genders.other');
                  }
                }}
              /> */}
            {/* <NumberField
                label="users.table.gifted_amount"
                source="gift.cost"
                style={{ fontWeight: 700 }}
                options={{ style: 'currency', currency: 'ARS' }}
              />
              */}
            {/* <FunctionField
                label="users.table.tags"
                render={(record) =>
                  record?.tags.map((tag: string) => (
                    <StyledChip label={`users.tags.${tag}`} />
                  ))
                }
              /> */}
          </Datagrid>
        )}
      <Pagination />
    </ListBase>
  );
};

export default SenderList;
