import { put, call, takeEvery, select } from 'redux-saga/effects';
import {
  SUBSCRIPTION_FETCH_SUCCEDED,
  SUBSCRIPTION_FETCH_FAILED,
  SUBSCRIPTION_FETCH_LOADED,
  FETCH_SUBSCRIPTION,
  SUPERADMIN
} from 'config/constants';
import axios from 'axios';
import config from 'config';


async function fetchSubscription(token: string, clientId:string, isSuperAdmin: boolean) {
  if(isSuperAdmin) return {};
  try{
  const result = await axios.get(`${config.AWS_API_SITE}/client`, {
    params: { clientID: clientId },
    headers: { Authorization: `Bearer ${token}` },
  });
  return result.data;
}catch(err){
  return {error: true, message: err}
}
}
const getToken = (state: any) => state.auth?.token || '';
const getClientId = (state: any) => state.auth?.decoded?.clientID || '';
const checkIsSuperAdmin = (state: ReduxState) => state.auth?.decoded.role === SUPERADMIN;

export default function* fetchSubscriptionSaga() {
  yield takeEvery(FETCH_SUBSCRIPTION, function* () {
    // @ts-ignore: Unreachable code error
    const token = yield select(getToken);
    // @ts-ignore: Unreachable code error
    const clientID = yield select(getClientId);
    // @ts-ignore: Unreachable code error
    const isSuperAdmin = yield select(checkIsSuperAdmin);
    try {
      // @ts-ignore: Unreachable code error
      const clientInfo = yield call(fetchSubscription, token, clientID,isSuperAdmin);
      yield put({ type: SUBSCRIPTION_FETCH_SUCCEDED, payload: clientInfo });
    } catch (e: any) {
      yield put({ type: SUBSCRIPTION_FETCH_FAILED, payload: e.message });
    } finally {
      yield put({ type: SUBSCRIPTION_FETCH_LOADED });
    }
  });
}
