import React, { ReactElement } from 'react';
import { makeStyles, Link, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& a, & svg': {
      color: '#2196F3',
    },
    maxWidth: '100%',
  },
  value: {
    display: 'flex',
    alignItems: 'center',
    '& span, & a': {
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: '1rem',
    },
    maxWidth: 'inherit',
    '& *': {
      maxWidth: 'inherit',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 5,
    '& svg': {
      height: '22px',
    },
  },
  label: {
    marginBottom: 3,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: '0.8rem',
  },
  wrapper: {
    display: 'flex',
  },
}));

interface IViewFieldProps {
  icon?: ReactElement;
  label: string;
  value?: number | string | ReactElement;
  link?: boolean;
  className?: string;
  linkHref?: string;
}

const ViewField: React.FC<IViewFieldProps> = ({
  icon,
  label,
  value,
  link,
  className,
  linkHref,
}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className}`}>
      <label className={classes.label}>{label}</label>

      <div className={classes.wrapper}>
        {link && value && typeof value === 'string' ? (
          <div className={classes.value}>
            <Link href={linkHref || value} target="_blank" rel="noopener">
              <Typography style={{ overflowWrap: 'break-word' }}>
                {value}
              </Typography>
            </Link>
          </div>
        ) : typeof value === 'string' || typeof value === 'number' ? (
          <div className={classes.value}>
            <Typography style={{ overflowWrap: 'break-word' }}>
              {value || '-'}
            </Typography>
          </div>
        ) : (
          value || '-'
        )}
        {icon && value && <div className={classes.icon}>{icon}</div>}
      </div>
    </div>
  );
};

export default ViewField;
