import React, { useState } from 'react';
import {
  Grid,
  Button,
  FormControl,
  MenuItem,
  Select,
  makeStyles,
  InputLabel,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Collapse,
} from '@material-ui/core';
import copy from 'copy-to-clipboard';
import { useTranslate } from 'react-admin';
import config from 'config/index';
import CodeIcon from '@material-ui/icons/Code';
import { CardTitleWithIcon, ArrowChevron, InputContainer } from 'components';
import { default as useDefaultStyles } from '../styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '2em !important',
  },
  copyBtn: {
    margin: '1.5em 0',
    width: '100%',
  },
  dropdown: {
    width: '100%',
  },
}));

enum Platforms {
  ORDER_PRINTER = 'Order Printer',
}

type Props = {
  client: Record<string, any>;
};

const OrderPrinterTag = `<div class="tag-container"><img src="{{'@API_URL/tag-order?apiKey=@API_KEY&order=' | append: order_number }}" class="tag-img" alt=""></div><style>.tag-container{height:40vh;width:24vh;margin:0 auto}.tag-img{width:100%;height:100%;transform:rotate(90deg)}.tag-img::after{content:"";display:block;position:absolute;top:0;left:0;width:100%;height:100%;background-color:#fff}</style>`;

export default function SpOrderTag({ client }: Props) {
  const [selected, setSelected] = useState<Platforms>(Platforms.ORDER_PRINTER);
  const [toggleCard, setToggleCard] = useState(false);
  const [copied, setCopied] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const defaultStyles = useDefaultStyles();

  const getOrderPrinterTag = () => {
    const result = OrderPrinterTag.replace(
      '@API_URL',
      config.AWS_API || '',
    ).replace('@API_KEY', client['api_key']);
    return result;
  };

  const handleCopy = () => {
    let result: string = '';
    if (selected === Platforms.ORDER_PRINTER) {
      result = getOrderPrinterTag();
    }
    copy(result);
    setCopied(true);
  };

  return (
    <Card className={defaultStyles.formPart}>
      <CardActionArea onClick={() => setToggleCard(!toggleCard)}>
        <CardHeader
          title={CardTitleWithIcon(
            translate('customizations.spOrderTag.title'),
            <CodeIcon color="primary" />,
          )}
          action={
            <ArrowChevron
              setToggleCard={setToggleCard}
              toggleCard={toggleCard}
              errorListener={toggleCard}
            />
          }
        />
      </CardActionArea>
      <Collapse in={toggleCard}>
        <CardContent className={defaultStyles.formCardContent}>
              <InputContainer
              title={translate('customizations.spOrderTag.title')}
              content={translate('customizations.spOrderTag.description')}
              >
              <FormControl className={classes.dropdown} variant="outlined">
                <InputLabel id="demo-simple-select-label">
                  {translate('customizations.spOrderTag.inputLabel')}
                </InputLabel>
                <Select
                  value={selected}
                  label={selected}
                  onChange={(e: any) => setSelected(e)}
                >
                  {Object.values(Platforms).map((Platform) => (
                    <MenuItem value={Platform}>{Platform}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                onClick={handleCopy}
                variant="contained"
                color="primary"
                className={classes.copyBtn}
              >
                {translate('customizations.spOrderTag.copyBtn')}
                {copied && `(${translate('customizations.spOrderTag.copied')})`}
              </Button>
              </InputContainer>
        </CardContent>
      </Collapse>
    </Card>
  );
}
