import React from 'react';
import {
  useTranslate,
  ListProps,
  useRedirect,
  ReduxState,
  ListContext,
} from 'react-admin';
import PageContainer from '../../components/PageContainer';
import { Card, makeStyles, useMediaQuery } from '@material-ui/core';
import PageTitle from '../../components/PageTitle';
import UserIcon from '@material-ui/icons/Group';
import { ReceiverList, SenderList, TrackingList } from './lists';
import { useSelector } from 'react-redux';
import SpinnerWrapper from '../../components/SpinnerWrapper';
import CustomTabsHeader from '../../components/CustomTabHeader/index';
import { useCheckFeature } from 'customHooks';

const useListStyles = makeStyles((theme) => ({
  datagrid: {
    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
    '& thead': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    },
  },
  simpleList: {
    padding: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',

    '& > a': {
      display: 'block',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },

    '& [class*="RaSimpleList"]': {
      opacity: 1,
    },
  },
}));

const UserList: React.FC<ListProps> = (props) => {
  const classes = useListStyles();
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const redirect = useRedirect();
  const token = useSelector(
    (state: ReduxState) => state.auth && state.auth.token,
  );

  // Checks if the user has the feature that allows him to use this resource
  useCheckFeature("users-list");

  return (
    <ListContext.Provider value={props as any}>
      <PageContainer>
        <PageTitle
          title={translate('users.title')}
          subtitle={translate('users.subtitle')}
          icon={<UserIcon />}
        />
        <SpinnerWrapper isLoading={!token}>
          <Card>
            <CustomTabsHeader
              resource={props.resource!}
              actions={[
                {
                  name: translate('users.header_titles.senders'),
                  callback: () => redirect('list', '/senders'),
                  resource: 'senders',
                  sortField: 'last_activity',
                  fieldsNames: [
                    'sender_name',
                    'sender_email',
                    'last_activity',
                    'total_sent',
                  ],
                  columnsNames: [
                    translate('users.table.name'),
                    translate('users.table.email'),
                    translate('users.table.last_activity'),
                    translate('users.table.greetings_sent'),
                  ],
                },
                {
                  name: translate('users.header_titles.receivers'),
                  callback: () => redirect('list', '/receivers'),
                  resource: 'receivers',
                  sortField: 'last_activity',
                  fieldsNames: [
                    'receiver_name',
                    'receiver_email',
                    'last_activity',
                    'total_received',
                  ],
                  columnsNames: [
                    translate('users.table.name'),
                    translate('users.table.email'),
                    translate('users.table.last_activity'),
                    translate('users.table.greetings_amount'),
                  ],
                },
                {
                  name: translate('users.header_titles.tracking'),
                  callback: () => redirect('list', '/tracking'),
                  resource: 'tracking',
                  sortField: 'created_at',
                  fieldsNames: [
                    'id',
                    'updated_at',
                    'sender_name',
                    'sender_email',
                    'receiver_name',
                    'receiver_email',
                  ],
                  columnsNames: [
                    '#ID',
                    translate('tracking.table.date'),
                    translate('users.table.name'),
                    translate('users.table.email'),
                    translate('users.table.name'),
                    translate('users.table.email'),
                  ],
                },
              ]}
            />
            {props.resource === 'senders' && (
              <SenderList classes={classes} isSmall={isSmall} {...props} />
            )}
            {props.resource === 'receivers' && (
              <ReceiverList classes={classes} isSmall={isSmall} {...props} />
            )}
            {props.resource === 'tracking' && (
              <TrackingList classes={classes} isSmall={isSmall} {...props} />
            )}
          </Card>
        </SpinnerWrapper>
      </PageContainer>
    </ListContext.Provider>
  );
};

export default UserList;
