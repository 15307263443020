import React, { useState, useEffect , useRef } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslate } from 'react-admin';
import useStyles from '../styles';
import Collapse from '@material-ui/core/Collapse';
import { ONLINE_PLATFORMS, ONSITE_PLATFORMS } from 'config';
import { InputContainer, ArrowChevron, CardTitleWithIcon } from 'components';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { store_type_obj } from './store_types';
import { isRequired } from 'Utils/index';
import { values } from 'lodash';
import { useIsSuperAdmin } from 'customHooks';

const ShopData: React.FC = () => {
  const [toggleCard, setToggleCard] = useState(false);
  const [formErrors, setErrors] = useState(false);
  const isSuperadmin = useIsSuperAdmin();
  const translate = useTranslate();
  const classes = useStyles();
  const { register, errors, control, watch, getValues } = useFormContext();
  const storeName = useRef(document.createElement("div"));
  const storeUrl = useRef(document.createElement("div"));
  const origin = watch("origin")
  const type = watch("type")
  const isKeyInputsOpen = type === 'online' && origin === 5 && isSuperadmin;

  useEffect(() => {
    if(errors.hasOwnProperty('store_name') || errors.hasOwnProperty('store_url') ) {
      setErrors(true)
    } else {
      setErrors(false)
    }
    errors.hasOwnProperty('store_name') && storeName.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    errors.hasOwnProperty('store_url') && storeUrl.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [Object.keys(errors).length])

  return (
    <Card className={`${classes.formPart} ${formErrors && classes.formPartError}`}>
      <CardActionArea onClick={() => setToggleCard(!toggleCard)}>
        <CardHeader
          title={CardTitleWithIcon(
            translate('client_form.form.shop_data'),
            <StorefrontIcon color="primary" />,
          )}
          classes={{ action: classes.cardHeader }}
          action={<ArrowChevron setToggleCard={setToggleCard} toggleCard={toggleCard} errorListener={toggleCard} />}
        />
      </CardActionArea>
      <Collapse
        in={toggleCard}
        // classes={{ wrapperInner: classes.inputCollapseInner }}
      >
        <CardContent className={classes.formCardContent}>
          <InputContainer
            title={translate('client_form.form.name')}
            content={translate('client_form.form.shopNameDescription')}
            mb
          >
            <TextField
              error={Boolean(errors.store_name)}
              helperText={errors.store_name?.message || null}
              name="store_name"
              variant="outlined"
              className={classes.input}
              inputProps={{
                ref: register({
                  validate: (value) =>
                    isRequired(value, () => setToggleCard(true)),
                }),
              }}
              type="text"
              label={translate('client_form.form.name') + ' *'}
              InputLabelProps={{
                shrink: !!watch('store_name'),
              }}
              ref={storeName}
            />
          </InputContainer>
          <InputContainer
            title={translate('client_form.form.type')}
            content={translate('client_form.form.typeDescription')}
            mb
          >
            <Controller
              control={control}
              name="type"
              defaultValue="onsite"
              render={({ onChange, value, name, ref }) => (
                <FormControl variant="outlined" className={classes.input}>
                  <InputLabel id="type-label">
                    {translate('client_form.form.type') + ' *'}
                  </InputLabel>
                  <Select
                    labelId="type-label"
                    id="type"
                    value={value || ''}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    label={translate('client_form.form.type') + ' *'}
                  >
                    {['onsite', 'online'].map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {translate(`client_form.form.type_select.${type}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </InputContainer>
            <InputContainer
              content={translate('client_form.form.platformDescription')}
              title={translate('client_form.form.platform')}
              mb
            >
              <Controller
                control={control}
                name="origin"
                render={({ onChange, value, name, ref }) => (
                  <FormControl variant="outlined" className={classes.input}>
                    <InputLabel id="origin-label">
                      {translate('client_form.form.platform') + ' *'}
                    </InputLabel>
                    <Select
                      labelId="origin-label"
                      id="origin"
                      value={value || ''}
                      ref={ref}
                      name={name}
                      defaultValue=""
                      onChange={onChange}
                      required
                      label={translate('client_form.form.platform') + ' *'}
                    >
                      {Object.entries(
                        watch('type') == 'online' ?  ONLINE_PLATFORMS : ONSITE_PLATFORMS).map(
                        ([key, value], index) => (
                          <MenuItem key={index} value={value}>
                            {translate(`platforms.${key}`)}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                )}
              />
            </InputContainer>
          <Collapse
            in={isKeyInputsOpen}
          >
             <InputContainer
              content={"Enter the AppKey generated by VTEX Admin"}
              title={"AppKey"}
              mb
              >
                <TextField
                  error={Boolean(errors.app_key)}
                  name="app_key"
                  variant="outlined"
                  className={classes.input}
                  inputProps={{
                    ref: register,
                  }}
                  type="text"
                  label={"AppKey"}
                  InputLabelProps={{
                    shrink: !!watch('app_key'),
                  }}
                />
              </InputContainer>
          </Collapse>
          <Collapse
            in={isKeyInputsOpen}
          >
            <InputContainer
              content={"Enter the AppToken generated by VTEX Admin"}
              title={"AppToken"}
              mb
            >
              <TextField
                error={Boolean(errors.access_token)}
                name="access_token"
                variant="outlined"
                className={classes.input}
                inputProps={{
                  ref: register,
                }}
                type="text"
                label={"AppToken"}
                InputLabelProps={{
                  shrink: !!watch('access_token'),
                }}
              />
            </InputContainer>
          </Collapse>
          <InputContainer
            content={translate('client_form.form.urlDescription')}
            title={translate('client_form.form.url')}
          >
            <TextField
              error={Boolean(errors.store_url)}
              helperText={errors.store_url?.message || null}
              name="store_url"
              variant="outlined"
              className={classes.input}
              inputProps={{
                ref: register({
                  validate: (value) => {
                    if(watch('origin') === 6){
                      return isRequired(value, () => setToggleCard(true))
                    }
                    return true;
                  }
                }),
              }}
              type="text"
              label={translate('client_form.form.url')}
              InputLabelProps={{
                shrink: !!watch('store_url'),
              }}
              ref={storeUrl}
            />
          </InputContainer>
          <InputContainer
            content={translate('client_form.form.storeTypeDescription')}
            title={translate('client_form.form.storeTypeTitle')}
            mt
          >
            <Controller
                control={control}
                name="store_type"
                render={({ onChange, value, name, ref }) => {
                  return (
                    <FormControl variant="outlined" className={classes.input}>
                      <InputLabel id="store_type-label">
                        {translate('client_form.form.category')}
                      </InputLabel>
                      <Select
                        labelId="store_type-label"
                        id="store_type"
                        value={value || ''}
                        ref={ref}
                        name={name}
                        defaultValue={value}
                        onChange={onChange}
                        //required={watch('type') === 'online'}
                        label={translate('client_form.form.category')}
                      >
                        {Object.entries(store_type_obj).map(
                          ([key, value], index) => (
                            <MenuItem key={index} value={key}>
                              {translate(`client_form.store_types.${key}`)}
                            </MenuItem>
                          ),)}
                      </Select>
                    </FormControl>
                  )
                }}
              />
          </InputContainer>      
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ShopData;
