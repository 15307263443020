import React, { useState} from 'react';
import {
  List,
  Datagrid,
  ListProps,
  FunctionField,
  SimpleList
} from 'react-admin';
import { useTranslate } from 'ra-core';
import PageTitle from '../../components/PageTitle';
import PageContainer from '../../components/PageContainer';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles, useMediaQuery, IconButton, Link } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SpinnerWrapper from '../../components/SpinnerWrapper';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';



const useListStyles = makeStyles((theme) => ({
  datagrid: {
    borderCollapse: 'unset',
    '& thead': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      fontSize: '1.2rem',
      '& th': {
        paddingTop: 12,
        paddingBottom: 12,
        background: 'transparent',
      },
    },
    '& a': {
      color: '#2196F3'
    }
  },
  name: {
    fontWeight: 500
  },
  list: {
    '& [class*="RaList-content"]': {
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
  },
  simpleList: {
    padding: 0,

    '& > a': {
      display: 'block',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },

    '& [class*="RaSimpleList"]': {
      opacity: 1,
    },
  },
  addButton: {
    backgroundColor: 'white',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    marginRight: 10
  },
  buttonWrapper: {
      display:'flex'
  }
}));

const UsersList: React.FC<ListProps> = (props) => {
  const translate = useTranslate();
  const classes = useListStyles();
  const isSmall = useMediaQuery('(max-width:1000px)');

  const token = useSelector((state: ReduxState) => state.auth && state.auth.token);

  return (
    <PageContainer>
      <PageTitle
        title={translate('admin_users.title')}
        icon={<AccountCircleIcon />}
        subtitle={translate('admin_users.subtitle')}

      />
      <SpinnerWrapper isLoading={!token}>
        <List
          {...props}
          exporter={false}
          bulkActionButtons={false}
          actions={false}
          sort={{ field: 'timestamp', order: 'DESC' }}
          className={classes.list}
        >
          {isSmall ? (
            <SimpleList
              className={classes.simpleList}
              secondaryText={(record) => record.email}
              primaryText={(record) => record.name}
            />
          ) : (
              <Datagrid rowClick="edit" className={classes.datagrid}>
                <FunctionField<UserResource>
                  source="id"
                  label="admin_users.table.name"
                  render={(resource?) => (
                    <>{resource?.name|| "-"}</>
                  )}
                />
                <FunctionField<UserResource>
                  source="email"
                  label="admin_users.table.email"
                  render={(resource?) => (
                    <>{resource?.email|| "-"}</>
                  )}
                />
                <FunctionField<UserResource>
                  source="role"
                  label="admin_users.table.role"
                  render={(resource?) => (
                    <>{resource?.role || "-"}</>
                  )}
                />
                <FunctionField<UserResource>
                  label="admin_users.table.clientID"
                  render={(resource?) => (
                    <b>{resource?.clientID || "-"}</b>
                  )}
                />
                <FunctionField<UserResource>
                  label=""
                  render={(resource?) => (<div className={classes.buttonWrapper}>
                    <IconButton size='small' children={<MoreVertIcon />} 
                    //onClick={(event) => onMenuClick(event, resource)} 
                    />
                  </div>
                  )}
                />
              </Datagrid>
            )}
        </List>
      </SpinnerWrapper>

    </PageContainer>
  );
};

export default UsersList;
