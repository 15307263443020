import React from 'react';
import {
  Datagrid,
  DateField,
  SimpleList,
  ListProps,
  ListBase,
  Pagination,
  FunctionField,
  //FunctionField,
  //useListContext
} from 'react-admin';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-admin';
import UserField from '../../../components/UserField/simple';
import { dateFormatter } from '../../../Utils';
import { useIsSuperAdmin } from '../../../customHooks';
// import GiftField from '../../../components/GiftField';
// import { TagStatusField } from '../../../components/StatusFields';

interface ITrackingListProps extends ListProps {
  isSmall?: boolean;
  classes: any;
}

const TrackingList: React.FC<ITrackingListProps> = (props) => {
  const { isSmall, classes } = props;
  const isSuperadmin = useIsSuperAdmin();
  const translate = useTranslate();
  const idColumnLabel = isSuperadmin
    ? translate('users.table.id_superadmin')
    : translate('users.table.id_regular');
  return (
    <ListBase {...props} sort={{ field: 'updated_at', order: 'DESC' }}>
      {isSmall ? (
        <SimpleList
          className={classes.simpleList}
          linkType="show"
          primaryText={(record) => record?.sender_name}
          secondaryText={(record) =>
            record?.sender_email || record?.buyer_email
          }
          tertiaryText={(record) => record?.receiver_name}
        />
      ) : (
        <Datagrid optimized className={classes.datagrid} size="medium">
          <FunctionField<TrackingResource>
            label={idColumnLabel}
            render={(record) =>
              isSuperadmin ? record?.id || '-' : record?.order_number || '-'
            }
          />
          <DateField label="tracking.table.date" source="updated_at" locales={navigator.language} />
          <FunctionField<TrackingResource>
            label="users.table.name"
            source="sender_name"
            render={(record) => (
              <UserField identification={record?.sender_name || '-'} />
            )}
          />
          <FunctionField<TrackingResource>
            label="users.table.email"
            source="sender_email"
            render={(record) => (
              <UserField identification={record?.sender_email || record?.buyer_email || '-'} />
            )}
          />
          <FunctionField<TrackingResource>
            label="users.table.name"
            source="receiver_name"
            render={(record) => (
              <UserField identification={record?.receiver_name || '-'} />
            )}
          />
          <FunctionField<TrackingResource>
            label="users.table.email"
            source="receiver_email"
            render={(record) => (
              <UserField identification={record?.receiver_email || '-'} />
            )}
          />
          {/* ADVANCED USERS FIELD */}
          {/* <FunctionField
            label="tracking.table.sender"
            source="senderName"
            render={(record) => (
              <UserField
                name={record?.senderName}
                email={record?.senderEmail}
              />
            )}
          /> */}
          {/* <FunctionField
            label="tracking.table.gift"
            render={(record) => (
              <GiftField title={record?.gift.title} cost={record?.gift.cost} />
            )}
          /> */}
          {/* <FunctionField
            label="tracking.table.receiver"
            render={(record) => (
              <UserField
                name={record?.receiverName}
                email={record?.receiverEmail}
              />
            )}
          />
          <FunctionField
            label="tracking.table.status"
            render={(record) => <TagStatusField status={record?.status} />}
          /> */}
        </Datagrid>
      )}
      <Pagination />
    </ListBase>
  );
};

export default TrackingList;
