import React, { useState } from 'react';
import { Button, Modal, Box, Typography } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
  open: boolean;
  onClose: any;
  onContinue: any;
};

export default function CancelSubDialog({ open, onClose, onContinue }: Props) {
  const [openUnderstood, setOpenUnderstood] = useState(false);
  const translate = useTranslate();

  const handleConfirm = () => {
    onContinue();
    setOpenUnderstood(true);
  };
  
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          backgroundColor: 'white',
          borderRadius: '12px',
          textAlign: 'center',
          outline: 'none',
        }}
      >
        <div
          onClick={onClose}
          style={{ cursor: 'pointer', padding: '10px', float: 'right' }}
        >
          <CloseIcon color="primary" />
        </div>
        
        {openUnderstood ? (
          <div style={{ padding: '1.5em' }}>
            <Typography
              variant="body1"
              style={{ fontSize: '24px', fontWeight: 700 }}
            >
              {translate('subscriptions_plans.popups.cancelSub.confirmtitle')}
            </Typography>
            <Typography
              variant="body1"
              style={{ fontSize: '16px', marginTop: '5px' }}
            >
              {translate(
                'subscriptions_plans.popups.cancelSub.confirmsubtitle',
              )}
            </Typography>
            <Button
              variant="contained"
              onClick={onClose}
              color="primary"
              style={{ marginTop: '30px' }}
            >
              {translate('subscriptions_plans.popups.cancelSub.confirmbtn')}
            </Button>
          </div>
        ) : (
          <div
            style={{
              padding: '1.5em',
            }}
          >
            <Box>
              <Typography
                variant="body1"
                style={{ fontSize: '24px', fontWeight: 700 }}
              >
                {translate('subscriptions_plans.popups.cancelSub.title')}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontSize: '18px', fontWeight: 600 }}
              >
                {translate('subscriptions_plans.popups.cancelSub.subtitle')}
              </Typography>
            </Box>

            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '30px',
              }}
            >
              <Button
                variant="contained"
                onClick={onClose}
                style={{ marginRight: '15px' }}
                color="primary"
              >
                {translate('subscriptions_plans.popups.cancelSub.cancelbtn')}
              </Button>
              <Button
                variant="text"
                onClick={handleConfirm}
                style={{ color: '#8F3FE6' }}
              >
                {translate('subscriptions_plans.popups.cancelSub.continuebtn')}
              </Button>
            </Box>
          </div>
        )}
      </Box>
    </Modal>
  );
}
