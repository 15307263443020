import {
  STATS_FETCH_LOADING,
  STATS_FETCH_SUCCEDED,
  STATS_FETCH_FAILED,
  FLUSH_STATS,
} from '../config';

const initialState = {
  statsLoading: true,
  data: {},
  message: null,
};

export default (previousState = initialState, { type, payload }: any) => {
  switch (type) {
    case STATS_FETCH_LOADING:
      return { ...previousState, statsLoading: true };
    case STATS_FETCH_SUCCEDED:
      return {
        ...previousState,
        statsLoading: false,
        data: { ...previousState.data, ...payload },
      };
    case STATS_FETCH_FAILED:
      return { ...previousState, statsLoading: false, message: payload };
    case FLUSH_STATS:
      return initialState;
    default:
      return previousState;
  }
};
