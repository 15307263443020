import React, { useState } from "react";
import { useListContext } from "react-admin";
import { Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { useTranslate } from 'ra-core';
import CustomSearch from "./CustomSearch";
import RangePicker from "./RangePicker";
import FilterAppliedLabel from "./FilterAppliedLabel";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between"
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  rangesContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-10px'
  },
  button: {
    marginLeft: 10
  }
}))

interface rangeProps {
  from: string
  to: string
}

const CustomFilters = () => {
  const defaultDatetimeRange = {
    from: '1970-01-01T00:00',
    to: new Date().toISOString().slice(0, -13).concat('23:59')
  }
  const { setFilters, displayedFilters } = useListContext();
  const [datetimeRange, setDatetimeRange] = useState<rangeProps>(defaultDatetimeRange);
  const [datetimeFilterApplied, setDatetimeFilterApplied] = useState<null | rangeProps>(null)
  const translate = useTranslate();
  const classes = useStyles();

  const setCustomFilters = (labelRemoved?:string) => {
    let filters = {}

    const setRangeFilter = (
      range: rangeProps,
      setRange: React.Dispatch<React.SetStateAction<rangeProps>>,
      setRangeApplied: React.Dispatch<React.SetStateAction<null | rangeProps>>,
      filterName: string,
      removedLabel: boolean,
      defaultRange: rangeProps
    ) => {
      if (removedLabel ||
        ((range.from === defaultRange.from || !range.from) && 
        (range.to === defaultRange.to || !range.to))
      ) {
        setRangeApplied(null);
        setRange(defaultRange);
        return;
      }
      
      const rangeToApply = {
        from: range.from || defaultRange.from,
        to: range.to || defaultRange.to
      };

      setRange(rangeToApply);
      setRangeApplied(rangeToApply);
      filters = {
        ...filters,
        [filterName]: rangeToApply
      };
    };

    setRangeFilter(
      datetimeRange,
      setDatetimeRange,
      setDatetimeFilterApplied,
      "assigned_at",
      labelRemoved === 'datetimeFilterRemoved',
      defaultDatetimeRange
    );

    setFilters(filters, displayedFilters);
  }

  return (
    <div className={classes.root}>
      <CustomSearch setDatetimeFilterApplied={setDatetimeFilterApplied} />
      <div className={classes.container}>
        {datetimeFilterApplied &&
          <FilterAppliedLabel
            filterText={translate('logisticPrint.dateLabel')}
            removeFilter={setCustomFilters}
            removeString={'datetimeFilterRemoved'}
            setFilterApplied={setDatetimeFilterApplied}
          />
        }
        <div className={classes.rangesContainer}>
          <RangePicker
            textFieldType={'datetime-local'}
            range={datetimeRange}
            setRange={setDatetimeRange}
          />
        </div>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={() => setCustomFilters()}
        >
          {translate('logisticPrint.searchButton')}
        </Button>
      </div>
    </div>
  )
}

export default CustomFilters;
