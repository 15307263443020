export default `<P>
Recopilamos, almacenamos, mensajes de saludo (en forma de videos,
imágenes y texto) y direcciones de correo electrónico. Lo hacemos
en nombre de nuestros clientes (marcas reconocidas y pequeñas
tiendas) en beneficio de sus clientes que utilizan nuestra solución.</P>
<P>
Por supuesto, nos preocupamos profundamente por la privacidad de sus
datos y cumplimos con la última legislación RGPD.</P>
<P>
Aquí hay una breve descripción de los procedimientos que tenemos en
marcha para asegurar que sus datos son protegidos como deben ser.</P>
<P>
<B>Lo que recogemos</B>
</P>
<P>
El mínimo requerido para que la experiencia de StoryDots funcione de
manera correcta es un mensaje de vídeo, un nombre y una dirección
de correo electrónico de la persona que graba el mensaje. Además,
si el destinatario decide responder con una selfie, le solicitamos
los mismos datos para poder entregarla.
</P>
<P>
En base a la operación y las distintas necesidades o requerimientos
de nuestros clientes, podríamos ocasionalmente, pedir algunos datos
extra. Además, la aceptación de nuestros términos de uso es, por
supuesto, necesaria para que la experiencia de StoryDots funcione.</P>
<P>
<B>Por qué</B>
</P>
<P>
Como se ha dicho antes, necesitamos como mínimo el nombre y el
correo electrónico de la persona que creó el mensaje por 3 razones:</P>
<P>
1- Personalizar la experiencia con el nombre de quien la envía.
</P>
<P>
2- Brindar la posibilidad de grabar de nuevo el mensaje de ser
necesario.</P>
<P>
3- Permitir que el beneficiario responda también con una experiencia
personalizada.</P>
<P>
4- Notificarle a quien mandó el primer saludo, cuando le responden.</P>

<P>
<B>Qué hacemos con los datos que recogemos</B>
</P>
<P>
Analizamos los datos para obtener perfiles de consumo siempre con la
idea de mejorar la calidad de la publicidad que reciben y ofrecer lo
mejor para ellos en el momento adecuado, compartiendo esos elementos
de datos únicamente con nuestros clientes con fines analíticos y
publicitarios.</P>
<P>
Ocasionalmente, capturamos y estudiamos las sesiones de los usuarios
para entender cómo se percibe y se utiliza nuestra interfaz, de modo
que siempre podamos mejorarla.
</P>
<P>
<B>Lo que no hacemos</B>
</P>
<P>
No compartimos ni mostramos el contenido de los mensajes creados.
Cada mensaje es único y sólo se puede acceder a él a través del
QR o del enlace para compartir.</P>
<P>
No revendemos los datos que recogemos. Nuestros clientes tienen
acceso a través de nuestra plataforma desde donde pueden además ver
estadísticas y configurar anuncios.</P>
<P>
No enviamos boletines de noticias. Sabemos lo molesto que resulta y
nuestro objetivo se trata de lo contrario, mejorar la calidad de lo
que reciben.</P>
<P><B>¿Dónde
    se guardan los datos?</B></P>
<P>Nuestra plataforma
está actualmente alojada en Amazon Web Services
(https://aws.amazon.com/) y junto con sus respectivos datos, contando
con los mejores estándares de seguridad.</P>
<P><BR>
</P>
<P><B>Solicitar
    la baja de sus datos</B></P>
<P>Los vídeos se
almacenan en la nube y pueden ser vistos durante un mes (comenzando a
correr este tiempo desde que se graba), una vez alcanzado este
periodo, se borran totalmente sin quedar registros del mismo y
resultando irrecuperables.</P>
<P>Como usuario (no
cliente) de nuestro servicio, puede enviar un correo electrónico a
support@storydots.app y solicitar la eliminación de sus
datos.<BR>StoryDots se reserva el derecho de solicitar pruebas de
identidad previamente.</P>`