import React, { useEffect } from 'react';
import { useRedirect, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import StatCard from '../../../components/StatCard';
import PageDivider from '../../../components/PageDivider';
import { Equalizer as StatsIcon, Group as UserIcon, AllInclusive } from '@material-ui/icons';
import {
  LastTagsTable,
  LastUsersTable,
  LastTagsList,
  LastUsersList,
} from './Tables';
import QRIcon from '../../../img/QRIcon';
import { Grid, SvgIcon, useMediaQuery } from '@material-ui/core';
import config, { FETCH_HOME, FETCH_STATS, SUPERADMIN } from '../../../config';
import axios from 'axios';

const HomeScreen: React.FC = () => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const isSmall = useMediaQuery('(max-width:1400px)');
  //@ts-ignore
  const statsState = useSelector((state) => state.stats);
  //@ts-ignore
  const homeState = useSelector((state) => state.home);
  //@ts-ignore
  const token = useSelector((state) => state.auth?.token);
  //@ts-ignore
  const clientId = useSelector((state) => state.auth?.decoded?.clientID);

  const isSuperAdmin = useSelector((state: ReduxState) => state.auth?.decoded.role === SUPERADMIN);

  useEffect(() => {
    if (token) {
      dispatch({ type: FETCH_STATS });
    }
    if(token && clientId && !isSuperAdmin) fetchClient();
  }, [dispatch, token, clientId]);

  const fetchClient = async () => {
    try {
      if (localStorage.getItem('r')) {
        localStorage.removeItem('r');
        return;
      }
      const result = await axios.get(`${config.AWS_API_SITE}/client`, {
        params: { clientID: clientId },
        headers: { Authorization: `Bearer ${token}` },
      });
      const storedFeatures = localStorage.getItem('f') || null;
      if (storedFeatures === null && result.data.features === null) return;
      if (storedFeatures !== JSON.stringify(result.data.features)) {
        localStorage.setItem(
          'f',
          result.data.features ? JSON.stringify(result.data.features) : '',
        );
        localStorage.setItem('r', 'true');
        window.location.reload();
      }
    } catch (e) {
      console.error('error fetching data');
      localStorage.removeItem('f');
    }
  };

  return (
    <>
      <Grid container direction="row" spacing={4} style={{ marginTop: 16 }}>
        <Grid item xs={12} md={4}>
          <StatCard
            color="primary"
            title={translate('dashboard.stat_cards.stock.title')}
            number={statsState.data?.stockLeft > 100 ? <AllInclusive /> : statsState.data?.stockLeft}
            isLoading={statsState.statsLoading}
            icon={
              <SvgIcon>
                <QRIcon />
              </SvgIcon>
            }
            action={{
              title: translate('dashboard.stat_cards.stock.footer_link'),
              callback: () => redirect('/support'),
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard
            color="secondary"
            title={translate('dashboard.stat_cards.redirections.title')}
            number={statsState.data?.redirectionsThisMonth}
            isLoading={statsState.statsLoading}
            icon={<StatsIcon />}
            action={{
              title: translate('dashboard.stat_cards.redirections.footer_link'),
              callback: () => redirect('list', '/senders'),
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard
            color="tertiary"
            title={translate('dashboard.stat_cards.users.title')}
            number={statsState.data?.users}
            isLoading={statsState.statsLoading}
            icon={<UserIcon />}
            action={{
              title: translate('dashboard.stat_cards.users.footer_link'),
              callback: () => redirect('list', '/senders'),
            }}
          />
        </Grid>
      </Grid>
      <PageDivider />
      <Grid
        container
        direction="row"
        justify="space-between"
        spacing={4}
        alignItems="stretch"
      >
        <Grid item xs={12} lg={6}>
          {isSmall ? (
            <LastTagsList
              rows={homeState.data?.lastTags}
              isLoading={homeState.lastTagsLoading}
            />
          ) : (
            <LastTagsTable
              rows={homeState.data?.lastTags}
              isLoading={homeState.lastTagsLoading}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {isSmall ? (
            <LastUsersList
              rows={homeState.data?.lastUsers}
              isLoading={homeState.lastUsersLoading}
            />
          ) : (
            <LastUsersTable
              rows={homeState.data?.lastUsers}
              isLoading={homeState.lastUsersLoading}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default HomeScreen;
