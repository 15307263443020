import React, { useContext, useState } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { OnboardingContext } from '../context/OnboardingContext';
import Tooltip from './Tooltip';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

function OnboardingStepper () {
  const translate = useTranslate();
  const { slideN, palette, styles, stepper } = useContext(OnboardingContext);
  const [slideToShow, setSlideToShow] = slideN;
  const { primary } = palette;
  const onboardingStyles = styles;
  const { manrope, primaryDarkBG, pAbsolute, w100, mtAuto, mbAuto, mrAuto, mlAuto} = onboardingStyles();
  const [activeStep, setActiveStep] = stepper.active;
  const [introCompleted, setIntroCompleted] = stepper.intro;
  const [integrationCompleted, setIntegrationCompleted] = stepper.integ;
  const [printCompleted, setPrintCompleted] = stepper.print; // eslint-disable-line @typescript-eslint/no-unused-vars
  const [displayTooltip0, setDisplayTooltip0] = useState("none");
  const [displayTooltip1, setDisplayTooltip1] = useState("none");
  const [displayTooltip2, setDisplayTooltip2] = useState("none");
  const steps = [
    {
      title: translate('onboarding.stepper.0.title'),
      subtitle: translate('onboarding.stepper.0.subtitle'),
      completed: introCompleted,
      onClick: () => {
        setSlideToShow(1); // FIXED SLIDE NUMBER
        setActiveStep(0);
      },
      tooltipText: translate('onboarding.stepper.0.tooltip'),
      tooltipLeft: "36.4583vw",
      tooltipDisplay: displayTooltip0,
      onMouseEnter: () => setDisplayTooltip0("flex"),
      onMouseLeave: () => setDisplayTooltip0("none")
    }, {
      title: translate('onboarding.stepper.1.title'),
      subtitle: translate('onboarding.stepper.1.subtitle'),
      completed: integrationCompleted,
      onClick: () => {
        setSlideToShow(4); // FIXED SLIDE NUMBER
        setActiveStep(1);
        if (slideToShow === 3) setIntroCompleted(true);
      },
      tooltipText: translate('onboarding.stepper.1.tooltip'),
      tooltipLeft: "46.875vw",
      tooltipDisplay: displayTooltip1,
      onMouseEnter: () => setDisplayTooltip1("flex"),
      onMouseLeave: () => setDisplayTooltip1("none")
    }, {
      title: translate('onboarding.stepper.2.title'),
      subtitle: translate('onboarding.stepper.2.subtitle'),
      completed: printCompleted,
      onClick: () => {
        setSlideToShow(10); // FIXED SLIDE NUMBER
        setActiveStep(2);
        if (slideToShow === 9) setIntegrationCompleted(true);
      },
      tooltipText: translate('onboarding.stepper.2.tooltip'),
      tooltipLeft: "57.2917vw",
      tooltipDisplay: displayTooltip2,
      onMouseEnter: () => setDisplayTooltip2("flex"),
      onMouseLeave: () => setDisplayTooltip2("none")
    }
  ];

  const useStepLabelStyles = makeStyles({
    label: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      textAlign: "left",
      color: "white",
      opacity: "0.4",
      cursor: "pointer",
      "&$completed": {
        color: "white",
        opacity: "0.8"
      },
      "&:hover": {
        color: "white",
        opacity: "0.9"
      },
      "&$active": {
        color: "white",
        opacity: "1"
      }
    },
    active: {},
    completed: {}
  });
  const stepLabelClasses = useStepLabelStyles();
  const { label, active, completed } = stepLabelClasses;

  const useStepIconStyles = makeStyles({
    root: {
      height: "1.6vw",
      cursor: "pointer",
      "&$active": {
        color: `${primary.light}`,
      },
      "&$completed": {
        color: "#4EBC9B"
      }
    },
    active: {},
    completed: {}
  });
  const stepIconClasses = useStepIconStyles();

  return (
    <div
      className={[manrope, pAbsolute, w100, mtAuto, mbAuto].concat().join(' ')} 
      style={{zIndex: "6", top: "42.34375vw", bottom: "4.01vw", maxHeight: "3.125vw"}}
    >
      <Stepper
        activeStep={activeStep}
        className={[primaryDarkBG, mrAuto, mlAuto].join(' ')}
        style={{width: "30.125vw", height: "3.125vw", padding: "0.83vw 1.04vw", borderRadius: "2.03125vw"}}>
        {steps.map((step) => {
          return (
            <Step
              onClick={step.onClick}
              onMouseEnter={step.onMouseEnter}
              onMouseLeave={step.onMouseLeave}
              key={step.title}
              completed={step.completed}
            >
              <Tooltip
                text={step.tooltipText}
                top={'-2.5vw'}
                left={step.tooltipLeft}
                display={step.tooltipDisplay}
              />
              <StepLabel
                classes={{label, active, completed}}
                StepIconProps={{ classes: {root: stepIconClasses.root, active: stepIconClasses.active, completed: stepIconClasses.completed} }}
              >
                <p style={{margin: "0", fontSize: "0.83vw"}}>{step.title}</p>
                <p style={{margin: "0", fontSize: "0.625vw", whiteSpace: "nowrap"}}>{step.subtitle}</p>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

export default OnboardingStepper;
