// VENDOR
import _get from 'lodash/get';
import { useTranslate } from 'react-admin';
import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import axios, { AxiosError, AxiosResponse } from 'axios';
// INTERNAL
import useDebounce from '../../../Utils/hooks/useDebounce';
import config from '../../../config';
import { useSelector } from 'react-redux';



// I can't use useFetch inside an useEffect call, so I had to make this helper

const searchClients = async (
  search: string,
  token: string,
): Promise<{
  response: AxiosResponse<ClientResource[]> | {};
  error: any;
}> => {
  let response = {};
  let error = false;
  try {
    response = await axios.get(`${config.AWS_API_SITE}/clients`, {
      params: {
        limit: 10,
        offset: 0,
        sort: `["created_at","DESC"]`,
        filter: { store_name: search },
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err: any) {
    error = err;
  } finally {
    return { response, error };
  }
};

export const useClientAutocomplete = () => {
    const translate = useTranslate();
    const { control, errors } = useFormContext();
    const [open, setOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>();
    const [options, setOptions] = useState<any[]>([]);
    const [search, setSearch] = useState<string>('');
    const token = useSelector((state:ReduxState)=> state.auth?.token);
    const debouncedSearch = useDebounce(search,500);

    useEffect(()=>{
        const searchClientsAndSetOptions = async (search:string, token:string) => {
            setIsLoading(true);
            const { response, error } = await searchClients(search,token);
            if (error) {
                setError(error);
            }
            else setError(false)
            if ('data' in response) {
                setOptions(response.data)
            }
            setIsLoading(false);
        }
        if (debouncedSearch && token) {
            searchClientsAndSetOptions(debouncedSearch, token)
        }
    },[debouncedSearch, token]);

    // auto complete methods
    const togglePanel = () => setOpen(o => !o)
    const getOptionSelected = (option: any, value: any) => option.id === value.id;
    // inputChange is when an option gets selected
    const onInputChange = (data: any, onChange: any) => data && onChange(data);
    // textChange is when you write on the input
    const onTextChange = (newText: string) => setSearch(newText);
    const getOptionLabel = (option: any) => option.store_name || ''

    const hasError = _get(errors, 'client', false) || !!error;
    const helperText = _get(errors, 'client.message', '') || (error ? translate('ra.notification.dataprovider_error') : false)

    return {
        open,
        options,
        isLoading,
        control,
        hasError,
        helperText,
        translate,
        togglePanel,
        getOptionSelected,
        onInputChange,
        onTextChange,
        getOptionLabel
    }
}