import firebase from 'firebase/compat/app';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback } from 'react';

const useCustomLogin = (): CustomLogin => {
  const location = useLocation();
  const locationState = location.state as any;
  const history = useHistory();
  const nextPathName = locationState && locationState.nextPathname;
  if (nextPathName) window.localStorage.setItem('nextPathName', nextPathName);
  localStorage.removeItem('auth');

  return useCallback(
    (customToken: string, pathName) =>
      firebase
        .auth()
        .signInWithCustomToken(customToken)
        .then((ret) => {
          // pathName = window.localStorage.getItem('nextPathName') || undefined
          // window.localStorage.removeItem('nextPathName')
          history.push(pathName || '/');
          return ret;
        }),
    [history],
  );
};
type CustomLogin = (customToken: string, pathName?: string) => Promise<any>;

export default useCustomLogin;
