import React, { useEffect, useState } from 'react';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { IconButton, Icon, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  icon: {
    transition: 'all .5s cubic-bezier(0,.8,.35,1.32)',
  },
}));
interface IProps {
  setToggleCard: (arg:boolean) => void,
  errorListener?: boolean,
  toggleCard?: boolean
}

const ArrowChevron: React.FC<IProps> = ({setToggleCard, toggleCard, errorListener = false}) => {
  const classes = useStyles();

  

  useEffect(()=>{
      errorListener && toggleCard === false && setToggleCard(true);
  },[errorListener])

  return (
    <IconButton
      className={classes.icon}
      
      color={toggleCard ? 'primary' : 'default'}
      style={{ transform: toggleCard ? 'rotate(90deg)' : 'rotate(0deg)' }}
    >
      <ArrowForwardIosRoundedIcon />
    </IconButton>
  );
};
export default ArrowChevron;
