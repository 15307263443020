// VENDOR
import React, { useEffect, useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import {
  Card,
  CardContent,
  Button,
  makeStyles,
  CircularProgress,
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import PageTitle from '../../components/PageTitle';
import { useTranslate, useNotify, ReduxState } from 'react-admin';
import PageContainer from '../../components/PageContainer';
import { useForm, FormProvider } from 'react-hook-form';
import config from '../../config';
import { DEFAULT_COORDINATES } from '../../config/constants';
import PrintIcon from '@material-ui/icons/Print';
import axios from 'axios';
import PageDivider from '../../components/PageDivider';
import templateReceiverDefault from './mockTemplate/tag_es.png';
import templateReceiverDefaultEn from './mockTemplate/tag_en.png';
import templateSenderDefault from './mockTemplate/tag_sender_es.png';
import templateSenderDefaultEn from './mockTemplate/tag_sender_en.png';
import './print.css';
import { useDispatch, useSelector } from 'react-redux';
import { CHECK_ROUTE_SUPERADMIN } from '../../config';
import ClientAutocomplete from './ClientAutocomplete';
import BatchAutocomplete from './BatchAutocomplete';
import CuttingLinesInfoWrapper from './CuttingLinesInfoWrapper';
import PaperSelect from './PaperSelect';
import { downloadTxt, parseURLToTxt } from './utils';

type TemplateCoords = { width: number };

type Coords = { top: number; left: number; width: number };

type QRCoords = { sender: Coords; receiver: Coords };

const useStyles = (
  coordinates: QRCoords,
  templateCoords: TemplateCoords,
  pageWidth: string,
) =>
  makeStyles(
    (theme) => ({
      form: {
        width: '100%',
      },
      input: {
        flexGrow: 1,
      },
      tag: {
        position: 'relative',
        width: templateCoords.width + 'cm',
        height: 'auto',
      },
      tagImage: {
        width: templateCoords.width + 'cm',
        height: 'auto',
      },
      qrReceiver: {
        position: 'absolute',
        top: coordinates.receiver.top + 'cm',
        left: coordinates.receiver.left + 'cm',
        width: coordinates.receiver.width + 'cm',
        height: coordinates.receiver.width + 'cm',
        textAlign: 'center',
        fontFamily: 'roboto',
        fontSize: 15,
      },
      qrSender: {
        position: 'absolute',
        top: coordinates.sender.top + 'cm',
        left: coordinates.sender.left + 'cm',
        width: coordinates.sender.width + 'cm',
        height: coordinates.sender.width + 'cm',
        textAlign: 'center',
        fontFamily: 'roboto',
        fontSize: 15,
      },
      printZoneContainer: {
        position: 'relative',
        overflow: 'auto',
        width: 0,
        minWidth: '100%',
        maxHeight: '80vh',
        backgroundColor: 'gray',
        textAlign: 'center',
      },
      printZone: {
        textAlign: 'left',
        position: 'relative',
        //a4 paper width
        width: pageWidth,
        minWidth: pageWidth,
        overflow: 'hidden',
        //padding: '2.5cm',
        backgroundColor: 'white',
        margin: 'auto',
      },
      printButton: {
        position: 'absolute',
        top: 20,
        right: 15,
      },
      gridButton: {
        display: 'flex',
        justifyContent: 'space-around',
        maxHeight: '75px',
      },
    }),
    { name: 'RaLoginForm' },
  );

const Print: React.FC = () => {
  const dispatch = useDispatch();
  const token = useSelector((state: ReduxState) => state.auth?.token || '');
  const translate = useTranslate();
  const methods = useForm<{ client: any; batch: string; paperSize: string }>({
    defaultValues: {
      client: {},
      batch: '',
      paperSize: '21cm',
    },
  });
  const [isLoading, setLoadingState] = useState(false);
  const [tags, setTagsState] = useState<TagResource[] | null>(null);
  const [tagsTxt, setTxtTags] = useState<string | null>();
  const notify = useNotify();
  const [receiverCoordinates, setReceiverCoordinates] = useState<Coords>({
    ...DEFAULT_COORDINATES.receiverTag,
  });
  const [senderCoordinates, setSenderCoordinates] = useState<Coords>({
    ...DEFAULT_COORDINATES.senderTag,
  });
  const [templateCoords, setTemplateCoords] = useState<TemplateCoords>({
    width: 5.65,
  });
  const [templateReceiverSrc, setTemplateReceiverSrc] = useState<string>(
    templateReceiverDefault,
  );
  const [templateSenderSrc, setTemplateSenderSrc] = useState<string>(
    templateSenderDefault,
  );
  const [clientName, setClientName] = useState('-');
  const classes = useStyles(
    { sender: senderCoordinates, receiver: receiverCoordinates },
    templateCoords,
    methods.watch('paperSize').split(' ')[0],
  )();
  const componentRef = useRef(null);
  const [isPrintLoading, setPrintLoading] = useState(false);
  const [cuttingLines, setCuttingLines] = useState(true);
  const [codeIndexRef, setCodeIndexRef] = useState(true);
  const [tagSpacing, setTagSpacing] = useState(true);
  const [showSenderTag, setShowSenderTag] = useState(true);
  const [swapTagOrder, setSwapTagOrder] = useState(false);
  const [printPageMargin, setPrintPageMargin] = useState(0);
  const onSubmit = methods.handleSubmit(async ({ client, batch }) => {
    //Reset default coordinates and templates sources before fetching new client
    setTemplateReceiverSrc(templateReceiverDefault);
    setTemplateSenderSrc(templateSenderDefault);
    setReceiverCoordinates({ ...DEFAULT_COORDINATES.receiverTag });
    setSenderCoordinates({ ...DEFAULT_COORDINATES.senderTag });

    setLoadingState(true);
    setTagsState(null);
    console.log(client);
    try {
      const clientResponse = await axios.get(`${config.AWS_API_SITE}/client`, {
        params: { clientID: client.id },
        headers: { Authorization: `Bearer ${token}` },
      });
      const {
        tag_config,
        tag_template_url,
        sender_tag_config,
        sender_tag_template_url,
        tag_custom,
        sender_tag_custom,
        store_name,
        store_language,
      } = clientResponse.data;

      console.log(sender_tag_custom);

      if (store_name) setClientName(store_name);

      if (store_language === 'en') {
        setTemplateReceiverSrc(templateReceiverDefaultEn);
        setTemplateSenderSrc(templateSenderDefaultEn);
      }

      if (tag_custom) {
        const { width, left, top, tagWidth } = tag_config;
        setReceiverCoordinates({
          width: width || DEFAULT_COORDINATES.receiverTag.width,
          left: left || DEFAULT_COORDINATES.receiverTag.left,
          top: top || DEFAULT_COORDINATES.receiverTag.top,
        });
        setTemplateCoords({ width: tagWidth });
        setTemplateReceiverSrc(tag_template_url);
      }

      if (sender_tag_custom) {
        const { width, left, top, tagWidth } = sender_tag_config;
        setSenderCoordinates({
          width: width || DEFAULT_COORDINATES.senderTag.width,
          left: left || DEFAULT_COORDINATES.senderTag.left,
          top: top || DEFAULT_COORDINATES.senderTag.top,
        });
        setTemplateCoords({ width: Math.max(tagWidth, templateCoords.width) });
        setTemplateSenderSrc(sender_tag_template_url);
      }

      const tagsResponse = await axios.get<TagResource[]>(
        `${config.AWS_API_SITE}/qrBatch`,
        {
          params: { clientID: client.id, batch },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setTagsState(tagsResponse.data);
      setTxtTags(parseURLToTxt(tagsResponse.data));
    } catch (err) {
      console.error(err);
      notify('Error ');
    } finally {
      setLoadingState(false);
    }
  });

  useEffect(() => {
    dispatch({ type: CHECK_ROUTE_SUPERADMIN });
  }, [dispatch]);

  const disabled =
    !Boolean(methods.watch('client').id) ||
    !Boolean(methods.watch('batch')) ||
    isLoading;
  return (
    <PageContainer>
      <PageTitle
        title={translate('print.title')}
        icon={<PrintIcon />}
        subtitle={translate('print.subtitle')}
      />
      <Card>
        <CardContent>
          <FormProvider {...methods}>
            <form onSubmit={onSubmit} className={classes.form}>
              <Grid
                container
                direction="row"
                justify="space-between"
                spacing={3}
              >
                <Grid item xs={12} lg={3} md={6}>
                  <ClientAutocomplete className={classes.input} />
                </Grid>
                <Grid item xs={12} lg={3} md={6}>
                  <BatchAutocomplete
                    className={classes.input}
                    clientID={methods.watch('client').id || ''}
                  />
                </Grid>
                <Grid item xs={12} lg={3} md={12}>
                  <PaperSelect className={classes.input} />
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={3}
                  md={12}
                  className={classes.gridButton}
                >
                  {tagsTxt && (
                    <Button
                      style={{ marginRight: '4px' }}
                      variant="contained"
                      onClick={() => downloadTxt(tagsTxt)}
                    >
                      TXT
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={disabled}
                    size="large"
                    startIcon={
                      isLoading ? <CircularProgress size="1rem" /> : undefined
                    }
                  >
                    {translate('print.form.submit')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          {tags && tags.length && (
            <Grid
              container
              style={{ marginTop: '16px' }}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={12} lg={3}>
                <TextField
                  error={printPageMargin < 0}
                  variant="outlined"
                  type="number"
                  onChange={(event) =>
                    setPrintPageMargin(Number(event.target.value))
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">mm</InputAdornment>
                    ),
                  }}
                  label={translate('print.form.page_margin')}
                />
              </Grid>
              <Grid container>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      onChange={() => setCodeIndexRef((prev) => !prev)}
                    />
                  }
                  label={translate('print.form.code_index_ref')}
                />
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      onChange={() => setTagSpacing((prev) => !prev)}
                    />
                  }
                  label={translate('print.form.tag_spacing')}
                />
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      name="show-sender-tag"
                      onChange={() =>
                        setShowSenderTag((prev) => {
                          /**
                           * preset layout with cutting lines if
                           * showing sender tag too. 
                           */
                          if (prev === false) {
                            setCuttingLines(true);
                            return !prev;
                          }
                          setCuttingLines(false);
                          setSwapTagOrder(false);
                          return !prev;
                        })
                      }
                    />
                  }
                  label={translate('print.form.sender_tag')}
                />
                {showSenderTag && (
                  <>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked
                          value={cuttingLines}
                          onChange={(e) => setCuttingLines((prev) => !prev)}
                        />
                      }
                      label={translate('print.form.cutting_lines')}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          name="tag-order"
                          value={swapTagOrder}
                          onChange={() => setSwapTagOrder((prev) => !prev)}
                        />
                      }
                      label="Invertir orden de tags"
                    />
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      {tags && tags.length > 0 && (
        <>
          <PageDivider small />
          <Card style={{ position: 'relative' }}>
            <CardContent className={classes.printZoneContainer}>
              <div
                className={classes.printZone}
                style={{ padding: `${printPageMargin}mm` }}
                id="section-to-print"
                ref={componentRef}
              >
                {tags.map((tag: TagResource) => (
                  <div style={{ display: 'inline-block' }}>
                    <CuttingLinesInfoWrapper
                      swapOrder={swapTagOrder}
                      tagSpacing={tagSpacing}
                      showSenderTag={showSenderTag}
                      codeIndexRefs={codeIndexRef}
                      cuttingLines={cuttingLines}
                      clientURL={tag.url + '?a=r&source=wa_campaign'}
                      contentWidth={templateCoords.width}
                      index={tag.code_index}
                      tagSender={
                        <div className={classes.tag}>
                          <img
                            src={`${templateSenderSrc}?no_cache${performance.now()}`}
                            className={classes.tagImage}
                            alt="Tag dtemplate"
                          />
                          <img
                            className={classes.qrSender}
                            src={`${config.AWS_API_SITE}/qrImage/${tag.code}?sender=true`}
                            alt={translate('print.sheet.alt')}
                          />
                        </div>
                      }
                      tagReceiver={
                        <div className={classes.tag}>
                          <img
                            src={`${templateReceiverSrc}?no_cache${performance.now()}`}
                            className={classes.tagImage}
                            alt="Tag template"
                          />
                          <img
                            className={classes.qrReceiver}
                            src={`${config.AWS_API_SITE}/qrImage/${tag.code}`}
                            alt={translate('print.sheet.alt')}
                          />
                        </div>
                      }
                    />
                  </div>
                ))}
              </div>
            </CardContent>
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isPrintLoading}
                  startIcon={
                    isPrintLoading ? (
                      <CircularProgress size="1rem" />
                    ) : (
                      <PrintIcon />
                    )
                  }
                  className={classes.printButton}
                >
                  {translate('print.button')}
                </Button>
              )}
              content={() => componentRef.current}
              onBeforeGetContent={() => setPrintLoading(true)}
              onBeforePrint={() => setPrintLoading(false)}
              documentTitle={translate('print.document_title')}
              pageStyle={`@page {
                  margin: ${printPageMargin}mm !important;
                  size: ${methods.watch('paperSize')}
                }
                `}
            />
          </Card>
        </>
      )}
    </PageContainer>
  );
};

export default Print;
