import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslate } from 'react-admin';
import { OnboardingContext } from '../context/OnboardingContext';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import storydotsLogo from '../../../img/onboardingAssets/SD-logo-white.svg';
import carouselSlides from '../../../img/onboardingAssets/carousel/index';
import useSendGTMEvent from "Utils/hooks/useSendGTMEvent";

function ThanksForJoining () {
  const translate = useTranslate();
  const lang = translate('onboarding.language')
  const { slideN, styles } = useContext(OnboardingContext);
  const [slideToShow, setSlideToShow] = slideN; // eslint-disable-line @typescript-eslint/no-unused-vars
  const onboardingStyles = styles;
  const { manrope, whiteBG, greenGradientBG, pAbsolute, pRelative, w100, h100, mh100vh, m0, mr5, mb1, ml5, mtAuto, mrAuto, mbAuto, mlAuto, dFlex, column, alignCenter, justifyCenter, radiusSm, radiusLg, textWhite, textCenter, hiddenOverflow, paperShadow, noBorder, uppercase, pointer} = onboardingStyles();
  const { carouselWomen, carouselMen, carouselBabies, carouselWomenPT, carouselMenPT, carouselBabiesPT } = carouselSlides;
  let carouselImages = [carouselBabies, carouselWomen, carouselMen, carouselBabies, carouselWomen];
  if (lang === "PT") carouselImages = [carouselBabiesPT, carouselWomenPT, carouselMenPT, carouselBabiesPT, carouselWomenPT];
  const slidesPositions = [
    "translateX(0)",
    "translateX(-100%)",
    "translateX(-200%)",
    "translateX(-300%)",
    "translateX(-400%)",
  ];
  const transformTransition = "transform 1.7s ease-in-out"; // This value is related to goAroundCarousel function timeout
  const [carouselTranslate, setCarouselTranslate] = useState(slidesPositions[1]);
  const [carouselTransition, setCarouselTransition] = useState(transformTransition);
  const restartCarouselInterval = useRef(0);

  const goAroundCarousel = (rightOrLeft:string) => {
    let temporarySlide = slidesPositions[0];
    let targetSlide = slidesPositions[slidesPositions.length - 2];
    if (rightOrLeft === 'right') {
      temporarySlide = slidesPositions[slidesPositions.length - 1];
      targetSlide = slidesPositions[1];
    };

    setCarouselTranslate(temporarySlide);
    setTimeout(() => {
      setCarouselTransition("unset");
      setCarouselTranslate(targetSlide);
      setTimeout(() => {setCarouselTransition(transformTransition)}, 10);
    }, 1710); // This value is related to transformTransitions variable
  };

  useSendGTMEvent("onboarding start");

  useEffect(() => {
    const setCarouselInterval = () => {
      let carouselSlideN = slidesPositions.indexOf(carouselTranslate, 0);

      return setInterval(() => {
        carouselSlideN++;
        if (carouselSlideN > slidesPositions.length - 2) {
          goAroundCarousel('right');
          carouselSlideN = 1;
          return;
        };
        setCarouselTranslate(slidesPositions[carouselSlideN]);
      }, 7000);
    };

    let automaticCarousel = setCarouselInterval();

    return () => clearInterval(automaticCarousel);
  }, [restartCarouselInterval.current]); // eslint-disable-line

  const arrowClick = (rightOrLeft:string) => {
    const sliderAt = slidesPositions.indexOf(carouselTranslate, 0);

    restartCarouselInterval.current++;

    if (rightOrLeft === 'right') {
      if (sliderAt === slidesPositions.length - 2) return goAroundCarousel(rightOrLeft);
      return setCarouselTranslate(slidesPositions[sliderAt + 1]);
    }

    if (sliderAt === 1) return goAroundCarousel(rightOrLeft);
    setCarouselTranslate(slidesPositions[sliderAt - 1]);
  };

  const handleCarouselLeft = () => arrowClick('left');

  const handleCarouselRight = () => arrowClick('right');
  
  const handleGoToTutorial = () => setSlideToShow((prev:number) => prev + 1);

  return (
    <div
      className={[manrope, pRelative, w100, h100, mh100vh, dFlex, column, textWhite, textCenter, mbAuto].join(' ')}
      style={{height: "49.48vw", paddingTop: "3vw", paddingBottom: "7.7vw"}}
    >
      <img
        src={storydotsLogo}
        alt="Storydots logo"
        className={[pAbsolute].join(' ')}
        style={{width: "14vw", top: "3vw", left: "4vw"}}
      />
      <h1
        className={[mtAuto, mb1].join(' ')}
        style={{fontWeight: "600", fontSize: "1.66vw"}}
      >{translate('onboarding.thanksForJoining.title')}</h1>
      <h2
        className={[m0].join(' ')}
        style={{fontWeight: "400", fontSize: "1.25vw", marginBottom: "2.1vw"}}
      >{translate('onboarding.thanksForJoining.subtitle')}</h2>
      <div
        className={[w100, dFlex, alignCenter, justifyCenter, mrAuto, mlAuto].join(' ')}
        style={{marginBottom: "2.1vw"}}
      >
        <IconButton
          onClick={handleCarouselLeft}
          aria-label="carousel left"
          style={{color: "white"}}
        >
          <ChevronLeftIcon style={{fontSize: "5.2vw"}} />
        </IconButton>
        <div
          className={[whiteBG, mr5, ml5, radiusLg, paperShadow].join(' ')}
          style={{width: "62.3vw", height: "26.71875vw"}}
        >
          <div
            className={[whiteBG, w100, dFlex, radiusLg, hiddenOverflow].join(' ')}
          >
            {carouselImages.map((image) => {
              return(
                <img
                  src={image}
                  alt="store at carousel"
                  className={[w100].join(' ')}
                  style={{transform: `${carouselTranslate}`, transition: `${carouselTransition}`}}
                />
              )
            })}
          </div>
        </div>
        <IconButton
          onClick={handleCarouselRight}
          aria-label="carousel right"
          style={{color: "white"}}
        >
          <ChevronRightIcon style={{fontSize: "5.2vw"}} />
        </IconButton>
      </div>
      <div
        className={[mrAuto, mbAuto, mlAuto, radiusSm].join(' ')}
        style={{boxShadow: "0px 0px 3.9vw 0px rgba(255,255,255, 0.7)"}}
      >
        <Button
          onClick={handleGoToTutorial}
          className={[greenGradientBG, m0, radiusSm, noBorder, textWhite, uppercase, paperShadow, pointer].join(' ')}
          style={{width: "19.74vw", height: "3.125vw", fontSize: "1.15vw", fontWeight: "600", letterSpacing: "0.46px"}}
        >{translate('onboarding.thanksForJoining.button')}</Button>
      </div>
    </div>
  );
}

export default ThanksForJoining;
