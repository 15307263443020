import { makeStyles } from '@material-ui/core';
import onboardingPalette from './onboardingPalette';

const onboardingStyles:any = makeStyles((theme) => ({
  manrope: { fontFamily: "Manrope, sans-serif" },
  roboto: { fontFamily: "Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif" },
  primaryBG: { background: `${onboardingPalette.primary.main}` },
  primaryLightBG: { background: `${onboardingPalette.primary.light}` },
  primaryDarkBG: { background: `${onboardingPalette.primary.dark}` },
  //primaryGradientBG: { background: `linear-gradient(97.5deg, ${onboardingPalette.primary.light} 20.64%, ${onboardingPalette.primary.semiDark} 90.86%), #FFFFFF` },
  primaryGradientBG: { background: "white" },
  noRepeatBG: {backgroundRepeat: "no-repeat" },
  coverBG: {backgroundSize: "cover" },
  BGw100: {backgroundSize: "100%" },
  whiteBG: { background: "white" },
  shadowBG: { background: "rgba(0, 0, 0, 0.3)" },
  greenGradientBG: { background: "linear-gradient(100.89deg, #4EBC9B 20.3%, #08BA65 80.67%)" },
  pAbsolute: { position: "absolute" },
  pRelative: { position: "relative" },
  w100: { width: "100%" },
  h100: { height: "100%" },
  mh100vh: {maxHeight: "100vh !important"},
  m0: { margin: "0" },
  m1: { margin: "0.104vw" },
  m2: { margin: "0.208vw" },
  m3: { margin: "0.4167vw" },
  m4: { margin: "0.625vw" },
  m5: { margin: "0.9375vw" },
  m6: { margin: "1.25vw" },
  mt0: { marginTop: "0" },
  mt1: { marginTop: "0.104vw" },
  mt2: { marginTop: "0.208vw" },
  mt3: { marginTop: "0.4167vw" },
  mt4: { marginTop: "0.625vw" },
  mt5: { marginTop: "0.9375vw" },
  mt6: { marginTop: "1.25vw" },
  mt50: { marginTop: "2.604vw" },
  mr0: { marginRight: "0" },
  mr1: { marginRight: "0.104vw" },
  mr2: { marginRight: "0.208vw" },
  mr3: { marginRight: "0.4167vw" },
  mr4: { marginRight: "0.625vw" },
  mr5: { marginRight: "0.9375vw" },
  mr6: { marginRight: "1.25vw" },
  mb0: { marginBottom: "0" },
  mb1: { marginBottom: "0.104vw" },
  mb2: { marginBottom: "0.208vw" },
  mb3: { marginBottom: "0.4167vw" },
  mb4: { marginBottom: "0.625vw" },
  mb5: { marginBottom: "0.9375vw" },
  mb6: { marginBottom: "1.25vw" },
  ml0: { marginLeft: "0" },
  ml1: { marginLeft: "0.104vw" },
  ml2: { marginLeft: "0.208vw" },
  ml3: { marginLeft: "0.4167vw" },
  ml4: { marginLeft: "0.625vw" },
  ml5: { marginLeft: "0.9375vw" },
  ml6: { marginLeft: "1.25vw" },
  mAuto: { margin: "auto" },
  mtAuto: { marginTop: "auto" },
  mrAuto: { marginRight: "auto" },
  mbAuto: { marginBottom: "auto" },
  mlAuto: { marginLeft: "auto" },
  p0: { padding: "0" },
  p1: { padding: "0.104vw" },
  p2: { padding: "0.208vw" },
  p3: { padding: "0.4167vw" },
  p4: { padding: "0.625vw" },
  p5: { padding: "0.9375vw" },
  p6: { padding: "1.25vw" },
  pt0: { paddingTop: "0" },
  pt1: { paddingTop: "0.104vw" },
  pt2: { paddingTop: "0.208vw" },
  pt3: { paddingTop: "0.4167vw" },
  pt4: { paddingTop: "0.625vw" },
  pt5: { paddingTop: "0.9375vw" },
  pt6: { paddingTop: "1.25vw" },
  pr0: { paddingRight: "0" },
  pr1: { paddingRight: "0.104vw" },
  pr2: { paddingRight: "0.208vw" },
  pr3: { paddingRight: "0.4167vw" },
  pr4: { paddingRight: "0.625vw" },
  pr5: { paddingRight: "0.9375vw" },
  pr6: { paddingRight: "1.25vw" },
  pb0: { paddingBottom: "0" },
  pb1: { paddingBottom: "0.104vw" },
  pb2: { paddingBottom: "0.208vw" },
  pb3: { paddingBottom: "0.4167vw" },
  pb4: { paddingBottom: "0.625vw" },
  pb5: { paddingBottom: "0.9375vw" },
  pb6: { paddingBottom: "1.25vw" },
  pl0: { paddingLeft: "0" },
  pl1: { paddingLeft: "0.104vw" },
  pl2: { paddingLeft: "0.208vw" },
  pl3: { paddingLeft: "0.4167vw" },
  pl4: { paddingLeft: "0.625vw" },
  pl5: { paddingLeft: "0.9375vw" },
  pl6: { paddingLeft: "1.25vw" },
  paddingLg: {padding: "2.5vw" },
  dFlex: { display: "flex" },
  column: { flexDirection: "column" },
  alignCenter: { alignItems: "center" },
  alignEnd: { alignItems: "flex-end"},
  justifyCenter: { justifyContent: "center" },
  justifyAround: { justifyContent: "space-around" },
  justifyBetween: { justifyContent: "space-between" },
  justifyEvenly: { justifyContent: "space-evenly" },
  noBorder: { border: "none" },
  radiusSm: { borderRadius: "0.625vw" },
  radiusMd: { borderRadius: "0.99vw" },
  radiusLg: { borderRadius: "1.25vw" },
  radius50: { borderRadius: "50%" },
  textPrimary: { color: `${onboardingPalette.primary.main}` },
  textWhite: { color: "white" },
  textCenter: { textAlign: "center" },
  textJustify: { textAlign: "justify" },
  font18: { fontSize: "0.9375vw" },
  font19: { fontSize: "0.99vw" },
  font20: { fontSize: "1.04vw" },
  bold: { fontWeight: "bold"},
  uppercase: { textTransform: "uppercase"},
  noUppercase: { textTransform: "none"},
  hiddenOverflow: { overflow: "hidden" },
  paperShadow: { boxShadow: "0px 0.104vw 0.208vw -0.052vw rgba(0, 0, 0, 0.2), 0px 0.208vw 0.2604vw rgba(0, 0, 0, 0.14), 0px 0.052vw 0.52vw rgba(0, 0, 0, 0.12)" },
  btnShadow: { boxShadow: `0px 0.15625vw 0.4167vw ${onboardingPalette.primary.light}, 0px 0.15625vw 0.4167vw ${onboardingPalette.secondary.main}` },
  pointer: { cursor: "pointer" },
  scrollY: { overflowY: "scroll" },
  printEndMoveWizard: { transform: "translateX(8vw)" },
  tooltip: {
    background: "rgba(0, 0, 0, 0.7)",
    borderRadius: "0.52vw",
    "&::before": {
      content: "''",
      position: "absolute",
      top: "100%",
      left: "calc(50% - 0.52vw)",
      width: "0",
      height: "0",
      borderLeft: "0.52vw solid transparent",
      borderRight: "0.52vw solid transparent",
      borderTop: "0.364583vw solid rgba(0, 0, 0, 0.7)"
    }
  },
  btnHover: {
    '&:hover': {
      background: `${onboardingPalette.primary.semiDark}`
    }
  },
  backdropOpacity: { background: "rgba(0, 0, 0, 0.7)" },
  exitModal: {
    "&::before": {
      content: "''",
      position: "absolute",
      top: "calc(100% - 1px)",
      left: "85%",
      width: "0",
      height: "0",
      borderLeft: "2vw solid transparent",
      borderTop: "2vw solid white"
    }
  }
}));

export default onboardingStyles;
