import React from 'react';
import { FunctionField, useTranslate } from 'react-admin';

interface IProductFieldProps {
    label: string;
    record?:any;
}

export const ProductField: React.FC<IProductFieldProps> = (props) => {
  const translate = useTranslate();
  const products = {
    miniPack: translate('purchases.products.mini_pack'),
    plusPack: translate('purchases.products.plus_pack'),
    fullPack: translate('purchases.products.full_pack'),
    entrepreneur: translate('purchases.products.entrepreneur'),
    expansion: translate('purchases.products.expansion'),
    professional: translate('purchases.products.professional'),
  };
  return (
    // IF "record" IS PASSED AS PROP, WE DON'T NEED TO USE THE FUNCTION FIELD
    // LIKE SimpleList's CASE
    props.record ? 
    //@ts-ignore
    products[props.record.product] :
    <FunctionField
      {...props}
      render={(record:any) =>
        //@ts-ignore
        products[record.product]
      }
    />
  );
};

export const TypeField: React.FC<IProductFieldProps> = (props) => {
  const translate = useTranslate();
  const types = {
    subscription: translate('purchases.types.subscription'),
    pack: translate('purchases.types.pack'),
  };
  return (
    props.record ? 
    //@ts-ignore
    types[props.record.type] :
    <FunctionField
      {...props}
      render={(record:any) =>
        //@ts-ignore
        types[record.type]
      }
    />
  );
};
