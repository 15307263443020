import React, { useState } from 'react';
import { makeStyles, Button, CircularProgress} from '@material-ui/core';
import { useTranslate, useNotify } from 'react-admin';
import TitledDialog from '../../../components/TitledDialog';
import axios from "axios";
import config from '../../../config';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
}));


interface IDisableDialogProps {
  open: boolean;
  onClose: () => void;
  tag?: string;
}

const DisableDialog: React.FC<IDisableDialogProps> = ({
  tag,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const token = useSelector((state: ReduxState) => state.auth?.token);
  const onSubmit = () => {
    if (tag && !tag.includes("_disabled")){
      setIsLoading(true);
      axios.post(`${config.AWS_API}/tag/disableCode`, {
        code: tag,
      }, /*{
        headers: {
          Authorization: `Bearer ${token}`
        }
      }*/).then((r) => {
        notify(translate('tags.dialog.disable.successNotification'), "info");
        onClose();
        setIsLoading(false);
      }).catch((e) => {
        setIsLoading(false);
        notify(translate('tags.dialog.disable.failedNotification'), "info");
        console.error(e);
      });
    }else{
      notify(translate('tags.dialog.disable.alreadyDisabledNotification'), "info");
      onClose();
    }
    
  }
  return (
    <TitledDialog title={translate(`tags.dialog.disable.title`)} subtitle={translate(`tags.dialog.disable.subtitle`, { code: tag })} onClose={onClose} open={open}> 
        <div className={classes.buttons}>
          <Button disabled={isLoading} startIcon={isLoading ? <CircularProgress size="1rem" /> : undefined} color="primary" onClick={onSubmit}>{translate('tags.dialog.disable.confirm')}</Button>
          <Button color="primary" onClick={onClose}>{translate('tags.dialog.disable.cancel')}</Button>
        </div>
    </TitledDialog>
  );
};

export default DisableDialog;

export const useDisableDialog = () => {
  const [disableDialogIsOpen, setDisableDialogIsOpen] = useState(false);
  return {
    disableDialogIsOpen,
    disableDialogOnClick: () => setDisableDialogIsOpen(true),
    disableDialogOnClose: () => setDisableDialogIsOpen(false)
  };
};
