import React from 'react';
import {
  Datagrid,
  DateField,
  SimpleList,
  ListProps,
  ListBase,
  Pagination,
  TextField,
  //useTranslate,
  FunctionField,
  //NumberField,
} from 'react-admin';
import { parseTimestamp } from '../../../Utils';
// import StyledChip from '../../../components/StyledChip';
import UserField from '../../../components/UserField/simple';
import { dateFormatter } from '../../../Utils';

interface IReceiverListProps extends ListProps {
  isSmall?: boolean;
  classes: any;
}

const ReceiverList: React.FC<IReceiverListProps> = (props) => {
  const { isSmall, classes } = props;
  //const translate = useTranslate();
  return (
    <ListBase {...props} sort={{ field: 'last_activity', order: 'DESC' }}>
      {isSmall ? (
        <SimpleList
          className={classes.simpleList}
          linkType="show"
          primaryText={(record) => record?.receiver_name}
          secondaryText={(record) => record?.receiver_email}
          tertiaryText={(record) => parseTimestamp(record?.last_activity, navigator.language)}
        />
      ) : (
          <Datagrid optimized className={classes.datagrid} size="medium">
            <FunctionField<ReceiverResource> label="users.table.name" source="receiver_name" render={(record) => (<UserField identification={record?.receiver_name || "-"} />)} />
            <FunctionField<ReceiverResource> label="users.table.email" source="receiver_email" render={(record) => (<UserField identification={record?.receiver_email || "-"} />)} />
            <DateField
              label="users.table.last_activity"
              source="last_activity"
              locales={navigator.language}
            />
            <TextField
              label="users.table.greetings_amount"
              source="total_received"
            />
            {/* ADVANCED USERS FIELD */}
            {/* <FunctionField
            label="users.table.user"
            render={(record) => (
              <UserField
                name={record?.receiverName}
                email={record?.receiverEmail}
              />
            )}
          /> */}
            {/* <FunctionField
            label="users.table.gender"
            render={(record) => {
              switch (record?.receiver.gender) {
                case 'M':
                  return translate('genders.male');
                case 'F':
                  return translate('genders.female');
                default:
                  return translate('genders.other');
              }
            }}
          /> */}
            {/* <NumberField
            label="users.table.received_amount"
            source="gift.cost"
            style={{ fontWeight: 700 }}
            options={{ style: 'currency', currency: 'ARS' }}
          />
          <FunctionField
            label="users.table.tags"
            render={(record) =>
              record?.tags.map((tag: string) => (
                <StyledChip label={`users.tags.${tag}`} />
              ))
            }
          /> */}
          </Datagrid>
        )}
      <Pagination />
    </ListBase>
  );
};

export default ReceiverList;
