import { FLUSH_TOKEN, REGISTER_COMPLETE_UPDATE_DATA, TOKEN_LOADED } from '../config';
import jwt from 'jsonwebtoken';


const initialState = {
  token: false,
  decoded: {
    uid: '',
    clientID: '',
    iat: 0,
    role: 'REGULAR',
    email: '',
    exp: 0
  }
};

export default (previousState = initialState, { type, payload }: any) => {
  switch (type) {
    case FLUSH_TOKEN:
      localStorage.removeItem('token');
      return null;
    case TOKEN_LOADED:
      localStorage.setItem('token', payload);
      return {token: payload, decoded: jwt.decode(payload)};
    case REGISTER_COMPLETE_UPDATE_DATA:
      return {...previousState, decoded:{...previousState.decoded, registration_data:payload, state:"REGISTERED"}}
    default:
      return previousState;
  }
};
