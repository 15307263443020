/*
CHECKS AUTH STATE, PREVENTING APP SHOWING BEFORE AWS AND GOOGLE TOKENS ARE OBTAINED
IF USER IS NOT LOGGED, REDIRECTS TO LOGIN PAGE,
IF USER IS LOGGED, DISPLAYS A SPINNER UNTIL BOTH TOKENS ARE SAVED
*/

import React, { useState, useEffect } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { useCheckAuth, USER_LOGIN_SUCCESS, ReduxState } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import useQuery from '../../Utils/hooks/useQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.primary.dark,
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const DisplaySpinner: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress size={100} color="secondary" />
    </div>
  );
};

const AuthChecker: React.FC = () => {
  const checkAuth = useCheckAuth();
  const dispatch = useDispatch();
  const [isChecked, setisChecked] = useState(false);
  const query = useQuery();

  useEffect(() => {
    const mp_suscription_id = query.get('preapproval_id');
    if (mp_suscription_id) {
      sessionStorage.setItem('mp_suscription_id', mp_suscription_id);
      query.delete('mp_suscription_id');
    }

    checkAuth(false)
      .then(() => {
        setisChecked(true);
        dispatch({ type: USER_LOGIN_SUCCESS });
      })
      .catch((err: any) => console.log(err));
  }, [checkAuth, dispatch, setisChecked]);
  const token = useSelector((state: ReduxState) => state.auth?.token || false);
  return isChecked ? token ? <></> : <DisplaySpinner /> : <DisplaySpinner />;
};

export default AuthChecker;
