export default `<P>
Recolhemos, armazenamos, armazenamos, cumprimentamos mensagens (sob a forma de vídeos,
    imagens e texto) e endereços de correio electrónico. Fazemos isto
    em nome dos nossos clientes (marcas bem conhecidas e pequenas lojas) para a
    lojas) para o benefício dos seus clientes que utilizam a nossa solução.</P>
<P>
Naturalmente, preocupamo-nos profundamente com a privacidade dos seus dados e cumprimos as últimas
e cumprimos a mais recente legislação GDPR.</P>
<P>
Eis uma breve descrição dos procedimentos que temos em vigor para assegurar que os seus dados são protegidos como devem ser.
para assegurar que os seus dados são protegidos como devem ser.</P>
<P>
<B>O que recolhemos</B>
</P>
<P>
O mínimo exigido para que a experiência StoryDots funcione correctamente é uma mensagem de vídeo, um nome e uma
é uma mensagem vídeo, um nome e um endereço electrónico da pessoa que está a gravar a mensagem.
endereço de e-mail da pessoa que regista a mensagem. Além disso, se o destinatário optar por responder com uma fotografia de si mesmo
se o destinatário optar por responder com uma fotografia de si mesmo, pedimos as mesmas informações
as mesmas informações, a fim de as entregar.
</P>
<P>
Com base na operação e nas diferentes necessidades ou exigências dos nossos clientes, podemos ocasionalmente
requisitos dos nossos clientes, podemos ocasionalmente pedir alguns dados extra.
extra. Além disso, a aceitação dos nossos termos de utilização é, evidentemente, necessária para o StoryDots
claro, necessário para que a experiência StoryDots funcione.</P>
<P>
<B>Por que</B>
</P>
<P>
Como mencionado acima, precisamos pelo menos do nome e endereço de e-mail da pessoa que criou a mensagem por 3 razões
e-mail da pessoa que criou a mensagem, por 3 razões:</P>
<P>
1- Personalizar a experiência com o nome do remetente.
</P>
<P>
2- Proporcionar a possibilidade de regravar a mensagem se necessário.
necessário.</P>
<P>
3- Permitir que o beneficiário responda também com uma experiência personalizada.
experiência.</P>
<P>
4- Notificar o remetente da primeira saudação quando responder.</P>

<P>
<B>O que fazemos com os dados que recolhemos</B>
</P>
<P>
Analisamos os dados para obter perfis de consumidor, sempre com a ideia de melhorar a qualidade da publicidade que recebe e oferecer-lhe o melhor serviço possível.
para melhorar a qualidade da publicidade que recebem e oferecer o melhor para eles à direita
o melhor para eles na altura certa, partilhando estes elementos de dados com os nossos clientes
apenas com os nossos clientes para fins de análise e publicidade.
fins publicitários.</P>
<P>
Ocasionalmente, capturamos e estudamos sessões de utilizadores a fim de compreender como a nossa interface é percebida e utilizada, para que possamos
para compreender como a nossa interface é percebida e utilizada, para que possamos sempre melhorá-la.
para que possamos sempre melhorá-lo.
</P>
<P>
<B>O que não fazemos</B>
</P>
<P>
Não partilhamos nem exibimos o conteúdo das mensagens criadas.
Cada mensagem é única e só pode ser acedida através do QR ou do link de partilha.
QR ou link de partilha.</P>
<P>
Não revendemos os dados que recolhemos. Os nossos clientes têm
acesso através da nossa plataforma de onde também podem ver estatísticas e configurar
estatísticas e criar anúncios.</P>
<P>
Não enviamos boletins informativos. Sabemos como isso é irritante e
o nosso objectivo é o oposto, melhorar a qualidade do que se recebe.
que recebe.</P>
<P><B>Onde está
os dados são armazenados?</B></P>
<P>A nossa plataforma
está actualmente alojado na Amazon Web Services
(https://aws.amazon.com/) e juntamente com os seus respectivos dados, contando com os melhores
com os melhores padrões de segurança.</P>
<P><BR>
</P>
<P><B>Pedido
o cancelamento dos seus dados</B></P>
<P>Os vídeos são armazenados no
armazenados na nuvem e podem ser vistos durante um mês (a partir do momento da gravação).
o tempo começa a correr a partir do momento em que é gravado), uma vez atingido este período, os vídeos são
é atingido, são completamente apagados sem qualquer registo e são irrecuperáveis.
irremediável.</P>
<P>Como usuário (não como
cliente) do nosso serviço, pode enviar um e-mail para
support@storydots.app e solicite a eliminação do seu
dados.<BR>A StoryDots reserva-se o direito de solicitar a prova de identidade com antecedência.
prova de identidade com antecedência.</P>`