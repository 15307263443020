//@ts-nocheck
import React from 'react';
import StyledChip from '../StyledChip';

interface IStatusFieldProps {
  status: any;
  negativeMargin?: number;
}

export const TagStatusField: React.FC<IStatusFieldProps> = ({ status }) => {
  const colorsByStatus = {
    Pending:'primary',
    Sent:'tertiary',
    Received:'secondary',
    Responded:'quaternary',
  };
  const labelsByStatus = {
    Pending:'tag_status_chips.pending',
    Sent:'tag_status_chips.sent',
    Received:'tag_status_chips.received',
    Responded:'tag_status_chips.responded',
  };
  return (
    <StyledChip color={colorsByStatus[status]} label={labelsByStatus[status]} />
  );
};

export const BooleanStatusField: React.FC<IStatusFieldProps> = ({ status }) => {
  return (
    // 'Primary' one alters "Pending status"
    <StyledChip color={status ? 'quaternary' : 'tertiary'} label={status ? 'tag_status_chips.ready' : 'tag_status_chips.pending' } />
  );
};


//only 2 style variants, having 4 different states
export const TagStatusAlt: React.FC<IStatusFieldProps> = ({ status }) => {
  const colorsByStatus = {
    Pending:'tertiary',
    Ready:'secondary',
  };
  const labelsByStatus = {
    Pending:'tag_status_chips.pending',
    Ready:'tag_status_chips.ready',
  };
  return (
    <StyledChip color={colorsByStatus[status]} label={labelsByStatus[status]} />
  );
};


export const VideoStatusField: React.FC<IStatusFieldProps> = ({ status, negativeMargin }) => {
  const colorsByStatus = {
    Pending:'tertiary',
    Ready:'secondary',
  };
  const labelsByStatus = {
    Pending:'video_status_chips.pending',
    Ready:'video_status_chips.ready',
  };
  return (
    <StyledChip color={colorsByStatus[status]} label={labelsByStatus[status]} negativeMargin={negativeMargin}/>
  );
};


export const ResponseStatusField: React.FC<IStatusFieldProps> = ({ status }) => {
  const colorsByStatus = {
    Pending:'tertiary',
    Text:'secondary',
    Selfie:'quaternary',
    Ready:'secondary'
  };
  const labelsByStatus = {
    Pending:'response_status_chips.pending',
    Text:'response_status_chips.text',
    Selfie:'response_status_chips.selfie',
    //temporary fix to missing label
    Ready:'video_status_chips.ready'
  };
  return (
    <StyledChip color={colorsByStatus[status]} label={labelsByStatus[status]} />
  );
};

export const PurchaseStatusField: React.FC<IStatusFieldProps> = ({ status, negativeMargin }) => {
  const colorsByStatus = {
    Pending:'tertiary',
    Approved:'secondary',
    Rejected:'primary',
  };
  const labelsByStatus = {
    Pending:'purchase_status_chips.pending',
    Approved:'purchase_status_chips.aproved',
    Rejected:'purchase_status_chips.rejected',
  };
  return (
    <StyledChip color={colorsByStatus[status]} label={labelsByStatus[status]} negativeMargin={negativeMargin}/>
  );
};
