import React, { useContext } from 'react';
import { OnboardingContext } from '../context/OnboardingContext';

function SlideBG ({ BGImage, content }:any) {
  const { styles } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const { manrope, noRepeatBG, BGw100, pRelative, w100, h100, dFlex, alignCenter, justifyCenter, mAuto } = onboardingStyles();

  return (
    <div 
      className={[manrope, noRepeatBG, BGw100, pRelative, w100, h100, mAuto, dFlex, alignCenter, justifyCenter].join(' ')}
      style={{height: "49.48vw", backgroundImage: `url(${BGImage})`}}
    >
      {content}
    </div>
  );
}

export default SlideBG;